import React from "react";
import firebase from "firebase";
import { Formik, Form } from "formik";
import { remove, map } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import FormFooter from "../../../components/form-footer/form-footer";
import useStoreProvider from "../../../common/providers/store/use-app-context";
import { db, PARTICIPANT_ROLES } from "../../../constants";

import { useIntl } from "react-intl";
import messages from '../../../i18n/messages';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: "100%",
  },
  formContainer: {
    marginBottom: spacing(3),
  },
  formStatusAndActionContainer: {
    paddingTop: spacing(2),
  },
  actionContainer: {
    "& button:last-child": {
      marginLeft: spacing(3),
    },
  },
  menuPaper: {
    maxHeight: 400,
  },
  progressIndicator: {
    display: "flex",
    alignItems: "center",
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
}));

const AddProjectParticipantForm = ({ onClose, participantToEdit }) => {
  const classes = useStyles();
  const { loggedInUser, selectedProject, projectParticipants } =
    useStoreProvider();

  const uidOfProjectParticipants = map(projectParticipants, "userInfo.uid");

  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [allUsers, setAllUsers] = React.useState([]);
  const [rolesOfParticipant, setRolesOfParticipant] = React.useState(
    participantToEdit?.roles || []
  );
  const [userInfo, setUserInfo] = React.useState(
    participantToEdit?.userInfo || {
      uid: "",
      fname: "",
      lname: "",
    }
  );

  const [userRoleCheckState, setUserRoleCheckState] = React.useState(
    PARTICIPANT_ROLES.reduce((acc, role) => {
      let { key } = role;
      return { ...acc, [key]: rolesOfParticipant.indexOf(role.key) >= 0 };
    }, {})
  );

  const projectParticipantsCollectionRef = db
    .collection("projects")
    .doc(`${selectedProject?.id}`)
    .collection("participants");

  const getUsersInfo = React.useCallback(async () => {
    const userInfoCollectionSnapshot = await db
      .collection("user-info")
      .orderBy("fname", "asc")
      .where("active", "==", true)
      .get();
    const usersData = userInfoCollectionSnapshot.docs.map((doc) => doc.data());
    setAllUsers(usersData);
  }, []);

  const handleUserRoleChange = React.useCallback(
    (event) => {
      const userRoles = rolesOfParticipant;
      const isChecked = event.target.checked;
      const role = event.target.name;
      if (isChecked) {
        userRoles.push(event.target.name);
      } else {
        remove(userRoles, (userRole) => {
          return userRole === role;
        });
      }
      setRolesOfParticipant(userRoles);
      setUserRoleCheckState({
        ...userRoleCheckState,
        [event.target.name]: event.target.checked,
      });
    },
    [rolesOfParticipant, userRoleCheckState]
  );

  const handleUserChange = (event) => {
    const uid = event.target.value;
    const userData = allUsers.filter((user) => user.uid === uid);
    if (userData.length) {
      const { uid, fname, lname } = userData[0];
      setUserInfo({ uid, fname, lname });
    }
  };

  const handleCancel = () => {
    setRolesOfParticipant(rolesOfParticipant);
    onClose();
  };

  const initialValue = {
    userInfo,
    rolesOfParticipant,
  };

  const intl = useIntl();

  React.useEffect(() => {
    getUsersInfo();
  }, [getUsersInfo]);

  return (
    (<Formik
      initialValues={initialValue}
      onSubmit={(values, { resetForm }) => {
        const participantInfo = {
          userInfo,
          rolesOfParticipant,
          createdBy: loggedInUser?.uid,
          createdAt: firebase.firestore.Timestamp.now(),
        };
        try {
          if (userInfo?.uid === "" || rolesOfParticipant.length === 0) {
            throw new Error("Wrong input. Please select user and user's role");
          }
          setSubmitting(true);
          setSubmitted(false);
          projectParticipantsCollectionRef
            .doc(`${userInfo.uid}`)
            .set(participantInfo)
            .then(() => {
              resetForm();
              setSubmitting(false);
              setSubmitted(true);
              setUserRoleCheckState([]);
              setRolesOfParticipant([]);
            })
            .catch((error) => {
              setSubmitting(false);
              setErrorMessage(intl.formatMessage(messages.somethingWentWrong));
              console.log(`Add project participant:: ${error}`);
            });
        } catch (error) {
          setErrorMessage(intl.formatMessage(messages.somethingWentWrong));
          console.log(`Add project participant:: ${error}`);
        }
      }}
    >
      {() => (
        <Form className={classes.root}>
          <Grid container spacing={4} className={classes.formContainer}>
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth margin="normal" size="small">
                <InputLabel>
                  {intl.formatMessage(messages.user)}
                </InputLabel>
                <Select
                  required
                  fullWidth
                  margin="normal"
                  autoComplete="off"
                  disabled={!!participantToEdit?.userInfo?.uid}
                  value={userInfo.uid}
                  onChange={handleUserChange}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  <MenuItem value="">
                    <em>
                      {intl.formatMessage(messages.none)}
                    </em>
                  </MenuItem>
                  {allUsers
                    .filter(
                      (user) =>
                        uidOfProjectParticipants.indexOf(user?.uid) < 0 ||
                        !!participantToEdit?.userInfo?.uid
                    )
                    .map((user, index) => (
                      <MenuItem key={index} value={user.uid}>
                        {user.fname} {user.lname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormLabel fullWidth margin="normal">
                {intl.formatMessage(messages.participatingAs)}
              </FormLabel>
              <Box display="flex" flexDirection="column">
                {PARTICIPANT_ROLES.map((role) => {
                  const label = intl.formatMessage(messages[role.localizationKey]);
                  return (
                    <FormControlLabel
                      label={label}
                      key={role.key}
                      control={
                        <Checkbox
                          checked={userRoleCheckState[role?.key]}
                          name={role.key}
                          onChange={handleUserRoleChange}
                        />
                      }
                    />
                  );
                })}
              </Box>
            </Grid>
          </Grid>
          <FormFooter
            submitting={submitting}
            submitted={submitted}
            submitButtonText={
              participantToEdit?.userInfo?.uid
                ? intl.formatMessage(messages.saveChanges)
                : intl.formatMessage(messages.addParticipant)
            }
            cancelButtonText={intl.formatMessage(messages.close)}
            cancelButtonProps={{
              onClick: () => {
                handleCancel();
              },
            }}
            submitButtonProps={{
              type: "submit",
              disabled: !rolesOfParticipant?.length || !userInfo?.uid,
            }}
            progressMessage={
              participantToEdit?.userInfo?.uid
                ? intl.formatMessage(messages.savingChanges)
                : intl.formatMessage(messages.addingUserToProject)
            }
            successMessage={
              participantToEdit?.userInfo?.uid
                ? intl.formatMessage(messages.changesSaved)
                : intl.formatMessage(messages.userAddedToProject)
            }
            errorMessage={errorMessage}
          />
        </Form>
      )}
    </Formik>)
  );
};

export default AddProjectParticipantForm;
