import React from "react";
import Box from "@material-ui/core/Box";

import TrashedWeldLogList from "../weld-log-list/weld-logs-trashed";

const TrashBin = () => {
  return (
    <Box width="100%">
      <TrashedWeldLogList />
    </Box>
  );
};

export default TrashBin;
