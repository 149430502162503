import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import notFound from "../../assets/not-found.svg";

import { useIntl } from "react-intl";
import messages from '../../i18n/messages';

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    width: "100%",
    flex: 100,
    display: "flex",
    flexDirection: "column",
    placeContent: "center",
    alignItems: "center",
  },
  titleSection: {
    display: "flex",
    color: "gray",
    fontSize: "34px",
    alignItems: "center",
    marginBottom: spacing(5),
    height: spacing(5),
  },
  titleSeparator: {
    marginRight: "20px",
    marginLeft: "20px",
  },
}));
const PageNotFound = () => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    (<Box className={classes.container}>
      <Box display="flex" flexDirection="row" className={classes.titleSection}>
        <Typography variant="subtitle2">
          {intl.formatMessage(messages.errorCode)}
        </Typography>
        <Divider orientation="vertical" className={classes.titleSeparator} />
        <Typography variant="subtitle2">
          {intl.formatMessage(messages.pageCouldNotBeFound)}
        </Typography>
      </Box>
      <img src={notFound} width={500} alt="logo" />
    </Box>)
  );
};

export default PageNotFound;
