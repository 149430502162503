import React from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

// Material UI
import ProjectParticipantsList from "./project-participants-list";
import AddProjectParticipantsDialog from "./add-project-participants-dialog";

// Hooks
import useStoreProvider from "../../../common/providers/store/use-app-context";
import useFetchCollection from "../../../common/utils/use-fetch-collection-v2";

// Utilities and constants
import { db } from "../../../constants";
import messages from "../../../i18n/messages";

const ProjectParticipants = () => {
  const intl = useIntl();
  const { pid } = useParams();
  const [open, setOpen] = React.useState(false);
  const [participantToEdit, setParticipantToEdit] = React.useState({});

  const { projectParticipants, setProjectParticipants } = useStoreProvider();
  const {
    fetching: projectParticipantsFetching,
    error: projectParticipantsError,
    data: projectParticipantsData,
    listenToCollectionUpdates,
  } = useFetchCollection();

  const projectParticipantsCollectionRef = React.useMemo(
    () => db.collection("projects").doc(`${pid}`).collection("participants"),
    [pid]
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleProjectParticipantEditOpen = (participantToEdit) => {
    if (participantToEdit) {
      setParticipantToEdit(participantToEdit);
    }
    setOpen(true);
  };

  const buttonProps = {
    color: "primary",
    variant: "contained",
    label: intl.formatMessage(messages.addParticipants),
    onClick: handleProjectParticipantEditOpen,
  };

  React.useEffect(() => {
    // Listen to collection updates and cleanup listener on component unmount
    const unsubscribe = listenToCollectionUpdates(projectParticipantsCollectionRef);
    return () => unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (projectParticipantsData.length > 0) {
      const formattedParticipants = projectParticipantsData.map((doc) => ({
        roles: doc.rolesOfParticipant,
        userInfo: doc.userInfo,
      }));
      setProjectParticipants(formattedParticipants);
    }
  }, [projectParticipantsData, setProjectParticipants]);

  return (
    <>
      <ProjectParticipantsList
        buttonProps={buttonProps}
        fetching={projectParticipantsFetching}
        error={projectParticipantsError}
        projectParticipants={projectParticipants || []}
        onOpen={handleProjectParticipantEditOpen}
      />
      <AddProjectParticipantsDialog
        open={open}
        onClose={handleClose}
        participantToEdit={participantToEdit}
      />
    </>
  );
};

export default ProjectParticipants;
