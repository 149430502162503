import React from "react";
import * as yup from "yup";
import firebase from "firebase";
import { isEmpty } from "lodash";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useFormik, FieldArray, FormikProvider } from "formik";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";

// Icons
import IconButton from "@material-ui/core/IconButton";
import LinkIcon from "@material-ui/icons/Link";
import { MdOutlineDelete } from "react-icons/md";
import { HiOutlineDocumentText } from "react-icons/hi";

import Title from "../../../components/title/title";
import CustomCard from "../../../components/custom-card/custom-card";
import FormFooter from "../../../components/form-footer/form-footer";
import DocumentLinkDialog from "../../../components/document-link-dialog/document-link-dialog";

// Hooks
import useStoreProvider from "../../../common/providers/store/use-app-context";

// Utilities and constants
import messages from "../../../i18n/messages";
import { db, NOTIFICATION_POSITION } from "../../../constants";

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  form: {
    width: "100%",
    marginTop: spacing(5),
  },
  formContainer: {
    marginBottom: spacing(3),
  },
  gridItem: {
    [breakpoints.down("md")]: {
      padding: `${spacing(0, 4)} !important`,
    },
  },
  documentIcon: {
    fontSize: spacing(2.4),
  },
  menuIcon: {
    fontSize: spacing(2.5),
    color: palette.secondary.dark,
  },
}));

const validationSchema = (localizedString) =>
  yup.object({
    weldLogName: yup.string().required(localizedString),
  });

const AddWeldLog = () => {
  const intl = useIntl();
  const classes = useStyles();
  const location = useLocation();
  const { selectedProject } = useStoreProvider();

  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [indexOfDocumentToLink, setIndexOfDocumentToLink] = React.useState();

  const weldLogData = location?.state?.weldLogData ? location?.state?.weldLogData : {};

  const weldLogCollectionRef = db
    .collection("projects")
    .doc(selectedProject?.id)
    .collection("weld-logs");

  let weldLogId = weldLogCollectionRef.doc().id;

  let initialValues = {
    id: "",
    weldLogName: "",
    status: "active",
    description: "",
    linkedDocuments: [{ name: "", storageRef: "" }],
    documentDescription: "",
  };

  // This happens when editing a project
  if (!isEmpty(weldLogData)) {
    initialValues = weldLogData;
    weldLogId = weldLogData?.id;
  }

  const handleClickOpen = (documentIndex) => {
    // Get index of the document to update its content with document name and storageRef
    setIndexOfDocumentToLink(documentIndex);
    setOpen(true);
  };

  const handleClose = (value) => {
    if (value) {
      formik.values.linkedDocuments[indexOfDocumentToLink] = value;
    }
    setOpen(false);
  };

  const successMessage = intl.formatMessage(messages.weldLogCreatedSuccessfully);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(intl.formatMessage(messages.projectIsRequired)),
    onSubmit: (values, { resetForm }) => {
      setSubmitting(true);
      setSubmitted(false);

      weldLogCollectionRef
        .doc(weldLogId)
        .set({
          ...values,
          ...(isEmpty(weldLogData) && {
            id: weldLogId,
            createdAt: firebase.firestore.Timestamp.now(),
          }),
          ...(!isEmpty(weldLogData) && {
            updatedAt: firebase.firestore.Timestamp.now(),
          }),
        })
        .then(() => {
          isEmpty(weldLogData) && resetForm({});
          setSubmitting(false);
          setSubmitted(true);
          toast.success(successMessage, {
            position: NOTIFICATION_POSITION,
          });
        })
        .catch((error) => {
          const errorMessage = intl.formatMessage(messages.somethingWentWrong);
          setSubmitting(false);
          setErrorMessage(errorMessage);
          toast.error(errorMessage, {
            position: NOTIFICATION_POSITION,
          });
          console.log("AddWeldLog::", error);
        });
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  // TODO: extract to a separate component. There are duplicates of the same code
  const CustomInputText = () => (
    <FieldArray
      name="linkedDocuments"
      render={(arrayHelpers) => (
        <div>
          {formik.values.linkedDocuments?.length > 0 &&
            formik.values.linkedDocuments.map((linkedDocument, index) => {
              const color = formik.values.linkedDocuments[index].name
                ? "#000"
                : "rgba(0, 0, 0, 0.38)";
              return (
                <div className="row" key={index}>
                  <TextField
                    fullWidth
                    margin="normal"
                    name={`linkedDocuments.[${index}].name`}
                    autoComplete="off"
                    label={intl.formatMessage(messages.linkedDocument)}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    InputProps={{
                      style: { color: color },
                      endAdornment: (
                        <>
                          <InputAdornment position="end">
                            <IconButton onClick={() => handleClickOpen(index)}>
                              <LinkIcon color="primary" />
                            </IconButton>
                          </InputAdornment>
                          <Divider orientation="vertical" flexItem />
                          <InputAdornment position="end">
                            <IconButton onClick={() => arrayHelpers.remove(index)}>
                              <MdOutlineDelete className={classes.menuIcon} />
                            </IconButton>
                          </InputAdornment>
                        </>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <HiOutlineDocumentText className={classes.documentIcon} />
                        </InputAdornment>
                      ),
                    }}
                    disabled
                    onChange={formik.handleChange}
                    value={
                      formik.values.linkedDocuments[index].name ||
                      intl.formatMessage(messages.noDocumentLinked)
                    }
                  />
                </div>
              );
            })}
          <Button
            color="primary"
            variant="contained"
            disableElevation
            className={classes.submit}
            onClick={() => arrayHelpers.push({ name: "", storageRef: "" })}
          >
            {intl.formatMessage(messages.add)}
          </Button>

          <DocumentLinkDialog open={open} onClose={handleClose} />
        </div>
      )}
    />
  );

  const title = !isEmpty(weldLogData)
    ? intl.formatMessage(messages.save)
    : intl.formatMessage(messages.addWeldLog);

  return (
    <Box width="100%">
      <Title title={title} />
      <CustomCard
        footer={
          <FormFooter
            submitting={submitting}
            submitted={submitted}
            submitButtonText={title}
            cancelButtonText={intl.formatMessage(messages.close)}
            handleSubmit={handleSubmit}
            cancelButtonProps={{
              to: `/projects/${selectedProject?.id}/weld-logs`,
            }}
            progressMessage={intl.formatMessage(messages.creatingWeldLog)}
            successMessage={intl.formatMessage(messages.weldLogCreatedSuccessfully)}
            errorMessage={errorMessage}
          />
        }
      >
        <FormikProvider value={formik}>
          <form className={classes.form}>
            <Grid container spacing={5} className={classes.formContainer}>
              <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={4}>
                <TextField
                  fullWidth
                  autoFocus
                  margin="normal"
                  id="weldLogName"
                  label={intl.formatMessage(messages.weldLogName)}
                  name="weldLogName"
                  autoComplete="off"
                  value={formik.values.weldLogName}
                  onChange={formik.handleChange}
                  error={formik.touched.weldLogName && Boolean(formik.errors.weldLogName)}
                  helperText={formik.touched.weldLogName && formik.errors.weldLogName}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  id="description"
                  label={intl.formatMessage(messages.description)}
                  name="description"
                  autoComplete="off"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={4}>
                <CustomInputText />
              </Grid>
              <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={4}>
                <TextField
                  fullWidth
                  margin="normal"
                  id="documentDescription"
                  label={intl.formatMessage(messages.internalCommentOnDocuments)}
                  name="documentDescription"
                  autoComplete="off"
                  value={formik.values.documentDescription}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.documentDescription && Boolean(formik.errors.documentDescription)
                  }
                  helperText={
                    formik.touched.documentDescription && formik.errors.documentDescription
                  }
                  InputLabelProps={{ style: { fontSize: 14 } }}
                />
              </Grid>
            </Grid>
          </form>
        </FormikProvider>
      </CustomCard>
    </Box>
  );
};

export default AddWeldLog;
