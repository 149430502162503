import React from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

// Material UI
import { Box } from "@material-ui/core";

// Components
import Title from "../../../components/title/title";
import StateCard from "../../../components/state-card/state-card";
import NdtOrderDetailsContent from "./ndt-order-details-content";
import NdtOrderWeldsTable from "./ndt-order-welds-table";

// Hooks
import useFetchWeldsAndLogs from "../hooks/use-fetch-welds-and-logs";
import useFetchDocument from "../../../common/utils/use-fetch-document-v2";
import useStoreProvider from "../../../common/providers/store/use-app-context";

// Constants and utilities
import { calculateNdtInspections } from "../utils/calculate-ndt-inspections";
import { db, NDT_ORDER_STATUSES } from "../../../constants";
import messages from "../../../i18n/messages";

const NdtOrderDetails = () => {
  const intl = useIntl();
  const { pid, ndtOrderId } = useParams();
  const { selectedProject, setSelectedProject } = useStoreProvider();
  const { data: projectData, getDocument: fetchProjectDetail } = useFetchDocument();
  const {
    fetching: ndtOrderFetching,
    error: ndtOrderFetchError,
    data: selectedNdtOrder,
    getDocument,
  } = useFetchDocument();

  const {
    fetching: weldAndLogsFetching,
    data: { welds, weldLogs },
    error: weldAndLogsFetchError,
  } = useFetchWeldsAndLogs(selectedNdtOrder);

  const [weldsInNdtOrder, setWeldsInNdtOrder] = React.useState([]);
  const [completedInspectionPerNdtMethod, setCompletedInspectionPerNdtMethod] =
    React.useState(null);
  const [completedInspectionPerNdtOrder, setCompletedInspectionPerNdtOrder] = React.useState(null);
  const [overallCompletionPercentage, setOverallCompletionPercentage] = React.useState(null);
  const [overallCompletion, setOverallCompletion] = React.useState(null);
  const [isAnyMethodRejected, setIsAnyMethodRejected] = React.useState(false);

  const fetching = ndtOrderFetching || weldAndLogsFetching;

  const addWeldToNdtOrderButtonProps = React.useMemo(
    () => ({
      color: "primary",
      variant: "contained",
      label: intl.formatMessage(messages.goToWeldLogsPage),
      to: `/projects/${pid}/weld-logs`,
    }),
    [pid, intl]
  );

  const projectDocumentRef = React.useMemo(() => db.collection("projects").doc(pid), [pid]);
  const ndtOrderDocumentRef = React.useMemo(
    () => db.collection("projects").doc(pid).collection("ndt-orders").doc(ndtOrderId),
    [pid, ndtOrderId]
  );

  const breadcrumbsNav = [
    { label: "Projects", to: "/" },
    { label: selectedProject?.projectName, to: `/projects/${pid}` },
    { label: "NDT Orders", to: `/projects/${pid}/ndt-orders` },
    { label: selectedNdtOrder?.ndtOrderName, to: `/projects/${pid}/ndt-orders` },
  ];

  const mergeWeldsWithLogDetails = (welds, weldLogs) => {
    if (welds.length === 0 || weldLogs.length === 0) {
      return [];
    }

    const weldsInNdtOrder = welds.map((weld) => {
      const correspondingWeldLog = weldLogs.find((weldLog) => weldLog.id === weld.weldLogId);

      return {
        ...weld,
        weldLogId: correspondingWeldLog?.id,
        weldLogName: correspondingWeldLog?.weldLogName,
      };
    });
    setWeldsInNdtOrder(weldsInNdtOrder);
  };

  const handleWeldRemovalFromNdtOrder = (deletedWeldIds) => {
    setWeldsInNdtOrder((prevWelds) =>
      prevWelds.filter((weld) => !deletedWeldIds.includes(weld.id))
    );
  };

  // Fetch project details on page refresh
  React.useEffect(() => {
    if (!selectedProject) {
      fetchProjectDetail(projectDocumentRef);
      setSelectedProject(projectData);
    }
  }, [fetchProjectDetail, projectData, projectDocumentRef, selectedProject, setSelectedProject]);

  // Fetch NDT order
  React.useEffect(() => {
    if (!selectedNdtOrder || selectedNdtOrder.id !== ndtOrderId) {
      getDocument(ndtOrderDocumentRef);
    }
  }, [ndtOrderId, selectedNdtOrder, getDocument, ndtOrderDocumentRef]);

  // Merge welds with log details
  React.useEffect(() => {
    mergeWeldsWithLogDetails(welds, weldLogs);
  }, [welds, weldLogs]);

  // Calculate NDT progress
  React.useEffect(() => {
    if (weldsInNdtOrder.length === 0) {
      return;
    }

    const {
      completedInspectionPerNdtOrder,
      completedInspectionPerNdtMethod,
      overallCompletionPercentage,
      overallCompletion,
      isAnyMethodRejected,
    } = calculateNdtInspections(selectedNdtOrder, weldsInNdtOrder);

    setOverallCompletion(overallCompletion);
    setIsAnyMethodRejected(isAnyMethodRejected);
    setOverallCompletionPercentage(overallCompletionPercentage);
    setCompletedInspectionPerNdtOrder(completedInspectionPerNdtOrder);
    setCompletedInspectionPerNdtMethod(completedInspectionPerNdtMethod);
  }, [
    intl,
    fetching,
    selectedNdtOrder,
    weldsInNdtOrder,
    ndtOrderDocumentRef,
    selectedNdtOrder?.ndtProgress,
  ]);

  return (
    <Box width="100%">
      <Box mb={2}>
        <Title
          title={intl.formatMessage(messages.ndtOrderDetails)}
          breadcrumbsNav={breadcrumbsNav}
        />
        <NdtOrderDetailsContent
          fetching={ndtOrderFetching}
          error={ndtOrderFetchError}
          ndtOrder={selectedNdtOrder}
          ndtOrderDocumentRef={ndtOrderDocumentRef}
          completedInspectionPerNdtMethod={completedInspectionPerNdtMethod}
          completedInspectionPerNdtOrder={completedInspectionPerNdtOrder}
          overallCompletionPercentage={overallCompletionPercentage}
          overallCompletion={overallCompletion}
          isAnyMethodRejected={isAnyMethodRejected}
        />
      </Box>

      <>
        {weldsInNdtOrder?.length ? (
          <NdtOrderWeldsTable
            fetching={weldAndLogsFetching}
            error={weldAndLogsFetchError}
            ndtOrder={selectedNdtOrder}
            weldsInNdtOrder={weldsInNdtOrder}
            onWeldRemove={handleWeldRemovalFromNdtOrder}
          />
        ) : selectedNdtOrder?.ndtStatus === NDT_ORDER_STATUSES.compiling.status ? (
          <StateCard
            title={intl.formatMessage(messages.compilingNdtOrder)}
            description={intl.formatMessage(messages.NdtOrderWillBeReadyOnce)}
            buttonProps={addWeldToNdtOrderButtonProps}
          />
        ) : (
          <StateCard
            title={intl.formatMessage(messages.noWeldsInNdtOrder)}
            description={intl.formatMessage(messages.startByAddingWeld)}
            buttonProps={addWeldToNdtOrderButtonProps}
          />
        )}
      </>
    </Box>
  );
};

export default NdtOrderDetails;
