import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import StateCard from "../../../components/state-card/state-card";

import { useIntl } from "react-intl";
import messages from '../../../i18n/messages';

const style = {
  width: "100%",
  maxWidth: 360,
  backgroundColor: "background.paper",
};

const TemplateDetails = ({ documentSections, isGenericTemplate }) => {
  const intl = useIntl();

  return (
    (<List sx={style}>
      {isGenericTemplate && (
        <StateCard
          title={intl.formatMessage(messages.genericTemplate)}
          description={intl.formatMessage(messages.genericTemplateDescription)}
          showButton={false}
          border={false}
        />
      )}
      {documentSections.map((section) => (
        <>
          <ListItem>
            <ListItemText primary={section.name} />
          </ListItem>
        </>
      ))}
    </List>)
  );
};

export default TemplateDetails;
