import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

// Icons
import { LuPencilLine } from "react-icons/lu";
import { MdOutlineCopyAll } from "react-icons/md";
import { TbArchive } from "react-icons/tb";
import { TbArchiveOff } from "react-icons/tb";
import { MdOutlineDelete } from "react-icons/md";

// Hooks
import useDuplicateDocument from "../hooks/duplicate-project";
import useUpdateDocument from "../../../common/utils/use-update-document";

// Utilities and constants
import messages from "../../../i18n/messages";
import { db } from "../../../constants";

const useStyles = makeStyles(({ spacing, palette }) => ({
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuItemHeight: {
    height: 45,
    display: "flex",
    alignItems: "center",
  },
  menuIcon: {
    fontSize: spacing(2.5),
    marginRight: spacing(2),
    color: palette.secondary.dark,
  },
  menuIconSmaller: {
    fontSize: spacing(2.3),
    marginRight: spacing(2),
    color: palette.secondary.dark,
  },
}));

const ProjectListItemMenu = ({ projectData }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { updateDocument } = useUpdateDocument();
  const { duplicateDocument } = useDuplicateDocument(projectData);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDuplicateProject = () => {
    duplicateDocument();
    handleClose();
  };

  const updateProject = React.useCallback(
    (status) => {
      const documentRef = db.collection("projects").doc(projectData?.id);
      updateDocument({
        documentRef,
        payload: { status },
      });
    },
    [projectData?.id, updateDocument]
  );

  const activeProject = projectData.status === "active";
  const archivedProject = projectData.status === "archived";

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: "0px" }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        open={open}
        keepMounted
        elevation={5}
        anchorEl={anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            width: 160,
            boxShadow: "none",
            border: "1px solid #e3e3e3",
          },
        }}
        classes={{ list: classes.menuList }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {activeProject && (
          <MenuItem
            classes={{ root: classes.menuItemHeight }}
            onClick={handleClose}
            component={Link}
            to={{
              pathname: `/projects/${projectData.id}/edit`,
              state: { projectData },
            }}
          >
            <LuPencilLine className={classes.menuIconSmaller} />
            <Typography display="block">{intl.formatMessage(messages.edit)}</Typography>
          </MenuItem>
        )}

        <MenuItem classes={{ root: classes.menuItemHeight }} onClick={handleDuplicateProject}>
          <MdOutlineCopyAll className={classes.menuIconSmaller} />
          <Typography display="block">{intl.formatMessage(messages.duplicate)}</Typography>
        </MenuItem>

        {activeProject && (
          <Box>
            <MenuItem
              classes={{ root: classes.menuItemHeight }}
              onClick={() => updateProject("archived")}
            >
              <TbArchive className={classes.menuIconSmaller} />
              <Typography display="block">{intl.formatMessage(messages.archive)}</Typography>
            </MenuItem>
            <Divider />
          </Box>
        )}
        {archivedProject && (
          <Box>
            <MenuItem
              classes={{ root: classes.menuItemHeight }}
              onClick={() => updateProject("active")}
            >
              <TbArchiveOff className={classes.menuIconSmaller} />
              <Typography display="block">{intl.formatMessage(messages.unarchive)}</Typography>
            </MenuItem>
            <Divider />
          </Box>
        )}
        <MenuItem
          classes={{ root: classes.menuItemHeight }}
          onClick={() => updateProject("deleted")}
        >
          <MdOutlineDelete className={classes.menuIcon} />
          <Typography display="block">{intl.formatMessage(messages.delete)}</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

ProjectListItemMenu.propTypes = {
  projectData: PropTypes.object.isRequired,
};

export default ProjectListItemMenu;
