export const formatAcceptedFileTypes = (fileTypes) => {
    let fileArray = fileTypes.split(', ');
  
    fileArray = fileArray.map(fileType => fileType.split('/')[1]);

    const lastElement = fileArray.pop();
    fileArray.push('or ' + lastElement);
  
    return fileArray.join(', ');
  }
