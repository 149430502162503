import React from "react";
import { useIntl } from "react-intl";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MuiAlert from "@material-ui/lab/Alert";
import Hidden from "@material-ui/core/Hidden";

// Hooks
import useStoreProvider from "../../common/providers/store/use-app-context";

// Components
import SimpleSlider from "./slider";
import { login, logout } from "../../common/utils/auth";

// Assets
import bg1 from "../../assets/bg-pattern-1.png";
import bg2 from "../../assets/bg-pattern-2.png";
import logo from "../../assets/logo-light-bg.png";

import messages from "../../i18n/messages";
const navbarHeight = 200;
const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  logoContainer: {
    padding: spacing(2),
  },
  infoContainer: {
    backgroundColor: palette.primary.main,
    display: "flex",
    flexDirection: "column",
    placeContent: "center",
    backgroundImage: `url(${bg1}), url(${bg1}), url(${bg2})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top 0px right 0px, bottom 0px left 0px, bottom 0px right 0px",
  },
  formContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: spacing(2),
    marginTop: `calc(50vh - ${navbarHeight}px - ${spacing(10)}px)`,
  },
  form: {
    maxWidth: 400,
    width: "100%",
    marginTop: spacing(5),
  },
  button: {
    margin: spacing(6, 0, 4),
    padding: spacing(1),
  },
}));

export default function Login() {
  const intl = useIntl();
  const classes = useStyles();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loginMessage, setLoginMessage] = React.useState("");
  const { loggedInUser } = useStoreProvider();

  const handleSubmit = (e) => {
    e.preventDefault();
    login(email, password).catch((error) => {
      setLoginMessage(`Invalid username or password.`);
    });

    if (!loggedInUser?.active) {
      logout();
    }
  };

  return (
    <Grid container className={classes.container} md={12}>
      <Grid item xs={12} md={5}>
        <Box className={classes.logoContainer}>
          <img src={logo} alt="logo" width="150px" />
        </Box>
        <Box className={classes.formContainer}>
          <Typography variant="h6">{intl.formatMessage(messages.login)}</Typography>
          <form className={classes.form} onSubmit={(event) => handleSubmit(event)}>
            <TextField
              variant="outlined"
              value={email}
              margin="normal"
              required
              fullWidth
              id="email"
              label={intl.formatMessage(messages.emailAddress)}
              name="email"
              autoComplete="off"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              value={password}
              margin="normal"
              required
              fullWidth
              name="password"
              label={intl.formatMessage(messages.password)}
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            {loginMessage && (
              <Box mt={4}>
                <MuiAlert severity="error">{loginMessage}</MuiAlert>
              </Box>
            )}
            <Grid container spacing={2}>
              <Grid item xs>
                <Button
                  fullWidth
                  disableElevation
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                >
                  {intl.formatMessage(messages.login)}
                </Button>
              </Grid>
              <Grid item xs>
                <Button
                  component={Link}
                  to="/reset-password"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                >
                  {intl.formatMessage(messages.forgotPassword)}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} md={7} className={classes.infoContainer}>
          <SimpleSlider />
        </Grid>
      </Hidden>
    </Grid>
  );
}
