import React from "react";
import { useIntl } from "react-intl";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Box from "@material-ui/core/Box";

// Components
import Title from "../../components/title/title";
import ProjectList from "./partials/projects-list";

// Constants
import messages from "../../i18n/messages";

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: "#ffffff",
    border: "1px solid #e3e3e3",
    borderRadius: spacing(0.5),
  },
  tab: {
    color: palette.secondary.main,
  },
  tabPanel: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
}));

const Projects = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const intl = useIntl();

  return (
    <Box width="100%">
      <Title title={intl.formatMessage(messages.projects)} />
      <div className={classes.root}>
        <TabContext value={value}>
          <AppBar className={classes.appBar} position="static" elevation={0}>
            <TabList onChange={handleChange} aria-label="Project tabs" color="transparent">
              <Tab className={classes.tab} label={intl.formatMessage(messages.active)} value="1" />
              <Tab
                className={classes.tab}
                label={intl.formatMessage(messages.archived)}
                value="2"
              />
            </TabList>
          </AppBar>
          <TabPanel className={classes.tabPanel} value="1">
            <ProjectList status="active" />
          </TabPanel>
          <TabPanel className={classes.tabPanel} value="2">
            <ProjectList status="archived" />
          </TabPanel>
        </TabContext>
      </div>
    </Box>
  );
};

export default Projects;
