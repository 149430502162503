import React from "react";
import { useIntl } from "react-intl";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";

import messages from "../../../i18n/messages";
import ProgressIndicator from "../../../components/progress-indicator/progress-indicator";
import { NDT_METHODS } from "../../../constants";

const NdtInspectionProgress = ({ ndtProgressData }) => {
  const intl = useIntl();

  const renderInspectionCounts = () => {
    return (
      <>
        <Grid container spacing={2} style={{ marginBottom: "10px" }}>
          <Grid item sm={12}>
            <Typography variant="caption">Completed inspections per NDT method</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {Object.keys(ndtProgressData).map((key) => {
            const { completed, numOfRequiredInspections } = ndtProgressData[key];
            const progress = parseInt((completed / numOfRequiredInspections) * 100, 10).toFixed(0);

            return (
              <Grid key={key} container item spacing={2} xs={12}>
                <Grid item xs={2} sm={5}>
                  <Hidden xsDown>
                    <Typography variant="caption">{intl.formatMessage(messages[key])}</Typography>
                  </Hidden>
                  <Hidden smUp>
                    <Typography variant="caption">{NDT_METHODS[key]?.code}</Typography>
                  </Hidden>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <Typography variant="body1">
                    <Box fontWeight="normal">
                      {completed}/{numOfRequiredInspections}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={5}>
                  <ProgressIndicator rounded progress={progress > 100 ? 100 : progress} />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  };

  return <div>{renderInspectionCounts()}</div>;
};

export default NdtInspectionProgress;
