import messages from "../../i18n/messages";

const getStatus = (statusObj, intl) => {
  const todo = intl.formatMessage(messages.todo);
  const done = intl.formatMessage(messages.done);
  const toRedo = intl.formatMessage(messages.toRedo);

  if (statusObj?.done === true) return { label: done, status: "done" };
  if (statusObj?.redo === true) return { label: toRedo, status: "toRedo" };
  if (statusObj?.done === true && statusObj?.redone) return { label: todo, status: "todo" };
  return { label: todo, status: "todo" };
};

/**
 * Determine weld status
 * @param {object} selectedWeld
 * @returns {object}
 */
const getWeldStatus = (selectedWeld, intl) => {
  return getStatus(selectedWeld?.weldStatus, intl);
};

/**
 * Determine heat treatment status of a weld
 * @param {object} selectedWeld
 * @returns
 */
const getHeatTreatmentStatus = (selectedWeld, intl) => {
  const notRequired = intl.formatMessage(messages.notRequired);
  if (!selectedWeld?.htRequired) {
    return { label: notRequired, status: "notrequired" };
  }
  return getStatus(selectedWeld?.heatTreatmentStatus, intl);
};

export { getWeldStatus, getHeatTreatmentStatus };
