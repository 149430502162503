import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

import CustomCard from "../../components/custom-card/custom-card";
import UserProfileImage from "../../components/user-profile-image/user-profile-image";
import useStoreProvider from "../../common/providers/store/use-app-context";
import { Typography } from "@material-ui/core";

import { useIntl } from "react-intl";
import messages from '../../i18n/messages';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  profileImageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  userBriefContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
  },
  userDetailsContainer: {
    placeContent: "center",
    marginTop: 20,
  },
  userDetailItem: {
    flex: "1 1 0px",
  },
  formStatusAndActionContainer: {
    display: "flex",
    placeContent: "end",
    paddingTop: spacing(2),
  },
}));

function UserProfile() {
  const intl = useIntl();

  const classes = useStyles();
  const { loggedInUser } = useStoreProvider();

  const userRole =
    loggedInUser?.userRole && loggedInUser?.userRole[0] === "admin"
      ? ` | ${loggedInUser.userRole[0]}`
      : "";

  return (
    (<Box className={classes.root}>
      <Box className={classes.profileImageContainer}>
        <UserProfileImage
          imagePath={loggedInUser?.photo}
          placeholder="/images/user.png"
        />
        <Box className={classes.userBriefContainer}>
          <Typography variant="subtitle2" align="center">
            {loggedInUser?.fname} {loggedInUser?.lname}
          </Typography>
          <Typography variant="caption" align="center">
            {loggedInUser?.email} {userRole}
          </Typography>
        </Box>
      </Box>
      <Grid container className={classes.userDetailsContainer}>
        <Grid item xs={12} lg={10}>
          <CustomCard>
            <Box display="flex" mb={4}>
              <Box className={classes.userDetailItem}>
                <Typography variant="caption">
                  {intl.formatMessage(messages.status)}
                </Typography>
                <Typography variant="body2">
                  {loggedInUser?.status || "Active"}
                </Typography>
              </Box>
              <Box className={classes.userDetailItem}>
                <Typography variant="caption">
                  {intl.formatMessage(messages.activeSince)}
                </Typography>
                <Typography variant="body2">
                  {loggedInUser?.metadata?.creationTime}
                </Typography>
              </Box>
              <Box className={classes.userDetailItem}>
                <Typography variant="caption">
                  {intl.formatMessage(messages.lastSignInTime)}
                </Typography>
                <Typography variant="body2">
                  {loggedInUser?.metadata?.lastSignInTime}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className={classes.formStatusAndActionContainer}
            >
              <Button
                variant="contained"
                label={intl.formatMessage(messages.addProject)}
                disableElevation
                component={Link}
                to={"/user/profile/edit"}
              >
                {intl.formatMessage(messages.edit)}
              </Button>
            </Box>
          </CustomCard>
        </Grid>
      </Grid>
    </Box>)
  );
}

UserProfile.propTypes = {};

export default UserProfile;
