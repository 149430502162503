import React from "react";
import { useIntl } from "react-intl";

import { Grid, Hidden, CircularProgress, Typography, makeStyles, Divider } from "@material-ui/core";

import messages from "../../../i18n/messages";
import { NDT_METHODS } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  circularProgress: {
    position: "relative",
  },
  bottom: {
    color: "#28b87c40",
  },
  top: {
    color: "#1a90ff",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
  textContainer: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
}));

const CustomCircularProgress = ({
  ndtOrder,
  overallCompletion,
  completedInspectionPerNdtMethod,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const { completed, total } = overallCompletion;
  const progress = (completed / total) * 100;

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={7} sm={8}>
          <Hidden xsDown>
            <Typography variant="caption">Number of welds required per NDT method</Typography>
          </Hidden>
          <Hidden smUp>
            <Typography variant="caption">Number of welds required</Typography>
          </Hidden>
        </Grid>
        <Grid item xs={5} sm={4}>
          <Typography variant="caption">Overall progress</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid container item spacing={2} xs={7} sm={8}>
          {Object.keys(completedInspectionPerNdtMethod).map((key) => {
            const { numOfRequiredInspections } = completedInspectionPerNdtMethod[key];
            return (
              <Grid container item spacing={2}>
                <Grid item xs={2} sm={7}>
                  <Hidden xsDown>
                    <Typography variant="caption">{intl.formatMessage(messages[key])}</Typography>
                  </Hidden>
                  <Hidden smUp>
                    <Typography variant="caption">{NDT_METHODS[key]?.code}</Typography>
                  </Hidden>
                </Grid>
                <Grid item xs={8} sm={5}>
                  <Typography variant="body1" style={{ fontSize: "13px" }}>
                    {numOfRequiredInspections} welds (
                    {ndtOrder.ndtRequirements.inspectionRates[key]}%)
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid container item xs={5} sm={4}>
          <div className={classes.root}>
            <div className={classes.circularProgress}>
              <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={80}
                value={100}
                thickness={4}
              />
              <CircularProgress
                variant="determinate"
                value={progress}
                disableShrink
                className={classes.top}
                classes={{
                  circle: classes.circle,
                }}
                size={80}
                thickness={4}
              />
              <Typography
                variant="h6"
                component="div"
                color="textSecondary"
                className={classes.textContainer}
              >
                {`${completed}/${total}`}
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomCircularProgress;
