import React from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

// Material UI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

// Components
import ActivityTimeline from "../../components/activity-timeline/activity-timeline";
import LoadingStateCard from "../../components/loading-state-card/loading-state-card";
import StateCard from "../../components/state-card/state-card";
import Title from "../../components/title/title";
import ProjectParticipants from "./partials/project-participants";
import ProjectDetails from "./partials/project-details";

// Hooks
import useStoreProvider from "../../common/providers/store/use-app-context";
import useFetchDocument from "../../common/utils/use-fetch-document-v2";
import useFetchCollection from "../../common/utils/use-fetch-collection-v2";

// Constants and utilities
import { db } from "../../constants";
import messages from "../../i18n/messages";

const ProjectDetailsWrapper = () => {
  const intl = useIntl();
  const { pid } = useParams();
  const { selectedProject, setSelectedProject, selectedProjectEvents, setSelectedProjectEvents } =
    useStoreProvider();

  const projectDocumentRef = React.useMemo(() => db.collection("projects").doc(pid), [pid]);
  const projectEventsDocumentRef = React.useMemo(
    () => db.collection("projects").doc(pid).collection("activities").orderBy("loggedAt", "asc"),
    [pid]
  );

  const {
    data: projectData,
    fetching: projectFetching,
    error: projectError,
    getDocument: fetchProjectDetail,
  } = useFetchDocument();

  const {
    data: projectEventsData,
    fetching: projectEventsFetching,
    error: projectEventsError,
    fetchCollectionOnce: fetchProjectEvents,
  } = useFetchCollection();

  const breadcrumbsNav = [
    { label: "Projects", to: "/" },
    { label: selectedProject?.projectName, to: `/projects/${pid}` },
    { label: "Overview", to: `/projects/${pid}` },
  ];

  const getProjectEvents = React.useCallback(async () => {
    await fetchProjectEvents(projectEventsDocumentRef);
    setSelectedProjectEvents(projectEventsData.reverse());
  }, [fetchProjectEvents, projectEventsData, projectEventsDocumentRef, setSelectedProjectEvents]);

  // Fetch data if not in context
  React.useEffect(() => {
    if (!selectedProjectEvents) {
      getProjectEvents();
    }
    if (!selectedProject) {
      fetchProjectDetail(projectDocumentRef);
      setSelectedProject(projectData);
    }
  }, [
    selectedProject,
    setSelectedProject,
    projectData,
    selectedProjectEvents,
    projectDocumentRef,
    fetchProjectDetail,
    getProjectEvents,
  ]);

  return (
    <Box width="100%">
      {!selectedProject && projectFetching ? (
        <LoadingStateCard />
      ) : projectError ? (
        <StateCard
          title={intl.formatMessage(messages.errorFetchingData)}
          description={projectError.message || intl.formatMessage(messages.unknownErrorOccurred)}
        />
      ) : (
        <>
          <Title
            title={intl.formatMessage(messages.overview)}
            breadcrumbsNav={breadcrumbsNav}
            fetchingProjectDetail={projectFetching}
          />
          <Grid container spacing={4}>
            <Grid container item xs={12} md={8} direction="column">
              <ProjectDetails projectData={selectedProject || projectData} />
              <ProjectParticipants />
            </Grid>
            <Grid item xs={12} md={4}>
              <ActivityTimeline
                events={selectedProjectEvents}
                fetching={projectEventsFetching}
                fetchError={projectEventsError}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default ProjectDetailsWrapper;
