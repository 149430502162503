import React, { useState, useCallback } from "react";

export const NotificationContext = React.createContext({
  notification: null,
  addNotification: () => {},
  removeNotification: () => {},
});

const NotificationProvider = ({ children })  => {
  const [notification, setNotification] = useState(null);

  const contextValue = {
    notification,
    addNotification: useCallback((message, status) => setNotification({ message, status }), []),
    removeNotification: useCallback(() => setNotification(null), []),
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
}

export default NotificationProvider;