import React, { useState } from "react";
import { ListItem, ListItemIcon, ListItemText, List, Collapse } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { GrLanguage } from "react-icons/gr";

import useUpdateDocument from "../../common/utils/use-update-document";
import { db } from "../../constants";

function LanguageSelectionMenu({ intl, messages, loggedInUser, setLoggedInUser, className }) {
  const [open, setOpen] = useState(false);
  const { updateDocument } = useUpdateDocument();

  const updateLanguagePreference = (language) => {
    const documentRef = db.collection("user-info").doc(loggedInUser?.uid);
    updateDocument({
      documentRef,
      payload: { language: language },
    });

    // Enables reloading of the page to reflect the language change
    setLoggedInUser({ ...loggedInUser, language: language });
    setOpen(false);
  };

  const toggleOpen = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <List>
      <ListItem button onClick={toggleOpen}>
        <ListItemIcon>
          <GrLanguage className={className} />
        </ListItemIcon>
        <ListItemText primary={intl.formatMessage(messages.language)} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button onClick={() => updateLanguagePreference("en")}>
            <ListItemText inset primary="English" />
          </ListItem>
          <ListItem button onClick={() => updateLanguagePreference("dk")}>
            <ListItemText inset primary="Danish" />
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
}

export default LanguageSelectionMenu;
