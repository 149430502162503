import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import useStoreProvider from "../../../common/providers/store/use-app-context";
import { db } from "../../../constants";

const useStyles = makeStyles(({ breakpoints }) => ({
  dialogPaper: {
    [`${breakpoints.up("sm")}`]: {
      minWidth: 800,
    },
  },
}));

function WeldEventLoggingDialog({
  Form,
  title,
  open,
  onClose,
  multilog,
  usersInvolved,
  onEventLogged,
  weldEventToEdit,
  indexOfWeldEventToEdit,
  selectedWeldsForMassLogging,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { selectedProject, selectedWeldLog, selectedWeld, selectedNdtOrder } =
    useStoreProvider();
  const projectDocumentRef = db.collection("projects").doc(selectedProject?.id);

  const weldLogDocumentRef = db
    .collection("projects")
    .doc(selectedProject?.id)
    .collection("weld-logs")
    .doc(selectedWeldLog?.id);

  const selectedWelds = multilog ? selectedWeldsForMassLogging : [selectedWeld];

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullScreen={fullScreen}
      aria-labelledby="confirm-dialog"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>{title}</DialogTitle>
      <Form
        multilog
        projectDocumentRef={projectDocumentRef}
        weldLogDocumentRef={weldLogDocumentRef}
        weldLogId={selectedWeldLog?.id}
        weldId={selectedWeld?.id}
        usersInvolved={usersInvolved}
        onEventLogged={onEventLogged}
        weldEventToEdit={weldEventToEdit}
        indexOfWeldEventToEdit={indexOfWeldEventToEdit}
        onClose={handleCancel}
        selectedWeldLog={selectedWeldLog}
        selectedWelds={selectedWelds}
        selectedNdtOrder={selectedNdtOrder}
      />
    </Dialog>
  );
}

WeldEventLoggingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WeldEventLoggingDialog;
