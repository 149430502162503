import React from "react";
import firebase from "firebase";
import { toast } from "react-toastify";
import { db, NOTIFICATION_POSITION } from "../../../constants";
import useFetchCollection from "../../../common/utils/use-fetch-collection";
import useStoreProvider from "../../../common/providers/store/use-app-context";

import { useIntl } from "react-intl";
import messages from '../../../i18n/messages';

const PROJECTS_COLLECTION = "projects";
const PROJECTS_PARTICIPANTS_COLLECTION = "participants";
const DOCUMENTS_SECTION_COLLECTION = "document-sections";
const DOCUMENTS_COLLECTION = "documents";
const WELD_LOGS_COLLECTION = "weld-logs";

const getCollectionRef = (collectionPath, docId, subCollection) => {
  let ref = db.collection(collectionPath);
  if (docId) {
    ref = ref.doc(docId);
  }
  if (subCollection) {
    ref = ref.collection(subCollection);
  }
  return ref;
};

export const useDuplicateDocument = (projectData) => {
  const batch = db.batch();
  const { getCollection } = useFetchCollection();
  const { loggedInUser } = useStoreProvider();
  const { fname, lname } = loggedInUser;

  const [state, setState] = React.useState({
    error: false,
    completed: false,
  });

  const projectCollectionRef = getCollectionRef(PROJECTS_COLLECTION);
  const pid = projectCollectionRef.doc().id;

  const participantsCollectionRef = getCollectionRef(
    PROJECTS_COLLECTION,
    projectData?.id,
    PROJECTS_PARTICIPANTS_COLLECTION
  );
  const participantsCollectionToCreateRef = getCollectionRef(
    PROJECTS_COLLECTION,
    pid,
    PROJECTS_PARTICIPANTS_COLLECTION
  );

  const documentSectionsCollectionRef = getCollectionRef(
    PROJECTS_COLLECTION,
    projectData?.id,
    DOCUMENTS_SECTION_COLLECTION
  );
  const documentSectionsCollectionToCreateRef = getCollectionRef(
    PROJECTS_COLLECTION,
    pid,
    DOCUMENTS_SECTION_COLLECTION
  );

  const documentsCollectionRef = getCollectionRef(
    PROJECTS_COLLECTION,
    projectData?.id,
    DOCUMENTS_COLLECTION
  );
  const documentsCollectionToCreateRef = getCollectionRef(
    PROJECTS_COLLECTION,
    pid,
    DOCUMENTS_COLLECTION
  );

  const weldLogCollectionRef = getCollectionRef(
    PROJECTS_COLLECTION,
    projectData?.id,
    WELD_LOGS_COLLECTION
  );

  const weldLogCollectionToCreateRef = getCollectionRef(
    PROJECTS_COLLECTION,
    pid,
    WELD_LOGS_COLLECTION
  );

  const intl = useIntl();

  const copyWelds = React.useCallback(
    async (weldLogId, weldLogData, fname, lname) => {
      const weldCollectionRef = getCollectionRef(
        PROJECTS_COLLECTION,
        projectData?.id,
        `${WELD_LOGS_COLLECTION}/${weldLogData?.id}/welds`
      );
      const weldCollectionToCreateRef = getCollectionRef(
        PROJECTS_COLLECTION,
        pid,
        `${WELD_LOGS_COLLECTION}/${weldLogId}/welds`
      );

      const welds = await getCollection(weldCollectionRef);

      if (welds.docs) {
        for (const doc of welds.docs) {
          let weldId = weldCollectionToCreateRef.doc().id;
          const logCreationEvent = {
            what: intl.formatMessage(messages.weldCreated),
            when: firebase.firestore.Timestamp.now(),
            loggedAt: firebase.firestore.Timestamp.now(),
            doneAt: firebase.firestore.Timestamp.now(),
            doneBy: {
              fname: fname,
              lname: lname,
            },
            loggedBy: `${fname} ${lname}`,
          };

          batch.set(weldCollectionToCreateRef.doc(weldId), {
            ...doc.data(),
            id: weldId,
            events: [logCreationEvent],
            weldStatus: {},
            ndtStatus: { ndtResults: {}, ndtResultsForReporting: {} },
            heatTreatmentStatus: {},
            remark: "",
            createdAt: firebase.firestore.Timestamp.now(),
            updatedAt: firebase.firestore.Timestamp.now(),
          });
        }
      }
    },
    [batch, getCollection, pid, projectData?.id, intl]
  );

  const duplicateDocument = React.useCallback(async () => {
    const DUPLICATE_SUCCESS_MESSAGE = intl.formatMessage(messages.projectDuplicatedSuccessfully);
    const DUPLICATE_FAILURE_MESSAGE = intl.formatMessage(messages.somethingWentWrong);

    if (!projectData || !projectCollectionRef) {
      setState({ error: true, completed: true });
      toast.error(DUPLICATE_FAILURE_MESSAGE, {
        position: NOTIFICATION_POSITION,
      });
      return;
    }

    try {
      const weldsLogs = await getCollection(weldLogCollectionRef);

      const projectParticipants = await getCollection(
        participantsCollectionRef
      );

      const documentSections = await getCollection(
        documentSectionsCollectionRef
      );

      const documents = await getCollection(documentsCollectionRef);

      // Copy project
      batch.set(projectCollectionRef.doc(pid), {
        ...projectData,
        id: pid,
        latestActivity: null,
        projectName: `[copy] - ${projectData.projectName}`,
        createdAt: firebase.firestore.Timestamp.now(),
        updatedAt: firebase.firestore.Timestamp.now(),
      });

      // Copy project participants
      if (projectParticipants?.docs) {
        for (const doc of projectParticipants.docs) {
          const participantData = doc.data();

          batch.set(
            participantsCollectionToCreateRef.doc(
              participantData?.userInfo?.uid
            ),
            {
              ...participantData,
              createdAt: firebase.firestore.Timestamp.now(),
              updatedAt: firebase.firestore.Timestamp.now(),
            }
          );
        }
      }

      // Copy document sections
      if (documentSections?.docs) {
        for (const doc of documentSections.docs) {
          const documentSectionData = doc.data();

          batch.set(
            documentSectionsCollectionToCreateRef.doc(documentSectionData?.id),
            {
              ...documentSectionData,
            }
          );
        }
      }

      // Copy documents
      if (documents?.docs) {
        for (const doc of documents.docs) {
          const documentData = doc.data();

          batch.set(documentsCollectionToCreateRef.doc(documentData?.id), {
            ...documentData,
            createdAt: firebase.firestore.Timestamp.now(),
            updatedAt: firebase.firestore.Timestamp.now(),
          });
        }
      }

      // Copy weld logs and welds
      if (weldsLogs?.docs) {
        for (const doc of weldsLogs.docs) {
          let weldLogId = weldLogCollectionToCreateRef.doc().id;
          const weldLogData = doc.data();

          batch.set(weldLogCollectionToCreateRef.doc(weldLogId), {
            ...weldLogData,
            id: weldLogId,
            latestActivity: {},
            progress: { weldProgress: 0, htProgress: 0 },
            createdAt: firebase.firestore.Timestamp.now(),
            updatedAt: firebase.firestore.Timestamp.now(),
          });

          await copyWelds(weldLogId, weldLogData, fname, lname);
        }
      }

      await batch.commit();

      setState({ error: false, completed: true });
      toast.success(DUPLICATE_SUCCESS_MESSAGE, {
        position: NOTIFICATION_POSITION,
      });
    } catch (e) {
      console.error(`Error duplicating project: ${e.message}`);
      setState({ error: true, completed: false });
      toast.error(DUPLICATE_FAILURE_MESSAGE, {
        position: NOTIFICATION_POSITION,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectData,
    projectCollectionRef,
    getCollection,
    weldLogCollectionRef,
    participantsCollectionRef,
    documentSectionsCollectionRef,
    documentsCollectionRef,
    batch,
    pid,
    participantsCollectionToCreateRef,
    documentSectionsCollectionToCreateRef,
    documentsCollectionToCreateRef,
    weldLogCollectionToCreateRef,
    copyWelds,
    fname,
    lname,
  ]);

  return {
    status: state,
    duplicateDocument,
  };
};

export default useDuplicateDocument;
