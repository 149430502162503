import React from "react";
import { useIntl } from "react-intl";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";

// Utilities and constants
import messages from '../../i18n/messages';

const useStyles = makeStyles(({ spacing }) => ({
  footer: {
    height: spacing(8),
  },
  container: {
    height: "100%",
    display: "flex",
    placeContent: "center",
    alignItems: "center",
  },
}));

export default function Footer() {
  const classes = useStyles();

  const intl = useIntl();

  return (
    (<Box className={classes.footer} alignItems="center">
      <Divider />
      <Container className={classes.container}>
        <Box display="flex">
          {intl.formatMessage(messages.copyright)}
          <Link variant="body2" href="https://docweld.com/" target="_blank">
            &nbsp;Docweld&nbsp;
          </Link>
          {new Date().getFullYear()}
        </Box>
      </Container>
    </Box>)
  );
}
