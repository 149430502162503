import React from "react";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";

// Material UI
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import weldLog from "../../assets/weldingIcon.svg";
import { BsEyeglasses } from "react-icons/bs";
import { RiFireLine } from "react-icons/ri";
import { HiOutlineDocumentAdd } from "react-icons/hi";
import { TbLayoutGridAdd } from "react-icons/tb";
import { LuPencilLine } from "react-icons/lu";

// Components
import LoadingStateCard from "../../components/loading-state-card/loading-state-card";
import RoleAccess from "../../components/role-access/role-access";
import Indicator from "../../components/indicator/indicator";
import CustomCard from "../../components/custom-card/custom-card";
import StateCard from "../../components/state-card/state-card";

// Constants and utilities
import { formatDate } from "../../common/utils/format-date";
import messages from "../../i18n/messages";
import { NDT_METHODS, USER_ROLES, WELD_EVENT_TYPES, DEPRECATED_NDT_CODES } from "../../constants";

const flexCenter = {
  display: "flex",
  alignItems: "center",
};

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  flexCenter: {
    ...flexCenter,
  },
  icon: {
    fontSize: spacing(2),
    marginRight: spacing(1.5),
  },
  approvedIcon: {
    color: "#4EB70B",
  },
  rejectedIcon: {
    color: "#F16061",
  },
  toDoIcon: {
    color: "#cccccc",
  },
  connectorCustomSize: {
    width: 0.8,
    backgroundColor: palette.grey[400],
  },
  customizedTimelineDot: {
    borderColor: "transparent",
    borderWidth: 1.5,
  },
  paper: {
    padding: "6px 12px",
    border: `0.8px solid ${palette.grey[300]}`,
  },
  eventIcon: {
    width: 20,
    height: 20,
    color: palette.grey[500],
  },
  weldIcon: {
    width: 20,
    height: 20,
    filter: "grayscale(100%)",
  },
  titleAndIconContainer: {
    ...flexCenter,
    marginBottom: spacing(1),
  },
  activityWrapper: {
    ...flexCenter,
    justifyContent: "space-between",
  },
  editButton: {
    ...flexCenter,
    justifyContent: "center",
    width: 35,
    height: 35,
    background: "#F7F6F9",
    border: "2px solid #EBEBED",
    borderRadius: 6,
    transition: "all 0.2s",
    "&:hover": {
      cursor: "pointer",
      background: "#007aff30",
      border: "2px solid #007aff50",
    },
  },
  editButtonIcon: {
    fontSize: spacing(2.5),
    color: palette.primary.main,
  },
  infoBox: {
    display: "flex",
    flexDirection: "column",
  },
  infoText: {
    margin: spacing(1, 0),
  },
  [breakpoints.down("md")]: {
    paper: {
      padding: "6px",
    },
    content: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    taskTitle: {
      marginLeft: spacing(1),
    },
  },
  ndtStatusContainer: {
    display: "flex",
    alignItems: "center",
  },
  activityTimeline: {
    minHeight: "45VH",
    maxHeight: "70VH",
    overflow: "scroll",
  },
}));

const ensureArray = (doneBy) => {
  if (!Array.isArray(doneBy)) {
    return [doneBy];
  }

  return doneBy;
};

const ActivityTimeline = ({ fetching, events = [], showEditButton = false, handleEditClick }) => {
  const intl = useIntl();
  const classes = useStyles();
  const eventLength = events?.length - 1;

  const eventMessageMap = {
    [WELD_EVENT_TYPES.weldCreated]: messages.weldCreated,
    [WELD_EVENT_TYPES.weldLogged]: messages.weldLogged,
    [WELD_EVENT_TYPES.heatTreatmentLogged]: messages.heatTreatmentLogged,
    [WELD_EVENT_TYPES.addedToNdtOrder]: messages.weldAddedToNdtOrder,
    [WELD_EVENT_TYPES.ndtLogged]: messages.ndtLogged,
    [WELD_EVENT_TYPES.inspectionApproved.status]: messages.approved,
    [WELD_EVENT_TYPES.inspectionRejected.status]: messages.rejected,
  };

  return (
    <CustomCard
      header={
        <Typography variant="subtitle1">{intl.formatMessage(messages.activities)}</Typography>
      }
    >
      {fetching ? (
        <LoadingStateCard border={false} />
      ) : !!events && events?.length === 0 ? (
        <StateCard
          title={intl.formatMessage(messages.noEventYet)}
          description={intl.formatMessage(messages.noEventYetDescription)}
          border={false}
        />
      ) : (
        <Box className={classes.activityTimeline}>
          <Timeline align="left" style={{ padding: 0 }}>
            {events?.map((event, index) => {
              let loggedAt = intl.formatMessage(messages.na);
              let doneAt = intl.formatMessage(messages.na);

              if (event?.loggedAt) {
                loggedAt = formatDate(event?.loggedAt, intl);
              }

              if (event?.doneAt) {
                doneAt = formatDate(event?.doneAt, intl);
              }

              // This is added to ensure backward compatibility since the data structure of doneBy
              // has changed from object to array. Since multiple users can log a weld, we need to
              // check if doneBy is an array and map through it to get the names of the users.
              const by =
                event.what === WELD_EVENT_TYPES.weldLogged
                  ? ensureArray(event?.doneBy)
                      ?.map((user) => `${user.fname} ${user.lname}`)
                      .join(", ")
                  : `${event?.doneBy?.fname} ${event?.doneBy?.lname}`;

              const loggedBy = `${event?.loggedBy} - ${loggedAt}`;
              const doneBy = `${by} - ${doneAt}`;
              const commentOnNdts = event?.commentOnNdts;

              const ndtStatusIndicator = (value) => {
                return <Indicator state={value} />;
              };

              const ndtDetails = !!event?.ndtResults
                ? Object.keys(event?.ndtResults).map((key) => (
                    <Box className={classes.ndtStatusContainer} key={key}>
                      {ndtStatusIndicator(event?.ndtResults[key])}
                      &nbsp;
                      <Typography variant="caption">
                        {!!NDT_METHODS[key]
                          ? intl.formatMessage(messages[NDT_METHODS[key]?.localizationKey])
                          : intl.formatMessage(messages[DEPRECATED_NDT_CODES[key]])}
                        &nbsp;-&nbsp;
                        {eventMessageMap[event?.ndtResults[key]] &&
                          intl.formatMessage(eventMessageMap[event?.ndtResults[key]])}
                      </Typography>
                    </Box>
                  ))
                : null;

              return (
                <TimelineItem key={index}>
                  <TimelineOppositeContent
                    style={{ flex: 0, padding: 0 }}
                  ></TimelineOppositeContent>
                  <Hidden mdDown>
                    <TimelineSeparator>
                      <TimelineDot
                        variant="outlined"
                        classes={{ root: classes.customizedTimelineDot }}
                      >
                        {event.what === WELD_EVENT_TYPES.weldCreated && (
                          <HiOutlineDocumentAdd className={classes.eventIcon} />
                        )}
                        {event.what === WELD_EVENT_TYPES.weldLogged && (
                          <img src={weldLog} alt="weldLog" className={classes.weldIcon} />
                        )}
                        {event.what === WELD_EVENT_TYPES.heatTreatmentLogged && (
                          <RiFireLine className={classes.eventIcon} />
                        )}
                        {event.what === WELD_EVENT_TYPES.addedToNdtOrder && (
                          <TbLayoutGridAdd className={classes.eventIcon} />
                        )}
                        {event.what === WELD_EVENT_TYPES.ndtLogged && (
                          <BsEyeglasses className={classes.eventIcon} />
                        )}
                      </TimelineDot>
                      <TimelineConnector classes={{ root: classes.connectorCustomSize }} />
                    </TimelineSeparator>
                  </Hidden>

                  <TimelineContent classes={{ root: classes.content }}>
                    <Paper elevation={0} className={classes.paper}>
                      <Box key={index} className={classes.activityWrapper}>
                        <Box display="flex" flexDirection="column">
                          <Box className={classes.titleAndIconContainer}>
                            <Hidden mdUp>
                              {event.what === WELD_EVENT_TYPES.weldCreated && (
                                <HiOutlineDocumentAdd className={classes.eventIcon} />
                              )}
                              {event.what === WELD_EVENT_TYPES.weldLogged && (
                                <img src={weldLog} alt="weldLog" className={classes.weldIcon} />
                              )}
                              {event.what === WELD_EVENT_TYPES.heatTreatmentLogged && (
                                <RiFireLine className={classes.eventIcon} />
                              )}
                              {event.what === WELD_EVENT_TYPES.addedToNdtOrder && (
                                <TbLayoutGridAdd className={classes.eventIcon} />
                              )}
                              {event.what === WELD_EVENT_TYPES.ndtLogged && (
                                <BsEyeglasses className={classes.eventIcon} />
                              )}
                            </Hidden>
                            <Typography display="block" className={classes.taskTitle}>
                              {eventMessageMap[event.what] &&
                                intl.formatMessage(eventMessageMap[event.what])}
                            </Typography>
                          </Box>
                          <Box display="flex" flexDirection="column" className="infoBox">
                            <Typography variant="caption" className="infoText">
                              {intl.formatMessage(messages.loggedBy)} - {loggedBy}
                            </Typography>
                            <Typography variant="caption" className="infoText">
                              {intl.formatMessage(messages.doneBy)}&nbsp;-&nbsp;
                              {doneBy}
                            </Typography>
                            {commentOnNdts && (
                              <Typography variant="caption" className="infoText">
                                {intl.formatMessage(messages.comment)}
                                &nbsp;-&nbsp;
                                {commentOnNdts}
                              </Typography>
                            )}
                          </Box>
                          {ndtDetails}
                        </Box>
                        {/*
                        Show edit button only in weld detail page and not on weld creation event.
                        Also, do not show edit button on NDT logged event since NDT logging is
                        done in the NDT order page.
                        */}
                        {showEditButton &&
                          event.what !== WELD_EVENT_TYPES.ndtLogged &&
                          index !== eventLength && (
                            <RoleAccess
                              roles={[
                                USER_ROLES.ADMIN,
                                USER_ROLES.PROJECT_LEADER,
                                USER_ROLES.WELDING_COORDINATOR,
                                USER_ROLES.RESPONSIBLE_WELDING_COORDINATOR,
                              ]}
                            >
                              <Tooltip title="Edit">
                                <Box
                                  className={classes.editButton}
                                  onClick={() => handleEditClick(eventLength - index)}
                                >
                                  <LuPencilLine className={classes.editButtonIcon} />
                                </Box>
                              </Tooltip>
                            </RoleAccess>
                          )}
                      </Box>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </Box>
      )}
    </CustomCard>
  );
};

export default ActivityTimeline;
