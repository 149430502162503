import React from "react";
import { Page } from "react-pdf";
import { StyleSheet } from "@react-pdf/renderer";
import { Document } from "react-pdf/dist/esm/entry.webpack";

import Box from "@material-ui/core/Box";

const styles = StyleSheet.create({
  document: {
    backgroundColor: "#FFFFFF",
  },
});

const SinglePagePdfViewer = ({ pdf }) => {
  const [orientation, setOrientation] = React.useState(0);
  const canvasRef = React.useRef();

  const checkPdfOrientation = React.useCallback((ref) => {
    const canvas = ref?.current;
    const rotationValue = canvas?.clientHeight < canvas?.clientWidth ? -90 : 0;
    setOrientation(rotationValue);
  }, []);

  return (
    <Box
      style={{
        display: "flex",
        placeContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Document file={pdf} style={styles.document}>
        <Page
          canvasRef={canvasRef}
          renderAnnotationLayer={false}
          onLoadSuccess={() => checkPdfOrientation(canvasRef)}
          pageNumber={1}
          height={282}
          rotate={orientation}
        />
      </Document>
    </Box>
  );
};
export default SinglePagePdfViewer;
