import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import { RiUserLine } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";

import LanguageSelectionMenu from "./language-selection-submenu";
import UserProfileImage from "../../components/user-profile-image/user-profile-image";
import useStoreProvider from "../../common/providers/store/use-app-context";

import { useIntl } from "react-intl";
import messages from "../../i18n/messages";

const useStyles = makeStyles(({ spacing, palette }) => ({
  menuList: {
    minWidth: 180,
    paddingTop: 0,
    paddingBottom: 0,
  },
  small: {
    width: spacing(4),
    height: spacing(4),
  },
  large: {
    width: spacing(7),
    height: spacing(7),
  },
  nameContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  menuItemHeight: {
    minHeight: 45,
  },
  menuItemPadding: {
    padding: 15,
  },
  icon: {
    color: palette.primary.main,
  },
}));

export default function AccountMenu({ logout }) {
  const intl = useIntl();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { loggedInUser, setLoggedInUser, setSelectedProject } = useStoreProvider();
  const userName = `${loggedInUser?.fname} ${loggedInUser?.lname}`;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          marginLeft: 20,
        }}
      >
        <Tooltip title="Account settings">
          <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
            <UserProfileImage
              width={35}
              height={35}
              imagePath={loggedInUser?.photo}
              placeholder="/images/user.png"
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        getContentAnchorEl={null}
        PaperProps={{
          elevation: 0,
          style: {
            marginTop: 12,
            minWidth: 250,
            boxShadow: "none",
            border: "1px solid #e3e3e3",
          },
        }}
        classes={{ list: classes.menuList }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem className={clsx({ root: classes.menuItemHeight }, classes.menuItemPadding)}>
          <Box className={classes.nameContainer}>
            <UserProfileImage imagePath={loggedInUser?.photo} placeholder="/images/user.png" />
            <Typography component="span">{userName}</Typography>
            <Typography variant="caption">{loggedInUser?.email}</Typography>
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem
          classes={{ root: classes.menuItemHeight }}
          to={`/user/profile`}
          component={Link}
          onClick={() => setSelectedProject(null)}
        >
          <ListItemIcon>
            <RiUserLine size={20} className={classes.icon} />
          </ListItemIcon>
          {intl.formatMessage(messages.profile)}
        </MenuItem>
        <Divider />
        <LanguageSelectionMenu
          intl={intl}
          messages={messages}
          loggedInUser={loggedInUser}
          setLoggedInUser={setLoggedInUser}
          classNameTest={classes.icon}
        />
        <Divider />
        <MenuItem classes={{ root: classes.menuItemHeight }} onClick={() => logout()}>
          <ListItemIcon>
            <FiLogOut size={20} className={classes.icon} />
          </ListItemIcon>
          {intl.formatMessage(messages.logout)}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
