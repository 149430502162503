import React from "react";
import firebase from "firebase";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { orderBy, last } from "lodash";
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-dnd";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tooltip from "@material-ui/core/Tooltip";

//Icons
import { MdDelete } from "react-icons/md";

// Components
import Image from "../image/image";
import ImageUploadArea from "../image-upload-area/image-upload-area";
import RoleAccess from "../../components/role-access/role-access";
import UploadPreviewList from "../upload-preview-list/upload-preview-list";

// Hooks
import useStoreProvider from "../../common/providers/store/use-app-context";

// Utilities and Constants
import messages from "../../i18n/messages";
import { db, USER_ROLES, NOTIFICATION_POSITION } from "../../constants";

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    minHeight: 300,
  },
  accordionRoot: {
    border: `1px solid ${palette.divider}`,
    "&:not(:first-child)": {
      borderTop: 0,
    },
    "&:before": {
      display: "none",
    },
    flexDirection: "row-reverse",
  },
  accordionExpanded: {
    margin: "0px !important",
  },
  accordionSummaryRoot: {
    flexDirection: "row-reverse",
    padding: "0px 16px 0px 0px",
    "& .MuiAccordionSummary-content": {
      marginLeft: spacing(1),
    },
  },
  deleteButton: {
    display: "flex",
    width: 35,
    height: 35,
    background: "#F7F6F9",
    border: "2px solid #EBEBED",
    alignItems: "center",
    placeContent: "center",
    borderRadius: 6,
    transition: "all 0.2s",
    "&:hover": {
      cursor: "pointer",
      background: "#fe5f6e30",
      border: "2px solid #fe5f6e50",
    },
  },
  deleteButtonIcon: {
    color: "#fe5f6e",
  },
  accordionDetails: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  // Image
  container: {
    display: "flex",
    flexDirection: "column",
    touchAction: "none",
    width: "100%",
    height: "100%",
    minHeight: "500px",
    margin: "1rem auto",
    placeContent: "flex-start",    
  },
  dropzone: {
    display: "flex",
    justifyContent: "flex-start",
    minHeight: "280px",
    height: "100%",
    borderRadius: spacing(0.6),
    border: "1px dashed rgba(0, 0, 0, 0.1)",
    overflow: "auto",
  },
  gridItem: {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    padding: "0.5em",
  },
}));

const ProjectDocumentTemplates = ({
  section,
  sectionKey,
  sectionNumber,
  docForSection,
  sectionId,
  onDocumentSelect,
  showLinkMenu = false,
  handleDocumentSectionDelete,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const { pid } = useParams();
  const { loggedInUser } = useStoreProvider();

  const matchesXS = useMediaQuery(theme.breakpoints.up("xs"));
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));

  const [imageList, setImageList] = React.useState([]);
  const [imageSection, setImageSection] = React.useState("");

  // Reorder documents by position within a section
  docForSection = orderBy(docForSection, ["position"], ["asc"]);

  // Adjust number of document based on media query
  let boxesPerRow, rowHeight;
  if (matchesXL) {
    boxesPerRow = 6;
    rowHeight = 280;
  } else if (matchesLG) {
    boxesPerRow = 6;
    rowHeight = 280;
  } else if (matchesMD) {
    boxesPerRow = 5;
    rowHeight = 220;
  } else if (matchesSM) {
    boxesPerRow = 3;
    rowHeight = 210;
  } else if (matchesXS) {
    boxesPerRow = 2;
    rowHeight = 210;
  }

  const documentsCollectionRef = React.useMemo(
    () => db.collection("projects").doc(pid).collection("documents"),
    [pid]
  );

  const changeImageField = React.useCallback(
    (index, parameter, value) => {
      const newArray = [...imageList];
      newArray[index][parameter] = value;
      setImageList(newArray);
    },
    [imageList]
  );

  // Update document positions
  const updateSectionDocumentPositions = async (reorderedWeldPhotos) => {
    const batch = db.batch();
    try {
      reorderedWeldPhotos.forEach((file, index) => {
        batch.update(documentsCollectionRef.doc(file.id), { position: index });
      });
      await batch.commit();
      toast.success("Reordered", {
        position: NOTIFICATION_POSITION,
      });
    } catch (error) {
      console.error("Error while ordering weld photos::", error);
      toast.error("Failed to reorder", {
        position: NOTIFICATION_POSITION,
      });
    }
  };

  /**
   * Reorder position of document list item in a grid
   * @param {string} sourceId not required
   * @param {string} sourceIndex
   * @param {string} targetIndex
   * @param {string} targetId not required
   */
  const handleOrderChange = (_sourceId, sourceIndex, targetIndex, _targetId) => {
    const reorderedWeldPhotos = swap(docForSection, sourceIndex, targetIndex);
    updateSectionDocumentPositions(reorderedWeldPhotos);
  };

  const addFileRelatedDocument = React.useCallback(
    (image, documentId, position) => {
      const isPdf = image?.file?.name && image?.file.name?.split(".").pop() === "pdf";
      const documentInfo = {
        id: documentId,
        createdAt: firebase.firestore.Timestamp.now(),
        createdBy: `${loggedInUser.fname} ${loggedInUser.lname}`,
        updatedAt: null,
        updatedBy: null,
        filename: image?.fileName,
        section: imageSection,
        status: "active",
        position,
        thumbnailStorageRef: `documents/${pid}/${documentId}/${image?.file.name}`,
        pdfFileStorageRef: isPdf ? `documents/${pid}/${documentId}/${image?.file.name}` : null,
        storageRef: `documents/${pid}/${documentId}/${image?.file.name}`,
      };

      documentsCollectionRef
        .doc(documentId)
        .set(documentInfo, { merge: true })
        .then(() => {
          console.log("Document created");
        })
        .catch((error) => {
          console.log("addFileRelatedDocument::", error);
        });
    },
    [loggedInUser.fname, loggedInUser.lname, imageSection, pid, documentsCollectionRef]
  );

  // Refactor
  React.useEffect(() => {
    const maxPosition = last(docForSection)?.position;

    imageList.forEach((image, index) => {
      if (image.status === "UPLOADED" || image.status === "UPLOADING") {
        return;
      }

      const documentId = documentsCollectionRef.doc().id;
      image.storageRef = firebase
        .storage()
        .ref()
        .child(`documents/${pid}/${documentId}/${image.file.name}`);

      changeImageField(index, "status", "UPLOADING");

      let position = maxPosition > 0 ? maxPosition + index : index;
      const uploadTask = image.storageRef.put(image?.file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = parseInt((snapshot.bytesTransferred * 100) / snapshot.totalBytes);
          changeImageField(index, "progress", progress);
        },
        (error) => {
          throw error;
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadUrl) => {
            changeImageField(index, "status", "UPLOADED");
            changeImageField(index, "downloadUrl", downloadUrl);
            addFileRelatedDocument(image, documentId, position);
          });
        }
      );
    });
  }, [
    imageList,
    pid,
    documentsCollectionRef,
    changeImageField,
    addFileRelatedDocument,
    docForSection,
  ]);

  return (
    <Accordion
      classes={{
        root: classes.accordionRoot,
        expanded: classes.accordionExpanded,
      }}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c"
        id="panel1c-header"
        classes={{
          root: classes.accordionSummaryRoot,
        }}
      >
        <Box
          style={{
            width: "100%",
            display: "flex",
            placeContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="overline" style={{ minWidth: 10 }}>
              {sectionNumber}
            </Typography>
            <Typography variant="overline" style={{ marginLeft: 10 }}>
              {section.name}
            </Typography>
          </Box>
          <RoleAccess
            roles={[
              USER_ROLES.ADMIN,
              USER_ROLES.PROJECT_LEADER,
              USER_ROLES.WELDING_COORDINATOR,
              USER_ROLES.RESPONSIBLE_WELDING_COORDINATOR,
            ]}
          >
            <Tooltip title={intl.formatMessage(messages.delete)}>
              <Box
                className={classes.deleteButton}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleDocumentSectionDelete(sectionKey, sectionId);
                }}
              >
                <MdDelete size={18} className={classes.deleteButtonIcon} />
              </Box>
            </Tooltip>
          </RoleAccess>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={classes.accordionDetails}>
          <ImageUploadArea
            imageList={imageList}
            sectionId={section.id}
            setImageList={setImageList}
            setImageSection={setImageSection}
            ProgressThumbnails={() => <UploadPreviewList imageList={imageList} />}
          />
          <GridContextProvider onChange={handleOrderChange}>
            <div className={classes.container}>
              <GridDropZone
                className={classes.dropzone}
                boxesPerRow={boxesPerRow}
                rowHeight={rowHeight}
              >
                {docForSection.map((item) => {
                  let documentRef = db
                    .collection("projects")
                    .doc(pid)
                    .collection("documents")
                    .doc(item?.id);

                  return (
                    <GridItem key={item?.position}>
                      <Image
                        key={item?.id}
                        fileData={item}
                        documentRef={documentRef}
                        showActionBar
                        showOpenMenu
                        showRenameMenu
                        showDeleteMenu
                        showLinkMenu={showLinkMenu}
                        onDocumentSelect={onDocumentSelect}
                      />
                    </GridItem>
                  );
                })}
              </GridDropZone>
            </div>
          </GridContextProvider>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default ProjectDocumentTemplates;
