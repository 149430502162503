import React from "react";
import { toast } from "react-toastify";

import useStoreProvider from "../../common/providers/store/use-app-context";
import { db, NOTIFICATION_POSITION } from "../../constants";

import { useIntl } from "react-intl";
import messages from '../../i18n/messages';

export const useRemoveProjectParticipants = (uids) => {
  const intl = useIntl();

  const batch = db.batch();
  const { selectedProject } = useStoreProvider();

  const projectParticipantsCollectionRef = db
    .collection("projects")
    .doc(`${selectedProject?.id}`)
    .collection("participants");

  const [state, setState] = React.useState({
    error: false,
    completed: false,
  });

  const removeProjectParticipants = React.useCallback(async () => {
    if (!projectParticipantsCollectionRef) {
      setState({
        error: true,
        completed: false,
      });
      toast.error(intl.formatMessage(messages.somethingWentWrong), {
        position: NOTIFICATION_POSITION,
      });
      return;
    }

    try {
      uids.forEach((uid) => {
        batch.delete(projectParticipantsCollectionRef.doc(uid));
      });
      await batch.commit();
      setState({ error: false, completed: true });
      toast.success(intl.formatMessage(messages.projectParticipantRemovedSuccessfully), {
        position: NOTIFICATION_POSITION,
      });
    } catch (error) {
      console.error("useRemoveProjectParticipants::", error);
      setState({ error: true, completed: false });
      toast.error(intl.formatMessage(messages.somethingWentWrong), {
        position: NOTIFICATION_POSITION,
      });
    }
  }, [projectParticipantsCollectionRef, uids, batch, intl]);

  return {
    status: state,
    removeProjectParticipants,
  };
};

export default useRemoveProjectParticipants;
