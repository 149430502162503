import React from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

// Material UI
import Box from "@material-ui/core/Box";

// Components
import WeldList from "./partials/weld-list";
import Title from "../../components/title/title";
import WeldLogDetailsBar from "./partials/weld-log-details-bar";

// Hooks
import useStoreProvider from "../../common/providers/store/use-app-context";
import useFetchDocument from "../../common/utils/use-fetch-document";
import useFetchCollection from "../../common/utils/use-fetch-collection-v2";

// Utilities and constants
import { db } from "../../constants";
import messages from "../../i18n/messages";

const WeldLogDetails = () => {
  const intl = useIntl();
  const { pid, weldLogId } = useParams();
  const { getDocument } = useFetchDocument();
  const { fetching, data: welds, error, listenToCollectionUpdates } = useFetchCollection();
  const { selectedProject, selectedWeldLog, setSelectedWeldLog, setSelectedProject } =
    useStoreProvider();

  const breadcrumbsNav = [
    { label: "Projects", to: "/" },
    { label: selectedProject?.projectName, to: `/projects/${pid}` },
    { label: "Weld logs", to: `/projects/${pid}/weld-logs` },
    { label: selectedWeldLog?.weldLogName, to: `/projects/${pid}/weld-logs/${selectedWeldLog}` },
  ];

  const weldLogDocumentRef = React.useMemo(
    () => db.collection("projects").doc(pid).collection("weld-logs").doc(weldLogId),
    [pid, weldLogId]
  );

  const weldsCollectionRef = React.useMemo(
    () =>
      weldLogDocumentRef
        .collection("welds")
        .orderBy("weldNumber", "asc")
        .where("status", "==", "active"),
    [weldLogDocumentRef]
  );

  // Get current project document on weld log details page refresh
  const getProjectDetails = React.useCallback(async () => {
    const docRef = db.collection("projects").doc(pid);
    const selectedProject = await getDocument(docRef);
    setSelectedProject(selectedProject);
  }, [getDocument, pid, setSelectedProject]);

  // Get current weld log document on weld log details page refresh
  const getWeldLog = React.useCallback(async () => {
    const selectedWeldLog = await getDocument(weldLogDocumentRef);
    setSelectedWeldLog(selectedWeldLog);
  }, [getDocument, setSelectedWeldLog, weldLogDocumentRef]);

  React.useEffect(() => {
    if (!selectedProject) {
      getProjectDetails();
    }

    if (!selectedWeldLog) {
      getWeldLog();
    }

    const unsubscribe = listenToCollectionUpdates(weldsCollectionRef);

    // Listen to collection updates and cleanup listener on component unmount
    return () => unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box width="100%">
      <Title title={intl.formatMessage(messages.weldLogDetails)} breadcrumbsNav={breadcrumbsNav}/>
      <WeldLogDetailsBar
        weldLogDocumentRef={weldLogDocumentRef}
        weldLogDetails={selectedWeldLog}
        welds={welds}
      />
      <WeldList fetching={fetching} welds={welds} error={error} />
    </Box>
  );
};

export default WeldLogDetails;
