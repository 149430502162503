import React from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

// Components
import Header from "../header/header";
import Footer from "../footer/footer";

const useStyles = makeStyles(({ spacing }) => ({
  wrapper: {
    height: "100VH",
    maxHeight: "100VH",
  },
  container: {
    display: "flex",
    height: "100%",
    placeContent: "center",
    alignItems: "flex-start",
  },
  centerAlign: {
    alignItems: "center",
  },
}));

function PageContainer({ contentAlignment, authenticated, children }) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const isLoginPage = pathname === "/login" || pathname === "/reset-password";

  return (
    <Box display="flex" flexDirection="column" className={classes.wrapper}>
      <Box item="true" xs={12}>
        {!isLoginPage && <Header authenticated={authenticated} />}
      </Box>
      <Box item="true" xs={12} flexGrow={1}>
        {isLoginPage && children}
        {!isLoginPage && (
          <Container
            component="main"
            maxWidth="lg"
            className={clsx(classes.container, {
              [classes.centerAlign]: contentAlignment === "center",
            })}
          >
            {children}
          </Container>
        )}
      </Box>
      <Box item="true" xs={12}>
        {!isLoginPage && <Footer />}
      </Box>
    </Box>
  );
}

export default PageContainer;
