import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { db } from "../../../constants";
import messages from "../../../i18n/messages";

const useStyles = makeStyles(({ spacing }) => ({
  formControl: {
    marginBottom: spacing(8),
    minWidth: 250,
  },
}));

const DocumentTemplates = ({ selectedTemplate, setSelectedDocumentTemplateId }) => {
  const classes = useStyles();
  const [customTemplates, setCustomTemplates] = React.useState([]);
  const intl = useIntl();

  const getDocumentTemplates = React.useCallback(async () => {
    const documentTemplatesCollectionSnapshot = await db
      .collection("project-templates")
      .doc("customs")
      .collection("english")
      .get();

    const templates = documentTemplatesCollectionSnapshot.docs.map((doc) => doc.data());
    setCustomTemplates(
      templates.map((doc) => {
        return { id: doc.id, templateName: doc.templateName };
      })
    );
  }, []);

  React.useEffect(() => {
    getDocumentTemplates();
  }, [getDocumentTemplates]);

  const handleChange = (event) => {
    setSelectedDocumentTemplateId(event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="grouped-select">
        {intl.formatMessage(messages.selectDocumentTemplate)}
      </InputLabel>
      <Select
        defaultValue=""
        id="grouped-select"
        onChange={handleChange}
        value={selectedTemplate || ""}
      >
        {customTemplates.map((template) => (
          <MenuItem value={template?.id}>{template?.templateName}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

DocumentTemplates.propTypes = {
  selectedTemplate: PropTypes.string,
  setSelectedTemplate: PropTypes.func,
};

export default DocumentTemplates;
