import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import { useIntl } from "react-intl";
import messages from '../../i18n/messages';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  dialogContent: {
    [breakpoints.up("md")]: {
      width: 600,
    },
  },
  actionContainer: {
    "& :last-child": {
      marginLeft: spacing(3),
    },
  },
  buttonLabel: {
    marginLeft: "0 !important",
  },
}));
const EditDocumentNameDialog = ({ onClose, open }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCancel = () => {
    onClose(null);
  };

  const handleFileRename = () => {
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const intl = useIntl();

  return (
    (<Dialog fullScreen={fullScreen} onClose={handleCancel} open={open}>
      <DialogTitle id="confirmation-dialog-title">Rename Document</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          autoFocus
          margin="dense"
          id="headerName"
          label="Enter new name"
          type="text"
          fullWidth
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions
        style={{ padding: "12px 24px" }}
        className={classes.actionContainer}
      >
        <Button autoFocus onClick={handleCancel} variant="outlined">
          {intl.formatMessage(messages.close)}
        </Button>
        <Button
          autoFocus
          color="primary"
          disableElevation
          disabled={!value}
          variant="contained"
          onClick={handleFileRename}
          classes={{
            label: classes.buttonLabel,
          }}
        >
          {intl.formatMessage(messages.rename)}
        </Button>
      </DialogActions>
    </Dialog>)
  );
};

export default EditDocumentNameDialog;
