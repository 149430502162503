import React from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { LuPencilLine } from "react-icons/lu";
import { MdOutlineCopyAll } from "react-icons/md";
import { MdOutlineDelete } from "react-icons/md";

import useDuplicateWeldLog from "../hooks/duplicate-weld-log";
import useArchiveWeldLog from "../../../common/utils/archive-weld-log";

import { useIntl } from "react-intl";
import messages from "../../../i18n/messages";

const useStyles = makeStyles(({ palette, spacing }) => ({
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuItemHeight: {
    height: 45,
  },
  iconNormal: {
    fontSize: spacing(2.5),
    marginRight: spacing(3),
  },
  iconSmaller: {
    fontSize: spacing(2.2),
    marginRight: spacing(3),
  },
  menuIcon: {
    fontSize: spacing(2.5),
    marginRight: spacing(2),
    color: palette.secondary.dark,
  },
  menuIconSmaller: {
    fontSize: spacing(2.3),
    marginRight: spacing(2),
    color: palette.secondary.dark,
  },
}));

const ProjectListItemMenu = ({ weldLogData, trashed }) => {
  const intl = useIntl();

  const classes = useStyles();
  const { pid } = useParams();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { duplicateWeldLog } = useDuplicateWeldLog(pid, weldLogData);
  const { archiveWeldLogs } = useArchiveWeldLog(pid, [weldLogData?.id]);

  const handleDuplicateWeldLog = () => {
    duplicateWeldLog();
    handleClose();
  };

  const handleDeleteWeldLog = (status) => {
    archiveWeldLogs(status);
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: "0px" }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        open={open}
        keepMounted
        elevation={5}
        anchorEl={anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            width: 160,
            boxShadow: "none",
            border: "1px solid #e3e3e3",
          },
        }}
        classes={{ list: classes.menuList }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {!trashed && (
          <div key={weldLogData?.id}>
            <MenuItem
              classes={{ root: classes.menuItemHeight }}
              onClick={handleClose}
              component={Link}
              to={{
                pathname: `/projects/${pid}/weld-logs/${weldLogData?.id}/edit`,
                state: { weldLogData },
              }}
            >
              <LuPencilLine className={classes.menuIconSmaller} />
              <Typography display="block">{intl.formatMessage(messages.edit)}</Typography>
            </MenuItem>

            <MenuItem classes={{ root: classes.menuItemHeight }} onClick={handleDuplicateWeldLog}>
              <MdOutlineCopyAll className={classes.menuIconSmaller} />
              <Typography display="block">{intl.formatMessage(messages.duplicate)}</Typography>
            </MenuItem>
            <Divider />

            <MenuItem
              classes={{ root: classes.menuItemHeight }}
              onClick={() => handleDeleteWeldLog("trashed")}
            >
              <MdOutlineDelete className={classes.menuIconSmaller} />
              <Typography display="block">{intl.formatMessage(messages.delete)}</Typography>
            </MenuItem>
          </div>
        )}

        {trashed && (
          <MenuItem
            classes={{ root: classes.menuItemHeight }}
            onClick={() => handleDeleteWeldLog("active")}
          >
            <MdOutlineDelete className={classes.menuIconSmaller} />
            <Typography display="block">{intl.formatMessage(messages.restore)}</Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

ProjectListItemMenu.propTypes = {
  weldLogData: PropTypes.object.isRequired,
};

export default ProjectListItemMenu;
