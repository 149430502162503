import React from "react";
import { useIntl } from "react-intl";

// Material UI
import Box from "@material-ui/core/Box";

// Components
import StateCard from "../../../components/state-card/state-card";
import AddMaterialDailog from "./add-material-dialog";
import AddParentMaterialForm from "./add-parent-material-form";
import ParentMaterialsTable from "./parent-materials-table";

// Constants and utilities
import { db } from "../../../constants";
import messages from "../../../i18n/messages";

const ParentMaterials = () => {
  const intl = useIntl();

  const [open, setOpen] = React.useState(false);
  const [parentMaterials, setParentMaterials] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const parentMaterialsCollectionRef = db
    .collection("materials")
    .doc("parent-materials")
    .collection("items");

  const buttonProps = {
    color: "primary",
    variant: "contained",
    label: intl.formatMessage(messages.createParentMaterial),
    onClick: () => setOpen(true),
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onCollectionUpdate = (querySnapshot) => {
    const materials = [];
    setFetching(true);
    querySnapshot.forEach((doc) => {
      const { id, alloy, type, dimension, thickness, createdAt, createdBy } = doc.data();

      materials.push({
        id,
        alloy,
        type,
        dimension,
        thickness,
        createdAt,
        createdBy,
      });
    });

    setParentMaterials(materials);
    setFetching(false);
  };

  const renderEmptyState = () => {
    return (
      <StateCard
        title={intl.formatMessage(messages.noParentMaterialsYet)}
        description={intl.formatMessage(messages.youCanCreatNewParentMaterials)}
        buttonProps={buttonProps}
      />
    );
  };

  React.useEffect(() => {
    const collectionRef = db
      .collection("materials")
      .doc("parent-materials")
      .collection("items")
      .orderBy("createdAt", "desc");

    collectionRef.onSnapshot(onCollectionUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!parentMaterials.length && !fetching ? (
        renderEmptyState()
      ) : (
        <Box width="100%">
          <ParentMaterialsTable
            rows={parentMaterials}
            fetching={fetching}
            buttonProps={buttonProps}
          />
        </Box>
      )}
      <AddMaterialDailog
        open={open}
        onClose={handleClose}
        Form={AddParentMaterialForm}
        title={intl.formatMessage(messages.createParentMaterial)}
        collectionRef={parentMaterialsCollectionRef}
      />
    </>
  );
};

export default ParentMaterials;
