import React from "react";
import { fieldToTextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";

import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import { RiAddLine } from "react-icons/ri";

const useStyles = makeStyles(({ spacing, palette }) => ({
  icon: {
    color: palette.primary.main,
    marginRight: spacing(1.5),
  },
}));

const InputAutocomplete = ({
  textFieldProps,
  onClickHandler,
  materialRegForm,
  label,
  ...props
}) => {
  const {
    form: { setTouched, setFieldValue },
  } = props;

  const classes = useStyles();

  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;

  const Link = ({ children }) => (
    <Paper>
      <MenuItem
        onMouseDown={(event) => {
          event.preventDefault();
        }}
        onClick={() => onClickHandler(materialRegForm)}
      >
        <RiAddLine size={22} className={classes.icon} /> {label}
      </MenuItem>
      <Divider />
      {children}
    </Paper>
  );

  return (
    <Autocomplete
      {...props}
      {...field}
      PaperComponent={Link}
      onChange={(_, value) => setFieldValue(name, value)}
      onBlur={() => setTouched({ [name]: true })}
      getOptionSelected={(item, current) => item.value === current.value}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          helperText={helperText}
          error={error}
        />
      )}
    />
  );
};

export default InputAutocomplete;
