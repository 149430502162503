import React from "react";
import { useIntl } from "react-intl";

// Material UI
import Box from "@material-ui/core/Box";

// Components
import StateCard from "../../../components/state-card/state-card";
import AddMaterialDailog from "./add-material-dialog";
import AddFillerMaterialForm from "./add-filler-material-form";
import FillerMaterialsTable from "./filler-materials-table";

// Constants and utilities
import { db } from "../../../constants";
import messages from "../../../i18n/messages";

const FillerMaterials = () => {
  const intl = useIntl();

  const [open, setOpen] = React.useState(false);
  const [fillerMaterials, setFillerMaterials] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);

  const fillerMaterialCollectionRef = db
    .collection("materials")
    .doc("filler-materials")
    .collection("items");

  const buttonProps = {
    color: "primary",
    variant: "contained",
    label: intl.formatMessage(messages.createFillerMaterial),
    onClick: () => setOpen(true),
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onCollectionUpdate = (querySnapshot) => {
    const materials = [];
    setFetching(true);
    querySnapshot.forEach((doc) => {
      const { id, name, createdAt, createdBy } = doc.data();
      materials.push({ id, name, createdAt, createdBy });
    });

    setFillerMaterials(materials);
    setFetching(false);
  };

  const renderEmptyState = () => {
    return (
      <StateCard
        title={intl.formatMessage(messages.noFillerMaterialsYet)}
        description={intl.formatMessage(messages.youCanAddFillerMaterials)}
        buttonProps={buttonProps}
      />
    );
  };

  React.useEffect(() => {
    const collectionRef = db
      .collection("materials")
      .doc("filler-materials")
      .collection("items")
      .orderBy("createdAt", "desc");
    collectionRef.onSnapshot(onCollectionUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!fillerMaterials.length && !fetching ? (
        renderEmptyState()
      ) : (
        <Box width="100%">
          <FillerMaterialsTable
            rows={fillerMaterials}
            fetching={fetching}
            buttonProps={buttonProps}
          />
        </Box>
      )}
      <AddMaterialDailog
        open={open}
        onClose={handleClose}
        Form={AddFillerMaterialForm}
        title={intl.formatMessage(messages.createFillerMaterial)}
        collectionRef={fillerMaterialCollectionRef}
      />
    </>
  );
};

export default FillerMaterials;
