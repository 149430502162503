import React from "react";
import firebase from "firebase";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

// Hooks
import useStoreProvider from "../providers/store/use-app-context";

// Utilities and constants
import messages from "../../i18n/messages";
import { NOTIFICATION_POSITION } from "../../constants";

export const useUpdateDocument = () => {
  const intl = useIntl();

  const [state, setState] = React.useState({
    error: false,
    completed: false,
  });

  const ARCHIVE_SUCCESS_MESSAGE = intl.formatMessage(messages.deletedSuccessfully);
  const UPDATE_SUCCESS_MESSAGE = intl.formatMessage(messages.updatedSuccessfully);
  const DOCUMENT_UPDATE_FAILURE_MESSAGE = intl.formatMessage(messages.somethingWentWrong);

  const { loggedInUser } = useStoreProvider();
  const userId = loggedInUser?.uid || "";
  const updatedAt = firebase.firestore.Timestamp.now();

  const updateDocument = React.useCallback(
    async ({ documentRef, payload }) => {
      if (!documentRef) {
        setState({
          error: true,
          completed: false,
        });
        toast.error(DOCUMENT_UPDATE_FAILURE_MESSAGE, {
          position: NOTIFICATION_POSITION,
        });
        return;
      }

      try {
        await documentRef.update({ updatedAt, updatedBy: userId, ...payload });
        setState({ error: false, completed: true });

        toast.success(
          payload.status === "deleted" ? ARCHIVE_SUCCESS_MESSAGE : UPDATE_SUCCESS_MESSAGE,
          {
            position: NOTIFICATION_POSITION,
          }
        );
      } catch (e) {
        setState({ error: true, completed: false });
        toast.error(DOCUMENT_UPDATE_FAILURE_MESSAGE, {
          position: NOTIFICATION_POSITION,
        });
      }
    },
    [
      updatedAt,
      userId,
      ARCHIVE_SUCCESS_MESSAGE,
      UPDATE_SUCCESS_MESSAGE,
      DOCUMENT_UPDATE_FAILURE_MESSAGE,
    ]
  );

  /**
   * This function is used to batch update documents. It expects an array of document
   * references and a payload to update all the documents with.
   * @param {array} documentRefs
   * @param {object} payload
   */
  const updateDocumentsBatch = React.useCallback(
    async ({ documentRefs, payload }) => {
      if (!documentRefs || documentRefs.length === 0) {
        setState({
          error: true,
          completed: false,
        });
        toast.error(DOCUMENT_UPDATE_FAILURE_MESSAGE, {
          position: NOTIFICATION_POSITION,
        });
        return;
      }

      const batch = firebase.firestore().batch();

      documentRefs.forEach((documentRef) => {
        batch.update(documentRef, { updatedAt, updatedBy: userId, ...payload });
      });

      try {
        await batch.commit();
        setState({ error: false, completed: true });

        toast.success(
          payload.status === "deleted" ? ARCHIVE_SUCCESS_MESSAGE : UPDATE_SUCCESS_MESSAGE,
          {
            position: NOTIFICATION_POSITION,
          }
        );
      } catch (e) {
        setState({ error: true, completed: false });
        toast.error(DOCUMENT_UPDATE_FAILURE_MESSAGE, {
          position: NOTIFICATION_POSITION,
        });
      }
    },
    [
      ARCHIVE_SUCCESS_MESSAGE,
      DOCUMENT_UPDATE_FAILURE_MESSAGE,
      UPDATE_SUCCESS_MESSAGE,
      updatedAt,
      userId,
    ]
  );

  return {
    status: state,
    updateDocument,
    updateDocumentsBatch,
  };
};

export default useUpdateDocument;
