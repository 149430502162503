import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import CircularProgress from "@material-ui/core/CircularProgress";

import CheckIcon from "@material-ui/icons/Check";
import { MdErrorOutline } from "react-icons/md";
import { AiOutlineInfoCircle } from "react-icons/ai";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  formFooter: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: spacing(2.4),
    [breakpoints.down("md")]: {
      padding: spacing(1, 1.4),
    },
  },
  actionContainer: {
    "& button:last-child": {
      marginLeft: spacing(3),
      [breakpoints.down("md")]: {
        marginLeft: spacing(1),
      },
    },
  },
  progressIndicator: {
    display: "flex",
    alignItems: "center",
  },
  statusIcon: {
    marginRight: "10px",
  },
}));

export const FormFooter = ({
  submitting,
  submitted,
  errorMessage,
  handleSubmit,
  submitButtonText,
  cancelButtonText,
  cancelButtonProps,
  submitButtonProps,
  progressMessage,
  successMessage,
  notification,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.formFooter}>
      <Box>
        <Hidden mdDown>
          {submitting && (
            <Box className={classes.progressIndicator}>
              <CircularProgress size={20} />
              <Box ml={2}>{progressMessage}</Box>
            </Box>
          )}
          {submitted && (
            <Box
              style={{
                color: "#4EB70B",
                display: "flex",
                placeContent: "center",
                alignItems: "center",
              }}
            >
              <CheckIcon fontSize="24" className={classes.statusIcon} />
              {successMessage}
            </Box>
          )}
          {!submitting && !submitted && errorMessage && (
            <Box
              style={{
                color: "#e5271a",
                display: "flex",
                placeContent: "center",
                alignItems: "center",
              }}
            >
              <MdErrorOutline fontSize="24" className={classes.statusIcon} />
              {errorMessage}
            </Box>
          )}
          {notification && (
            <Box
              style={{
                color: "#2196f3",
                display: "flex",
                placeContent: "center",
                alignItems: "center",
              }}
            >
              <AiOutlineInfoCircle fontSize="24" className={classes.statusIcon} />
              {notification}
            </Box>
          )}
        </Hidden>
      </Box>
      <Box className={classes.actionContainer}>
        <Button
          component={Link}
          variant="outlined"
          className={classes.submit}
          {...cancelButtonProps}
        >
          {cancelButtonText}
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disableElevation
          className={classes.submit}
          onClick={handleSubmit}
          {...submitButtonProps}
        >
          {submitButtonText}
        </Button>
      </Box>
    </Box>
  );
};

export default FormFooter;
