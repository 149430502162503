export const findMissingNumbers = (numArr) => {
  let missing = [];

  for (let i = 1; i < numArr.length; i++) {
    if (numArr[i] - numArr[i - 1] !== 1) {
      let x = numArr[i] - numArr[i - 1];
      let diff = 1;
      while (diff < x) {
        missing.push(numArr[i - 1] + diff);
        diff++;
      }
    }
  }

  return missing;
};
