import * as React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Skeleton from "@material-ui/lab/Skeleton";

import StateCard from "../../components/state-card/state-card";
import useStoreProvider from "../../common/providers/store/use-app-context";
import ProjectDocumentTemplates from "../project-templates/project-document-templates";

import { db } from "../../constants";

import { useIntl } from "react-intl";
import messages from '../../i18n/messages';

const useStyles = makeStyles(({ spacing }) => ({
  dialogContentArea: {
    width: 1380,
  },
  actionContainer: {
    "& :last-child": {
      marginLeft: spacing(3),
    },
  },
}));

export default function DocumentLinkDialog({
  onClose,
  open,
  selectedValue,
  identifier,
}) {
  const intl = useIntl();
  const classes = useStyles();
  const { pid } = useParams();

  const { selectedProject } = useStoreProvider();
  const skeletonArray = Array(15).fill("");

  const [fetching, setFetching] = React.useState(true);
  const [documentSections, setDocumentSections] = React.useState({});
  const [projectDocuments, setProjectDocuments] = React.useState([]);

  const selectedDocumentTemplateId = selectedProject?.documentTemplate;

  const documentsCollectionRef = db
    .collection("projects")
    .doc(selectedProject?.id)
    .collection("documents")
    .orderBy("createdAt", "desc")
    .where("status", "==", "active");

  const getSelectedDocumentTemplate = React.useCallback(async () => {
    if (!selectedDocumentTemplateId || !selectedProject?.id) {
      setFetching(false);
      return;
    }

    setFetching(true);
    const document = await db
      .collection("projects")
      .doc(pid)
      .collection("document-sections")
      .doc(selectedDocumentTemplateId)
      .get();

    setDocumentSections(document.data());
    setFetching(false);
  }, [pid, selectedDocumentTemplateId, selectedProject?.id]);

  const getProjectDocuments = (querySnapshot) => {
    let projectDoc = [];
    querySnapshot.forEach((doc) => {
      const { id, createdAt, createdBy, filename, section, storageRef } =
        doc.data();

      projectDoc.push({
        id,
        section,
        createdAt,
        createdBy,
        filename,
        storageRef,
      });
    });
    setProjectDocuments(projectDoc);
  };

  const handleClose = () => {
    onClose(selectedValue, identifier);
  };

  const handleDocumentSelect = (value) => {
    onClose(value, identifier);
  };

  const renderEmptyStateCard = () => {
    const buttonProps = {
      color: "primary",
      variant: "contained",
      label: intl.formatMessage(messages.goToDocumentsSection),
      to: `/projects/${pid}/documents`,
    };

    return (
      (<StateCard
        title={intl.formatMessage(messages.noDocumentsYet)}
        description={intl.formatMessage(messages.pleaseAddDocumentsUnder)}
        buttonProps={buttonProps}
        width={1330}
        border={false}
      />)
    );
  };

  React.useEffect(() => {
    if (open) {
      documentsCollectionRef.onSnapshot(getProjectDocuments);
      getSelectedDocumentTemplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    (<Dialog onClose={handleClose} open={open} maxWidth="lg">
      <DialogTitle>
        {intl.formatMessage(messages.linkDocument)}
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContentArea}>
        {(() => {
          if (fetching) {
            return skeletonArray.map(() => (
              <Skeleton animation="wave" height={56} />
            ));
          }

          if (!isEmpty(documentSections?.documentSections)) {
            return Object.values(documentSections?.documentSections)
              .sort((a, b) => a.position - b.position)
              .map((section, index) => {
                const docForSection = projectDocuments.filter((doc) => {
                  return doc.section === section.id;
                });
                return (
                  <ProjectDocumentTemplates
                    key={index}
                    showLinkMenu
                    section={section}
                    sectionNumber={index + 1}
                    docForSection={docForSection}
                    onDocumentSelect={handleDocumentSelect}
                  />
                );
              });
          } else {
            return renderEmptyStateCard();
          }
        })()}
      </DialogContent>
      <DialogActions
        style={{ padding: "12px 24px" }}
        className={classes.actionContainer}
      >
        <Button autoFocus onClick={handleClose} variant="outlined">
          {intl.formatMessage(messages.close)}
        </Button>
        {isEmpty(documentSections) && (
          <Button
            color="primary"
            disableElevation
            variant="contained"
            onClick={handleClose}
          >
            {intl.formatMessage(messages.linkDocument)}
          </Button>
        )}
      </DialogActions>
    </Dialog>)
  );
}

DocumentLinkDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string,
};
