import React from "react";
import firebase from "firebase";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  profileImage: {
    objectFit: "cover",
    width: (props) => props.width || 100,
    height: (props) => props.height || 100,
    borderRadius: (props) => props.borderRadius || "100%",
  },
}));

const UserProfileImage = ({
  width,
  height,
  imagePath,
  borderRadius,
  placeholder,
}) => {
  const classes = useStyles({ width, height, borderRadius });
  const [image, setProfileImage] = React.useState("");
  const imagePlaceholder = placeholder;

  const getImageDownloadUrl = React.useCallback(async () => {
    if (!imagePath) return;

    const url = await firebase
      .storage()
      .ref()
      .child(imagePath)
      .getDownloadURL();
    setProfileImage(url);
  }, [imagePath]);

  React.useEffect(() => {
    getImageDownloadUrl();
  }, [getImageDownloadUrl]);

  return (
    <img
      alt=""
      className={classes.profileImage}
      src={image || imagePlaceholder}
    />
  );
};

export default UserProfileImage;
