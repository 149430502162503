import React from "react";
import { Formik, Form } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import useStoreProvider from "../../../common/providers/store/use-app-context";
import { register } from "../../../common/utils/auth";

import { useIntl } from "react-intl";
import messages from '../../../i18n/messages';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: "100%",
  },
  gridItem: {
    padding: `${spacing(1)} !important`,
  },
  formContainer: {
    marginBottom: spacing(3),
  },
  formStatusAndActionContainer: {
    paddingTop: spacing(2),
  },
  progressIndicator: {
    display: "flex",
    alignItems: "center",
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
}));

const RegistrationForm = () => {
  const intl = useIntl();
  const classes = useStyles();

  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const { loggedInUser } = useStoreProvider();
  const adminUserInfo = loggedInUser;
  const initialValue = {
    fname: "",
    lname: "",
    email: "",
    password: "",
    isAdmin: false,
  };

  return (
    (<Formik
      initialValues={initialValue}
      onSubmit={(values, { resetForm }) => {
        const userInfo = {
          fname: values?.fname,
          lname: values?.lname,
          email: values?.email,
          password: values?.password,
          userRole: values?.isAdmin ? ["admin"] : ["user"],
        };
        setSubmitting(true);
        setSubmitted(false);
        register(userInfo, adminUserInfo)
          .then(() => {
            resetForm(initialValue);
            setSubmitting(false);
            setSubmitted(true);
          })
          .catch((error) => {
            setSubmitting(false);
            setErrorMessage(error?.message);
          });
      }}
    >
      {(formik) => (
        <Form className={classes.root}>
          <Grid container spacing={4} className={classes.formContainer}>
            <Grid item xs={12} lg={4} classes={{ item: classes.gridItem }}>
              <TextField
                required
                autoFocus
                fullWidth
                id="fname"
                name="fname"
                label={intl.formatMessage(messages.firstName)}
                margin="normal"
                autoComplete="off"
                value={formik.values?.fname}
                onChange={formik.handleChange}
              />
              <TextField
                required
                fullWidth
                id="lname"
                name="lname"
                label={intl.formatMessage(messages.lastName)}
                margin="normal"
                autoComplete="off"
                value={formik.values?.lname}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={4} classes={{ item: classes.gridItem }}>
              <TextField
                required
                fullWidth
                id="email"
                name="email"
                label={intl.formatMessage(messages.emailAddress)}
                margin="normal"
                autoComplete="off"
                value={formik.values?.email}
                onChange={formik.handleChange}
              />
              <TextField
                required
                fullWidth
                id="password"
                name="password"
                type="password"
                label={intl.formatMessage(messages.password)}
                margin="normal"
                autoComplete="off"
                value={formik.values?.password}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={3}
              classes={{ item: classes.gridItem }}
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              <FormControlLabel
                control={
                  <Switch
                    id="isAdmin"
                    name="isAdmin"
                    checked={formik.values?.isAdmin}
                    onChange={formik.handleChange}
                  />
                }
                label={intl.formatMessage(messages.admin)}
              />
            </Grid>
          </Grid>
          <Divider />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={classes.formStatusAndActionContainer}
          >
            <Box>
              {submitting && (
                <Box className={classes.progressIndicator}>
                  <CircularProgress size={25} />
                  <Box ml={2}>
                    {intl.formatMessage(messages.addingNewUser)}
                  </Box>
                </Box>
              )}
              {submitted && (
                <Alert
                  severity="success"
                  icon={<CheckIcon fontSize="inherit" />}
                >
                  {intl.formatMessage(messages.newUserAdded)}
                </Alert>
              )}
              {!submitting && !submitted && errorMessage && (
                <Alert severity="error">
                  {errorMessage || intl.formatMessage(messages.somethingWentWrong)}
                </Alert>
              )}
            </Box>
            <Box className={classes.actionContainer}>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                disabled={
                  formik.values?.fname === "" ||
                  formik.values?.lname === "" ||
                  formik.values?.email === "" ||
                  formik.values?.password === ""
                }
              >
                {intl.formatMessage(messages.addUser)}
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>)
  );
};

export default RegistrationForm;
