import firebase from "firebase";
import { db, firebaseAuth } from "../../constants";

export async function register(userInfo, adminUserInfo) {
  const { email, password, fname, lname, userRole } = userInfo;
  const { uid, fname: adminFname, lname: adminLname } = adminUserInfo;
  const currentAdminUser = firebaseAuth().currentUser;
  const userCredential = await firebaseAuth().createUserWithEmailAndPassword(
    email,
    password
  );

  const userExtraInfo = {
    uid: userCredential?.user?.uid,
    email: userCredential?.user?.email,
    fname,
    lname,
    userRole,
    addedBy: uid,
    addedByFname: adminFname,
    addedByLname: adminLname,
    createdAt: firebase.firestore.Timestamp.now(),
  };

  await saveExtraUserInfo(userExtraInfo);
  // Prevent admin user from automatically logging in with the newly created
  // user account by setting back the current user, which
  // is current admin user
  firebaseAuth().updateCurrentUser(currentAdminUser);
}

export function logout() {
  return firebaseAuth().signOut();
}

export function login(email, pw) {
  return firebaseAuth().signInWithEmailAndPassword(email, pw);
}

export function resetPassword(email) {
  return firebaseAuth().sendPasswordResetEmail(email);
}

export const saveExtraUserInfo = async (userExtraInfo) => {
  try {
    return await db
      .collection("user-info")
      .doc(userExtraInfo.uid)
      .set({
        ...userExtraInfo,
        photo: "",
        active: true,
        userRole: userExtraInfo?.userRole || ["viewer"],
      });
  } catch (error) {
    console.log("Auth::SaveExtraUserInfo::", error);
    throw error;
  }
};

export const updateExtraUserInfo = async (updatedUserExtraInfo) => {
  try {
    return await db
      .collection("user-info")
      .doc(updatedUserExtraInfo.uid)
      .update({
        ...updatedUserExtraInfo,
      });
  } catch (error) {
    console.log("Auth::UpdateExtraUserInfo::", error);
    throw error;
  }
};

export const deactivateUsers = async (uids) => {
  const batch = db.batch();
  const userInfoCollectionRef = db.collection("user-info");
  try {
    uids.forEach((uid) => {
      batch.update(userInfoCollectionRef.doc(uid), { uid, active: false });
    });
    return await batch.commit();
  } catch (error) {
    console.log("Auth::deactivateUsers::", error);
    throw error;
  }
};
