import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";

// Material
import { lighten, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Popover from "@material-ui/core/Popover";

// Icon
import { LuPencilLine } from "react-icons/lu";
import { MdOutlineDelete } from "react-icons/md";
import { AiOutlineInfoCircle } from "react-icons/ai";

// Hooks
import useStoreProvider from "../../../common/providers/store/use-app-context";
import useUpdateDocument from "../../../common/utils/use-update-document";
import useDeleteDocuments from "../../../common/utils/use-delete-documents";
import useWeldEventLoggingForm from "../../../common/utils/use-weld-event-logging-form";
import useTablePaginationSettings from "../../../common/utils/use-table-pagination-settings";
import useRemoveWeldFromNdtOrder from "../../../common/utils/use-remove-weld-from-ndt-order";

// Components
import Indicator from "../../../components/indicator/indicator";
import WeldActionMenu from "../../weld-details/partials/weld-action-menu";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import LoadingStateCard from "../../../components/loading-state-card/loading-state-card";
import ExternalDocumentLink from "../../../components/external-link/external-link";
import RoleAccess from "../../../components/role-access/role-access";
import WeldEventLoggingDialog from "../../weld-details/partials/weld-event-logging-dialog";
import AddWeldToNdtOrderForm from "../../weld-details/partials/add-weld-to-ndt-order-form";

// Utilities and constants
import extractEventDetails from "../../../common/utils/extract-event-details";
import { db, USER_ROLES } from "../../../constants";
import { getWeldStatus, getHeatTreatmentStatus } from "../../../common/utils/evaluate-weld-status";
import messages from "../../../i18n/messages";

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: spacing(3),
    borderRadius: spacing(0.5),
    boxShadow: "none",
    border: "1px solid #e3e3e3",
  },
  table: {
    minWidth: 750,
  },
  tableCell: {
    whiteSpace: "nowrap",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  imageLinkContainer: {
    display: "flex",
    alignItems: "center",
  },
  popover: {
    padding: spacing(2),
  },
  popoverButtonContainer: {
    marginTop: spacing(2),
    display: "flex",
    placeContent: "space-between",
  },
  actions: {
    marginLeft: 0,
  },
  menuIcon: {
    fontSize: spacing(2.5),
    marginRight: spacing(2),
    color: palette.secondary.dark,
  },
  menuIconSmaller: {
    fontSize: spacing(2.3),
    marginRight: spacing(2),
    color: palette.secondary.dark,
  },
  menuIconSmallerEdit: {
    fontSize: spacing(2.3),
    marginRight: spacing(2),
    color: palette.grey[500],
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// ToDo: Currently table sorting is not working when row value is an object.
// One possible solution would be to flatten out nested array values and use
// a modified version of the row value.
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const CustomTableCell = ({ weld }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { pid, weldLogId } = useParams();
  const { updateDocument } = useUpdateDocument();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [position, setPosition] = React.useState(null);

  const preventRowSelection = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    preventRowSelection(e);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    preventRowSelection(e);
  };

  const handleChange = (e) => {
    setPosition(e.target.value);
  };

  const handleUpdateWeldPosition = (e) => {
    const documentRef = db
      .collection("projects")
      .doc(pid)
      .collection("weld-logs")
      .doc(weldLogId)
      .collection("welds")
      .doc(weld?.id);

    updateDocument({
      documentRef,
      payload: { position: position },
    });
    handleClose(e);
    preventRowSelection(e);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <TableCell className={classes.tableCell} align="left" onClick={handleClick}>
        {!weld?.position && (
          <Box display="flex" alignContent="center">
            <LuPencilLine className={classes.menuIconSmallerEdit} />
            {intl.formatMessage(messages.addPosition)}
          </Box>
        )}
        {weld?.position && (
          <Box display="flex" alignContent="center">
            <LuPencilLine className={classes.menuIconSmallerEdit} />
            {weld?.position}
          </Box>
        )}
      </TableCell>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box display="flex" flexDirection="column" className={classes.popover}>
          <Typography variant="title">{intl.formatMessage(messages.updateWeldPosition)}</Typography>
          <TextField
            id="position"
            onChange={handleChange}
            onClick={(e) => preventRowSelection(e)}
          />
          <Box className={classes.popoverButtonContainer}>
            <Button variant="outlined" size="small" onClick={handleClose}>
              {intl.formatMessage(messages.cancel)}
            </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              size="small"
              onClick={(e) => handleUpdateWeldPosition(e)}
            >
              {intl.formatMessage(messages.save)}
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

const WeldListTableHead = (props) => {
  const intl = useIntl();

  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "weldNumber",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.number),
    },
    {
      id: "parentMaterials",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.parentMaterials),
    },
    {
      id: "position",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.position),
    },
    {
      id: "wps",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.wps),
    },
    {
      id: "weld",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.weld),
    },
    {
      id: "heatTreatment",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.ht),
    },
    {
      id: "weldActivity",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.lastActivity),
    },
    {
      id: "actions",
      numeric: false,
      label: "",
      align: "right",
    },
  ];

  return (
    <TableHead style={{ backgroundColor: "#e9e9e9" }}>
      <TableRow>
        <TableCell className={classes.tableCell} padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": intl.formatMessage(messages.selectAllWelds),
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tableCell}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

WeldListTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1",
  },
  addButton: {
    padding: theme.spacing(0.5, 4),
  },
  statusIcon: {
    marginRight: "10px",
  },
  infoBox: {
    display: "flex",
    color: "#2196f3",
    alignItems: "center",
    placeContent: "center",
  },
}));

const WeldListTableToolbar = ({
  selectedWeldIds,
  numSelected,
  buttonProps,
  setSelectedWeldIds,
  allOnSameWeldLogState,
  weldLogState,
  selectedWelds,
  handleWeldLogDialogOpen,
}) => {
  const intl = useIntl();
  const classes = useToolbarStyles();
  const { pid, weldLogId } = useParams();
  const { removeWeldsFromNdtOrder } = useRemoveWeldFromNdtOrder(pid);

  const [confirmOpen, setConfirmOpen] = React.useState(false);

  const removeSuccessMessage = intl.formatMessage(messages.weldsDeletedSuccessfully);
  const removeFailureMessage = intl.formatMessage(messages.somethingWentWrong);
  const collectionRef = db
    .collection("projects")
    .doc(pid)
    .collection("weld-logs")
    .doc(weldLogId)
    .collection("welds");

  const { deleteDocuments } = useDeleteDocuments(
    collectionRef,
    selectedWeldIds,
    setSelectedWeldIds,
    removeSuccessMessage,
    removeFailureMessage
  );

  const handleDeleteWelds = React.useCallback(async () => {
    const weldsToRemoveFromNdtOrder = selectedWelds
      .filter((weld) => weld?.ndtOrder?.ndtOrderId)
      .map((weld) => {
        return {
          weldId: weld.id,
          weldLogId: weld.weldLogId,
          ndtOrderId: weld.ndtOrder?.ndtOrderId,
        };
      });

    removeWeldsFromNdtOrder(weldsToRemoveFromNdtOrder);
    deleteDocuments();
    setSelectedWeldIds([]);
  }, [selectedWelds, setSelectedWeldIds, deleteDocuments, removeWeldsFromNdtOrder]);

  /**
   * In case of multi logging check if selected welds have identical parent and filler materials.
   */
  const checkMaterialsEquality = (materialsArray) => {
    if (materialsArray.length === 0) return true;

    const firstObjectParentMaterials = materialsArray[0].parentMaterials;
    const firstObjectFillerMaterials = materialsArray[0].fillerMaterials;

    const firstObjectParentMaterialValues = new Set(
      firstObjectParentMaterials.map((material) => material.value)
    );
    const firstObjectFillerMaterialValues = new Set(
      firstObjectFillerMaterials.map((material) => material.value)
    );

    return materialsArray.every((object) => {
      return (
        object.parentMaterials.length === firstObjectParentMaterials.length &&
        object.fillerMaterials.length === firstObjectFillerMaterials.length &&
        object.parentMaterials.every((material) =>
          firstObjectParentMaterialValues.has(material.value)
        ) &&
        object.fillerMaterials.every((material) =>
          firstObjectFillerMaterialValues.has(material.value)
        )
      );
    });
  };

  const validForMultiLogging = checkMaterialsEquality(selectedWelds);

  const showNotInSameWeldStateInfo =
    numSelected > 0 && !allOnSameWeldLogState && weldLogState.some((weld) => weld.form);

  const showNotWithSameMaterials =
    numSelected > 0 && allOnSameWeldLogState && !validForMultiLogging;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} variant="subtitle1" component="div">
          {numSelected} {intl.formatMessage(messages.selected)}
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      )}
      <Box style={{ marginRight: 20 }}>
        {allOnSameWeldLogState &&
          validForMultiLogging &&
          selectedWeldIds.length > 0 &&
          weldLogState[0]?.formTitle === intl.formatMessage(messages.logWeld) && (
            <Button
              className={classes.editButton}
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => weldLogState[0]?.buttonProps?.onClick()}
            >
              {intl.formatMessage(messages.logWeld)}
            </Button>
          )}

        {allOnSameWeldLogState &&
          selectedWeldIds.length > 0 &&
          weldLogState[0]?.formTitle === intl.formatMessage(messages.logHeatTreatment) && (
            <Button
              className={classes.editButton}
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => weldLogState[0]?.buttonProps?.onClick()}
            >
              {intl.formatMessage(messages.logHeatTreatment)}
            </Button>
          )}

        {showNotInSameWeldStateInfo && (
          <Box className={classes.infoBox}>
            <AiOutlineInfoCircle fontSize="24" className={classes.statusIcon} />
            {intl.formatMessage(messages.selectedWeldsNotOnSameWeldEvent)}
          </Box>
        )}

        {showNotWithSameMaterials && (
          <Box className={classes.infoBox}>
            <AiOutlineInfoCircle fontSize="24" className={classes.statusIcon} />
            {intl.formatMessage(messages.youHaveWeldsWithDifferentSet)}
          </Box>
        )}
      </Box>
      <RoleAccess
        roles={[
          USER_ROLES.ADMIN,
          USER_ROLES.PROJECT_LEADER,
          USER_ROLES.WELDING_COORDINATOR,
          USER_ROLES.RESPONSIBLE_WELDING_COORDINATOR,
        ]}
      >
        {numSelected > 0 ? (
          <>
            <Button
              className={classes.editButton}
              color="primary"
              variant="contained"
              disableElevation
              onClick={handleWeldLogDialogOpen}
            >
              {intl.formatMessage(messages.addToNdtOrder)}
            </Button>

            <Tooltip title={intl.formatMessage(messages.delete)}>
              <IconButton
                aria-label={intl.formatMessage(messages.delete)}
                onClick={() => setConfirmOpen(true)}
              >
                <MdOutlineDelete className={classes.menuIcon} />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <Button
            className={classes.addButton}
            disableElevation
            component={Link}
            to={buttonProps.to}
            {...buttonProps}
          >
            {intl.formatMessage(messages.add)}
          </Button>
        )}
      </RoleAccess>
      <Divider />
      <ConfirmDialog
        title={intl.formatMessage(messages.deleteWeld)}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDeleteWelds}
      >
        {`${intl.formatMessage(messages.areYouSureToDeleteSelected)} ${numSelected} ${
          numSelected > 1
            ? intl.formatMessage(messages.lowerCaseWelds)
            : intl.formatMessage(messages.lowerCaseWeld)
        }?`}
      </ConfirmDialog>
    </Toolbar>
  );
};

WeldListTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function WeldListTable({ rows, buttonProps, fetching, projectId, weldLogId }) {
  const intl = useIntl();
  const classes = useStyles();
  const { rowsPerPageOptions, rowsPerPage, setRowsPerPage } = useTablePaginationSettings(rows);

  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState("asc");
  const [isWeldEventLogDialogOpen, setWeldEventLogDialogOpen] = React.useState(false);
  const [isAddToNdtOrderDialogOpen, setAddToNdtOrderDialogOpen] = React.useState(false);
  const [selectedWeldIds, setSelectedWeldIds] = React.useState([]);
  const [selectedWelds, setSelectedWelds] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState("createdAt.seconds");
  const [weldLogState, setWeldLogState] = React.useState([]);
  const [allOnSameWeldLogState, setAllOnSameWeldLogState] = React.useState(false);
  const [eventUpdated, setEventUpdated] = React.useState(false);

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedWeldIds = rows.map((n) => n.id);
      setSelectedWelds(rows);
      setSelectedWeldIds(newSelectedWeldIds);
      return;
    }
    setSelectedWeldIds([]);
  };

  const handleClick = (allWelds, weld) => {
    let newSelectedWeldId = [];
    const selectedIndex = selectedWeldIds.indexOf(weld?.id);

    if (selectedIndex === -1) {
      newSelectedWeldId = newSelectedWeldId.concat(selectedWeldIds, weld?.id);
    } else if (selectedIndex === 0) {
      newSelectedWeldId = newSelectedWeldId.concat(selectedWeldIds.slice(1));
    } else if (selectedIndex === selectedWeldIds.length - 1) {
      newSelectedWeldId = newSelectedWeldId.concat(selectedWeldIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedWeldId = newSelectedWeldId.concat(
        selectedWeldIds.slice(0, selectedIndex),
        selectedWeldIds.slice(selectedIndex + 1)
      );
    }

    const selectedWelds = allWelds.filter((w) => newSelectedWeldId.indexOf(w.id) > -1);

    setSelectedWelds(selectedWelds);
    setSelectedWeldIds(newSelectedWeldId);
  };

  const handleLogEventOpen = () => {
    setWeldEventLogDialogOpen(true);
  };

  const handleLogEventClose = () => {
    setWeldEventLogDialogOpen(false);
  };

  const handleWeldLogDialogOpen = () => {
    setAddToNdtOrderDialogOpen(true);
  };

  const handleNdtOrderDialogClose = () => {
    setAddToNdtOrderDialogOpen(false);
  };
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selectedWeldIds.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const { setSelectedWeld } = useStoreProvider();
  const weldStates = useWeldEventLoggingForm({
    selectedWelds,
    weldEventToEdit: null,
    onClickHandler: handleLogEventOpen,
  });

  React.useEffect(() => {
    if (eventUpdated) {
      setSelectedWeldIds([]);
      setEventUpdated(false);
    }
    if (weldStates.length) {
      const allOnSameWeldLogState = weldStates?.every(
        (obj) => obj.formTitle === weldStates[0].formTitle && obj.form !== null
      );
      setAllOnSameWeldLogState(allOnSameWeldLogState);
      setWeldLogState(weldStates);
    }
  }, [weldStates, eventUpdated]);

  return (
    <div className={classes.root}>
      {fetching ? (
        <LoadingStateCard />
      ) : (
        <Paper className={classes.paper}>
          <WeldListTableToolbar
            buttonProps={buttonProps}
            weldLogState={weldLogState}
            eventUpdated={eventUpdated}
            selectedWelds={selectedWelds}
            selectedWeldIds={selectedWeldIds}
            numSelected={selectedWeldIds.length}
            allOnSameWeldLogState={allOnSameWeldLogState}
            setSelectedWeldIds={setSelectedWeldIds}
            handleWeldLogDialogOpen={handleWeldLogDialogOpen}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <WeldListTableHead
                classes={classes}
                numSelected={selectedWeldIds.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />

              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const weld = rows.find((item) => item.id === row.id);
                    weld.isEditMode = false;
                    const weldStatus = getWeldStatus(row, intl);
                    const heatTreatmentStatus = getHeatTreatmentStatus(row, intl);
                    const { eventTime, eventOwner } = extractEventDetails(row, intl);
                    const linkToWeldDetails = `/projects/${projectId}/weld-logs/${weldLogId}/welds/${weld.id}`;

                    const renderParentMaterials = () =>
                      row?.parentMaterials?.length > 1 ? (
                        <Box display="flex" flexDirection="row">
                          <Typography>{row?.parentMaterials[0].label}</Typography>
                          <Typography variant="caption">
                            +{row?.parentMaterials?.length - 1}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography>
                          {row?.parentMaterials?.length ? row?.parentMaterials[0].label : ""}
                        </Typography>
                      );

                    const renderLinkedDocuments = () =>
                      row?.linkedDocuments?.length > 1 ? (
                        <Box display="flex" flexDirection="row">
                          <ExternalDocumentLink fileData={row?.linkedDocuments[0]} />
                          <Typography variant="caption">
                            +{row?.linkedDocuments?.length - 1}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography>
                          <ExternalDocumentLink
                            fileData={row?.linkedDocuments?.length ? row?.linkedDocuments[0] : []}
                          />
                        </Typography>
                      );

                    return (
                      <TableRow
                        hover
                        onClick={() => handleClick(rows, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell className={classes.tableCell} padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          id={labelId}
                          scope="row"
                          component={Link}
                          to={linkToWeldDetails}
                          style={{ whiteSpace: "nowrap" }}
                          onClick={() => setSelectedWeld(weld)}
                        >
                          {row.weldNumber}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {renderParentMaterials()}
                        </TableCell>
                        <CustomTableCell weld={weld} />
                        <TableCell
                          className={classes.tableCell}
                          align="left"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {renderLinkedDocuments()}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          <Box display="flex" alignItems="baseline">
                            <Indicator state={weldStatus.status} />
                            <Typography variant="body2">{weldStatus.label}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          <Box display="flex" alignItems="baseline">
                            {heatTreatmentStatus.status !== "notrequired" && (
                              <Indicator state={heatTreatmentStatus.status} />
                            )}
                            <Typography variant="body2">{heatTreatmentStatus.label}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell
                          align="left"
                          padding="checkbox"
                          className={classes.tableCell}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <Typography>{eventTime}</Typography>
                          {eventTime && eventTime !== "n/a" && (
                            <Typography variant="caption">
                              {intl.formatMessage(messages.by)} {eventOwner}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          align="left"
                          style={{ width: 50 }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <RoleAccess
                            roles={[
                              USER_ROLES.ADMIN,
                              USER_ROLES.PROJECT_LEADER,
                              USER_ROLES.WELDING_COORDINATOR,
                              USER_ROLES.RESPONSIBLE_WELDING_COORDINATOR,
                            ]}
                          >
                            <WeldActionMenu weld={weld} />
                          </RoleAccess>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell className={classes.tableCell} colSpan={12} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            classes={{ actions: classes.actions }}
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {allOnSameWeldLogState && (
            <WeldEventLoggingDialog
              multilog={true}
              open={isWeldEventLogDialogOpen}
              Form={weldLogState[0]?.form} // All welds are on the same weld log state. So we can use the first form.
              title={weldLogState[0]?.formTitle} // All welds are on the same weld log state. So we can use the first form title.
              disabled={weldLogState[0]?.disabled}
              selectedWeldsForMassLogging={selectedWelds}
              usersInvolved={weldLogState[0]?.usersInvolved}
              onClose={handleLogEventClose}
              onEventLogged={setEventUpdated}
            />
          )}
          {isSelected && (
            <WeldEventLoggingDialog
              multilog={true}
              open={isAddToNdtOrderDialogOpen}
              Form={AddWeldToNdtOrderForm}
              title={intl.formatMessage(messages.addWeldToNdtOrder)}
              selectedWeldsForMassLogging={selectedWelds}
              onClose={() => handleNdtOrderDialogClose()}
              onEventLogged={setEventUpdated}
            />
          )}
        </Paper>
      )}
    </div>
  );
}
