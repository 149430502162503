import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Typography from "@material-ui/core/Typography";

import { LuPencilLine } from "react-icons/lu";
import { MdOutlineDelete } from "react-icons/md";

import useStoreProvider from "../../../common/providers/store/use-app-context";
import { db } from "../../../constants";

import { useIntl } from "react-intl";
import messages from "../../../i18n/messages";

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    display: "flex",
    placeContent: "flex",
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuItemHeight: {
    height: 45,
  },
  menuIcon: {
    fontSize: spacing(2.5),
    marginRight: spacing(2),
    color: palette.secondary.dark,
  },
  menuIconSmaller: {
    fontSize: spacing(2.3),
    marginRight: spacing(2),
    color: palette.secondary.dark,
  },
}));

const ProjectParticipantsListItemMenu = ({ onOpen, projectParticipantToEdit }) => {
  const classes = useStyles();
  const { selectedProject } = useStoreProvider();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const projectParticipantsCollectionRef = db
    .collection("projects")
    .doc(`${selectedProject?.id}`)
    .collection("participants");

  const handleClick = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditParticipant = () => {
    onOpen(projectParticipantToEdit);
    handleClose();
  };

  const handleRemoveParticipant = (uid) => {
    projectParticipantsCollectionRef
      .doc(projectParticipantToEdit?.userInfo?.uid)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
    handleClose();
  };

  const intl = useIntl();

  return (
    <Box className={classes.container}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: "0px" }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        open={open}
        keepMounted
        elevation={5}
        anchorEl={anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            width: 240,
            boxShadow: "none",
            border: "1px solid #e3e3e3",
          },
        }}
        classes={{ list: classes.menuList }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem classes={{ root: classes.menuItemHeight }} onClick={handleEditParticipant}>
          <LuPencilLine className={classes.menuIcon} />
          <Box>
            <Typography display="block" gutterBottom>
              {intl.formatMessage(messages.edit)}
            </Typography>
          </Box>
        </MenuItem>

        <MenuItem classes={{ root: classes.menuItemHeight }} onClick={handleRemoveParticipant}>
          <MdOutlineDelete className={classes.menuIcon} />
          <Box>
            <Typography display="block">
              {intl.formatMessage(messages.removeFromProject)}
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ProjectParticipantsListItemMenu;
