import React from "react";
import firebase from "firebase";
import { useIntl } from "react-intl";
import { Formik, Form } from "formik";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// Hooks
import useStoreProvider from "../../../common/providers/store/use-app-context";

// Utilities and constants
import { db } from "../../../constants";
import messages from "../../../i18n/messages";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: "100%",
  },
  formContainer: {
    marginBottom: spacing(3),
  },
  formStatusAndActionContainer: {
    paddingTop: spacing(2),
  },
  actionContainer: {
    "& :last-child": {
      marginLeft: spacing(3),
    },
  },
  buttonLabel: {
    marginLeft: "0 !important",
  },
  menuPaper: {
    maxHeight: 400,
  },
  progressIndicator: {
    display: "flex",
    alignItems: "center",
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
}));

const AddParentMaterialForm = ({ onClose, collectionRef }) => {
  const intl = useIntl();
  const classes = useStyles();

  const { loggedInUser } = useStoreProvider();
  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [alloys, setAlloys] = React.useState([]);

  const parentMaterialId = collectionRef.doc().id;

  const initialValue = {
    alloy: "",
    type: "",
    dimension: "",
    thickness: "",
  };

  const getAlloys = React.useCallback(async () => {
    const alloyCollectionSnapshot = await db
      .collection("materials")
      .doc("alloy-materials")
      .collection("items")
      .get();
    const alloysData = alloyCollectionSnapshot.docs.map((doc) => doc.data());
    setAlloys(alloysData.map((alloyData) => alloyData.name));
  }, []);

  React.useEffect(() => {
    getAlloys();
  }, [getAlloys]);

  return (
    <Formik
      initialValues={initialValue}
      onSubmit={(values, { resetForm }) => {
        const materialInfo = {
          id: parentMaterialId,
          alloy: values?.alloy,
          type: values?.type,
          dimension: values?.dimension,
          thickness: values?.thickness,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: `${loggedInUser?.fname} ${loggedInUser?.lname}`,
          createdByUid: loggedInUser?.uid,
        };

        setSubmitting(true);
        setSubmitted(false);

        collectionRef
          .doc(`${parentMaterialId}`)
          .set(materialInfo)
          .then(() => {
            resetForm({});
            setSubmitting(false);
            setSubmitted(true);
          })
          .catch((error) => {
            setSubmitting(false);
            console.log(error);
            setErrorMessage(intl.formatMessage(messages.somethingWentWrong));
          });
      }}
    >
      {(formik) => (
        <Form className={classes.root}>
          <Grid container spacing={4} className={classes.formContainer}>
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth margin="normal" size="small">
                <InputLabel>{intl.formatMessage(messages.alloys)}</InputLabel>
                <Select
                  required
                  fullWidth
                  id="alloy"
                  name="alloy"
                  label={intl.formatMessage(messages.alloys)}
                  margin="normal"
                  autoComplete="off"
                  value={formik.values?.alloy}
                  onChange={formik.handleChange}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  <MenuItem value="">
                    <em>{intl.formatMessage(messages.none)}</em>
                  </MenuItem>
                  {alloys.map((value, index) => (
                    <MenuItem key={index} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                required
                fullWidth
                id="type"
                name="type"
                label={intl.formatMessage(messages.type)}
                margin="normal"
                autoComplete="off"
                value={formik.values?.type}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                required
                fullWidth
                id="dimension"
                name="dimension"
                label={intl.formatMessage(messages.dimension)}
                margin="normal"
                autoComplete="off"
                value={formik.values?.dimension}
                onChange={formik.handleChange}
              />
              <TextField
                required
                fullWidth
                id="thickness"
                name="thickness"
                type="thickness"
                label={intl.formatMessage(messages.thickness)}
                margin="normal"
                autoComplete="off"
                value={formik.values?.thickness}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <Divider />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={classes.formStatusAndActionContainer}
          >
            <Box>
              {submitting && (
                <Box className={classes.progressIndicator}>
                  <CircularProgress size={25} />
                  <Box ml={2}>{intl.formatMessage(messages.addingNewParentMaterial)}</Box>
                </Box>
              )}
              {submitted && (
                <Alert severity="success" icon={<CheckIcon fontSize="inherit" />}>
                  {intl.formatMessage(messages.parentMaterialAddedSuccessfully)}
                </Alert>
              )}
              {!submitting && !submitted && errorMessage && (
                <Alert severity="error" mt={10}>
                  {errorMessage}
                </Alert>
              )}
            </Box>
            <Box className={classes.actionContainer}>
              <Button autoFocus onClick={() => onClose()} variant="outlined">
                {intl.formatMessage(messages.close)}
              </Button>
              <Button
                type="submit"
                color="primary"
                disableElevation
                variant="contained"
                classes={{
                  label: classes.buttonLabel,
                }}
                disabled={
                  formik.values?.alloy === "" ||
                  formik.values?.type === "" ||
                  formik.values?.dimension === "" ||
                  formik.values?.thickness === ""
                }
              >
                {intl.formatMessage(messages.create)}
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddParentMaterialForm;
