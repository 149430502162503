import React from "react";

import Title from "../../../components/title/title";
import StateCard from "../../../components/state-card/state-card";
import LoadingStateCard from "../../../components/loading-state-card/loading-state-card";
import WeldListTable from "./weld-list-table";

import useStoreProvider from "../../../common/providers/store/use-app-context";

import { useIntl } from "react-intl";
import messages from '../../../i18n/messages';

function WeldList({ fetching, welds, error }) {
  const { selectedProject, selectedWeldLog } = useStoreProvider();

  const intl = useIntl();

  const buttonProps = {
    color: "primary",
    variant: "contained",
    label: intl.formatMessage(messages.addWeld),
    to: `/projects/${selectedProject?.id}/weld-logs/${selectedWeldLog?.id}/welds/add`,
  };

  const renderEmptyState = () => {
    return (
      (<StateCard
        title={intl.formatMessage(messages.noWeldsUnderWeldLog)}
        description={intl.formatMessage(messages.youCanStartByCreatingWeld)}
        buttonProps={buttonProps}
      />)
    );
  };

  return (<>
    {fetching ? (
      <LoadingStateCard />
    ) : error ? (
      <StateCard
        title={intl.formatMessage(messages.errorFetchingData)}
        description={error.message || intl.formatMessage(messages.unknownErrorOccurred)}
      />
    ) : (
      <>
        {welds && welds.length ? (
          <>
            <Title title={intl.formatMessage(messages.welds)} />
            <WeldListTable
              rows={welds}
              fetching={fetching}
              buttonProps={buttonProps}
              projectId={selectedProject?.id}
              weldLogId={selectedWeldLog?.id}
            />
          </>
        ) : (
          renderEmptyState()
        )}
      </>
    )}
  </>);
}

WeldList.propTypes = {};

export default WeldList;
