import { defineMessages } from "react-intl";

export default defineMessages({
  NdtOrderWillBeReadyOnce: {
    id: "NdtOrderWillBeReadyOnce",
    defaultMessage: "NDT order will be ready once compiling welds is complete.",
    description: "",
  },
  accountActivated: {
    id: "accountActivated",
    defaultMessage: "Account activated!",
    description: "",
  },
  accountDeactivated: {
    id: "accountDeactivated",
    defaultMessage: "Account deactivated!",
    description: "",
  },
  accountDeleted: {
    id: "accountDeleted",
    defaultMessage: "Account deleted!",
    description: "",
  },
  accountDemotedToUser: {
    id: "accountDemotedToUser",
    defaultMessage: "Account demoted to user!",
    description: "",
  },
  accountUpgradedToAdmin: {
    id: "accountUpgradedToAdmin",
    defaultMessage: "Account upgraded to admin!",
    description: "",
  },
  activateAccount: {
    id: "activateAccount",
    defaultMessage: "Activate account",
    description: "",
  },
  active: {
    id: "active",
    defaultMessage: "Active",
    description: "",
  },
  activeSince: {
    id: "activeSince",
    defaultMessage: "Active since",
    description: "",
  },
  activities: {
    id: "activities",
    defaultMessage: "Activities",
    description: "",
  },
  add: {
    id: "add",
    defaultMessage: "Add",
    description: "",
  },
  addNewSection: {
    id: "addNewSection",
    defaultMessage: "Add New Section",
    description: "",
  },
  addParticipant: {
    id: "addParticipant",
    defaultMessage: "Add participant",
    description: "",
  },
  addParticipants: {
    id: "addParticipants",
    defaultMessage: "Add participants",
    description: "",
  },
  addPosition: {
    id: "addPosition",
    defaultMessage: "Add position",
    description: "",
  },
  addProject: {
    id: "addProject",
    defaultMessage: "Add project",
    description: "",
  },
  addReportDescription: {
    id: "addReportDescription",
    defaultMessage: "Add report description (or note)",
    description: "",
  },
  addSection: {
    id: "addSection",
    defaultMessage: "Add section",
    description: "",
  },
  addToNdtOrder: {
    id: "addToNdtOrder",
    defaultMessage: "Add to NDT order",
    description: "",
  },
  addingToNdtOrder: {
    id: "addingToNdtOrder",
    defaultMessage: "Adding to NDT order...",
    description: "",
  },
  addUser: {
    id: "addUser",
    defaultMessage: "Add user",
    description: "",
  },
  addWeld: {
    id: "addWeld",
    defaultMessage: "Add weld",
    description: "",
  },
  addWeldLog: {
    id: "addWeldLog",
    defaultMessage: "Add weld log",
    description: "",
  },
  addWeldToNdtOrder: {
    id: "addWeldToNdtOrder",
    defaultMessage: "Add weld to NDT order",
    description: "",
  },
  addedBy: {
    id: "addedBy",
    defaultMessage: "Added by",
    description: "",
  },
  addingNewAlloy: {
    id: "addingNewAlloy",
    defaultMessage: "adding new alloy",
    description: "",
  },
  addingNewFillerMaterial: {
    id: "addingNewFillerMaterial",
    defaultMessage: "adding new filler material",
    description: "",
  },
  addingNewParentMaterial: {
    id: "addingNewParentMaterial",
    defaultMessage: "adding new parent material",
    description: "",
  },
  addingNewUser: {
    id: "addingNewUser",
    defaultMessage: "adding new user",
    description: "",
  },
  addingProject: {
    id: "addingProject",
    defaultMessage: "Adding project",
    description: "",
  },
  addingUserToProject: {
    id: "addingUserToProject",
    defaultMessage: "Adding user to project",
    description: "",
  },
  address: {
    id: "address",
    defaultMessage: "Address",
    description: "",
  },
  admin: {
    id: "admin",
    defaultMessage: "Admin",
    description: "",
  },
  alloyAddedSuccessfully: {
    id: "alloyAddedSuccessfully",
    defaultMessage: "Alloy added successfully!",
    description: "",
  },
  alloyMaterial: {
    id: "alloyMaterial",
    defaultMessage: "Alloy material",
    description: "",
  },
  alloyMaterialDeletedSuccessfully: {
    id: "alloyMaterialDeletedSuccessfully",
    defaultMessage: "Alloy material(s) deleted successfully",
    description: "",
  },
  alloyName: {
    id: "alloyName",
    defaultMessage: "Alloy name",
    description: "",
  },
  alloys: {
    id: "alloys",
    defaultMessage: "Alloys",
    description: "",
  },
  anUnknownErrorOccurred: {
    id: "anUnknownErrorOccurred",
    defaultMessage: "An unknown error occurred.",
    description: "",
  },
  approvalStatus: {
    id: "approvalStatus",
    defaultMessage: "Approval status",
    description: "",
  },
  approve: {
    id: "approve",
    defaultMessage: "Approve",
    description: "",
  },
  approved: {
    id: "approved",
    defaultMessage: "Approved",
    description: "",
  },
  archive: {
    id: "archive",
    defaultMessage: "Archive",
    description: "",
  },
  unarchive: {
    id: "unarchive",
    defaultMessage: "Unarchive",
    description: "",
  },
  archived: {
    id: "archived",
    defaultMessage: "Archived",
    description: "",
  },
  areYouSureToDeleteSelected: {
    id: "areYouSureToDeleteSelected",
    defaultMessage: "Are you sure you want to delete selected",
    description: "",
  },
  atLeastOneFillerMaterialRequired: {
    id: "atLeastOneFillerMaterialRequired",
    defaultMessage: "At least one filler material is required",
    description: "",
  },
  atLeastOneParentMaterialRequired: {
    id: "atLeastOneParentMaterialRequired",
    defaultMessage: "At least one parent material is required",
    description: "",
  },
  attachment: {
    id: "attachment",
    defaultMessage: "Attachment",
    description: "",
  },
  attachments: {
    id: "attachments",
    defaultMessage: "Attachments",
    description: "",
  },
  by: {
    id: "by",
    defaultMessage: "By",
    description: "",
  },
  cancel: {
    id: "cancel",
    defaultMessage: "Cancel",
    description: "",
  },
  changesSaved: {
    id: "changesSaved",
    defaultMessage: "Changes saved",
    description: "",
  },
  clickToReplaceImage: {
    id: "clickToReplaceImage",
    defaultMessage: "Click to replace image",
    description: "",
  },
  close: {
    id: "close",
    defaultMessage: "Close",
    description: "",
  },
  commentOnAttachments: {
    id: "commentOnAttachments",
    defaultMessage: "Comment on attachments",
    description: "",
  },
  companyAddress: {
    id: "companyAddress",
    defaultMessage: "Company address",
    description: "",
  },
  companyDataUpdated: {
    id: "companyDataUpdated",
    defaultMessage: "Company data is updated successfully!",
    description: "",
  },
  companyName: {
    id: "companyName",
    defaultMessage: "Company name",
    description: "",
  },
  compilingNdtOrder: {
    id: "compilingNdtOrder",
    defaultMessage: "Compiling NDT order",
    description: "",
  },
  contactPerson: {
    id: "contactPerson",
    defaultMessage: "Contact person",
    description: "",
  },
  copyright: {
    id: "copyright",
    defaultMessage: "Copyright ©",
    description: "",
  },
  coverageRate: {
    id: "coverageRate",
    defaultMessage: "Coverage rate %",
    description: "",
  },
  inspectionRate: {
    id: "inspectionRate",
    defaultMessage: "Inspection rate (%)",
    description: "",
  },
  coverageRates: {
    id: "coverageRates",
    defaultMessage: "Coverage rates",
    description: "",
  },
  create: {
    id: "create",
    defaultMessage: "Create",
    description: "",
  },
  createAlloy: {
    id: "createAlloy",
    defaultMessage: "Create Alloy",
    description: "",
  },
  createAlloyMaterial: {
    id: "createAlloyMaterial",
    defaultMessage: "Create alloy material",
    description: "",
  },
  createFillerMaterial: {
    id: "createFillerMaterial",
    defaultMessage: "Create filler material",
    description: "",
  },
  createParentMaterial: {
    id: "createParentMaterial",
    defaultMessage: "Create parent material",
    description: "",
  },
  created: {
    id: "created",
    defaultMessage: "Created",
    description: "",
  },
  createdAt: {
    id: "createdAt",
    defaultMessage: "Created at",
    description: "",
  },
  createdBy: {
    id: "createdBy",
    defaultMessage: "Created by",
    description: "",
  },
  creatingWeld: {
    id: "creatingWeld",
    defaultMessage: "Creating weld",
    description: "",
  },
  creatingWeldLog: {
    id: "creatingWeldLog",
    defaultMessage: "Creating weld log",
    description: "",
  },
  customer: {
    id: "customer",
    defaultMessage: "Customer",
    description: "",
  },
  deactivateAccount: {
    id: "deactivateAccount",
    defaultMessage: "Deactivate account",
    description: "",
  },
  delete: {
    id: "delete",
    defaultMessage: "Delete",
    description: "",
  },
  deleteAlloyMaterial: {
    id: "deleteAlloyMaterial",
    defaultMessage: "Delete alloy material?",
    description: "",
  },
  deleteFillerMaterial: {
    id: "deleteFillerMaterial",
    defaultMessage: "Delete filler material?",
    description: "",
  },
  deleteNdtOrders: {
    id: "deleteNdtOrders",
    defaultMessage: "Delete NDT orders",
    description: "",
  },
  deleteParentMaterial: {
    id: "deleteParentMaterial",
    defaultMessage: "Delete parent material?",
    description: "",
  },
  deleteProject: {
    id: "deleteProject",
    defaultMessage: "Delete project?",
    description: "",
  },
  deleteProjectParticipant: {
    id: "deleteProjectParticipant",
    defaultMessage: "Delete project participant?",
    description: "",
  },
  deleteTemplate: {
    id: "deleteTemplate",
    defaultMessage: "Delete template?",
    description: "",
  },
  deleteWeld: {
    id: "deleteWeld",
    defaultMessage: "Delete weld?",
    description: "",
  },
  deleteWeldLog: {
    id: "deleteWeldLog",
    defaultMessage: "Delete weld log?",
    description: "",
  },
  deletedSuccessfully: {
    id: "deletedSuccessfully",
    defaultMessage: "Deleted successfully",
    description: "",
  },
  description: {
    id: "description",
    defaultMessage: "Description",
    description: "",
  },
  weldLogDetails: {
    id: "weldLogDetails",
    defaultMessage: "Weld log details",
    description: "",
  },
  dimension: {
    id: "dimension",
    defaultMessage: "Dimension",
    description: "",
  },
  documents: {
    id: "documents",
    defaultMessage: "Documents",
    description: "",
  },
  docweldEnables: {
    id: "docweldEnables",
    defaultMessage: "DocWeld enables easy data entry and sharing among all parties involved",
    description: "",
  },
  dontHaveAnAccount: {
    id: "dontHaveAnAccount",
    defaultMessage: "Don't have an account?",
    description: "",
  },
  duplicate: {
    id: "duplicate",
    defaultMessage: "Duplicate",
    description: "",
  },
  easyReport: {
    id: "easyReport",
    defaultMessage: "Easy report",
    description: "",
  },
  edit: {
    id: "edit",
    defaultMessage: "Edit",
    description: "",
  },
  editHeatTreatmentLog: {
    id: "editHeatTreatmentLog",
    defaultMessage: "Edit heat treatment log",
    description: "",
  },
  editNdtLog: {
    id: "editNdtLog",
    defaultMessage: "Edit NDT log",
    description: "",
  },
  editNdtOrder: {
    id: "editNdtOrder",
    defaultMessage: "Edit NDT order",
    description: "",
  },
  editParticipant: {
    id: "editParticipant",
    defaultMessage: "Edit participant",
    description: "",
  },
  editWeld: {
    id: "editWeld",
    defaultMessage: "Edit weld",
    description: "",
  },
  editWeldLog: {
    id: "editWeldLog",
    defaultMessage: "Edit weld log",
    description: "",
  },
  emailAddress: {
    id: "emailAddress",
    defaultMessage: "Email address",
    description: "",
  },
  errorCode: {
    id: "errorCode",
    defaultMessage: "404",
    description: "",
  },
  errorFetchingData: {
    id: "errorFetchingData",
    defaultMessage: "Error fetching data",
    description: "",
  },
  pageCouldNotBeFound: {
    id: "pageCouldNotBeFound",
    defaultMessage: "The page could not be found",
    description: "",
  },
  eventTimestamp: {
    id: "eventTimestamp",
    defaultMessage: "Event timestamp",
    description: "",
  },
  events: {
    id: "events",
    defaultMessage: "Events",
    description: "",
  },
  externalReference: {
    id: "externalReference",
    defaultMessage: "External reference",
    description: "",
  },
  failedToDeleteNdtOrder: {
    id: "failedToDeleteNdtOrder",
    defaultMessage: "Failed to delete NDT order(s).",
    description: "",
  },
  failedToLogHt: {
    id: "failedToLogHt",
    defaultMessage: "Failed to log HT",
    description: "",
  },
  failedToLogWeld: {
    id: "failedToLogWeld",
    defaultMessage: "Failed to log weld",
    description: "",
  },
  failedToRemoveWeldFromNdtOrder: {
    id: "failedToRemoveWeldFromNdtOrder",
    defaultMessage: "Failed to remove weld from NDT order.",
    description: "",
  },
  fillerMaterial: {
    id: "fillerMaterial",
    defaultMessage: "Filler material",
    description: "",
  },
  fillerMaterialAddedSuccessfully: {
    id: "fillerMaterialAddedSuccessfully",
    defaultMessage: "Filler material added successfully!",
    description: "",
  },
  fillerMaterialCertificates: {
    id: "fillerMaterialCertificates",
    defaultMessage: "Filler material certificates",
    description: "",
  },
  fillerMaterialDeletedSuccessfully: {
    id: "fillerMaterialDeletedSuccessfully",
    defaultMessage: "Filler material(s) deleted successfully",
    description: "",
  },
  fillerMaterialName: {
    id: "fillerMaterialName",
    defaultMessage: "Filler material name",
    description: "",
  },
  fillerMaterialTraceable: {
    id: "fillerMaterialTraceable",
    defaultMessage: "Filler material traceable",
    description: "",
  },
  fillerMaterials: {
    id: "fillerMaterials",
    defaultMessage: "Filler materials",
    description: "",
  },
  fillerName: {
    id: "fillerName",
    defaultMessage: "Filler name",
    description: "",
  },
  firstName: {
    id: "firstName",
    defaultMessage: "First name",
    description: "",
  },
  forgotPassword: {
    id: "forgotPassword",
    defaultMessage: "Forgot password?",
    description: "",
  },
  generate: {
    id: "generate",
    defaultMessage: "Generate",
    description: "",
  },
  generateReport: {
    id: "generateReport",
    defaultMessage: "Generate report",
    description: "",
  },
  genericTemplate: {
    id: "genericTemplate",
    defaultMessage: "Generic Template",
    description: "",
  },
  genericTemplateDescription: {
    id: "genericTemplateDescription",
    defaultMessage:
      "This is a generic template with empty section which can be used to create custom templates. It cannot be deleted.",
    description: "",
  },
  goToWeldLogsPage: {
    id: "goToWeldLogsPage",
    defaultMessage: "Go to weld logs page",
    description: "",
  },
  headerName: {
    id: "headerName",
    defaultMessage: "Header name",
    description: "",
  },
  heatTreatment: {
    id: "heatTreatment",
    defaultMessage: "Heat treatment",
    description: "",
  },
  heatTreatmentLogged: {
    id: "heatTreatmentLogged",
    defaultMessage: "Heat treatment logged",
    description: "",
  },
  heatTreatmentNotRequired: {
    id: "heatTreatmentNotRequired",
    defaultMessage: "Heat treatment - Not required",
    description: "",
  },
  heatTreatmentProgress: {
    id: "heatTreatmentProgress",
    defaultMessage: "Heat treatment progress",
    description: "",
  },
  ht: {
    id: "ht",
    defaultMessage: "HT",
    description: "",
  },
  htOperator: {
    id: "htOperator",
    defaultMessage: "HT operator",
    description: "",
  },
  identifier: {
    id: "identifier",
    defaultMessage: "Identifier",
    description: "",
  },
  inactive: {
    id: "inactive",
    defaultMessage: "Inactive",
    description: "",
  },
  inspectionMethods: {
    id: "inspectionMethods",
    defaultMessage: "Inspection methods",
    description: "",
  },
  followProjectProgress: {
    id: "followProjectProgress",
    defaultMessage: "Follow project progress",
    description: "",
  },
  internalCommentOnDocuments: {
    id: "internalCommentOnDocuments",
    defaultMessage: "Internal comment on documents",
    description: "",
  },
  invalidEmail: {
    id: "invalidEmail",
    defaultMessage: "Invalid email.",
    description: "",
  },
  goToDocumentsSection: {
    id: "goToDocumentsSection",
    defaultMessage: "Go to documents section",
    description: "",
  },
  language: {
    id: "language",
    defaultMessage: "Language",
    description: "",
  },
  lastActivity: {
    id: "lastActivity",
    defaultMessage: "Last activity",
    description: "",
  },
  lastName: {
    id: "lastName",
    defaultMessage: "Last name",
    description: "",
  },
  lastSignInTime: {
    id: "lastSignInTime",
    defaultMessage: "Last sign in time",
    description: "",
  },
  link: {
    id: "link",
    defaultMessage: "Link",
    description: "",
  },
  linkDocument: {
    id: "linkDocument",
    defaultMessage: "Link document",
    description: "",
  },
  linkedDocument: {
    id: "linkedDocument",
    defaultMessage: "Linked document",
    description: "",
  },
  linkedWPS: {
    id: "linkedWPS",
    defaultMessage: "Linked WPS",
    description: "",
  },
  log: {
    id: "log",
    defaultMessage: "Log",
    description: "",
  },
  logHeatTreatment: {
    id: "logHeatTreatment",
    defaultMessage: "Log heat treatment",
    description: "",
  },
  logNdt: {
    id: "logNdt",
    defaultMessage: "Log NDT",
    description: "",
  },
  logWeld: {
    id: "logWeld",
    defaultMessage: "Log weld",
    description: "",
  },
  loggedHtSuccessfully: {
    id: "loggedHtSuccessfully",
    defaultMessage: "Logged HT successfully",
    description: "",
  },
  loggedWeldSuccessfully: {
    id: "loggedWeldSuccessfully",
    defaultMessage: "Logged weld successfully",
    description: "",
  },
  loggingHeatTreatment: {
    id: "loggingHeatTreatment",
    defaultMessage: "Logging heat treatment",
    description: "",
  },
  loggingWeld: {
    id: "loggingWeld",
    defaultMessage: "Logging weld",
    description: "",
  },
  login: {
    id: "login",
    defaultMessage: "Login",
    description: "",
  },
  logout: {
    id: "logout",
    defaultMessage: "Logout",
    description: "",
  },
  lowerCaseAlloyMaterial: {
    id: "lowerCaseAlloyMaterial",
    defaultMessage: "alloy material",
    description: "",
  },
  lowerCaseAlloyMaterials: {
    id: "lowerCaseAlloyMaterials",
    defaultMessage: "alloy materials",
    description: "",
  },
  lowerCaseFillerMaterial: {
    id: "lowerCaseFillerMaterial",
    defaultMessage: "filler material",
    description: "",
  },
  lowerCaseFillerMaterials: {
    id: "lowerCaseFillerMaterials",
    defaultMessage: "filler materials",
    description: "",
  },
  lowerCaseParentMaterial: {
    id: "lowerCaseParentMaterial",
    defaultMessage: "parent material",
    description: "",
  },
  lowerCaseParentMaterials: {
    id: "lowerCaseParentMaterials",
    defaultMessage: "parent materials",
    description: "",
  },
  lowerCaseProject: {
    id: "lowerCaseProject",
    defaultMessage: "project",
    description: "",
  },
  lowerCaseProjectParticipant: {
    id: "lowerCaseProjectParticipant",
    defaultMessage: "project participant",
    description: "",
  },
  lowerCaseProjectParticipants: {
    id: "lowerCaseProjectParticipants",
    defaultMessage: "project participants",
    description: "",
  },
  lowerCaseProjects: {
    id: "lowerCaseProjects",
    defaultMessage: "projects",
    description: "",
  },
  lowerCaseTemplate: {
    id: "lowerCaseTemplate",
    defaultMessage: "template",
    description: "",
  },
  lowerCaseTemplates: {
    id: "lowerCaseTemplates",
    defaultMessage: "templates",
    description: "",
  },
  lowerCaseUser: {
    id: "lowerCaseUser",
    defaultMessage: "user",
    description: "",
  },
  lowerCaseUsers: {
    id: "lowerCaseUsers",
    defaultMessage: "users",
    description: "",
  },
  lowerCaseWeld: {
    id: "lowerCaseWeld",
    defaultMessage: "weld",
    description: "",
  },
  lowerCaseWeldLog: {
    id: "lowerCaseWeldLog",
    defaultMessage: "weld log",
    description: "",
  },
  lowerCaseWeldLogs: {
    id: "lowerCaseWeldLogs",
    defaultMessage: "weld logs",
    description: "",
  },
  lowerCaseWelds: {
    id: "lowerCaseWelds",
    defaultMessage: "welds",
    description: "",
  },
  makeAdmin: {
    id: "makeAdmin",
    defaultMessage: "Make admin",
    description: "",
  },
  makeUser: {
    id: "makeUser",
    defaultMessage: "Make user",
    description: "",
  },
  manageNdt: {
    id: "manageNdt",
    defaultMessage: "Manage NDT",
    description: "",
  },
  materialManagement: {
    id: "materialManagement",
    defaultMessage: "Material management",
    description: "",
  },
  materials: {
    id: "materials",
    defaultMessage: "Materials",
    description: "",
  },
  method: {
    id: "method",
    defaultMessage: "Method",
    description: "",
  },
  more: {
    id: "more",
    defaultMessage: "more",
    description: "",
  },
  multiple: {
    id: "multiple",
    defaultMessage: "Multiple",
    description: "",
  },
  na: {
    id: "na",
    defaultMessage: "n/a",
    description: "",
  },
  name: {
    id: "name",
    defaultMessage: "Name",
    description: "",
  },
  ndtOrder: {
    id: "ndtOrder",
    defaultMessage: "NDT order",
    description: "",
  },
  ndtOrderedOn: {
    id: "ndtOrderedOn",
    defaultMessage: "NDT ordered on",
    description: "",
  },
  numOfRequiredNdts: {
    id: "numOfRequiredNdts",
    defaultMessage: "Number of welds required per NDT method",
    description: "",
  },
  completedInspectionsPerNdtMethod: {
    id: "completedInspectionsPerNdtMethod",
    defaultMessage: "Completed inspections per NDT method",
    description: "",
  },
  ndtOverallProgress: {
    id: "ndtOverallProgress",
    defaultMessage: "Overall progress",
    description: "",
  },
  showNdtDetailedStatus: {
    id: "ndtDetailedStatus",
    defaultMessage: "Show detailed status",
    description: "",
  },
  noNdtProgressYet: {
    id: "noNdtProgressYet",
    defaultMessage: "No progress data while being compiled",
  },
  confirmCompleteNdtInspection: {
    id: "confirmCompleteNdtInspection",
    defaultMessage: "Confirm completion of NDT inspection with failed methods?",
  },
  ndtOrderMessage: {
    id: "ndtOrderMessage",
    defaultMessage: "Message to NDT operator",
    description: "",
  },
  ndtStatus: {
    id: "ndtStatus",
    defaultMessage: "NDT status",
    description: "",
  },
  ndtComment: {
    id: "ndtComment",
    defaultMessage: "NDT comment",
    description: "",
  },
  ndtOrderDeleted: {
    id: "ndtOrderDeleted",
    defaultMessage: "NDT order(s) deleted.",
    description: "",
  },
  ndtOrderDetails: {
    id: "ndtOrderDetails",
    defaultMessage: "NDT order details",
    description: "",
  },
  ndtOrderName: {
    id: "ndtOrderName",
    defaultMessage: "NDT order name",
    description: "",
  },
  addNdtOrder: {
    id: "addNdtOrder",
    defaultMessage: "Add NDT order",
    description: "",
  },
  ndtOrders: {
    id: "ndtOrders",
    defaultMessage: "NDT orders",
    description: "",
  },
  newUserAdded: {
    id: "newUserAdded",
    defaultMessage: "New user added successfully!",
    description: "",
  },
  no: {
    id: "no",
    defaultMessage: "No",
    description: "",
  },
  noAlloysYet: {
    id: "noAlloysYet",
    defaultMessage: "No alloys yet",
    description: "",
  },
  noArchivedProjectYet: {
    id: "noArchivedProjectYet",
    defaultMessage: "No archived project yet",
    description: "",
  },
  noDocumentLinked: {
    id: "noDocumentLinked",
    defaultMessage: "No linked documents",
    description: "",
  },
  noDocumentsYet: {
    id: "noDocumentsYet",
    defaultMessage: "No documents yet",
    description: "",
  },
  noFillerMaterialsYet: {
    id: "noFillerMaterialsYet",
    defaultMessage: "No filler materials yet",
    description: "",
  },
  noNdtOrderYet: {
    id: "noNdtOrderYet",
    defaultMessage: "No NDT order yet",
    description: "",
  },
  noParentMaterialsYet: {
    id: "noParentMaterialsYet",
    defaultMessage: "No parent materials yet",
    description: "",
  },
  noParticipants: {
    id: "noParticipants",
    defaultMessage: "No project participants yet",
    description: "",
  },
  noProjectAssigned: {
    id: "noProjectAssigned",
    defaultMessage: "No project assigned to you yet",
    description: "",
  },
  noProjectYet: {
    id: "noProjectYet",
    defaultMessage: "No project yet",
    description: "",
  },
  noRole: {
    id: "noRole",
    defaultMessage: "No role",
    description: "",
  },
  noTrashedWeldLogs: {
    id: "noTrashedWeldLogs",
    defaultMessage: "No trashed weld logs yet",
    description: "",
  },
  noWeldLogs: {
    id: "noWeldLogs",
    defaultMessage: "No weld logs yet",
    description: "",
  },
  noWeldsInNdtOrder: {
    id: "noWeldsInNdtOrder",
    defaultMessage: "No welds in NDT order yet",
    description: "",
  },
  noWeldsUnderWeldLog: {
    id: "noWeldsUnderWeldLog",
    defaultMessage: "No welds under this weld log",
    description: "",
  },
  none: {
    id: "none",
    defaultMessage: "None",
    description: "",
  },
  notStarted: {
    id: "notStarted",
    defaultMessage: "Not Started",
    description: "",
  },
  notYetAddedToNdtOrder: {
    id: "notYetAddedToNdtOrder",
    defaultMessage: "Not yet added to NDT order",
    description: "",
  },
  note: {
    id: "note",
    defaultMessage: "Note",
    description: "",
  },
  number: {
    id: "number",
    defaultMessage: "Number",
    description: "",
  },
  numberOfSections: {
    id: "numberOfSections",
    defaultMessage: "Number of sections",
    description: "",
  },
  open: {
    id: "open",
    defaultMessage: "Open",
    description: "",
  },
  overview: {
    id: "overview",
    defaultMessage: "Overview",
    description: "",
  },
  parentMaterial: {
    id: "parentMaterial",
    defaultMessage: "Parent material",
    description: "",
  },
  parentMaterialAddedSuccessfully: {
    id: "parentMaterialAddedSuccessfully",
    defaultMessage: "Parent material added successfully!",
    description: "",
  },
  parentMaterialCertificates: {
    id: "parentMaterialCertificates",
    defaultMessage: "Parent material certificates",
    description: "",
  },
  parentMaterialDeletedSuccessfully: {
    id: "parentMaterialDeletedSuccessfully",
    defaultMessage: "Parent material(s) deleted successfully",
    description: "",
  },
  parentMaterialTraceable: {
    id: "parentMaterialTraceable",
    defaultMessage: "Parent material traceable",
    description: "",
  },
  parentMaterials: {
    id: "parentMaterials",
    defaultMessage: "Parent materials",
    description: "",
  },
  participatingAs: {
    id: "participatingAs",
    defaultMessage: "Participating as",
    description: "",
  },
  password: {
    id: "password",
    defaultMessage: "Password",
    description: "",
  },
  passwordResetLinkSent: {
    id: "passwordResetLinkSent",
    defaultMessage: "Password reset link sent to your email.",
    description: "",
  },
  pending: {
    id: "pending",
    defaultMessage: "Pending",
    description: "",
  },
  photosReorderedSuccessfully: {
    id: "photosReorderedSuccessfully",
    defaultMessage: "Photos reordered successfully",
    description: "",
  },
  pleaseAddDocumentsUnder: {
    id: "pleaseAddDocumentsUnder",
    defaultMessage:
      "Please add documents under documents section of a project. Then start adding weld logs and welds.",
    description: "",
  },
  pleaseContactYour: {
    id: "pleaseContactYour",
    defaultMessage: "Please contact your project manager to get assigned to a project.",
    description: "",
  },
  pleaseSelectTheDocument: {
    id: "pleaseSelectTheDocument",
    defaultMessage: "Please select the document template you want to use with the project",
    description: "",
  },
  position: {
    id: "position",
    defaultMessage: "Position",
    description: "",
  },
  profile: {
    id: "profile",
    defaultMessage: "Profile",
    description: "",
  },
  profileSettings: {
    id: "profileSettings",
    defaultMessage: "Profile Settings",
    description: "",
  },
  progress: {
    id: "progress",
    defaultMessage: "Progress",
    description: "",
  },
  projectAddedSuccessfully: {
    id: "projectAddedSuccessfully",
    defaultMessage: "Project is added successfully",
    description: "",
  },
  projectArchivedSuccessfully: {
    id: "projectArchivedSuccessfully",
    defaultMessage: "Project archived successfully",
    description: "",
  },
  projectDetails: {
    id: "projectDetails",
    defaultMessage: "Project details",
    description: "",
  },
  projectDuplicatedSuccessfully: {
    id: "projectDuplicatedSuccessfully",
    defaultMessage: "Project duplicated successfully",
    description: "",
  },
  projectIsRequired: {
    id: "projectIsRequired",
    defaultMessage: "Project is required",
    description: "",
  },
  projectName: {
    id: "projectName",
    defaultMessage: "Project name",
    description: "",
  },
  projectNumber: {
    id: "projectNumber",
    defaultMessage: "Project number",
    description: "",
  },
  projectNumberRequired: {
    id: "projectNumberRequired",
    defaultMessage: "Project number is required",
    description: "",
  },
  projectParticipants: {
    id: "projectParticipants",
    defaultMessage: "Project participants",
    description: "",
  },
  projectRequired: {
    id: "projectRequired",
    defaultMessage: "Project is required",
    description: "",
  },
  projectUpdatedSuccessfully: {
    id: "projectUpdatedSuccessfully",
    defaultMessage: "Project is updated successfully",
    description: "",
  },
  projects: {
    id: "projects",
    defaultMessage: "Projects",
    description: "",
  },
  projectsWillBeListedHere: {
    id: "projectsWillBeListedHere",
    defaultMessage: "Projects will be listed here once archived",
    description: "",
  },
  realTimeDocumentation: {
    id: "realTimeDocumentation",
    defaultMessage: "Real time documentation",
    description: "",
  },
  reference: {
    id: "reference",
    defaultMessage: "Reference",
    description: "",
  },
  register: {
    id: "register",
    defaultMessage: "Register",
    description: "",
  },
  remark: {
    id: "remark",
    defaultMessage: "Remark",
    description: "",
  },
  removeFromNdtOrder: {
    id: "removeFromNdtOrder",
    defaultMessage: "Remove from NDT order",
    description: "",
  },
  removeFromProject: {
    id: "removeFromProject",
    defaultMessage: "Remove from project",
    description: "",
  },
  removeUser: {
    id: "removeUser",
    defaultMessage: "Remove user?",
    description: "",
  },
  removeWeldsFromNdtOrder: {
    id: "removeWeldsFromNdtOrder",
    defaultMessage: "Remove welds from NDT order",
    description: "",
  },
  rename: {
    id: "rename",
    defaultMessage: "Rename",
    description: "",
  },
  report: {
    id: "report",
    defaultMessage: "Report",
    description: "",
  },
  reports: {
    id: "reports",
    defaultMessage: "Reports",
    description: "",
  },
  resetPassword: {
    id: "resetPassword",
    defaultMessage: "Reset Password",
    description: "",
  },
  resetPasswordCaps: {
    id: "resetPasswordCaps",
    defaultMessage: "RESET PASSWORD",
    description: "",
  },
  restore: {
    id: "restore",
    defaultMessage: "Restore",
    description: "",
  },
  sampleRate: {
    id: "sampleRate",
    defaultMessage: "Sample rate %",
    description: "",
  },
  sampleRates: {
    id: "sampleRates",
    defaultMessage: "Sample rates",
    description: "",
  },
  save: {
    id: "save",
    defaultMessage: "Save",
    description: "",
  },
  saveAsCustomTemplate: {
    id: "saveAsCustomTemplate",
    defaultMessage: "Save as custom template",
    description: "",
  },
  saveAsTemplate: {
    id: "saveAsTemplate",
    defaultMessage: "Save as template",
    description: "",
  },
  saveChanges: {
    id: "saveChanges",
    defaultMessage: "Save changes",
    description: "",
  },
  savingChanges: {
    id: "savingChanges",
    defaultMessage: "Saving changes",
    description: "",
  },
  selectAll: {
    id: "selectAll",
    defaultMessage: "select all",
    description: "",
  },
  selectAllAlloyMaterials: {
    id: "selectAllAlloyMaterials",
    defaultMessage: "Select all alloy materials",
    description: "",
  },
  selectAllFillerMaterials: {
    id: "selectAllFillerMaterials",
    defaultMessage: "Select all filler materials",
    description: "",
  },
  selectAllParentMaterials: {
    id: "selectAllParentMaterials",
    defaultMessage: "Select all parent materials",
    description: "",
  },
  selectAllProjectParticipants: {
    id: "selectAllProjectParticipants",
    defaultMessage: "Select all project participants",
    description: "",
  },
  selectAllProjects: {
    id: "selectAllProjects",
    defaultMessage: "Select all projects",
    description: "",
  },
  selectAllTemplates: {
    id: "selectAllTemplates",
    defaultMessage: "Select all templates",
    description: "",
  },
  selectAllUsers: {
    id: "selectAllUsers",
    defaultMessage: "Select all users",
    description: "",
  },
  selectAllWeldLogs: {
    id: "selectAllWeldLogs",
    defaultMessage: "Select all weld logs",
    description: "",
  },
  selectAllWelds: {
    id: "selectAllWelds",
    defaultMessage: "Select all welds",
    description: "",
  },
  selectDocumentSections: {
    id: "selectDocumentSections",
    defaultMessage: "Select document sections to include",
    description: "",
  },
  selectDocumentTemplate: {
    id: "selectDocumentTemplate",
    defaultMessage: "Select document templates",
    description: "",
  },
  selected: {
    id: "selected",
    defaultMessage: " selected",
    description: "",
  },
  selectedWeldsNotOnSameWeldEvent: {
    id: "selectedWeldsNotOnSameWeldEvent",
    defaultMessage: "Selected welds are not on the same weld event",
    description: "",
  },
  settings: {
    id: "settings",
    defaultMessage: "Settings",
    description: "",
  },
  somethingWentWrong: {
    id: "somethingWentWrong",
    defaultMessage: "Something went wrong",
    description: "",
  },
  startAdding: {
    id: "startAdding",
    defaultMessage:
      "You can start by adding project leader, welding coordinator and welders among others.",
    description: "",
  },
  started: {
    id: "started",
    defaultMessage: "Started",
    description: "",
  },
  status: {
    id: "status",
    defaultMessage: "Status",
    description: "",
  },
  submitForInspection: {
    id: "submitForInspection",
    defaultMessage: "Submit for inspection",
    description: "",
  },
  completeInspection: {
    id: "completeInspection",
    defaultMessage: "Complete inspection",
    description: "",
  },
  summary: {
    id: "summary",
    defaultMessage: "Summary",
    description: "",
  },
  templateDeleted: {
    id: "templateDeleted",
    defaultMessage: "Template deleted!",
    description: "",
  },
  templateManagement: {
    id: "templateManagement",
    defaultMessage: "Template management",
    description: "",
  },
  templateName: {
    id: "templateName",
    defaultMessage: "Template name",
    description: "",
  },
  templates: {
    id: "templates",
    defaultMessage: "Templates",
    description: "",
  },
  thickness: {
    id: "thickness",
    defaultMessage: "Thickness",
    description: "",
  },
  togglePasswordVisibility: {
    id: "togglePasswordVisibility",
    defaultMessage: "toggle password visibility",
    description: "",
  },
  traceabilityOfFillerMaterial: {
    id: "traceabilityOfFillerMaterial",
    defaultMessage: "Traceability of filler material",
    description: "",
  },
  traceabilityOfParentMaterial: {
    id: "traceabilityOfParentMaterial",
    defaultMessage: "Traceability of parent material",
    description: "",
  },
  trashedWeldLogs: {
    id: "trashedWeldLogs",
    defaultMessage: "Trashed weld logs",
    description: "",
  },
  type: {
    id: "type",
    defaultMessage: "Type",
    description: "",
  },
  unableToActivateAccount: {
    id: "unableToActivateAccount",
    defaultMessage: "Unable to activate account!",
    description: "",
  },
  unableToDeactivateAccount: {
    id: "unableToDeactivateAccount",
    defaultMessage: "Unable to deactivate account!",
    description: "",
  },
  unableToDeleteAccount: {
    id: "unableToDeleteAccount",
    defaultMessage: "Unable to delete account!",
    description: "",
  },
  unableToDeleteTemplate: {
    id: "unableToDeleteTemplate",
    defaultMessage: "Unable to delete template!",
    description: "",
  },
  unableToDemoteAccountUser: {
    id: "unableToDemoteAccountUser",
    defaultMessage: "Unable to demote account user!",
    description: "",
  },
  unableToMakeAccountAdmin: {
    id: "unableToMakeAccountAdmin",
    defaultMessage: "Unable to make account admin!",
    description: "",
  },
  unknownErrorOccurred: {
    id: "unknownErrorOccurred",
    defaultMessage: "An unknown error occurred.",
    description: "",
  },
  updateProject: {
    id: "updateProject",
    defaultMessage: "Update project",
    description: "",
  },
  updateWeldPosition: {
    id: "updateWeldPosition",
    defaultMessage: "Update weld position for weld",
    description: "",
  },
  updatedAt: {
    id: "updatedAt",
    defaultMessage: "Updated at",
    description: "",
  },
  updatedSuccessfully: {
    id: "updatedSuccessfully",
    defaultMessage: "Updated successfully",
    description: "",
  },
  updatingCompanyData: {
    id: "updatingCompanyData",
    defaultMessage: "updating company data",
    description: "",
  },
  updatingProject: {
    id: "updatingProject",
    defaultMessage: "Updating project",
    description: "",
  },
  updatingUserProfileData: {
    id: "updatingUserProfileData",
    defaultMessage: "updating user profile data",
    description: "",
  },
  useTemplate: {
    id: "useTemplate",
    defaultMessage: "Use template",
    description: "",
  },
  user: {
    id: "user",
    defaultMessage: "User",
    description: "",
  },
  userAddedToProject: {
    id: "userAddedToProject",
    defaultMessage: "User added to project",
    description: "",
  },
  userGroup: {
    id: "userGroup",
    defaultMessage: "User group",
    description: "",
  },
  userManagement: {
    id: "userManagement",
    defaultMessage: "User management",
    description: "",
  },
  userUID: {
    id: "userUID",
    defaultMessage: "User UID",
    description: "",
  },
  users: {
    id: "users",
    defaultMessage: "Users",
    description: "",
  },
  valueGreaterThanRangeStart: {
    id: "valueGreaterThanRangeStart",
    defaultMessage: "Value should be greater than range start number",
    description: "",
  },
  version: {
    id: "version",
    defaultMessage: "Version",
    description: "",
  },
  weld: {
    id: "weld",
    defaultMessage: "Weld",
    description: "",
  },
  weldCompleted: {
    id: "weldCompleted",
    defaultMessage: "Weld completed",
    description: "",
  },
  weldCreated: {
    id: "weldCreated",
    defaultMessage: "Weld created",
    description: "",
  },
  weldCreatedSuccessfully: {
    id: "weldCreatedSuccessfully",
    defaultMessage: "Weld created successfully",
    description: "",
  },
  weldDetails: {
    id: "weldDetails",
    defaultMessage: "Weld details",
    description: "",
  },
  weldDuplicatedSuccessfully: {
    id: "weldDuplicatedSuccessfully",
    defaultMessage: "Weld duplicated successfully",
    description: "",
  },
  weldLog: {
    id: "weldLog",
    defaultMessage: "Weld log",
    description: "",
  },
  weldLogCreatedSuccessfully: {
    id: "weldLogCreatedSuccessfully",
    defaultMessage: "Weld log is created successfully",
    description: "",
  },
  weldLogDuplicatedSuccessfully: {
    id: "weldLogDuplicatedSuccessfully",
    defaultMessage: "Weld log duplicated successfully",
    description: "",
  },
  weldLogName: {
    id: "weldLogName",
    defaultMessage: "Weld log name",
    description: "",
  },
  weldLogged: {
    id: "weldLogged",
    defaultMessage: "Weld logged",
    description: "",
  },
  weldLogs: {
    id: "weldLogs",
    defaultMessage: "Weld logs",
    description: "",
  },
  weldNumber: {
    id: "weldNumber",
    defaultMessage: "Weld number",
    description: "",
  },
  weldStatus: {
    id: "weldStatus",
    defaultMessage: "Weld status",
    description: "",
  },
  weldNumberFrom: {
    id: "weldNumberFrom",
    defaultMessage: "Weld number - from",
    description: "",
  },
  weldNumberIsRequired: {
    id: "weldNumberIsRequired",
    defaultMessage: "Weld number is required",
    description: "",
  },
  weldNumberRequired: {
    id: "weldNumberRequired",
    defaultMessage: "Weld number is required",
    description: "",
  },
  weldNumberTo: {
    id: "weldNumberTo",
    defaultMessage: "Weld number - to",
    description: "",
  },
  weldNumbersWillBeAutomaticallyGenerated: {
    id: "weldNumbersWillBeAutomaticallyGenerated",
    defaultMessage:
      "Weld numbers will be automatically generated. You will be able to add weld position from weld list view",
    description: "",
  },
  weldPhotos: {
    id: "weldPhotos",
    defaultMessage: "Weld photos",
    description: "",
  },
  weldProgress: {
    id: "weldProgress",
    defaultMessage: "Weld progress",
    description: "",
  },
  weldRemovedFromNdtOrder: {
    id: "weldRemovedFromNdtOrder",
    defaultMessage: "Weld removed from NDT order.",
    description: "",
  },
  welder: {
    id: "welder",
    defaultMessage: "Welder",
    description: "",
  },
  welders: {
    id: "welders",
    defaultMessage: "Welders",
    description: "",
  },
  welding: {
    id: "welding",
    defaultMessage: "Welding",
    description: "",
  },
  welds: {
    id: "welds",
    defaultMessage: "Welds",
    description: "",
  },
  weldsDeletedSuccessfully: {
    id: "weldsDeletedSuccessfully",
    defaultMessage: "Weld(s) deleted successfully",
    description: "",
  },
  weldsInNdtOrder: {
    id: "weldsInNdtOrder",
    defaultMessage: "Welds in NDT order",
    description: "",
  },
  withDocweld: {
    id: "withDocweld",
    defaultMessage:
      "With DocWeld, it is easy to go paperless and have all the reports ready for sharing",
    description: "",
  },
  wps: {
    id: "wps",
    defaultMessage: "WPS",
    description: "",
  },
  yes: {
    id: "yes",
    defaultMessage: "Yes",
    description: "",
  },
  youCanAddFillerMaterials: {
    id: "youCanAddFillerMaterials",
    defaultMessage: "You can add filler materials that can later be used with different projects.",
    description: "",
  },
  youCanCreatNewParentMaterials: {
    id: "youCanCreatNewParentMaterials",
    defaultMessage:
      "You can create new parent materials that can later be used with different projects.",
    description: "",
  },
  youCanStartBy: {
    id: "youCanStartBy",
    defaultMessage: "Here you can add alloys that can be used for all projects.",
    description: "",
  },
  startByAddingWeld: {
    id: "startByAddingWeld",
    defaultMessage: "Start by adding a weld to a NDT order",
    description: "",
  },
  youCanStartByAdding: {
    id: "youCanStartByAdding",
    defaultMessage: "You can start by adding a weld to an NDT order from weld details page.",
    description: "",
  },
  youCanStartByCreatingWeld: {
    id: "youCanStartByCreatingWeld",
    defaultMessage: "You can start by creating a weld and proceed from there.",
    description: "",
  },
  youCanStartByCreatingWeldLog: {
    id: "youCanStartByCreatingWeldLog",
    defaultMessage: "You can start by creating a weld log and proceed from there.",
    description: "",
  },
  youCanStartByNdt: {
    id: "youCanStartByNdt",
    defaultMessage: "You can start by creating an NDT order and proceed from there.",
    description: "",
  },
  youHaveWeldsWithDifferentSet: {
    id: "youHaveWeldsWithDifferentSet",
    defaultMessage: "You have welds with different set of parent or filler materials",
    description: "",
  },
  createFirstProject: {
    id: "createFirstProject",
    defaultMessage: "Please create your project to start welding management",
    description: "",
  },
  areYouSureYouWantToRemove: {
    id: "areYouSureYouWantToRemove",
    defaultMessage: "Are you sure you want to remove {count} {weldText}? from NDT order?",
    description: "",
  },
  unexpectedError: {
    id: "unexpectedError",
    defaultMessage: "An unexpected error occurred, please try again later.",
    description: "",
  },
  lowerCaseOrder: {
    id: "lowerCaseOrder",
    defaultMessage: "order",
    description: "",
  },
  areYouSureYouWantToDeleteNdt: {
    id: "areYouSureYouWantToDeleteNdt",
    defaultMessage:
      "This action cannot be undone. Are you sure you want to delete NDT {count} {orderText}?",
    description: "",
  },
  copyPrefix: {
    id: "copyPrefix",
    defaultMessage: "[copy] - {weldLogName}",
    description: "",
  },
  weldNumberAlreadyAddedMissingWeldNumbers: {
    id: "weldNumberAlreadyAddedMissingWeldNumbers",
    defaultMessage:
      "Weld number {value} is already added. Weld numbers {missingNumbers} or {nextWeldNumber} and above are available",
    description: "",
  },
  multipleUsers: {
    id: "multipleUsers",
    defaultMessage: "Multiple users",
    description: "",
  },
  loggedBy: {
    id: "loggedBy",
    defaultMessage: "Logged by",
    description: "",
  },
  done: {
    id: "done",
    defaultMessage: "Done",
    description: "",
  },
  weldLogTrashedSuccessfully: {
    id: "weldLogTrashedSuccessfully",
    defaultMessage: "Weld log(s) trashed successfully",
    description: "",
  },
  weldPositionUpdated: {
    id: "weldPositionUpdated",
    defaultMessage: "Weld position updated",
    description: "",
  },
  ndtOrderNameRequired: {
    id: "ndtOrderNameRequired",
    defaultMessage: "NDT order name is required",
    description: "",
  },
  atLeastOneNdtOrderRequired: {
    id: "atLeastOneNdtOrderRequired",
    defaultMessage: "At least one NDT item with Inspection Rate is required.",
    description: "",
  },
  invalidCoverageRate: {
    id: "invalidCoverageRate",
    defaultMessage: "Coverage rate should be between 0 and 100.",
    description: "",
  },
  invalidSampleRate: {
    id: "invalidSampleRate",
    defaultMessage: "Sample rate should be between 0 and 100.",
    description: "",
  },
  creatingNdtOrder: {
    id: "creatingNdtOrder",
    defaultMessage: "Creating NDT order...",
    description: "",
  },
  ndtCreatedSuccessfully: {
    id: "ndtCreatedSuccessfully",
    defaultMessage: "NDT order created successfully",
    description: "",
  },
  projectTrashedSuccessfully: {
    id: "projectTrashedSuccessfully",
    defaultMessage: "Project(s) trashed successfully",
    description: "",
  },
  todo: {
    id: "todo",
    defaultMessage: "To do",
    description: "",
  },
  alloy: {
    id: "alloy",
    defaultMessage: "Alloy",
    description: "",
  },
  lowerCaseOrders: {
    id: "lowerCaseOrders",
    defaultMessage: "orders",
    description: "",
  },
  comment: {
    id: "comment",
    defaultMessage: "Comment",
    description: "",
  },
  copyOf: {
    id: "copyOf",
    defaultMessage: "[Copy of] {weldNumber}",
    description: "",
  },
  weldNumbersAlreadyAdded: {
    id: "weldNumbersAlreadyAdded",
    defaultMessage: `{count, plural, 
        =1 {Weld number {alreadyUsedNumbers} is already added}
        other {Weld numbers {alreadyUsedNumbers} are already added}
    }`,
    description: "Message displayed when weld numbers are already added",
  },
  rangeWeldNumberError: {
    id: "rangeWeldNumberError",
    defaultMessage: "Range weld number should start from {nextWeldNumber}",
    description: "",
  },
  failedToAddWeldToNdtOrder: {
    id: "failedToAddWeldToNdtOrder",
    defaultMessage: "Failed to add weld to NDT order",
    description: "",
  },
  weldNumberCannotBeEmpty: {
    id: "weldNumberCannotBeEmpty",
    defaultMessage: "Weld number cannot be empty. Next available weld number is {nextWeldNumber}",
    description: "",
  },
  projectParticipantRemovedSuccessfully: {
    id: "projectParticipantRemovedSuccessfully",
    defaultMessage: "Project participant(s) removed successfully",
    description: "",
  },
  areYouSureYouWantToDelete: {
    id: "areYouSureYouWantToDelete",
    defaultMessage: "Are you sure you want to delete selected",
    description: "",
  },
  doneBy: {
    id: "doneBy",
    defaultMessage: "Done by",
    description: "",
  },
  fileTypeShouldBe: {
    id: "fileTypeShouldBe",
    defaultMessage: "File type should be either {types}",
    description: "",
  },
  weldNumberAlreadyAdded: {
    id: "weldNumberAlreadyAdded",
    defaultMessage:
      "Weld number {value} is already added. Next available number is {nextWeldNumber}",
    description: "",
  },
  notRequired: {
    id: "notRequired",
    defaultMessage: "Not required",
    description: "",
  },
  weldAddedToNdtOrder: {
    id: "weldAddedToNdtOrder",
    defaultMessage: "Weld added to NDT order",
    description: "",
  },
  ndtLogged: {
    id: "ndtLogged",
    defaultMessage: "NDT logged",
    description: "",
  },
  startByCreatingNDT: {
    id: "startByCreatingNDT",
    defaultMessage: "Start by creating an NDT order",
    description: "",
  },
  youCanStartByCreatingNdtOrder: {
    id: "youCanStartByCreatingNdtOrder",
    defaultMessage: "You can start by creating an NDT order and proceed from there.",
    description: "",
  },
  clickOrDropFiles: {
    id: "clickOrDropFiles",
    defaultMessage: "Click or drop files",
    description: "",
  },
  projectNotExist: {
    id: "projectNotExist",
    defaultMessage: "Project {projectId} does not exist or not available.",
    description: "",
  },
  toRedo: {
    id: "toRedo",
    defaultMessage: "To redo",
    description: "",
  },
  loggedNdtSuccessfully: {
    id: "loggedNdtSuccessfully",
    defaultMessage: "Logged NDT successfully",
    description: "",
  },
  failedToLogNdt: {
    id: "failedToLogNdt",
    defaultMessage: "Failed to log NDT",
    description: "",
  },
  toBeInspected: {
    id: "toBeInspected",
    defaultMessage: "To be inspected",
    description: "",
  },
  rejected: {
    id: "rejected",
    defaultMessage: "Rejected",
    description: "",
  },
  successfullyRemovedFromNdtOrder: {
    id: "successfullyRemovedFromNdtOrder",
    defaultMessage: "Successfully removed from NDT order",
    description: "",
  },
  viewer: {
    id: "viewer",
    defaultMessage: "Viewer",
    description: "",
  },
  weldingCoordinator: {
    id: "weldingCoordinator",
    defaultMessage: "Welding coordinator",
    description: "",
  },
  responsibleWeldingCoordinator: {
    id: "responsibleWeldingCoordinator",
    defaultMessage: "Responsible welding coordinator",
    description: "",
  },
  projectLeader: {
    id: "projectLeader",
    defaultMessage: "Project leader",
    description: "",
  },
  heatTreatmentOperator: {
    id: "heatTreatmentOperator",
    defaultMessage: "Heat treatment operator",
    description: "",
  },
  ndtOperator: {
    id: "ndtOperator",
    defaultMessage: "NDT operator",
    description: "",
  },
  eddyCurrentTesting: {
    id: "eddyCurrentTesting",
    defaultMessage: "Eddy current testing",
    description: "",
  },
  hardnessTesting: {
    id: "hardnessTesting",
    defaultMessage: "Hardness testing",
    description: "",
  },
  magneticParticleTesting: {
    id: "magneticParticleTesting",
    defaultMessage: "Magnetic particle testing",
    description: "",
  },
  radiographicTesting: {
    id: "radiographicTesting",
    defaultMessage: "Radiographic testing",
    description: "",
  },
  ultrasonicTesting: {
    id: "ultrasonicTesting",
    defaultMessage: "Ultrasonic testing",
    description: "",
  },
  liquidPenetrants: {
    id: "liquidPenetrants",
    defaultMessage: "Liquid penetrants",
    description: "",
  },
  visualInspection: {
    id: "visualInspection",
    defaultMessage: "Visual inspection",
    description: "",
  },
  compiling: {
    id: "compiling",
    defaultMessage: "Compiling",
    description: "Label for compiling status",
  },
  waitingInspection: {
    id: "waitingInspection",
    defaultMessage: "Waiting inspection",
    description: "Label for waitingInspection status",
  },
  inProgress: {
    id: "inProgress",
    defaultMessage: "In progress",
    description: "Label for inProgress status",
  },
  toBeInspection: {
    id: "toBeInspection",
    defaultMessage: "To be inspected",
    description: "Label for toBeInspection status",
  },
  sampleAndCoverageRequired: {
    id: "sampleAndCoverageRequired",
    defaultMessage: "Sample rates and Coverage rate values are required",
    description: "Error message for when both sample rates and coverage rates are missing",
  },
  sampleRatesRequired: {
    id: "sampleRatesRequired",
    defaultMessage: "Sample rates are required",
    description: "Error message for when only the sample rates are missing",
  },
  coverageRatesRequired: {
    id: "coverageRatesRequired",
    defaultMessage: "Coverage rates are required",
    description: "Error message for when only the coverage rates are missing",
  },
  noEventYet: {
    id: "noEventYet",
    defaultMessage: "No event yet",
    description: "Message displayed when there are no events",
  },
  noEventYetDescription: {
    id: "noEventYetDescription",
    defaultMessage: "Weld related events will show automatically here",
    description: "Message displayed when there are no events",
  },
  addNote: {
    id: "addNote",
    defaultMessage: "Add note",
    description: "Add note",
  },
  documentSectionAdded: {
    id: "documentSectionAdded",
    defaultMessage: "Document section added",
    description: "Toast message display when document section is added",
  },
  documentSectionDeleted: {
    id: "documentSectionDeleted",
    defaultMessage: "Document section deleted",
    description: "Toast message display when document section is deleted",
  },
  savedAsTemplate: {
    id: "savedAsTemplate",
    defaultMessage: "Saved as template",
    description: "Toast message display when document sections are saved as template",
  }
});
