import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";

import AddProjectParticipantForm from "./add-project-participant-form";

import { useIntl } from "react-intl";
import messages from '../../../i18n/messages';

const useStyles = makeStyles(({ breakpoints }) => ({
  dialogPaper: {
    [`${breakpoints.up("sm")}`]: {
      minWidth: 700,
    },
  },
}));

function AddProjectParticipantsDialog({ open, onClose, participantToEdit }) {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const title = participantToEdit?.userInfo?.uid ? intl.formatMessage(messages.editParticipant) : intl.formatMessage(messages.addParticipant);

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullScreen={fullScreen}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <AddProjectParticipantForm
          onClose={handleCancel}
          participantToEdit={participantToEdit}
        />
      </DialogContent>
    </Dialog>
  );
}

AddProjectParticipantsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  participantToEdit: PropTypes.object.isRequired,
};

export default AddProjectParticipantsDialog;
