import React from "react";
import { orderBy } from "lodash";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";

// Material
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";

// Icon
import { HiOutlineBadgeCheck } from "react-icons/hi";

// Src
import { db } from "../../../constants";
import messages from "../../../i18n/messages";
import WeldDocuments from "./weld-documents";
import WeldEventLoggingDialog from "./weld-event-logging-dialog";
import CustomCard from "../../../components/custom-card/custom-card";
import RoleAccess from "../../../components/role-access/role-access";
import ActivityTimeline from "../../../components/activity-timeline/activity-timeline";
import useStoreProvider from "../../../common/providers/store/use-app-context";
import useWeldEventLoggingForm from "../../../common/utils/use-weld-event-logging-form";

const useStyles = makeStyles(({ spacing }) => ({
  projectDetailItems: {
    flex: "1 1 0px",
  },
  header: {
    display: "flex",
    placeContent: "space-between",
    alignItems: "center",
  },
  doneChip: {
    background: "rgb(237, 247, 230)",
  },
  doneIcon: {
    fontSize: spacing(3),
    color: "#4EB70B",
  },
  verticalDivider: {
    background: "#e3e3e3",
    marginRight: spacing(2),
    marginLeft: spacing(2),
  },
}));

const ProjectDetailsHeader = ({ buttonProps, isWeldProcessCompleted }) => {
  const classes = useStyles();

  const intl = useIntl();

  return (
    <Box className={classes.header} display="flex">
      <Typography variant="subtitle1">
        {intl.formatMessage(messages.events)}
      </Typography>
      <Box className={classes.header} display="flex">
        {isWeldProcessCompleted && (
          <>
            <Chip
              label={intl.formatMessage(messages.weldCompleted)}
              className={classes.doneChip}
              icon={<HiOutlineBadgeCheck className={classes.doneIcon} />}
            />
            {buttonProps?.showInWeldDetailsPage &&
              (isWeldProcessCompleted === "failed" ||
                !isWeldProcessCompleted) && (
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.verticalDivider}
                />
              )}
          </>
        )}
        <RoleAccess roles={buttonProps?.roles || []}>
          <>
            {buttonProps?.showInWeldDetailsPage &&
              (isWeldProcessCompleted === "failed" ||
                !isWeldProcessCompleted) && (
                <Button
                  className={classes.editButton}
                  color="primary"
                  variant="contained"
                  disableElevation
                  disabled={buttonProps?.disabled}
                  onClick={() => buttonProps.onClick()}
                >
                  {buttonProps?.label}
                  {buttonProps?.showInWeldDetailsPage}
                </Button>
              )}
          </>
        </RoleAccess>
      </Box>
    </Box>
  );
};

const WeldEvents = () => {
  const { selectedProject, selectedWeldLog, selectedWeld, setSelectedWeld } =
    useStoreProvider();
  const intl = useIntl();

  const [open, setOpen] = React.useState(false);
  const [weldEventToEdit, setWeldEventToEdit] = React.useState({});
  const [indexOfWeldEventToEdit, setIndexOfWeldEventToEdit] = React.useState();
  const [eventUpdated, setEventUpdated] = React.useState(false);

  // Get weld details on event log. This is needed to display the newly
  // added event.
  const getWeld = React.useCallback(async () => {
    const documentRef = db
      .collection("projects")
      .doc(`${selectedProject?.id}`)
      .collection("weld-logs")
      .doc(`${selectedWeldLog?.id}`)
      .collection("welds")
      .doc(`${selectedWeld?.id}`);

    const doc = await documentRef.get();

    if (doc.exists) {
      setSelectedWeld(doc.data());
      setEventUpdated(false);
      setOpen(false);
    }
  }, [
    selectedProject?.id,
    selectedWeldLog?.id,
    selectedWeld?.id,
    setSelectedWeld,
  ]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogEventOpen = () => {
    setOpen(true);
    setWeldEventToEdit({});
    setIndexOfWeldEventToEdit();
  };

  const handleEditEventOpen = (weldEventIndex) => {
    setWeldEventToEdit(selectedWeld?.events[weldEventIndex]);
    setIndexOfWeldEventToEdit(weldEventIndex);
    setOpen(true);
  };

  const weldStates = useWeldEventLoggingForm({
    selectedWelds: React.useMemo(() => [selectedWeld], [selectedWeld]),
    weldEventToEdit: weldEventToEdit,
    onClickHandler: handleLogEventOpen,
  });

  const orderedWeldEvents = orderBy(
    selectedWeld?.events,
    ["loggedAt"],
    ["desc"]
  );

  React.useEffect(() => {
    if (eventUpdated) {
      getWeld();
    }
  }, [getWeld, eventUpdated]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <CustomCard
          header={
            <ProjectDetailsHeader
              buttonProps={weldStates[0]?.buttonProps}
              isWeldProcessCompleted={weldStates[0]?.isWeldProcessCompleted}
            />
          }
        >
          <Box
            style={{ minHeight: 295, maxHeight: "70VH", overflow: "scroll" }}
          >
            <ActivityTimeline
              showEditButton
              events={orderedWeldEvents}
              handleEditClick={handleEditEventOpen}
            />
          </Box>
        </CustomCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomCard
          header={
            <Typography variant="subtitle1">
              {intl.formatMessage(messages.weldPhotos)}
            </Typography>
          }
        >
          <WeldDocuments />
        </CustomCard>
        {!!weldStates[0]?.form && (
          <WeldEventLoggingDialog
            open={open}
            Form={weldStates[0]?.form}
            title={weldStates[0]?.formTitle}
            onClose={handleClose}
            onEventLogged={setEventUpdated}
            disabled={weldStates[0]?.disabled}
            usersInvolved={weldStates[0]?.usersInvolved}
            weldEventToEdit={weldEventToEdit}
            indexOfWeldEventToEdit={indexOfWeldEventToEdit}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default WeldEvents;
