// React and Third-Party Libraries
import React from "react";
import { useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

// Icons
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { LuPencilLine } from "react-icons/lu";
import { MdOutlineDelete } from "react-icons/md";

// Utilities, Constants, and Internationalization
import { db } from "../../../constants";
import messages from "../../../i18n/messages";
import useDeleteDocuments from "../../../common/utils/use-delete-documents";
import useRemoveWeldFromNdtOrder from "../../../common/utils/use-remove-weld-from-ndt-order";

const useStyles = makeStyles(({ palette, spacing }) => ({
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuItemHeight: {
    height: 45,
  },
  menuIcon: {
    fontSize: spacing(2.5),
    marginRight: spacing(2),
    color: palette.secondary.dark,
  },
  menuIconSmaller: {
    fontSize: spacing(2.3),
    marginRight: spacing(2),
    color: palette.secondary.dark,
  },
}));

const WeldActionMenu = ({ weld }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { pid, weldLogId } = useParams();
  const { removeWeldsFromNdtOrder } = useRemoveWeldFromNdtOrder(pid);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeSuccessMessage = intl.formatMessage(messages.weldsDeletedSuccessfully);
  const removeFailureMessage = intl.formatMessage(messages.somethingWentWrong);

  const setSelected = () => ({});
  const collectionRef = db
    .collection("projects")
    .doc(pid)
    .collection("weld-logs")
    .doc(weldLogId)
    .collection("welds");

  const selectedWeldIds = [weld?.id];

  const { deleteDocuments } = useDeleteDocuments(
    collectionRef,
    selectedWeldIds,
    setSelected,
    removeSuccessMessage,
    removeFailureMessage
  );

  const handleDeleteWeld = () => {
    const weldToRemoveFromNdtOrder = [
      {
        weldId: weld.id,
        weldLogId: weld.weldLogId,
        ndtOrderId: weld.ndtOrder?.ndtOrderId,
      },
    ];

    removeWeldsFromNdtOrder(weldToRemoveFromNdtOrder);
    deleteDocuments();
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label={intl.formatMessage(messages.more)}
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: "0px" }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        open={open}
        keepMounted
        elevation={5}
        anchorEl={anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            width: 160,
            boxShadow: "none",
            border: "1px solid #e3e3e3",
          },
        }}
        classes={{ list: classes.menuList }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          classes={{ root: classes.menuItemHeight }}
          onClick={handleClose}
          component={Link}
          to={{
            pathname: `/projects/${pid}/weld-logs/${weldLogId}/welds/${weld?.id}/edit`,
            state: { weldData: weld },
          }}
        >
          <LuPencilLine className={classes.menuIconSmaller} />
          <Box>
            <Typography display="block" gutterBottom>
              {intl.formatMessage(messages.edit)}
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem classes={{ root: classes.menuItemHeight }} onClick={handleDeleteWeld}>
          <MdOutlineDelete className={classes.menuIconSmaller} />
          <Box>
            <Typography display="block" gutterBottom>
              {intl.formatMessage(messages.delete)}
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default WeldActionMenu;
