import React from "react";

/**
 * Hook to get document data
 * @returns 
 */
const useFetchDocument = () => {
  const getDocument = React.useCallback(async (documentRef) => {
    const selectedDocument = await documentRef.get();

    if (selectedDocument.exists) {
      return selectedDocument.data();
    }

    return {};
  }, []);

  return { getDocument };
};

export default useFetchDocument