import firebase from "firebase";
import { format, getUnixTime } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { TIME_FORMAT } from "../../constants";
import messages from "../../i18n/messages";

export const formatDate = (timestamp, intl) => {
  if (!timestamp) {
    intl.formatMessage(messages.na);
    return;
  }

  if (!timestamp?.seconds) {
    const seconds = getUnixTime(new Date(timestamp));
    timestamp = new firebase.firestore.Timestamp(seconds, 0);
  } else {
    timestamp = new firebase.firestore.Timestamp(timestamp?.seconds, 0);
  }

  const date = timestamp?.toDate();
  const utcDate = utcToZonedTime(date, "UTC");
  return format(utcDate, TIME_FORMAT.LONG);
};

export const getCurrentDate = () => {
  const now = new Date();
  return format(now, TIME_FORMAT.DATE_PICKER);
};
