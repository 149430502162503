import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { format, addSeconds } from "date-fns";

// Material
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";

// Icons
import { MdOutlineDelete } from "react-icons/md";

// Src
import LoadingStateCard from "../../../components/loading-state-card/loading-state-card";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import RoleAccess from "../../../components/role-access/role-access";
import { deactivateUsers } from "../../../common/utils/auth";
import UserManagementMenu from "./user-management-menu";
import { NOTIFICATION_POSITION, USER_ROLES } from "../../../constants";

import { useIntl } from "react-intl";
import messages from '../../../i18n/messages';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function UsersListTableHead(props) {
  const intl = useIntl();

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.name),
    },
    {
      id: "identifier",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.identifier),
    },
    {
      id: "userUid",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.userUID),
    },
    {
      id: "userGroup",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.userGroup),
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.status),
    },
    {
      id: "created",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.created),
    },
    {
      id: "addedBy",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.addedBy),
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
    },
  ];

  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    (<TableHead style={{ backgroundColor: "#e9e9e9" }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": intl.formatMessage(messages.selectAllUsers) }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>)
  );
}

UsersListTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1",
  },
  addButton: {
    padding: theme.spacing(0.5, 4),
  },
  menuIcon: {
    fontSize: theme.spacing(3),
    color: theme.palette.secondary.dark,
  },
}));

const UsersListTableToolbar = ({ uids, numSelected, setSelected }) => {
  const classes = useToolbarStyles();
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  const intl = useIntl();

  const defaultTitleText = intl.formatMessage(messages.users);

  const tableTitle = numSelected > 0
    ? `${defaultTitleText} - ${numSelected} ${intl.formatMessage(messages.selected)}`
    : defaultTitleText;

  const deactivateUserAccounts = () => {
    const successMessage = intl.formatMessage(messages.accountDeleted);
    const failureMessage = intl.formatMessage(messages.unableToDeleteAccount);

    deactivateUsers(uids)
      .then(() => {
        toast.success(successMessage, {
          position: NOTIFICATION_POSITION,
        });
        setSelected([]);
      })
      .catch((err) => {
        console.log("DeleteAccount::", err);
        toast.error(failureMessage, {
          position: NOTIFICATION_POSITION,
        });
      });
  };

  return (
    (<Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Typography className={classes.title} variant="subtitle1" component="div">
        {tableTitle}
      </Typography>
      {numSelected > 0 && (
        <RoleAccess
          roles={[
            USER_ROLES.ADMIN,
            USER_ROLES.PROJECT_LEADER,
            USER_ROLES.WELDING_COORDINATOR,
            USER_ROLES.RESPONSIBLE_WELDING_COORDINATOR,
          ]}
        >
          <Tooltip title={intl.formatMessage(messages.delete)}>
            <IconButton
              aria-label={intl.formatMessage(messages.delete)}
              onClick={() => setConfirmOpen(true)}
            >
              <MdOutlineDelete className={classes.menuIcon} />
            </IconButton>
          </Tooltip>
        </RoleAccess>
      )}
      <Divider />
      <ConfirmDialog
        title={intl.formatMessage(messages.removeUser)}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={deactivateUserAccounts}
      >
        {`${intl.formatMessage(messages.areYouSureToDeleteSelected)
          } ${numSelected} ${numSelected > 1
            ? intl.formatMessage(messages.lowerCaseUsers)
            : intl.formatMessage(messages.lowerCaseUser)
          }?`}
      </ConfirmDialog>
    </Toolbar>)
  );
};

UsersListTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
    boxShadow: "none",
    border: "1px solid #e3e3e3",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  activeUser: {
    color: "#4EB70B",
    background: "rgb(237, 247, 230)",
  },
  inactiveUser: {
    color: "rgba(0, 0, 0, 0.6)",
  },
}));

const UsersList = (props) => {
  const intl = useIntl();

  const classes = useStyles();
  const { rows, fetching } = props;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const preventRowSelection = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.uid);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (uid) => {
    const selectedIndex = selected.indexOf(uid);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, uid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    (<div className={classes.root}>
      {fetching ? (
        <LoadingStateCard />
      ) : (
        <Paper className={classes.paper}>
          <UsersListTableToolbar
            uids={selected}
            numSelected={selected.length}
            setSelected={setSelected}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <UsersListTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />

              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.uid);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const helperDate = addSeconds(
                      new Date(0),
                      row?.createdAt?.seconds
                    );
                    const createdDate = row?.createdAt
                      ? format(helperDate, "MMM dd, yyyy")
                      : intl.formatMessage(messages.na);

                    const addedBy =
                      row?.addedByFname && row?.addedByLname
                        ? `${row.addedByFname} ${row.addedByLname}`
                        : intl.formatMessage(messages.na);

                    const userGroup = row?.userRole?.includes("admin")
                      ? intl.formatMessage(messages.admin)
                      : intl.formatMessage(messages.user);

                    const userStatus = row?.active ? intl.formatMessage(messages.active) : intl.formatMessage(messages.inactive);

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        onClick={(_event) => handleClick(row?.uid)}
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.uid}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell id={labelId} scope="row">
                          {row?.fname} {row?.lname}
                        </TableCell>
                        <TableCell align="left">{row?.email}</TableCell>
                        <TableCell align="left">
                          <Typography noWrap style={{ width: 150 }}>
                            {row?.uid}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{userGroup}</TableCell>
                        <TableCell align="left">
                          <Chip
                            label={userStatus}
                            size="small"
                            classes={{
                              root: row?.active
                                ? classes.activeUser
                                : classes.inactiveUser,
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">{createdDate}</TableCell>
                        <TableCell align="left">{addedBy}</TableCell>
                        <TableCell
                          align="left"
                          onClick={(e) => preventRowSelection(e)}
                        >
                          <UserManagementMenu
                            uid={row?.uid}
                            isUserActive={row?.active}
                            isAdmin={row?.userRole?.includes("admin")}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={12} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>)
  );
};

export default UsersList;
