import React from "react";
import { useIntl } from "react-intl";

// Material UI
import Box from "@material-ui/core/Box";

// Components
import LoadingStateCard from "../../../components/loading-state-card/loading-state-card";
import StateCard from "../../../components/state-card/state-card";
import ProjectListTable from "./project-list-table";

// Hooks
import useStoreProvider from "../../../common/providers/store/use-app-context";

// Utilities and constants
import messages from "../../../i18n/messages";
import { db } from "../../../constants";

const ProjectList = ({ status }) => {
  const intl = useIntl();
  const {
    loggedInUser,
    setSelectedProject,
    setSelectedProjectEvents,
    setProjectParticipants,
    setWeldLogCollection,
  } = useStoreProvider();

  const [assignedProjects, setAssignedProjects] = React.useState([]);
  const [fetching, setFetching] = React.useState(true);

  const isAdmin = loggedInUser?.userRole?.includes("admin");

  const projectsCollectionRef = db
    .collection("projects")
    .orderBy("createdAt", "desc")
    .where("status", "==", status);

  const buttonProps = {
    color: "primary",
    variant: "contained",
    label: intl.formatMessage(messages.addProject),
    to: "/projects/add",
  };

  /**
   * Admin will see all projects but other users will see projects that are assigned to them.
   * @param {Object} projects - array of project objects
   */
  const getProjectsAssignedToLoggedInUser = async (projects) => {
    // Get participant data if current user is assigned to the list of projects
    const promises = projects.map(async (project) => {
      const pp = await db
        .collection("projects")
        .doc(`${project?.id}`)
        .collection("participants")
        .where("userInfo.uid", "==", loggedInUser?.uid)
        .get();

      // Update each project with current user's participation status.
      project.isAssignedToCurrentUser = pp.docs.map((doc) => doc.data()).length;
      return project;
    });

    const projectsWithAssigneeInfo = await Promise.all(promises);
    const projectsAssignedToCurrentUser = isAdmin
      ? projectsWithAssigneeInfo
      : projectsWithAssigneeInfo.filter((project) => project.isAssignedToCurrentUser === 1);
    setAssignedProjects(projectsAssignedToCurrentUser);
    setFetching(false);
  };

  const onCollectionUpdate = async (querySnapshot) => {
    const projects = querySnapshot.docs.map((doc) => doc.data());

    getProjectsAssignedToLoggedInUser(projects);
  };

  const renderEmptyState = () => {
    const baseProps = {
      title: intl.formatMessage(messages.noProjectYet),
      description: intl.formatMessage(messages.createFirstProject),
      buttonProps,
      showButton: isAdmin,
    };

    if (status === "archived") {
      baseProps.title = intl.formatMessage(messages.noArchivedProjectYet);
      baseProps.showButton = false;
      baseProps.description = intl.formatMessage(messages.projectsWillBeListedHere);
    } else if (!isAdmin) {
      baseProps.title = intl.formatMessage(messages.noProjectAssigned);
      baseProps.showButton = false;
      baseProps.description = intl.formatMessage(messages.pleaseContactYour);
    }

    return <StateCard {...baseProps} />;
  };

  React.useEffect(() => {
    // Reset the selected project and project participants when the component is mounted.
    // This is to avoid showing the previous project details when the component is re-rendered with another project.
    setSelectedProject(null);
    setProjectParticipants(null);
    setSelectedProjectEvents(null);
    setWeldLogCollection(null);

    projectsCollectionRef.onSnapshot(onCollectionUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, loggedInUser]);

  return (
    <>
      {fetching ? (
        <LoadingStateCard />
      ) : assignedProjects.length > 0 ? (
        <Box width="100%">
          <ProjectListTable
            rows={assignedProjects}
            fetching={fetching}
            buttonProps={buttonProps}
            projectListType={status}
          />
        </Box>
      ) : (
        renderEmptyState()
      )}
    </>
  );
};

export default ProjectList;
