import React from "react";

import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import { useIntl } from 'react-intl';
import messages from '../../i18n/messages';

const GenericTableHead = ({
  classes,
  order,
  orderBy,
  headCells,
  rowCount,
  numberOfSelectedItems,
  onSelectAllClick,
  onRequestSort,
  withCheckbox = true,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const intl = useIntl();

  return (
    (<TableHead style={{ backgroundColor: "#e9e9e9" }}>
      <TableRow>
        {withCheckbox && (
          <TableCell className={classes.tableCell} padding="checkbox">
            <Checkbox
              indeterminate={
                numberOfSelectedItems > 0 && numberOfSelectedItems < rowCount
              }
              checked={rowCount > 0 && numberOfSelectedItems === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": intl.formatMessage(messages.selectAll) }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tableCell}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>)
  );
};
export default GenericTableHead;
