import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

// Material UI
import { lighten, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Popover from "@material-ui/core/Popover";
import TextField from "@material-ui/core/TextField";

// Icon
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { BsFileEarmarkText } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import { LuStamp } from "react-icons/lu";

// Components
import RoleAccess from "../../../components/role-access/role-access";
import ExternalDocumentLink from "../../../components/external-link/external-link";
import LoadingStateCard from "../../../components/loading-state-card/loading-state-card";

// Hooks
import useUpdateDocument from "../../../common/utils/use-update-document";
import useTablePaginationSettings from "../../../common/utils/use-table-pagination-settings";

// Constants and utilities
import useStoreProvider from "../../../common/providers/store/use-app-context";
import { useIsOverflow } from "../../../common/utils/text-overflow";
import { formatDate } from "../../../common/utils/format-date";
import { USER_ROLES } from "../../../constants";
import messages from "../../../i18n/messages";
import { db } from "../../../constants";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// TODO: Currently table sorting is not working when row value is an object.
// One possible solution would be to flatten out nested array values and use
// a modified version of the row value.
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useCustomTableCellStyle = makeStyles(({ spacing, palette }) => ({
  popover: {
    padding: spacing(2),
  },
  popoverButtonContainer: {
    marginTop: spacing(2),
    display: "flex",
    placeContent: "space-between",
  },
  editIcon: {
    fontSize: spacing(2),
    marginRight: spacing(1),
    color: palette.grey[500],
  },
  tooltip: {
    background: "#001B33",
  },
  tooltipArrow: {
    color: "#001B33",
  },
  tooltipText: {
    color: "#FFFFFF",
  },
  overFlowWrapper: {
    width: 200,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    alignContent: "center",
  },
}));

const CustomTableCell = ({ report }) => {
  const intl = useIntl();
  const ref = React.useRef();
  const { pid } = useParams();
  const isOverflow = useIsOverflow(ref);
  const classes = useCustomTableCellStyle();
  const { updateDocument } = useUpdateDocument();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [note, setReportNote] = React.useState(null);

  const reportDocumentRef = db
    .collection("projects")
    .doc(pid)
    .collection("reports")
    .doc(report?.id);

  const preventRowSelection = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    preventRowSelection(e);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    preventRowSelection(e);
  };

  const handleChange = (e) => {
    setReportNote(e.target.value);
  };

  const handleUpdateReportNote = () => {
    updateDocument({ documentRef: reportDocumentRef, payload: { note } });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <TableCell
        className={classes.tableCell}
        align="left"
        padding="checkbox"
        onClick={handleClick}
        style={{ width: 200, maxWidth: 200 }}
      >
        {!report?.note && (
          <Box display="flex" alignContent="center">
            <CiEdit fontSize="small" className={classes.editIcon} />
            {intl.formatMessage(messages.addNote)}
          </Box>
        )}
        {report?.note && !isOverflow && (
          <Box ref={ref} display="flex" alignContent="center">
            <CiEdit fontSize="small" className={classes.editIcon} />
            {report?.note}
          </Box>
        )}
        {report?.note && isOverflow && (
          <Tooltip
            arrow
            placement="top"
            classes={{
              arrow: classes.tooltipArrow,
              tooltip: classes.tooltip,
            }}
            title={<Typography className={classes.tooltipText}>{report?.note}</Typography>}
          >
            <Box display="flex">
              <CiEdit fontSize="small" className={classes.editIcon} />
              <Box ref={ref} className={classes.overFlowWrapper}>
                <Typography style={{ display: "inline" }}>{report?.note}</Typography>
              </Box>
            </Box>
          </Tooltip>
        )}
      </TableCell>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box display="flex" flexDirection="column" className={classes.popover}>
          <Typography variant="title">
            {intl.formatMessage(messages.addReportDescription)}
          </Typography>
          <TextField
            id="position"
            onChange={handleChange}
            onClick={(e) => preventRowSelection(e)}
          />
          <Box className={classes.popoverButtonContainer}>
            <Button variant="outlined" size="small" onClick={handleClose}>
              {intl.formatMessage(messages.cancel)}
            </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              size="small"
              onClick={handleUpdateReportNote}
            >
              {intl.formatMessage(messages.save)}
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

function ReportListTableHead(props) {
  const intl = useIntl();

  const headCells = [
    {
      id: "report",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.report),
    },
    {
      id: "version",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.version),
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.createdBy),
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.createdAt),
    },
    {
      id: "note",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.note),
      align: "left",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.approvalStatus),
      padding: "checkbox",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
    },
  ];

  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: "#e9e9e9" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tableCell}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ReportListTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  root: {
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    [breakpoints.down("md")]: {
      paddingLeft: spacing(1.5),
      paddingRight: spacing(1.5),
    },
  },
  highlight:
    palette.type === "light"
      ? {
          color: palette.secondary.main,
          backgroundColor: lighten(palette.secondary.light, 0.85),
        }
      : {
          color: palette.text.primary,
          backgroundColor: palette.secondary.dark,
        },
  title: {
    flex: "1",
  },
  generateReport: {
    padding: spacing(1, 0),
    minWidth: 200,
  },
  reportIcon: {
    marginRight: spacing(1.5),
  },
  reportGenerationCircularProgress: {
    marginRight: spacing(1.5),
    color: "white",
    sx: {
      position: "absolute",
      top: -6,
      left: -6,
      zIndex: 1,
    },
  },
}));

const ReportListTableToolbar = ({ numSelected, buttonProps }) => {
  const classes = useToolbarStyles();
  const intl = useIntl();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      ></Typography>
      <>
        <Button
          className={classes.generateReport}
          disableElevation
          disabled={buttonProps.isGenerating}
          {...buttonProps}
        >
          {buttonProps.isGenerating ? (
            <>{buttonProps?.progressText}</>
          ) : (
            <>
              <BsFileEarmarkText size={22} className={classes.reportIcon} />
              {intl.formatMessage(messages.generateReport)}
            </>
          )}
        </Button>
      </>
      <Divider />
    </Toolbar>
  );
};

ReportListTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useTableStyle = makeStyles(({ spacing }) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: spacing(3),
    borderRadius: spacing(0.5),
    boxShadow: "none",
    border: "1px solid #e3e3e3",
  },
  table: {
    minWidth: 750,
  },
  tableCell: {
    whiteSpace: "nowrap",
  },
  actions: {
    marginLeft: 0,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  imageLinkContainer: {
    display: "flex",
    alignItems: "center",
  },
  reportGenerationStatusContainer: {
    display: "flex",
    alignItems: "center",
  },
  reportGenerationCircularProgress: {
    marginRight: spacing(1.5),
  },
  reportGenerationStatusText: {
    color: "rgb(0, 122, 255)",
  },
  errorIcon: {
    color: "rgb(255 84 72)",
    marginRight: spacing(1.5),
  },
  reportGenerationErrorText: {
    color: "rgb(255 84 72)",
  },
  approvalButton: {
    display: "flex",
    width: 35,
    height: 35,
    background: "#F7F6F9",
    border: "2px solid #EBEBED",
    alignItems: "center",
    placeContent: "center",
    borderRadius: 6,
    transition: "all 0.2s",
    "&:hover": {
      cursor: "pointer",
      background: "#28b87c30",
      border: "2px solid #28b87c50",
    },
  },
  approvalButtonIcon: {
    color: "#28b87c",
  },
  approvedReportStatus: {
    color: "#28b87c",
  },
  pendingReportStatus: {
    color: "rgba(0, 0, 0, 0.6)",
  },
  margin: {
    margin: spacing(0),
    lineHeight: spacing(0.17),
  },
}));

export default function ReportListTable({
  rows,
  buttonProps,
  fetching,
  isError,
  reportGenerationError,
}) {
  const intl = useIntl();
  const { pid } = useParams();
  const classes = useTableStyle();
  const { loggedInUser } = useStoreProvider();
  const { updateDocument } = useUpdateDocument();
  const { rowsPerPageOptions, rowsPerPage, setRowsPerPage } = useTablePaginationSettings(rows);

  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState("asc");
  const [selected, setSelected] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState("createdAt.seconds");

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Update report document with approval status
  const updateReportApprovalStatus = (report) => {
    const reportDocumentRef = db
      .collection("projects")
      .doc(pid)
      .collection("reports")
      .doc(report?.id);
    const approvedBy = `${loggedInUser.fname} ${loggedInUser.lname}`;

    updateDocument({
      documentRef: reportDocumentRef,
      payload: { approved: true, approvedBy },
    });
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      {fetching ? (
        <LoadingStateCard />
      ) : (
        <Paper className={classes.paper}>
          <ReportListTableToolbar
            selectedWeldIds={selected}
            numSelected={selected.length}
            buttonProps={buttonProps}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <ReportListTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />

              <TableBody>
                {buttonProps.isGenerating && buttonProps?.progressText && (
                  <TableRow>
                    <TableCell className={classes.tableCell} colSpan={7}>
                      <Box display="flex" className={classes.reportGenerationStatusContainer}>
                        <CircularProgress
                          className={classes.reportGenerationCircularProgress}
                          size={20}
                        />
                        <Typography className={classes.reportGenerationStatusText}>
                          {buttonProps?.progressText}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}

                {isError && !buttonProps.isGenerating && (
                  <TableRow>
                    <TableCell className={classes.tableCell} colSpan={7}>
                      <Box display="flex" className={classes.reportGenerationStatusContainer}>
                        <ErrorOutlineIcon className={classes.errorIcon} />
                        <Typography className={classes.reportGenerationErrorText}>
                          {reportGenerationError}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}

                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, _index) => {
                    const isItemSelected = isSelected(row.id);

                    const reportStatus = row?.approved
                      ? intl.formatMessage(messages.approved)
                      : intl.formatMessage(messages.pending);

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell
                          className={classes.tableCell}
                          align="left"
                          style={{ width: 100 }}
                        >
                          <Box className={classes.imageLinkContainer}>
                            <ExternalDocumentLink fileData={row?.document} />
                          </Box>
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          align="left"
                          style={{ width: 100 }}
                        >
                          {row?.version || intl.formatMessage(messages.na)}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          align="left"
                          style={{ width: 150 }}
                        >
                          {row?.createdBy}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          align="left"
                          style={{ width: 150 }}
                        >
                          {formatDate(row?.createdAt, intl)}
                        </TableCell>
                        <CustomTableCell report={row} />
                        <TableCell align="left" padding="checkbox" style={{ width: 150 }}>
                          <Typography
                            classes={{
                              root: row?.approved
                                ? classes.approvedReportStatus
                                : classes.pendingReportStatus,
                            }}
                          >
                            {reportStatus}
                          </Typography>
                          {row?.approved && (
                            <Typography variant="caption">By {row?.approvedBy}</Typography>
                          )}
                        </TableCell>
                        <TableCell align="left" padding="checkbox">
                          {!row?.approved && (
                            <RoleAccess
                              roles={[
                                USER_ROLES.ADMIN,
                                USER_ROLES.PROJECT_LEADER,
                                USER_ROLES.WELDING_COORDINATOR,
                                USER_ROLES.RESPONSIBLE_WELDING_COORDINATOR,
                                USER_ROLES.NDT_OPERATOR,
                              ]}
                            >
                              <Tooltip title={intl.formatMessage(messages.approve)}>
                                <Box
                                  className={classes.approvalButton}
                                  onClick={() => updateReportApprovalStatus(row)}
                                >
                                  <LuStamp size={18} className={classes.approvalButtonIcon} />
                                </Box>
                              </Tooltip>
                            </RoleAccess>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell className={classes.tableCell} colSpan={12} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            classes={{ actions: classes.actions }}
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  );
}
