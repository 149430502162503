import { formatDate } from "./format-date";
import messages from "../../i18n/messages";

/**
 * Extracts the event time and event owner from event object
 * @param {Object} data - Event details
 * @returns {Object} - An object containing eventTime and eventOwner
 */
const extractEventDetails = (data, intl) => {
  let eventTime = intl.formatMessage(messages.na);
  let eventOwner = intl.formatMessage(messages.na);

  // Destructure with default values
  const { latestActivity = data?.events?.[data.events.length - 1] } = data || {};

  if (latestActivity) {
    const { doneBy, doneAt } = latestActivity;

    // Check the type of doneAt to determine formatting
    eventTime = formatDate(doneAt, intl);

    if (doneBy) {
      // If doneBy is an array, it indicates activity by multiple users
      eventOwner = Array.isArray(doneBy)
        ? intl.formatMessage(messages.multipleUsers)
        : `${doneBy?.fname} ${doneBy?.lname}`;
    }
  }

  return { eventTime, eventOwner };
};

export default extractEventDetails;
