import * as React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import AppsIcon from "@material-ui/icons/Apps";
import { IoIosHammer } from "react-icons/io";
import { RiFlaskLine } from "react-icons/ri";
import { RiUserAddLine } from "react-icons/ri";
import { AiOutlineSetting } from "react-icons/ai";
import { HiOutlineTemplate } from "react-icons/hi";
import { HiOutlineStatusOffline } from "react-icons/hi";

import { useIntl } from "react-intl";
import messages from '../../i18n/messages';

// Commented out because customer asked to remain in the active project
// import useStoreProvider from "../../common/providers/store/use-app-context";

const useStyles = makeStyles(({ spacing, palette }) => ({
  menuList: {
    minWidth: 180,
    paddingTop: 0,
    paddingBottom: 0,
  },
  small: {
    width: spacing(4),
    height: spacing(4),
  },
  large: {
    width: spacing(7),
    height: spacing(7),
  },
  nameContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  menuItemHeight: {
    minHeight: 45,
  },
  menuItemPadding: {
    padding: 15,
  },
  icon: {
    color: palette.primary.main,
  },
  appIcon: {
    color: "#FFFFFF",
  },
  offlineIconContainer: {
    width: spacing(3),
    height: spacing(3),
    display: "flex",
    alignItems: "center",
    placeContent: "center",
    marginRight: spacing(2.5),
    borderRadius: spacing(50),
    animation: `$pulseEffect 1500ms infinite`,
  },
  offlineIcon: {
    fontSize: "large",
  },
  "@keyframes pulseEffect": {
    "0%": {
      boxShadow: "#ffffff 0 0 0 0",
    },
    "75%": {
      boxShadow: "#ffffff00 0 0 0 16px",
    },
  },
}));

export default function AppMenu() {
  const classes = useStyles();
  // Commented out because customer asked to remain in the active project
  // const { setSelectedProject } = useStoreProvider();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  let [online, isOnline] = React.useState(navigator.onLine);

  const setOnline = () => {
    console.log("We are online!");
    isOnline(true);
  };

  const setOffline = () => {
    console.log("We are offline!");
    isOnline(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);

    // Cleanup if we unmount
    return () => {
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
    };
  }, []);

  const intl = useIntl();

  return (<>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        marginRight: 10,
      }}
    >
      {!online && (
        <Tooltip title="Device is offline">
          <Box className={classes.offlineIconContainer}>
            <HiOutlineStatusOffline className={classes.offlineIcon} />
          </Box>
        </Tooltip>
      )}
      <Tooltip title="Menu">
        <IconButton onClick={handleClick}>
          <AppsIcon className={classes.appIcon} />
        </IconButton>
      </Tooltip>
    </Box>
    <Menu
      keepMounted
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      getContentAnchorEl={null}
      PaperProps={{
        elevation: 0,
        style: {
          marginTop: 10,
          minWidth: 250,
          boxShadow: "none",
          border: "1px solid #e3e3e3",
        },
      }}
      classes={{ list: classes.menuList }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem
        classes={{ root: classes.menuItemHeight }}
        to={"/"}
        component={Link}
      >
        <ListItemIcon>
          <IoIosHammer size={20} className={classes.icon} />
        </ListItemIcon>
        {intl.formatMessage(messages.projects)}
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuItemHeight }}
        component={Link}
        to={"/material-management"}
        onClick={() => {
          // Commented out because customer asked to remain in the active project
          // setSelectedProject(null);
        }}
      >
        <ListItemIcon>
          <RiFlaskLine size={20} className={classes.icon} />
        </ListItemIcon>
        {intl.formatMessage(messages.materialManagement)}
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuItemHeight }}
        to={"/user-management"}
        component={Link}
      >
        <ListItemIcon>
          <RiUserAddLine size={20} className={classes.icon} />
        </ListItemIcon>
        {intl.formatMessage(messages.userManagement)}
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuItemHeight }}
        to={"/template-management"}
        component={Link}
      >
        <ListItemIcon>
          <HiOutlineTemplate size={20} className={classes.icon} />
        </ListItemIcon>
        {intl.formatMessage(messages.templateManagement)}
      </MenuItem>
      <Divider />
      <MenuItem
        classes={{ root: classes.menuItemHeight }}
        component={Link}
        to={"/company-setting"}
        onClick={() => {
          // Commented out because customer asked to remain in the active project
          // setSelectedProject(null);
        }}
      >
        <ListItemIcon>
          <AiOutlineSetting size={22} className={classes.icon} />
        </ListItemIcon>
        {intl.formatMessage(messages.settings)}
      </MenuItem>
    </Menu>
  </>);
}
