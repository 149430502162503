import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

// Material UI
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

// Icons
import { MdOutlineDelete } from "react-icons/md";

// Components
import LoadingStateCard from "../../../components/loading-state-card/loading-state-card";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import RoleAccess from "../../../components/role-access/role-access";

// Hooks
import useDeleteDocuments from "../../../common/utils/use-delete-documents";
import useTablePaginationSettings from "../../../common/utils/use-table-pagination-settings";

// Utilities and constants
import messages from "../../../i18n/messages";
import { db, USER_ROLES } from "../../../constants";
import { formatDate } from "../../../common/utils/format-date";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function ParentMaterialsTableHead(props) {
  const intl = useIntl();

  const headCells = [
    {
      id: "alloy",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.alloyName),
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.type),
    },
    {
      id: "dimension",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.dimension),
    },
    {
      id: "thickness",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.thickness),
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.createdAt),
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.createdBy),
    },
  ];

  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: "#e9e9e9" }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": intl.formatMessage(messages.selectAllParentMaterials) }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ParentMaterialsTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1",
  },
  addButton: {
    padding: theme.spacing(0.5, 4),
  },
  menuIcon: {
    fontSize: theme.spacing(3),
    color: theme.palette.secondary.dark,
  },
}));

const ParentMaterialsTableToolbar = ({ selectedIds, numSelected, buttonProps, setSelected }) => {
  const intl = useIntl();
  const classes = useToolbarStyles();
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  const removeSuccessMessage = intl.formatMessage(messages.parentMaterialDeletedSuccessfully);
  const removeFailureMessage = intl.formatMessage(messages.somethingWentWrong);

  const collectionRef = db.collection("materials").doc("parent-materials").collection("items");

  const { deleteDocuments } = useDeleteDocuments(
    collectionRef,
    selectedIds,
    setSelected,
    removeSuccessMessage,
    removeFailureMessage
  );

  const defaultTitleText = intl.formatMessage(messages.parentMaterial);

  const tableTitle =
    numSelected > 0
      ? `${defaultTitleText} - ${numSelected} ${intl.formatMessage(messages.selected)}`
      : defaultTitleText;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Typography className={classes.title} variant="subtitle1" component="div">
        {tableTitle}
      </Typography>
      {numSelected > 0 ? (
        <RoleAccess
          roles={[
            USER_ROLES.ADMIN,
            USER_ROLES.PROJECT_LEADER,
            USER_ROLES.WELDING_COORDINATOR,
            USER_ROLES.RESPONSIBLE_WELDING_COORDINATOR,
          ]}
        >
          <Tooltip title={intl.formatMessage(messages.delete)}>
            <IconButton
              aria-label={intl.formatMessage(messages.delete)}
              onClick={() => setConfirmOpen(true)}
            >
              <MdOutlineDelete className={classes.menuIcon} />
            </IconButton>
          </Tooltip>
        </RoleAccess>
      ) : (
        <RoleAccess
          roles={[
            USER_ROLES.ADMIN,
            USER_ROLES.PROJECT_LEADER,
            USER_ROLES.WELDING_COORDINATOR,
            USER_ROLES.RESPONSIBLE_WELDING_COORDINATOR,
          ]}
        >
          <Button className={classes.addButton} disableElevation {...buttonProps}>
            {intl.formatMessage(messages.create)}
          </Button>
        </RoleAccess>
      )}
      <Divider />
      <ConfirmDialog
        title={intl.formatMessage(messages.deleteParentMaterial)}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={deleteDocuments}
      >
        {`${intl.formatMessage(messages.areYouSureToDeleteSelected)} ${numSelected} ${
          numSelected > 1
            ? intl.formatMessage(messages.lowerCaseParentMaterials)
            : intl.formatMessage(messages.lowerCaseParentMaterial)
        }?`}
      </ConfirmDialog>
    </Toolbar>
  );
};

ParentMaterialsTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
    boxShadow: "none",
    border: "1px solid #e3e3e3",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function ParentMaterialsTable({ rows, buttonProps, fetching }) {
  const classes = useStyles();
  const { rowsPerPageOptions, rowsPerPage, setRowsPerPage } = useTablePaginationSettings(rows);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((row) => row.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (uid) => {
    const selectedIndex = selected.indexOf(uid);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, uid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const intl = useIntl();

  return (
    <div className={classes.root}>
      {fetching ? (
        <LoadingStateCard />
      ) : (
        <Paper className={classes.paper}>
          <ParentMaterialsTableToolbar
            selectedIds={selected}
            numSelected={selected.length}
            buttonProps={buttonProps}
            setSelected={setSelected}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <ParentMaterialsTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />

              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row?.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const createdDate = formatDate(row?.createdAt, intl);

                    return (
                      <TableRow
                        hover
                        onClick={(_event) => handleClick(row?.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell align="left">{row.alloy}</TableCell>
                        <TableCell align="left">{row.type}</TableCell>
                        <TableCell align="left">{row.dimension}</TableCell>
                        <TableCell align="left">{row.thickness}</TableCell>
                        <TableCell align="left">{createdDate}</TableCell>
                        <TableCell align="left">{row.createdBy}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={12} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            page={page}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  );
}
