import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";

// Icons
import { GiClick } from "react-icons/gi";

// Components
import ExternalDocumentLink from "../../../components/external-link/external-link";
import CustomCard from "../../../components/custom-card/custom-card";
import RoleAccess from "../../../components/role-access/role-access";
import Indicator from "../../../components/indicator/indicator";
import AddWeldToNdtOrderForm from "./add-weld-to-ndt-order-form";
import WeldEventLoggingDialog from "./weld-event-logging-dialog";
import useRemoveWeldFromNdtOrder from "../../../common/utils/use-remove-weld-from-ndt-order";

// Constants and utilities
import { formatDate } from "../../../common/utils/format-date";
import messages from "../../../i18n/messages";
import { USER_ROLES } from "../../../constants";

const useStyles = makeStyles(({ spacing, palette }) => ({
  projectDetailItems: {
    flex: "1 1 0px",
  },
  header: {
    display: "flex",
    placeContent: "space-between",
    alignItems: "center",
  },
  linkIcon: {
    fontSize: spacing(2.5),
    marginRight: spacing(1),
    color: palette.grey[500],
  },
  gridItem: {
    padding: spacing(2),
    height: "100%",
    borderRadius: spacing(1),
    background: "rgb(242 246 251)",
  },
}));

function WeldDetailsSummary({ weld, weldStatus, heatTreatmentStatus, setEventUpdated }) {
  const intl = useIntl();
  const classes = useStyles();
  const { pid } = useParams();
  const { removeWeldsFromNdtOrder } = useRemoveWeldFromNdtOrder(pid);

  const [open, setOpen] = React.useState(false);

  const removeWeldFromNdtOrder = React.useCallback(async () => {
    const weldsToRemoveFromNdtOrder = [
      {
        weldId: weld?.id,
        weldLogId: weld?.weldLogId,
        ndtOrderId: weld?.ndtOrder?.ndtOrderId,
      },
    ];

    removeWeldsFromNdtOrder(weldsToRemoveFromNdtOrder, setEventUpdated);
  }, [
    weld?.id,
    weld?.weldLogId,
    weld?.ndtOrder?.ndtOrderId,
    removeWeldsFromNdtOrder,
    setEventUpdated,
  ]);

  return (
    <>
      <CustomCard
        header={
          <Box className={classes.header}>
            <Typography variant="subtitle1">{intl.formatMessage(messages.summary)}</Typography>
          </Box>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Paper elevation={0} className={classes.gridItem}>
              <Grid container spacing={2}>
                <Grid container item spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {intl.formatMessage(messages.weldNumber)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">{weld?.weldNumber}</Typography>
                  </Grid>
                </Grid>
                <Grid container item spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {intl.formatMessage(messages.createdAt)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">{formatDate(weld?.createdAt, intl)}</Typography>
                  </Grid>
                </Grid>
                {weld?.updatedAt && (
                  <Grid container item spacing={0}>
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        {intl.formatMessage(messages.updatedAt)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">{formatDate(weld?.updatedAt, intl)}</Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Paper elevation={0} className={classes.gridItem}>
              <Grid container spacing={2}>
                <Grid container item spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {intl.formatMessage(messages.parentMaterials)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {!weld?.parentMaterials?.length > 0 && (
                      <Typography variant="body2">{intl.formatMessage(messages.na)}</Typography>
                    )}
                    {weld?.parentMaterials?.length > 0 &&
                      weld?.parentMaterials?.map((pm, index) => (
                        <Box style={{ display: "flex", alignItems: "center" }} key={index}>
                          {!pm?.storageRef && <Typography display="block">{pm.label}</Typography>}
                          {pm?.storageRef && (
                            <ExternalDocumentLink
                              fileData={{
                                name: pm.label,
                                storageRef: pm?.storageRef,
                              }}
                            />
                          )}
                        </Box>
                      ))}
                  </Grid>
                </Grid>
                <Grid container item spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {intl.formatMessage(messages.fillerMaterials)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {!weld?.fillerMaterials?.length > 0 && (
                      <Typography variant="body2">{intl.formatMessage(messages.na)}</Typography>
                    )}
                    {weld?.fillerMaterials?.length > 0 &&
                      weld?.fillerMaterials?.map((fm, index) => (
                        <Box style={{ display: "flex", alignItems: "center" }} key={index}>
                          {!fm?.storageRef && <Typography display="block">{fm.label}</Typography>}
                          {fm?.storageRef && (
                            <ExternalDocumentLink
                              fileData={{
                                name: fm.label,
                                storageRef: fm?.storageRef,
                              }}
                            />
                          )}
                        </Box>
                      ))}
                  </Grid>
                </Grid>
                <Grid container item spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {intl.formatMessage(messages.attachments)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {weld?.linkedDocuments?.map((linkedDocument, index) => (
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          overflow: "hidden",
                        }}
                        key={index}
                      >
                        <ExternalDocumentLink fileData={linkedDocument} />
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Paper elevation={0} className={classes.gridItem}>
              <Grid container spacing={2}>
                <Grid container item spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="caption">{intl.formatMessage(messages.weld)}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="baseline">
                      <Indicator state={weldStatus.status} />
                      <Typography variant="body2">{weldStatus.label}</Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container item spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {intl.formatMessage(messages.heatTreatment)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="baseline">
                      {heatTreatmentStatus.status !== "notrequired" && (
                        <Indicator state={heatTreatmentStatus.status} />
                      )}
                      <Typography variant="body2">{heatTreatmentStatus.label}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Divider style={{ marginBottom: "10px", marginTop: "50px" }} />
        <Grid>
          <Grid item xs={6} md={6}>
            <Typography variant="caption">{intl.formatMessage(messages.ndtOrder)}</Typography>
            <Typography variant="body2">
              {isEmpty(weld?.ndtOrder) ? (
                <Typography variant="body2">
                  {intl.formatMessage(messages.notYetAddedToNdtOrder)}
                </Typography>
              ) : (
                <Box display="flex" align="center">
                  <Link to={`/projects/${pid}/ndt-orders/${weld?.ndtOrder?.ndtOrderId}/details`}>
                    {weld?.ndtOrder?.ndtOrderName}
                  </Link>
                  <GiClick size={15} color="caption" className={classes.linkIcon} />
                </Box>
              )}
            </Typography>

            <Box display="flex" style={{ marginTop: "20px" }}>
              <RoleAccess
                roles={[
                  USER_ROLES.ADMIN,
                  USER_ROLES.WELDING_COORDINATOR,
                  USER_ROLES.RESPONSIBLE_WELDING_COORDINATOR,
                ]}
              >
                {isEmpty(weld?.ndtOrder) ? (
                  <Button
                    className={classes.editButton}
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={() => setOpen(true)}
                  >
                    {intl.formatMessage(messages.addToNdtOrder)}
                  </Button>
                ) : (
                  <Button
                    className={classes.editButton}
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={removeWeldFromNdtOrder}
                  >
                    {intl.formatMessage(messages.removeFromNdtOrder)}
                  </Button>
                )}
              </RoleAccess>
            </Box>
          </Grid>
        </Grid>
      </CustomCard>
      <WeldEventLoggingDialog
        open={open}
        Form={AddWeldToNdtOrderForm}
        title={intl.formatMessage(messages.addWeldToNdtOrder)}
        onClose={() => setOpen(false)}
        onEventLogged={setEventUpdated}
      />
    </>
  );
}

WeldDetailsSummary.propTypes = {
  weld: PropTypes.object.isRequired,
};

export default WeldDetailsSummary;
