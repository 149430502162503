import * as React from "react";
import { intersectionWith } from "lodash";

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import { MdCheckCircle, MdCancel } from "react-icons/md";
import { CgRedo } from "react-icons/cg";

import ListItemIcon from "@material-ui/core/ListItemIcon";

import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import { ALL_NDTS } from "../../../constants";

import { useIntl } from "react-intl";
import messages from '../../../i18n/messages';

const useStyles = makeStyles(({ spacing }) => ({
  resultContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  resultApproved: {
    color: "#4EB70B",
    background: "rgb(237, 247, 230)",
  },
  approvedIcon: {
    fontSize: spacing(2),
    marginRight: spacing(0.1),
    color: "#4EB70B",
  },
  rejectedIcon: {
    fontSize: spacing(2),
    color: "#F16061",
  },
  redoIcon: {
    fontSize: spacing(2.5),
  },
  listItemIcon: {
    minWidth: spacing(4),
  },
}));

const NdtResultEntryForm = ({ formik, involvedNdts }) => {
  const intl = useIntl();

  const classes = useStyles();

  const requiredNdts = intersectionWith(
    ALL_NDTS,
    involvedNdts,
    (ndtObject, involvedNdt) => {
      return ndtObject.name === involvedNdt;
    }
  );

  return (
    <>
      {requiredNdts.map((ndt) => {
        return (
          <Grid item xs={12} lg={12} key={ndt.label}>
            <FormControl fullWidth margin="normal">
              <InputLabel>{ndt.label}</InputLabel>
              <Select
                label={ndt.label}
                name={`ndtResults.${ndt.name}`}
                value={formik.values.ndtResults[ndt.name]}
                onChange={formik.handleChange}
                autoComplete="off"
              >
                <MenuItem value="toBeInspected">
                  <Box display="flex" alignItems="center">
                    <ListItemIcon className={classes.listItemIcon}>
                      <CgRedo className={classes.redoIcon} />
                    </ListItemIcon>
                    <Typography>
                      {intl.formatMessage(messages.toBeInspected)}
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem value="approved">
                  <Box display="flex" alignItems="center">
                    <ListItemIcon className={classes.listItemIcon}>
                      <MdCheckCircle className={classes.approvedIcon} />
                    </ListItemIcon>
                    <Typography>
                      {intl.formatMessage(messages.approved)}
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem value="rejected">
                  <Box display="flex" alignItems="center">
                    <ListItemIcon className={classes.listItemIcon}>
                      <MdCancel className={classes.rejectedIcon} />
                    </ListItemIcon>
                    <Typography>
                      {intl.formatMessage(messages.rejected)}
                    </Typography>
                  </Box>
                </MenuItem>
              </Select>
            </FormControl>
            {/* )} */}
          </Grid>
        );
      })}
    </>
  );
};

export default NdtResultEntryForm;
