import React from "react";

const useFetchDocument = () => {
  const [data, setData] = React.useState(null);
  const [fetching, setFetching] = React.useState(true);
  const [error, setError] = React.useState(null);

  const unsubscribeRef = React.useRef(null);

  const getDocument = React.useCallback((documentRef) => {
    // If there's an existing subscription, unsubscribe before starting a new one
    if (unsubscribeRef.current) {
      unsubscribeRef.current();
    }

    unsubscribeRef.current = documentRef.onSnapshot(
      (doc) => {
        if (doc.exists) {
          setData(doc.data());
        } else {
          setData(null);
        }
        setFetching(false);
      },
      (err) => {
        setError(err);
        setFetching(false);
      }
    );
  }, []);

  // Cleanup: unsubscribe from the document when the component using the hook is unmounted
  React.useEffect(() => {
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
    };
  }, []);

  return { data, fetching, error, getDocument };
};

export default useFetchDocument;
