import React from "react";
import { useIntl } from "react-intl";

// Material UI
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogTitle from "@material-ui/core/DialogTitle";

// Constants and utilities
import messages from "../../i18n/messages";

const useStyles = makeStyles(({ spacing }) => ({
  contentContainer: {
    paddingBottom: spacing(4),
    borderTop: "1px solid rgba(0,0,0,0.12)",
  },
  actionContainer: {
    "& :last-child": {
      marginLeft: spacing(3),
    },
  },
  buttonLabel: {
    marginLeft: "0 !important",
  },
}));

const ConfirmDialog = ({ title, children, open, setOpen, onConfirm }) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const intl = useIntl();

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
      fullScreen={fullScreen}
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent className={classes.contentContainer} style={{}}>
        {children}
      </DialogContent>
      <DialogActions style={{ padding: "12px 24px" }} className={classes.actionContainer}>
        <Button autoFocus variant="outlined" onClick={() => setOpen(false)}>
          {intl.formatMessage(messages.cancel)}
        </Button>
        <Button
          disableElevation
          color="primary"
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          classes={{ label: classes.buttonLabel }}
        >
          {intl.formatMessage(messages.delete)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
