import * as yup from "yup";
import { ALL_NDTS } from "../../../constants";
import messages from "../../../i18n/messages";

const ndtFormValidationSchema = (intl) =>
  yup.object({
    ndtOrderName: yup.string().required(intl.formatMessage(messages.ndtOrderNameRequired)),
    ndtRequirements: yup.object().test(
      "ndt-requirements-validation",
      "", // Placeholder error message, we'll set the actual message inside the test function
      (obj) => {
        let filledNdtMethodCount = 0;
        let errorMessages = [];

        ALL_NDTS.forEach((ndtItem) => {
          const inspectionRate = obj.inspectionRates && obj.inspectionRates[ndtItem.name];

          const isValidRate = (rate) => rate > 0 && rate <= 100;

          if (inspectionRate) {
            filledNdtMethodCount++;
            if (!isValidRate(inspectionRate)) {
              errorMessages.push(
                intl.formatMessage(messages.invalidSampleRate, {
                  label: ndtItem.label,
                })
              );
            }
          }
        });

        if (filledNdtMethodCount === 0) {
          errorMessages.push(intl.formatMessage(messages.atLeastOneNdtOrderRequired));
        }

        if (errorMessages.length) {
          return new yup.ValidationError(errorMessages.join("\n"), null, "ndtRequirements");
        }

        return true; // No errors
      }
    ),
  });

export default ndtFormValidationSchema;
