import { useState, useEffect, useCallback } from "react";
import useFetchCollection from "./use-fetch-collection";
import { db } from "../../constants";

const useFetchProjectParticipants = (pid) => {
  const [isLoading, setIsLoading] = useState(true);
  const [participants, setParticipants] = useState({
    welders: [],
    ndtOperators: [],
    htOperators: [],
    weldingCoordinators: [],
  });

  const { getCollection } = useFetchCollection();

  const getProjectParticipants = useCallback(async () => {
    const collectionRef = db
      .collection("projects")
      .doc(pid)
      .collection("participants");

    const rawParticipants = await getCollection(collectionRef);
    const participantDocs = rawParticipants?.docs?.map((doc) => doc.data()) || [];

    const newParticipants = {
      welders: participantDocs.filter((participant) =>
        participant.rolesOfParticipant.includes("welder")
      ),
      ndtOperators: participantDocs.filter((participant) =>
        participant.rolesOfParticipant.includes("ndt-operator")
      ),
      htOperators: participantDocs.filter((participant) =>
        participant.rolesOfParticipant.includes("heat-treatment-operator")
      ),
      weldingCoordinators: participantDocs.filter((participant) =>
        participant.rolesOfParticipant.includes("welding-coordinator")
      ),
    };

    setParticipants(newParticipants);
    setIsLoading(false);
    // these dependencies ensure that the function is updated when any of these values change
  }, [pid, getCollection]);

  useEffect(() => {
    getProjectParticipants();
    // this dependency is the memoized function
  }, [getProjectParticipants]);

  return { isLoading, ...participants };
};

export default useFetchProjectParticipants;
