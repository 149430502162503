import clsx from "clsx";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

// Material UI
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { lighten, makeStyles } from "@material-ui/core/styles";

// Icons
import { MdOutlineDelete } from "react-icons/md";

// Components
import ConfirmDialog from "../../components/confirm-dialog/confirm-dialog";
import RoleAccess from "../../components/role-access/role-access";

// Utilities and constants
import messages from "../../i18n/messages";
import { USER_ROLES } from "../../constants";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1",
  },
  addButton: {
    padding: theme.spacing(0.5, 4),
  },
  menuIcon: {
    fontSize: theme.spacing(3),
    color: theme.palette.secondary.dark,
  },
}));

const GenericTableToolbar = ({
  numberOfSelectedItems,
  addLabel = "Add",
  dialogMessages,
  buttonProps,
  setDialogOpen,
  dialogOpen,
  onDialogConfirm,
  withAddButton = true,
  withDeleteButton = true,
  withTitle = false,
  title = "",
}) => {
  const classes = useToolbarStyles();

  const intl = useIntl();

  const defaultTitleText = title;
  const tableTitle =
    numberOfSelectedItems > 0 && withTitle
      ? `${defaultTitleText} - ${numberOfSelectedItems} ${intl.formatMessage(messages.selected)}`
      : defaultTitleText;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numberOfSelectedItems > 0,
      })}
    >
      <Typography className={classes.title} variant="subtitle1" component="div">
        {tableTitle}
      </Typography>

      <RoleAccess
        roles={[
          USER_ROLES.ADMIN,
          USER_ROLES.PROJECT_LEADER,
          USER_ROLES.WELDING_COORDINATOR,
          USER_ROLES.RESPONSIBLE_WELDING_COORDINATOR,
        ]}
      >
        {numberOfSelectedItems > 0 && withDeleteButton ? (
          <Tooltip title={intl.formatMessage(messages.delete)} onClick={() => setDialogOpen(true)}>
            <IconButton aria-label={intl.formatMessage(messages.delete)}>
              <MdOutlineDelete className={classes.menuIcon} />
            </IconButton>
          </Tooltip>
        ) : withAddButton ? (
          <Button
            className={classes.addButton}
            disableElevation
            component={Link}
            to={buttonProps?.to}
            {...buttonProps}
          >
            {addLabel}
          </Button>
        ) : null}
      </RoleAccess>
      <Divider />
      <ConfirmDialog
        title={dialogMessages?.title}
        open={dialogOpen}
        setOpen={setDialogOpen}
        onConfirm={onDialogConfirm}
      >
        {dialogMessages?.content}
      </ConfirmDialog>
    </Toolbar>
  );
};

export default GenericTableToolbar;
