import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

function ProtectedRoute({ component: ComponentToRender, authenticated, ...rest }) {
  if (authenticated) {
    return (
      <Route {...rest} render={(routeProps) => <ComponentToRender {...routeProps} {...rest} />} />
    );
  }
  return <Redirect to={{ pathname: "/login" }} />;
}

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default ProtectedRoute;
