import React from "react";
import firebase from "firebase";
import { useIntl } from "react-intl";
import { Formik, Form } from "formik";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";

// Hooks
import useStoreProvider from "../../../common/providers/store/use-app-context";

// Utilities and constants
import messages from "../../../i18n/messages";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: "100%",
  },
  formContainer: {
    marginBottom: spacing(3),
  },
  formStatusAndActionContainer: {
    paddingTop: spacing(1),
  },
  actionContainer: {
    "& :last-child": {
      marginLeft: spacing(3),
    },
  },
  buttonLabel: {
    marginLeft: "0 !important",
  },
  menuPaper: {
    maxHeight: 400,
  },
  progressIndicator: {
    display: "flex",
    alignItems: "center",
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
}));

const AddAlloyMaterialForm = ({ onClose, collectionRef }) => {
  const intl = useIntl();
  const classes = useStyles();

  const { loggedInUser } = useStoreProvider();
  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const alloyMaterialId = collectionRef.doc().id;

  const initialValue = {
    name: "",
  };

  return (
    <Formik
      initialValues={initialValue}
      onSubmit={(values, { resetForm }) => {
        const materialInfo = {
          id: alloyMaterialId,
          name: values?.name,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: `${loggedInUser?.fname} ${loggedInUser?.lname}`,
          createdByUid: loggedInUser?.uid,
        };
        setSubmitting(true);
        setSubmitted(false);
        collectionRef
          .doc(alloyMaterialId)
          .set(materialInfo)
          .then(() => {
            resetForm({});
            setSubmitting(false);
            setSubmitted(true);
          })
          .catch((error) => {
            setSubmitting(false);
            console.log(error);
            setErrorMessage(intl.formatMessage(messages.somethingWentWrong));
          });
      }}
    >
      {(formik) => (
        <Form className={classes.root}>
          <TextField
            required
            fullWidth
            id="name"
            name="name"
            label={intl.formatMessage(messages.alloyName)}
            margin="normal"
            autoComplete="off"
            value={formik.values?.name}
            onChange={formik.handleChange}
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={classes.formStatusAndActionContainer}
          >
            <Box>
              {submitting && (
                <Box className={classes.progressIndicator}>
                  <CircularProgress size={25} />
                  <Box ml={2}>{intl.formatMessage(messages.addingNewAlloy)}</Box>
                </Box>
              )}
              {submitted && (
                <Alert severity="success" icon={<CheckIcon fontSize="inherit" />}>
                  {intl.formatMessage(messages.alloyAddedSuccessfully)}
                </Alert>
              )}
              {!submitting && !submitted && errorMessage && (
                <Alert severity="error" mt={10}>
                  {errorMessage}
                </Alert>
              )}
            </Box>
            <Box className={classes.actionContainer}>
              <Button autoFocus onClick={() => onClose()} variant="outlined">
                {intl.formatMessage(messages.close)}
              </Button>
              <Button
                type="submit"
                color="primary"
                disableElevation
                variant="contained"
                classes={{
                  label: classes.buttonLabel,
                }}
                disabled={formik.values?.name === ""}
              >
                {intl.formatMessage(messages.create)}
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddAlloyMaterialForm;
