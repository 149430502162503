import React from "react";
import { useParams } from "react-router-dom";
import { sortBy, values, remove } from "lodash";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Src
import FormFooter from "../../../components/form-footer/form-footer";
import CompanyProfileImage from "../../../components/user-profile-image/user-profile-image";
import { db } from "../../../constants";

import { useIntl } from "react-intl";
import messages from '../../../i18n/messages';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    placeContent: "space-between",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: spacing(2),
  },
  formStatusAndActionContainer: {
    paddingTop: spacing(2),
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    "& :last-child": {
      marginLeft: spacing(3),
    },
  },
  menuPaper: {
    maxHeight: 400,
  },
}));

const ReportSectionSelectionForm = ({
  onClose,
  generateReport,
  setIsGeneratingReport,
}) => {
  const intl = useIntl();

  const classes = useStyles();
  const { pid } = useParams();

  const [sectionsWithDocuments, setSectionsWithDocuments] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [reportPayload, setReportPayload] = React.useState({
    selectedSectionIds: [],
    companyName: "",
    companyAddress: "",
    companyLogo: "",
  });

  const companyInfoCollectionRef = db.collection("company");

  const documentsCollectionRef = db
    .collection("projects")
    .doc(pid)
    .collection("documents")
    .where("status", "==", "active");

  const documentsSectionsCollectionRef = db
    .collection("projects")
    .doc(pid)
    .collection("document-sections");

  // Handle section selection
  const handleSectionSelection = (event) => {
    if (event.target.checked) {
      reportPayload.selectedSectionIds.push(parseInt(event.target.value, 10));
    } else {
      remove(
        reportPayload.selectedSectionIds,
        (n) => n === parseInt(event.target.value, 10)
      );
    }

    setReportPayload(reportPayload);
  };

  const handleGenerateReportButtonClick = () => {
    generateReport(reportPayload);
    setIsGeneratingReport(true);
    onClose();
  };

  // Get section and document collections and filter those sections with documents
  const getDocumentsInfo = React.useCallback(async () => {
    setFetching(true);
    const selectedSectionIdsTemp = [];
    const sectionsSnapshot = await documentsSectionsCollectionRef.get();
    const documentsSnapshot = await documentsCollectionRef.get();

    const sectionsData = sectionsSnapshot.docs.map((doc) => doc.data());
    const documentsData = documentsSnapshot.docs.map((doc) => doc.data());

    // TODO: Temp fix related to document template struct change
    let documentSectionsInfo;
    if (sectionsData?.length && !sectionsData[0]?.documentSections) {
      documentSectionsInfo = sortBy(values(sectionsData[0]), "position");
    } else {
      documentSectionsInfo = sortBy(
        values(sectionsData[0]?.documentSections),
        "position"
      );
    }

    documentSectionsInfo = documentSectionsInfo
      .map((section) => {
        section = Object.assign(section, { documents: [] });
        documentsData.map((document) => {
          if (section.id === document.section) {
            section["documents"].push(document);
          }
          return "";
        });
        return section;
      })
      .filter((section) => {
        // On dialog open, all document sections with document are selected
        if (section.documents.length > 0) {
          selectedSectionIdsTemp.push(section.id);
        }

        return section.documents.length > 0;
      });

    setSectionsWithDocuments(documentSectionsInfo);
    setFetching(false);

    return selectedSectionIdsTemp;
  }, [documentsCollectionRef, documentsSectionsCollectionRef]);

  const getCompanyData = async () => {
    const companySnapshot = await companyInfoCollectionRef.get();
    const companyData = companySnapshot.docs.map((doc) => doc.data());
    const { name, address, logo } = companyData[0];
    const selectedSectionIds = await getDocumentsInfo();

    setReportPayload({
      selectedSectionIds,
      companyName: name,
      companyAddress: address,
      companyLogo: logo,
    });
  };

  const handleValueChange = (e) => {
    setReportPayload({
      ...reportPayload,
      [e.target.name]: e.target.value,
    });
  };

  React.useEffect(() => {
    getCompanyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (<>
    <DialogTitle>
      {intl.formatMessage(messages.generateReport)}
    </DialogTitle>
    <DialogContent dividers>
      {fetching ? (
        <Skeleton animation="wave" width="100%" height={45} />
      ) : (
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <Box className={classes.formContainer}>
              {sectionsWithDocuments.map((section) => {
                return (
                  <FormControlLabel
                    componentsProps={{
                      style: { textTransform: "uppercase" },
                    }}
                    label={
                      <Typography
                        variant="overline"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {section.name}
                      </Typography>
                    }
                    value={section.id}
                    key={section.id}
                    control={
                      <Checkbox
                        defaultChecked
                        onChange={handleSectionSelection}
                      />
                    }
                  />
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CompanyProfileImage
              borderRadius="0px"
              width="50%"
              height="auto"
              imagePath={reportPayload.companyLogo}
            />
            <TextField
              fullWidth
              margin="normal"
              id="companyName"
              label={intl.formatMessage(messages.companyName)}
              name="companyName"
              autoComplete="off"
              value={reportPayload.companyName}
              onChange={handleValueChange}
              InputLabelProps={{ style: { fontSize: 14 } }}
            />
            <TextField
              fullWidth
              margin="normal"
              id="companyAddress"
              label={intl.formatMessage(messages.companyAddress)}
              name="companyAddress"
              autoComplete="off"
              value={reportPayload.companyAddress}
              onChange={handleValueChange}
              InputLabelProps={{ style: { fontSize: 14 } }}
            />
          </Grid>
        </Grid>
      )}
    </DialogContent>
    <DialogActions>
      <FormFooter
        submitting={false}
        submitted={false}
        submitButtonText={intl.formatMessage(messages.generate)}
        cancelButtonText={intl.formatMessage(messages.close)}
        cancelButtonProps={{
          onClick: () => onClose(),
        }}
        submitButtonProps={{
          onClick: () => handleGenerateReportButtonClick(),
        }}
      />
    </DialogActions>
  </>);
};

export default ReportSectionSelectionForm;
