import React from "react";
import { toast } from "react-toastify";

import { db, NOTIFICATION_POSITION } from "../../constants";

import { useIntl } from "react-intl";
import messages from '../../i18n/messages';

export const useArchiveWeldLog = (pid, weldLogIds) => {
  const intl = useIntl();

  const batch = db.batch();

  const weldLogCollectionRef = db
    .collection("projects")
    .doc(pid)
    .collection("weld-logs");

  const [state, setState] = React.useState({
    error: false,
    completed: false,
  });

  const archiveWeldLogs = React.useCallback(async () => {
    const ARCHIVE_SUCCESS_MESSAGE = intl.formatMessage(messages.weldLogTrashedSuccessfully);
    const ARCHIVE_FAILURE_MESSAGE = intl.formatMessage(messages.somethingWentWrong);

    if (!weldLogCollectionRef) {
      setState({ error: true, completed: false });
      toast.error(ARCHIVE_FAILURE_MESSAGE, {
        position: NOTIFICATION_POSITION,
      });
      return;
    }

    try {
      weldLogIds.forEach((weldLogId) => {
        batch.update(weldLogCollectionRef.doc(weldLogId), { status: "trashed" });
      });
      await batch.commit();
      setState({ error: false, completed: true });
      toast.success(ARCHIVE_SUCCESS_MESSAGE, {
        position: NOTIFICATION_POSITION,
      });
    } catch (error) {
      console.error("useArchiveWeldLog::", error);
      setState({ error: true, completed: false });
      toast.error(ARCHIVE_FAILURE_MESSAGE, {
        position: NOTIFICATION_POSITION,
      });
    }
  }, [weldLogCollectionRef, weldLogIds, batch, intl]);

  return {
    status: state,
    archiveWeldLogs,
  };
};

export default useArchiveWeldLog;
