import React from "react";
import firebase from "firebase";
import { useFormik } from "formik";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

// Material
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// Icons
import useStoreProvider from "../../../common/providers/store/use-app-context";
import FormFooter from "../../../components/form-footer/form-footer";
import { db, NOTIFICATION_POSITION, WELD_EVENT_TYPES } from "../../../constants";

import { useIntl } from "react-intl";
import messages from '../../../i18n/messages';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: "100%",
  },
  formContainer: {
    marginBottom: spacing(3),
  },
  formStatusAndActionContainer: {
    paddingTop: spacing(1),
  },
  menuPaper: {
    maxHeight: 400,
  },
  progressIndicator: {
    display: "flex",
    alignItems: "center",
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
}));

const LogHeatTreatmentForm = ({
  projectDocumentRef,
  weldLogDocumentRef,
  usersInvolved,
  weldEventToEdit,
  indexOfWeldEventToEdit,
  onEventLogged,
  onClose,
  selectedWelds,
}) => {
  const intl = useIntl();

  const LOGGED_HT_SUCCESS_MESSAGE = intl.formatMessage(messages.loggedHtSuccessfully);
  const LOGGED_HT_FAILURE_MESSAGE = intl.formatMessage(messages.failedToLogHt);

  const batch = db.batch();

  const classes = useStyles();
  const { pid, weldLogId } = useParams();
  const { loggedInUser } = useStoreProvider();

  // Events array is needed when editing a specific weld
  const { events = [] } = indexOfWeldEventToEdit ? selectedWelds[0] : {};

  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [htOperatorInfo, setHtOperatorInfo] = React.useState(
    weldEventToEdit?.doneBy || { uid: "", fname: "", lname: "" }
  );

  const initialValues = {
    htOperator: htOperatorInfo,
    doneAt: weldEventToEdit?.doneAt
      ? format(new Date(weldEventToEdit?.doneAt), "yyyy-MM-dd'T'HH:mm")
      : format(new Date(), "yyyy-MM-dd'T'HH:mm"),
  };

  const handleHtOperatorSelectionChange = (event) => {
    const uid = event.target.value;
    const userData = usersInvolved.filter(
      (user) => user?.userInfo?.uid === uid
    );
    if (userData.length) {
      const { uid, fname, lname } = userData[0]?.userInfo;
      setHtOperatorInfo({ uid, fname, lname });
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) => {
      setSubmitting(true);
      setSubmitted(false);

      const editedEvent = {
        ...events[indexOfWeldEventToEdit],
        loggedBy: `${loggedInUser.fname} ${loggedInUser.lname}`,
        editedAt: firebase.firestore.Timestamp.now(),
        doneAt: values.doneAt,
        doneBy: htOperatorInfo,
      };

      // Update event object by selected index
      events[indexOfWeldEventToEdit] = editedEvent;

      const heatTreatmentEvent = {
        doneAt: values.doneAt,
        doneBy: htOperatorInfo,
        loggedAt: firebase.firestore.Timestamp.now(),
        loggedBy: `${loggedInUser.fname} ${loggedInUser.lname}`,
        what: WELD_EVENT_TYPES.heatTreatmentLogged,
      };

      const activitiesDocumentRef = projectDocumentRef
        .collection("activities")
        .doc();

      selectedWelds.forEach((selectedWeld) => {
        let weldDocumentRef = db
          .collection("projects")
          .doc(pid)
          .collection("weld-logs")
          .doc(weldLogId)
          .collection("welds")
          .doc(selectedWeld?.id);
        batch.update(weldDocumentRef, {
          heatTreatmentStatus: {
            done: true,
            logger: `${loggedInUser.fname} ${loggedInUser.lname}`,
            htOperator: htOperatorInfo,
            eventTimestamp: values.doneAt,
          },
          events:
            indexOfWeldEventToEdit > 0
              ? events
              : firebase.firestore.FieldValue.arrayUnion(heatTreatmentEvent),
        });

        // Add heat treatment to activities collection of a project
        batch.set(activitiesDocumentRef, {
          ...heatTreatmentEvent,
          weldLogId,
          weldId: selectedWeld?.id,
        });
      });

      // Get the weldId of the last weld in selectedWelds.
      let lastWeld = selectedWelds[selectedWelds.length - 1];

      // Update project with heat treatment event
      batch.update(projectDocumentRef, {
        latestActivity: {
          ...heatTreatmentEvent,
          weldLogId,
          weldId: lastWeld?.id,
        },
      });

      // Update weld log with heat treatment event
      batch.update(weldLogDocumentRef, {
        latestActivity: {
          ...heatTreatmentEvent,
          weldLogId,
          weldId: lastWeld?.id,
        },
      });

      batch
        .commit()
        .then(() => {
          resetForm({});
          setSubmitting(false);
          setSubmitted(true);
          onEventLogged(true);
          toast.success(LOGGED_HT_SUCCESS_MESSAGE, {
            position: NOTIFICATION_POSITION,
          });
        })
        .catch((error) => {
          setSubmitting(false);
          setErrorMessage(intl.formatMessage(messages.somethingWentWrong));
          toast.error(LOGGED_HT_FAILURE_MESSAGE, {
            position: NOTIFICATION_POSITION,
          });
          console.log("LogHtForm::", error);
        });
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  return (<>
    <DialogContent dividers>
      <form className={classes.form}>
        <Grid container spacing={4} className={classes.formContainer}>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              margin="normal"
              name="doneAt"
              label={intl.formatMessage(messages.eventTimestamp)}
              type="datetime-local"
              className={classes.textField}
              // TODO: This has to be updated to the set datetime when editing existing event.
              // defaultValue={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
              defaultValue={initialValues?.doneAt}
              InputLabelProps={{ shrink: true }}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth margin="normal" size="small">
              <InputLabel>
                {intl.formatMessage(messages.htOperator)}
              </InputLabel>
              <Select
                required
                fullWidth
                label={intl.formatMessage(messages.heatTreatmentOperator)}
                margin="normal"
                autoComplete="off"
                value={htOperatorInfo?.uid}
                onChange={handleHtOperatorSelectionChange}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                <MenuItem value="">
                  <em>
                    {intl.formatMessage(messages.none)}
                  </em>
                </MenuItem>
                {usersInvolved.map((user, index) => (
                  <MenuItem key={index} value={user?.userInfo?.uid}>
                    {user.userInfo.fname} {user.userInfo.lname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </DialogContent>
    <DialogActions>
      <FormFooter
        submitting={submitting}
        submitted={submitted}
        submitButtonText={intl.formatMessage(messages.log)}
        cancelButtonText={intl.formatMessage(messages.close)}
        cancelButtonProps={{
          onClick: () => onClose(),
        }}
        submitButtonProps={{
          onClick: () => handleSubmit(),
          disabled: htOperatorInfo?.uid === "",
        }}
        progressMessage={intl.formatMessage(messages.loggingHeatTreatment)}
        successMessage={intl.formatMessage(messages.heatTreatmentLogged)}
        errorMessage={errorMessage}
      />
    </DialogActions>
  </>);
};

export default LogHeatTreatmentForm;
