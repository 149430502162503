import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ProgressIndicator from "../progress-indicator/progress-indicator";

const useStyles = makeStyles({
  thumb: {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 2,
    boxSizing: "border-box",
  },
  thumbInner: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
    position: "relative",
    alignItems: "center",
    placeContent: "center",
  },
  previewImg: {
    display: "block",
    width: "100px",
    height: "100px",
    objectFit: "cover",
    filter: "brightness(50%)",
  },
  absoluteProgressIndicator: {
    bottom: 0,
    position: "absolute",
    color: "#ffffff !important",
  },
});

const UploadPreviewList = ({ imageList }) => {
  const classes = useStyles();

  return (
    <>
      {imageList.map(
        (file, index) =>
          file.status === "UPLOADING" && (
            <div className={classes.thumb} key={file.fileName}>
              <div className={classes.thumbInner}>
                <img src={file.preview} className={classes.previewImg} alt={file.fileName} />
                <ProgressIndicator
                  progress={file.progress}
                  key={index}
                  className={classes.absoluteProgressIndicator}
                />
              </div>
            </div>
          )
      )}
    </>
  );
};

export default UploadPreviewList;
