import React from "react";
import { useParams } from "react-router-dom";

import Box from "@material-ui/core/Box";

import Title from "../../components/title/title";
import StateCard from "../../components/state-card/state-card";
import LoadingStateCard from "../../components/loading-state-card/loading-state-card";
import WeldLogListTable from "./partials/weld-log-list-table";

import useStoreProvider from "../../common/providers/store/use-app-context";

import { db } from "../../constants";

import { useIntl } from "react-intl";
import messages from '../../i18n/messages';

const TrashedWeldLogList = () => {
  const intl = useIntl();

  const { pid } = useParams();
  const { selectedProject, setSelectedProject } = useStoreProvider();

  // Happens on details page refresh
  // TODO: replace with the use-fetch-project-details hook
  const getProjectDetails = React.useCallback(async () => {
    let selectedProject = {};
    const selectedProjectDoc = await db.collection("projects").doc(pid).get();

    if (selectedProjectDoc.exists) {
      selectedProject = selectedProjectDoc.data();
    }

    setSelectedProject(selectedProject);
  }, [pid, setSelectedProject]);

  const collectionRef = db
    .collection("projects")
    .doc(pid)
    .collection("weld-logs")
    .where("status", "==", "trashed");

  const [weldLogs, setWeldLogs] = React.useState([]);
  const [fetching, setFetching] = React.useState(true);

  const buttonProps = {
    color: "primary",
    variant: "contained",
    label: intl.formatMessage(messages.addWeldLog),
    to: `/projects/${pid}/weld-logs/add`,
  };

  const onCollectionUpdate = (querySnapshot) => {
    const weldLogsTemp = [];
    setFetching(true);
    querySnapshot.forEach((doc) => {
      const {
        id,
        status,
        createdAt,
        updatedAt,
        weldLogName,
        progress,
        description,
        latestActivity,
        ndtRequirements,
        linkedDocumentData,
      } = doc.data();

      weldLogsTemp.push({
        id,
        status,
        createdAt,
        updatedAt,
        weldLogName,
        progress,
        description,
        latestActivity,
        ndtRequirements,
        linkedDocumentData,
      });
    });

    setWeldLogs(weldLogsTemp);
    setFetching(false);
  };

  const renderEmptyState = () => {
    return (
      (<StateCard
        title={intl.formatMessage(messages.noTrashedWeldLogs)}
      />)
    );
  };

  React.useEffect(() => {
    if (!selectedProject) {
      getProjectDetails();
    }
    collectionRef.onSnapshot(onCollectionUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (<>
    {fetching ? (
      <LoadingStateCard />
    ) : (
      <Box width="100%">
        {weldLogs.length ? (
          <>
            <Title title={intl.formatMessage(messages.trashedWeldLogs)} />
            <WeldLogListTable
              rows={weldLogs}
              fetching={fetching}
              buttonProps={buttonProps}
              projectId={selectedProject?.id}
              trashed
            />
          </>
        ) : (
          renderEmptyState()
        )}
      </Box>
    )}
  </>);
};

TrashedWeldLogList.propTypes = {};

export default TrashedWeldLogList;
