import firebase from "firebase";
import { toast } from "react-toastify";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
};

firebase.initializeApp(config);
firebase
  .firestore()
  .enablePersistence()
  .catch((err) => {
    // TODO add the following status to a context/store. Then it can be used for different
    // purposes one being able to close dialogues on save. Currently it continues to
    // load since it does not get success response from backend
    // var source = querySnapshot.metadata.fromCache ? "local cache" : "server";
    // console.log("mlog::Data came from " + source);
    if (err.code === "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

export const db = firebase.firestore();
export const firebaseAuth = firebase.auth;

export const NOTIFICATION_POSITION = toast.POSITION.BOTTOM_RIGHT;

export const ALERT_STATE = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
};

export const TIME_FORMAT = {
  ONLY_TIME: "HH:mm",
  SHORT: "MMM dd yyyy",
  LONG: "MMM dd yyyy HH:mm",
  DATE_PICKER: "MM dd yyyy HH:mm",
  LONG_FOR_FILE_NAME: "MMM-dd-yyyy-HHmm",
};

export const USER_ROLES = {
  // This is app level role assigned to all users who are supposed to be admins of the app.
  // Those who are ADMIN can create a project and can create new users
  // Only ADMIN can create a new project, add new users
  ADMIN: "admin",

  // You are registered in the system
  USER: "user",

  // Roles applied at project level
  VIEWER: "viewer",
  WELDER: "welder",
  NDT_OPERATOR: "ndt-operator",
  PROJECT_LEADER: "project-leader",
  WELDING_COORDINATOR: "welding-coordinator",
  RESPONSIBLE_WELDING_COORDINATOR: "responsible-welding-coordinator",
  HEAT_TREATMENT_OPERATOR: "heat-treatment-operator",
};

export const PARTICIPANT_ROLES = [
  { key: "viewer", value: "Viewer", localizationKey: "viewer" },
  {
    key: "project-leader",
    value: "Project leader",
    localizationKey: "projectLeader",
  },
  {
    key: "welding-coordinator",
    value: "Welding coordinator",
    localizationKey: "weldingCoordinator",
  },
  {
    key: "responsible-welding-coordinator",
    value: "Responsible welding coordinator",
    localizationKey: "responsibleWeldingCoordinator",
  },
  { key: "welder", value: "Welder", localizationKey: "welder" },
  {
    key: "heat-treatment-operator",
    value: "Heat treatment operator",
    localizationKey: "heatTreatmentOperator",
  },
  {
    key: "ndt-operator",
    value: "NDT operator",
    localizationKey: "ndtOperator",
  },
];

// The following mapping is added for backward compatibility.
// Can be removed once all old projects are completed and archived.
export const DEPRECATED_NDT_CODES = {
  etPercentage: "eddyCurrentTesting",
  hdPercentage: "hardnessTesting",
  lpPercentage: "liquidPenetrants",
  mtPercentage: "magneticParticleTesting",
  rtPercentage: "radiographicTesting",
  utPercentage: "ultrasonicTesting",
  viPercentage: "visualInspection",
};

export const NDT_METHODS = {
  eddyCurrentTesting: {
    code: "ET",
    label: "Eddy current testing (ET)",
    name: "eddyCurrentTesting",
    inspectionRate: "etInspectionRate",
    localizationKey: "eddyCurrentTesting",
  },
  hardnessTesting: {
    code: "HD",
    label: "Hardness Testing (HT)",
    name: "hardnessTesting",
    inspectionRate: "hdInspectionRate",
    localizationKey: "hardnessTesting",
  },
  magneticParticleTesting: {
    code: "MT",
    label: "Magnetic Particle Testing (MT)",
    name: "magneticParticleTesting",
    inspectionRate: "mtInspectionRate",
    localizationKey: "magneticParticleTesting",
  },
  radiographicTesting: {
    code: "RT",
    label: "Radiographic Testing (RT)",
    name: "radiographicTesting",
    inspectionRate: "rtInspectionRate",
    localizationKey: "radiographicTesting",
  },
  ultrasonicTesting: {
    code: "UT",
    label: "Ultrasonic Testing (UT)",
    name: "ultrasonicTesting",
    inspectionRate: "utInspectionRate",
    localizationKey: "ultrasonicTesting",
  },
  liquidPenetrants: {
    code: "LP",
    label: "Liquid Penetrants (LP)",
    name: "liquidPenetrants",
    inspectionRate: "lpInspectionRate",
    localizationKey: "liquidPenetrants",
  },
  visualInspection: {
    code: "VI",
    label: "Visual Inspection (VI)",
    name: "visualInspection",
    inspectionRate: "viInspectionRate",
    localizationKey: "visualInspection",
  },
};

export const ALL_NDTS = Object.keys(NDT_METHODS).map((key) => ({
  name: key,
  ...NDT_METHODS[key],
}));

export const NDT_ORDER_STATUSES = {
  compiling: {
    status: "compiling",
    label: "Compiling",
  },
  waitingInspection: {
    status: "waitingInspection",
    label: "Waiting inspection",
  },
  inProgress: {
    status: "inProgress",
    label: "In progress",
  },
  approved: {
    status: "approved",
    label: "Approved",
  },
  rejected: {
    status: "rejected",
    label: "Rejected",
  },
  toBeInspection: {
    status: "toBeInspection",
    label: "To be inspected",
  },
};

export const WELD_EVENT_TYPES = {
  weldCreated: "Weld created",
  weldLogged: "Weld logged",
  heatTreatmentLogged: "Heat treatment logged",
  addedToNdtOrder: "Added to NDT order",
  removedFromNdtOrder: "Removed from NDT order",
  ndtLogged: "NDT logged",
  inspectionApproved: { status: "approved", label: "Approved" },
  inspectionRejected: { status: "rejected", label: "Rejected" },
};
