import { useState, useEffect, useCallback } from "react";

const defaultRowsPerPageOptions = [10, 15, 20];

const useTablePaginationSettings = (tableRows) => {
  const getTableSettings = useCallback(() => {
    let maxRowsOption = defaultRowsPerPageOptions[0];
    for (let option of defaultRowsPerPageOptions) {
      if (tableRows.length >= option) {
        maxRowsOption = option;
      } else {
        break;
      }
    }

    const newRowsPerPageOptions = defaultRowsPerPageOptions.filter(
      (option) => option <= maxRowsOption
    );
    const newRowsPerPage = tableRows.length < maxRowsOption ? tableRows.length : maxRowsOption;

    return { newRowsPerPageOptions, newRowsPerPage };
  }, [tableRows]);

  const initialSettings = getTableSettings();
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState(
    initialSettings.newRowsPerPageOptions
  );
  const [rowsPerPage, setRowsPerPage] = useState(initialSettings.newRowsPerPage);

  useEffect(() => {
    const updatedSettings = getTableSettings();
    setRowsPerPageOptions(updatedSettings.newRowsPerPageOptions);
    setRowsPerPage(updatedSettings.newRowsPerPage);
  }, [getTableSettings, tableRows.length]);

  return { rowsPerPageOptions, rowsPerPage, setRowsPerPage };
};

export default useTablePaginationSettings;
