import React from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { AiOutlineCloudUpload } from "react-icons/ai";

import { formatAcceptedFileTypes } from "../../common/utils/format-accepted-file-types";

import { useIntl } from "react-intl";
import messages from "../../i18n/messages";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  dropzone: {
    height: 110,
    display: "flex",
    placeContent: "center",
    alignItems: "left",
    cursor: "pointer",
    borderRadius: spacing(0.6),
    border: "2px dashed #9d9d9d",
    marginBottom: spacing(2),
    overflow:"scroll",
    [breakpoints.down("sm")]: {
      height: 50,
    },
  },
  textAndIconContainer: {
    display: "flex",
    alignItems: "center",
    color: "#9d9d9d",
  },
  text: {
    marginLeft: spacing(1),
  },
}));

const ImageUploadArea = ({
  imageList,
  setImageList,
  setImageSection,
  sectionId,
  acceptedFiles,
  ProgressThumbnails,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const shouldRenderThumbnails = imageList.some((file) => file.status === "UPLOADING");

  const allAcceptedFiles = "image/png, image/jpeg, application/pdf";
  const acceptedFileTypes = acceptedFiles || allAcceptedFiles;
  const acceptFileTypesString = formatAcceptedFileTypes(acceptedFileTypes);

  const generateUID = () => {
    const randomNum = Math.floor(Math.random() * 10000);
    return randomNum.toString().padStart(4, "0");
  };

  const sanitizeFileName = (fileName) => {
    // Generate a unique id to use as a prefix for the filename to prevent collisions
    const uid = generateUID();

    // Replace spaces with underscores
    let sanitized = fileName.replaceAll(" ", "_");

    // Split the filename into parts based on the dot
    const parts = sanitized.split(".");
    if (parts.length > 1) {
      // Get the file extension (last part)
      const extension = parts.pop();
      // Join all parts except the extension, removing dots, and then add the extension back
      sanitized = parts.join("") + "." + extension;
    }

    // Remove any remaining special characters
    sanitized = sanitized.replace(/[^a-zA-Z0-9_.-]/g, "");

    return `${uid}_${sanitized}`;
  };

  const onDrop = (acceptedFiles) => {
    if (fileRejections?.length || !acceptedFiles?.length) {
      toast.warning(
        intl.formatMessage(messages.fileTypeShouldBe, { types: acceptFileTypesString }),
        {
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
    }

    if (acceptedFiles.length > 0) {
      const newImages = Array.from(acceptedFiles).map((file) => {
        const originalFileName = file.name;
        const sanitizedFileName = sanitizeFileName(file.name);

        // Create a new File object with the sanitized name
        const updatedFile = new File([file], sanitizedFileName, {
          type: file.type,
          lastModified: file.lastModified,
        });

        if (setImageSection) setImageSection(sectionId);
        return {
          file: updatedFile, // this is used to upload file to firebase storage with sanitized file name
          fileName: originalFileName, // this is used to display file name as it was before sanitization
          downloadUrl: "",
          description: "",
          progress: 0,
          status: "created",
          preview: URL.createObjectURL(updatedFile),
        };
      });

      setImageList((prevState) => [...prevState, ...newImages]);
    }
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
  });

  return (
    <Box {...getRootProps({ className: "dropzone" })} className={classes.dropzone}>
      <input {...getInputProps()} id="file" />
      <Box className={classes.textAndIconContainer}>
        {shouldRenderThumbnails ? (
          <ProgressThumbnails />
        ) : (
          <>
            <AiOutlineCloudUpload size={25} />
            <Typography variant="caption" className={classes.text}>
              {intl.formatMessage(messages.clickOrDropFiles)}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ImageUploadArea;
