import React from "react";
import PropTypes from "prop-types";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    placeContent: "center",
    alignItems: "center",
    width: "100%",
    minHeight: spacing(10),
    padding: spacing(3, 3, 0),
    marginBottom: spacing(3),
    borderRadius: spacing(0.5),
    border: (props) => (props.border ? "1px solid #e3e3e3" : "none"),
  },
}));

export const LoadingStateCard = ({ border }) => {
  const classes = useStyles({ border });

  return (
    <Paper className={classes.root} elevation={0}>
      <CardContent>
        <CircularProgress />
      </CardContent>
    </Paper>
  );
};

LoadingStateCard.propTypes = {
  className: PropTypes.string,
};

LoadingStateCard.defaultProps = {
  className: undefined,
};

export default LoadingStateCard;
