import React from "react";
import { useIntl } from "react-intl";

// Component
import ProjectParticipantsTable from "./project-participants-table";

// Constants and utilities
import messages from "../../../i18n/messages";
import StateCard from "../../../components/state-card/state-card";

const ProjectParticipantsList = ({ onOpen, fetching, buttonProps, projectParticipants }) => {
  const intl = useIntl();

  const renderEmptyState = () => {
    return (
      <StateCard
        title={intl.formatMessage(messages.noParticipants)}
        description={intl.formatMessage(messages.startAdding)}
        buttonProps={buttonProps}
      />
    );
  };

  return (
    <>
      {!projectParticipants?.length && !fetching ? (
        renderEmptyState()
      ) : (
        <ProjectParticipantsTable
          onOpen={onOpen}
          fetching={fetching}
          buttonProps={buttonProps}
          projectParticipants={projectParticipants}
        />
      )}
    </>
  );
};

ProjectParticipantsList.propTypes = {};

export default ProjectParticipantsList;
