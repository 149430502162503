export const copyDocument = async (
  fromDocument,
  toDocument,
  addData,
) => {
  const docData = await fromDocument
    .get()
    .then((doc) => doc.exists && doc.data())
    .catch((error) => {
      console.error(
        "Error reading document",
        `${fromDocument}`,
        JSON.stringify(error)
      );
    });

  if (docData) {
    await toDocument
      .set({ ...docData, ...addData })
      .catch((error) => {
        console.error(
          "Error creating document",
          `${toDocument}`,
          JSON.stringify(error)
        );
      });
    return true;
  }
  return false;
};



