import React from "react";
import firebase from "firebase";
import { pickBy, keys, each, clone, isEmpty } from "lodash";
import { useFormik } from "formik";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

// Material
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// Src
import useStoreProvider from "../../../common/providers/store/use-app-context";
import FormFooter from "../../../components/form-footer/form-footer";
import NdtResultEntryForm from "./ndt-result-entry-form";
import { db, NOTIFICATION_POSITION, WELD_EVENT_TYPES } from "../../../constants";

import { useIntl } from "react-intl";
import messages from "../../../i18n/messages";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    width: "100%",
  },
  formContainer: {
    marginBottom: spacing(3),
  },
  formStatusAndActionContainer: {
    paddingTop: spacing(1),
  },
  menuPaper: {
    maxHeight: 400,
  },
  progressIndicator: {
    display: "flex",
    alignItems: "center",
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  formFooter: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: spacing(2.4),
    [breakpoints.down("md")]: {
      padding: spacing(1, 1.4),
    },
  },
  actionContainer: {
    "& button:last-child": {
      marginLeft: spacing(3),
      [breakpoints.down("md")]: {
        marginLeft: spacing(1.5),
      },
    },
  },
}));

const LogNdtForm = ({
  onClose,
  onEventLogged,
  usersInvolved,
  selectedWelds,
  selectedNdtOrder,
  weldEventToEdit,
  projectDocumentRef,
  indexOfWeldEventToEdit,
}) => {
  const intl = useIntl();

  const batch = db.batch();

  const classes = useStyles();
  const { pid } = useParams();
  const { loggedInUser } = useStoreProvider();

  // Events array is needed when editing a specific weld
  const { events = [] } =
    typeof indexOfWeldEventToEdit === "number" &&
    Array.isArray(selectedWelds) &&
    typeof selectedWelds[0] === "object" &&
    selectedWelds[0] !== null
      ? selectedWelds[0]
      : {};

  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [ndtOperatorInfo, setNdtOperatorInfo] = React.useState(
    weldEventToEdit?.doneBy || { uid: "", fname: "", lname: "" }
  );

  const initialValues = {
    ndtDocument: "",
    ndtOperator: ndtOperatorInfo,
    ndtResults: selectedWelds[0]?.ndtStatus?.ndtResults || {},
    doneAt: weldEventToEdit?.doneAt
      ? format(new Date(weldEventToEdit?.doneAt), "yyyy-MM-dd'T'HH:mm")
      : format(new Date(), "yyyy-MM-dd'T'HH:mm"),
  };

  // TODO: The option with sampleRates should be removed. Data structure has been updated based 
  // on recent NDT inspection flow change. Required NDTs are now evaluated from inspectionRates.
  const requiredNdts = keys(
    selectedNdtOrder?.ndtRequirements?.sampleRates ||
      selectedNdtOrder?.ndtRequirements?.inspectionRates
  );

  const handleNdtOperatorSelectionChange = (event) => {
    const uid = event.target.value;
    const userData = usersInvolved.filter((user) => user?.userInfo?.uid === uid);

    if (userData.length) {
      const { uid, fname, lname } = userData[0]?.userInfo;
      setNdtOperatorInfo({ uid, fname, lname });
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) => {
      setSubmitting(true);
      setSubmitted(false);

      const failedNdt = keys(pickBy(values.ndtResults, (value, _key) => value === "rejected"));

      const ndtNotCompleted = keys(
        pickBy(values.ndtResults, (value, _key) => value === "notInspected")
      );

      const ndtFailed = failedNdt.length > 0;
      const ndtInProgress = ndtNotCompleted.length > 0;
      const doneState = ndtFailed ? "failed" : ndtInProgress ? "inProgress" : true;

      // Clone real values of ndtResults for log trail purpose
      const realNdtValues = clone(values.ndtResults);

      // Reset all ndtResults to toBeInspected if there is any rejected NDT.
      if (ndtFailed) {
        each(values.ndtResults, (_value, key) => {
          values.ndtResults[key] = "toBeInspected";
        });
      }

      const editedNdtEvent = {
        ...events[indexOfWeldEventToEdit],
        loggedBy: `${loggedInUser.fname} ${loggedInUser.lname}`,
        editedAt: firebase.firestore.Timestamp.now(),
        doneAt: values.doneAt,
        doneBy: ndtOperatorInfo,
        ndtResults: realNdtValues,
      };

      // Update event object by selected index
      events[indexOfWeldEventToEdit] = editedNdtEvent;

      const ndtEvent = {
        what: WELD_EVENT_TYPES.ndtLogged,
        ndtResults: realNdtValues,
        loggedAt: firebase.firestore.Timestamp.now(),
        doneAt: values.doneAt,
        doneBy: ndtOperatorInfo,
        loggedBy: `${loggedInUser.fname} ${loggedInUser.lname}`,
        commentOnNdts: values?.commentOnNdts || "",
      };

      const activitiesDocumentRef = projectDocumentRef.collection("activities").doc();

      // Logging weld is done based on the selected welds. It is always one weld that is logged at a time.
      // But it is just one weld selected from the welds table when the log NDT button clicked.
      selectedWelds.forEach((weld) => {
        let weldDocumentRef = db
          .collection("projects")
          .doc(pid)
          .collection("weld-logs")
          .doc(weld?.weldLogId)
          .collection("welds")
          .doc(weld?.id);

        batch.update(weldDocumentRef, {
          ndtStatus: {
            done: doneState,
            logger: `${loggedInUser.fname} ${loggedInUser.lname}`,
            ndtOperator: ndtOperatorInfo,
            ndtResults: values.ndtResults,
            ndtResultsForReporting: realNdtValues,
            ndtComment: values?.commentOnNdts || "",
            eventTimestamp: firebase.firestore.Timestamp.fromDate(new Date(values.doneAt)),
          },
          "heatTreatmentStatus.done": ndtFailed ? "failed" : true,
          "heatTreatmentStatus.redo": ndtFailed ? true : false,
          "weldStatus.done": ndtFailed ? "failed" : true,
          "weldStatus.redo": ndtFailed ? true : false,
          "weldStatus.redone": ndtFailed ? true : false,
          events:
            indexOfWeldEventToEdit > 0
              ? events
              : firebase.firestore.FieldValue.arrayUnion(ndtEvent),
        });

        // Add ndt event to activities collection of a project
        batch.set(activitiesDocumentRef, {
          ...ndtEvent,
          weldLogId: weld?.weldLogId,
          weldId: weld?.id,
        });

        // Update weld log with ndt event
        let weldLogDocumentRef = db
          .collection("projects")
          .doc(pid)
          .collection("weld-logs")
          .doc(weld?.weldLogId);

        batch.update(weldLogDocumentRef, {
          latestActivity: {
            ...ndtEvent,
            weldLogId: weld?.weldLogId,
            weldId: weld?.id,
          },
        });

        // Update weld log with ndt event
        let ndtOrderDocumentRef = db
          .collection("projects")
          .doc(pid)
          .collection("ndt-orders")
          .doc(selectedNdtOrder?.id);

        batch.update(ndtOrderDocumentRef, {
          latestActivity: {
            ...ndtEvent,
            weldLogId: weld?.weldLogId,
            weldId: weld?.id,
          },
        });
      });

      // Get the weldId of the last weld in selectedWelds.
      let lastWeld = selectedNdtOrder?.welds[selectedNdtOrder?.welds?.length - 1];

      // Update project with ndt event
      batch.update(projectDocumentRef, {
        latestActivity: {
          ...ndtEvent,
          weldLogId: lastWeld?.weldLogId,
          weldId: lastWeld?.weldId,
        },
      });

      batch
        .commit()
        .then(() => {
          resetForm({});
          setSubmitting(false);
          setSubmitted(true);
          onEventLogged(true);
          toast.success(intl.formatMessage(messages.loggedNdtSuccessfully), {
            position: NOTIFICATION_POSITION,
          });
        })
        .catch((error) => {
          setSubmitting(false);
          setErrorMessage("Something went wrong");
          toast.error(intl.formatMessage(messages.failedToLogNdt), {
            position: NOTIFICATION_POSITION,
          });
          console.log("LogNdtForm::", error);
        });
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  return (
    <>
      <DialogContent dividers>
        <form className={classes.form}>
          <Grid
            container
            spacing={4}
            className={classes.formContainer}
            justifyContent="space-between"
          >
            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                margin="normal"
                name="doneAt"
                label="Event timestamp"
                type="datetime-local"
                defaultValue={initialValues?.doneAt}
                className={classes.textField}
                InputLabelProps={{ shrink: true }}
                value={formik.values?.eventTimestamp}
                onChange={formik.handleChange}
              />

              <FormControl fullWidth margin="normal" size="small">
                <InputLabel>NDT operator</InputLabel>
                <Select
                  required
                  fullWidth
                  name="ndtOperator"
                  label="NDT operator"
                  margin="normal"
                  autoComplete="off"
                  value={ndtOperatorInfo?.uid}
                  onChange={handleNdtOperatorSelectionChange}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {usersInvolved.map((user, index) => (
                    <MenuItem key={index} value={user?.userInfo?.uid}>
                      {user.userInfo.fname} {user.userInfo.lname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                margin="normal"
                id="commentOnNdts"
                label="Comment on NDTs"
                name="commentOnNdts"
                autoComplete="off"
                value={formik.values.commentOnNdts}
                onChange={formik.handleChange}
                InputLabelProps={{ style: { fontSize: 14 } }}
              />
            </Grid>
            <Grid item xs={12} lg={5}>
              <NdtResultEntryForm formik={formik} involvedNdts={requiredNdts} />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        {showConfirmation ? (
          <Box className={classes.formFooter}>
            <Typography variant="body1">Are you sure you want to submit?</Typography>
            <Box className={classes.actionContainer}>
              <Button variant="outlined" onClick={() => setShowConfirmation(false)}>
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                onClick={() => {
                  handleSubmit();
                  setShowConfirmation(false);
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        ) : (
          <FormFooter
            submitting={submitting}
            submitted={submitted}
            submitButtonText="Log"
            cancelButtonText="Close"
            cancelButtonProps={{
              onClick: () => onClose(),
            }}
            submitButtonProps={{
              onClick: () => setShowConfirmation(true),
              disabled: isEmpty(formik.values.ndtResults) || isEmpty(ndtOperatorInfo?.uid),
            }}
            progressMessage={"Logging NDT"}
            successMessage={"NDT logged"}
            errorMessage={errorMessage}
          />
        )}
      </DialogActions>
    </>
  );
};

export default LogNdtForm;
