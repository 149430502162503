import messages from "../../../i18n/messages";
const getHeadCells = (intl) => ([
  {
    id: "weldNumber",
    numeric: false,
    disablePadding: false,
    label: intl.formatMessage(messages.weldNumber),
  },
  {
    id: "weldLogName",
    numeric: false,
    disablePadding: false,
    label: intl.formatMessage(messages.weldLogName),
  },
  {
    id: "weldStatus",
    numeric: false,
    disablePadding: false,
    label: intl.formatMessage(messages.weldStatus),
  },
  {
    id: "ndtStatus",
    numeric: false,
    disablePadding: false,
    label: intl.formatMessage(messages.ndtStatus),
  },
  {
    id: "ndtComments",
    numeric: false,
    disablePadding: false,
    label: intl.formatMessage(messages.ndtComment),
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: intl.formatMessage(messages.logNdt),
  },
]);

export default getHeadCells;
