import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import ProgressIndicator from "../../../components/progress-indicator/progress-indicator";
import ExternalDocumentLink from "../../../components/external-link/external-link";
import { formatDate } from "../../../common/utils/format-date";
import messages from "../../../i18n/messages";

const useStyles = makeStyles(({ spacing }) => ({
  gridItem: {
    padding: spacing(2),
    height: "100%",
    borderRadius: spacing(1),
    background: "rgb(242 246 251)",
  },
  roundedProgressBar: {
    borderRadius: spacing(1),
  },
}));

const WeldLogDetailsSummary = ({
  name,
  progress,
  weldProgress,
  htProgress,
  isHtRequired,
  weldLogDetails,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const status =
    progress?.weldProgress > 0 || (isHtRequired && progress?.htProgress > 0)
      ? intl.formatMessage(messages.started)
      : intl.formatMessage(messages.notStarted);

  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Paper elevation={0} className={classes.gridItem}>
          <Grid container direction="column" spacing={2}>
            <Grid container item spacing={0}>
              <Grid item xs={12}>
                <Typography variant="caption">{intl.formatMessage(messages.weldLog)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">{name}</Typography>
              </Grid>
            </Grid>

            <Grid container item spacing={0}>
              <Grid item xs={12}>
                <Typography variant="caption">{intl.formatMessage(messages.status)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">{status}</Typography>
              </Grid>
            </Grid>

            <Grid container item spacing={0}>
              <Grid item xs={12}>
                <Typography variant="caption">{intl.formatMessage(messages.created)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {formatDate(weldLogDetails?.createdAt, intl)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Paper elevation={0} className={classes.gridItem}>
          <Grid container direction="column" spacing={2}>
            <Grid container item spacing={0}>
              <Grid item xs={12}>
                <Typography variant="caption">
                  {intl.formatMessage(messages.attachments)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {weldLogDetails?.linkedDocuments &&
                  weldLogDetails?.linkedDocuments.map((linkedDocument, index) => (
                    <Box style={{ display: "flex", alignItems: "center" }} key={index}>
                      <ExternalDocumentLink fileData={linkedDocument} />
                    </Box>
                  ))}
              </Grid>
            </Grid>

            <Grid container item spacing={0}>
              <Grid item xs={12}>
                <Typography variant="caption">
                  {intl.formatMessage(messages.description)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{weldLogDetails?.description || "n/a"}</Typography>
              </Grid>
            </Grid>

            <Grid container item spacing={0}>
              <Grid item xs={12}>
                <Typography variant="caption">
                  {intl.formatMessage(messages.commentOnAttachments)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{weldLogDetails?.documentDescription || "n/a"}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Paper elevation={0} className={classes.gridItem}>
          <Grid container direction="column" spacing={2}>
            <Grid container item spacing={0}>
              <Grid item xs={5}>
                <Typography variant="caption">
                  {intl.formatMessage(messages.weldProgress)}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <ProgressIndicator rounded progress={weldProgress} />
              </Grid>
            </Grid>

            <Grid container item spacing={0}>
              {!isHtRequired && (
                <>
                  <Grid item xs={5}>
                    <Typography variant="caption">
                      {intl.formatMessage(messages.heatTreatmentProgress)}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body2">
                      {intl.formatMessage(messages.notRequired)}
                    </Typography>
                  </Grid>
                </>
              )}
              {isHtRequired && (
                <>
                  <Grid item xs={5}>
                    <Typography variant="caption">
                      {intl.formatMessage(messages.heatTreatmentProgress)}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <ProgressIndicator rounded progress={htProgress} />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

WeldLogDetailsSummary.propTypes = {
  name: PropTypes.string.isRequired,
  linkedDocument: PropTypes.object,
  createdAt: PropTypes.number.isRequired,
  openDocPreview: PropTypes.func.isRequired,
};

WeldLogDetailsSummary.defaultProps = {
  linkedDocument: {},
};

export default WeldLogDetailsSummary;
