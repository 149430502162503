import React from "react";
import { toast } from "react-toastify";

import { db, NOTIFICATION_POSITION } from "../../constants";

import { useIntl } from "react-intl";
import messages from '../../i18n/messages';

export const useArchiveProject = (pids) => {
  const intl = useIntl();

  const batch = db.batch();

  const projectCollectionRef = db.collection("projects");

  const [state, setState] = React.useState({
    error: false,
    completed: false,
  });

  const archiveProjects = React.useCallback(async () => {

    if (!projectCollectionRef) {
      setState({ error: true, completed: false });
      toast.error(intl.formatMessage(messages.somethingWentWrong), {
        position: NOTIFICATION_POSITION,
      });
      return;
    }

    try {
      pids.forEach((pid) => {
        batch.update(projectCollectionRef.doc(pid), { status: "trashed" });
      });
      await batch.commit();
      setState({ error: false, completed: true });
      toast.success(intl.formatMessage(messages.projectTrashedSuccessfully), {
        position: NOTIFICATION_POSITION,
      });
    } catch (error) {
      console.error("useArchiveProject::", error);
      setState({ error: true, completed: false });
      toast.error(intl.formatMessage(messages.somethingWentWrong), {
        position: NOTIFICATION_POSITION,
      });
    }
  }, [projectCollectionRef, pids, batch]);

  return {
    status: state,
    archiveProjects,
  };
};

export default useArchiveProject;
