import React from "react";
import { Link } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { Breadcrumbs, Typography } from "@material-ui/core";

// Icon
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles(() => ({
  link: {
    color: "rgba(0, 0, 0, 0.6)",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  text: {
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

const BreadcrumbsNav = ({ breadcrumbsNav }) => {
  const classes = useStyles();
  const renderBreadcrumbItem = (crumb, index) => {
    const isLast = index === breadcrumbsNav.length - 1;

    if (isLast) {
      return (
        <Typography className={classes.text} key={index}>
          {crumb.label ? crumb.label : <Skeleton key={index} width={100} height={15} />}
        </Typography>
      );
    } else {
      return (
        <Link className={classes.link} to={crumb.to} key={index}>
          {crumb.label ? crumb.label : <Skeleton key={index} width={100} height={15} />}
        </Link>
      );
    }
  };

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {breadcrumbsNav.map(renderBreadcrumbItem)}
    </Breadcrumbs>
  );
};

export default BreadcrumbsNav;
