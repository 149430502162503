// React and Third-Party Libraries
import React from "react";
import firebase from "firebase";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";

// Constants and Internationalization
import { db, NOTIFICATION_POSITION } from "../../constants";
import messages from "../../i18n/messages";

export const useRemoveWeldFromNdtOrder = (pid) => {
  const intl = useIntl();

  const removeWeldsFromNdtOrder = React.useCallback(
    async (weldsToRemove, setEventUpdated = () => {}) => {
      if (!weldsToRemove || weldsToRemove.length === 0) {
        return;
      }

      const batch = firebase.firestore().batch();
      weldsToRemove.forEach((weld, index) => {
        const ndtOrderDocumentRef = db
          .collection("projects")
          .doc(pid)
          .collection("ndt-orders")
          .doc(weld?.ndtOrderId);

        batch.update(ndtOrderDocumentRef, {
          welds: firebase.firestore.FieldValue.arrayRemove({
            weldId: weld.weldId,
            weldLogId: weld.weldLogId,
          }),
        });

        let weldDocumentRef = db
          .collection("projects")
          .doc(pid)
          .collection("weld-logs")
          .doc(weld.weldLogId)
          .collection("welds")
          .doc(weld.weldId);

        batch.update(weldDocumentRef, {
          ndtOrder: null,
        });

        setEventUpdated(true);
      });

      try {
        await batch.commit();
        toast.success(
          intl.formatMessage(messages.successfullyRemovedFromNdtOrder),
          {
            position: NOTIFICATION_POSITION,
          }
        );

        return { error: false, completed: true };
      } catch (error) {
        toast.error(intl.formatMessage(messages.somethingWentWrong), {
          position: NOTIFICATION_POSITION,
        });

        return { error: true, completed: false };
      }
    },
    [intl, pid]
  );

  return {
    removeWeldsFromNdtOrder,
  };
};

export default useRemoveWeldFromNdtOrder;
