import React from "react";
import isEmpty from "lodash/isEmpty";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

// Material UI
import Box from "@material-ui/core/Box";

// Components
import Title from "../../components/title/title";
import ReportListTable from "./components/report-list-table";
import ReportGenerationDialog from "./components/report-generation-dialog";

// Hooks
import useStoreProvider from "../../common/providers/store/use-app-context";
import useFetchCollection from "../../common/utils/use-fetch-collection-v2";
import useFetchDocument from "../../common/utils/use-fetch-document-v2";

// Utilities and constants
import { db } from "../../constants";
import messages from "../../i18n/messages";

function ProjectReports() {
  const intl = useIntl();
  const { pid } = useParams();
  const { loggedInUser, selectedProject, setSelectedProject } = useStoreProvider();
  const { fetching, data: reports, error, listenToCollectionUpdates } = useFetchCollection();
  const { data: projectDetails, getDocument } = useFetchDocument();

  const [open, setOpen] = React.useState(false);
  const [projectReports, setProjectReports] = React.useState([]);
  const [reportGenerationError, setReportGenerationError] = React.useState("");
  const [isGeneratingReport, setIsGeneratingReport] = React.useState(false);
  const [reportGenerationStatus, setReportGenerationStatus] = React.useState("Starting...");
  const [reportGenerationStatusRef, setReportGenerationStatusRef] = React.useState(null);

  const reportGenerator = `${loggedInUser?.fname} ${loggedInUser?.lname}`;
  const breadcrumbsNav = [
    { label: "Projects", to: "/" },
    { label: selectedProject?.projectName, to: `/projects/${pid}` },
    { label: "Reports", to: `/projects/${pid}/reports` },
  ];

  const reportCollectionRef = React.useMemo(
    () => db.collection("projects").doc(pid).collection("reports").orderBy("createdAt", "desc"),
    [pid]
  );

  const onReportGenerationStatusUpdate = (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      const { status } = doc.data();
      if (status === "100% uploaded") {
        setIsGeneratingReport(false);
      }
      setReportGenerationStatus(status);
    });
  };

  // Takes in selected document sections and logged in user as the report generator
  const generateReport = async (payload) => {
    const requestParam = JSON.stringify({ ...payload, reportGenerator });
    const encodedRequestParam = encodeURIComponent(requestParam);
    const generateReportUrl = `${process.env.REACT_APP_BASE_URL}/v1/report/${pid}/?p=${encodedRequestParam}`;
    const errorMessage = intl.formatMessage(messages.somethingWentWrong);
    try {
      const response = await fetch(generateReportUrl);
      setIsGeneratingReport(false);

      if (response?.status !== 200) {
        setReportGenerationError(errorMessage);
      }
    } catch (e) {
      setReportGenerationError(errorMessage);
      console.log(e);
    }
  };

  const getProjectDetails = React.useCallback(async () => {
    if (!selectedProject && !projectDetails) {
      const pd = await getDocument(db.collection("projects").doc(pid));
      setSelectedProject(pd);
    }
  }, [getDocument, pid, selectedProject, setSelectedProject, projectDetails]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const buttonProps = {
    color: "primary",
    variant: "contained",
    isGenerating: isGeneratingReport,
    progressText: reportGenerationStatus,
    onClick: handleOpen,
  };

  React.useEffect(() => {
    const unsubscribe = listenToCollectionUpdates(reportCollectionRef);
    return () => unsubscribe();
  }, [listenToCollectionUpdates, reportCollectionRef]);

  React.useEffect(() => {
    const filteredReports = reports.filter((report) => !isEmpty(report?.document));
    setProjectReports(filteredReports);
  }, [reports]);

  React.useEffect(() => {
    setReportGenerationStatusRef(
      db
        .collection("projects")
        .doc(pid)
        .collection("reports")
        .doc(reports[0]?.id)
        .collection("statuses")
    );
  }, [pid, reports]);

  React.useEffect(() => {
    reportGenerationStatusRef?.onSnapshot(onReportGenerationStatusUpdate);
  }, [reportGenerationStatusRef]);

  React.useEffect(() => {
    getProjectDetails();
  }, [getProjectDetails]);

  return (
    <Box width="100%">
      <Title title={intl.formatMessage(messages.reports)} breadcrumbsNav={breadcrumbsNav} />

      <ReportListTable
        rows={projectReports}
        fetching={fetching}
        isError={error}
        reportGenerationError={reportGenerationError}
        buttonProps={buttonProps}
      />
      <ReportGenerationDialog
        open={open}
        onClose={handleClose}
        generateReport={generateReport}
        setIsGeneratingReport={setIsGeneratingReport}
        title={intl.formatMessage(messages.selectDocumentSections)}
      />
    </Box>
  );
}

ProjectReports.propTypes = {};

export default ProjectReports;
