import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import useNotification from "../../common/providers/notification/use-notification-provider";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Notification() {
  const { notification, removeNotification } = useNotification();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    removeNotification();
  };

  const open = !!notification;
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={notification?.status}>
        {notification?.message}
      </Alert>
    </Snackbar>
  );
}
