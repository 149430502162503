import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";


const useStyles = makeStyles(({ breakpoints }) => ({
  dialogPaper: {    
    [`${breakpoints.up('sm')}`]: {
      minWidth: 700,
    },
  },
}));

function AddMaterialDailog(props) {
  const { open, onClose, Form, title, collectionRef } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullScreen={fullScreen}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <Form onClose={handleCancel} collectionRef={collectionRef}/>
      </DialogContent>
    </Dialog>
  );
}

AddMaterialDailog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddMaterialDailog;
