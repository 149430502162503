import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import CustomCard from "../../../components/custom-card/custom-card";
import WeldLogDetailsSummary from "./weld-log-details-summary";
import messages from "../../../i18n/messages";

const WeldLogDetailsBar = ({ weldLogDocumentRef, weldLogDetails, welds }) => {
  const intl = useIntl();

  const [weldProgress, setWeldPercentage] = React.useState(0);
  const [htProgress, setHtProgress] = React.useState(0);
  const [isHtRequired, setIsHtRequired] = React.useState(true);

  /**
   * Get weld progress percentage
   */
  const completedWeldPercentage = React.useCallback(async () => {
    if (!welds.length) return;

    const totalWelds = welds.length;
    const numberOfCompletedWelds = welds.reduce(
      (acc, weld) => (weld.weldStatus.done === true ? acc + 1 : acc),
      0
    );

    const percentageOfCompletedWelds = Math.floor((100 * numberOfCompletedWelds) / totalWelds);

    // Update weld progress if changed from what has been saved already
    if (weldLogDetails?.progress?.weldProgress !== percentageOfCompletedWelds) {
      weldLogDocumentRef.update({
        progress: {
          weldProgress: percentageOfCompletedWelds,
          htProgress: weldLogDetails?.progress?.htProgress || 0,
        },
      });
    }
    setWeldPercentage(percentageOfCompletedWelds);
  }, [
    weldLogDetails?.progress?.htProgress,
    weldLogDetails?.progress?.weldProgress,
    weldLogDocumentRef,
    welds,
  ]);

  /**
   * Get heat treatment progress percentage
   */
  const completedHtPercentage = React.useCallback(async () => {
    if (!welds.length) {
      return;
    }

    const totalWeldWithHtRequirements = welds.reduce(
      (acc, weld) => (weld.htRequired ? acc + 1 : acc),
      0
    );

    if (totalWeldWithHtRequirements === 0) {
      setIsHtRequired(false);
      setHtProgress(0);
      return;
    }

    const numberOfCompletedHts = welds.reduce(
      (acc, weld) => (weld.htRequired && weld.heatTreatmentStatus.done === true ? acc + 1 : acc),
      0
    );

    const percentageOfCompletedWelds = Math.floor(
      (100 * numberOfCompletedHts) / totalWeldWithHtRequirements
    );

    // Update heat treatment progress if changed from what has been saved already
    if (weldLogDetails?.progress?.htProgress !== percentageOfCompletedWelds) {
      weldLogDocumentRef.update({
        progress: {
          weldProgress: weldLogDetails?.progress?.weldProgress || 0,
          htProgress: percentageOfCompletedWelds,
        },
      });
    }

    setHtProgress(percentageOfCompletedWelds);
  }, [
    weldLogDetails?.progress?.htProgress,
    weldLogDetails?.progress?.weldProgress,
    weldLogDocumentRef,
    welds,
  ]);

  React.useEffect(() => {
    completedWeldPercentage();
    completedHtPercentage();
  }, [completedHtPercentage, completedWeldPercentage]);

  return (
    <CustomCard
      header={<Typography variant="subtitle1">{intl.formatMessage(messages.summary)}</Typography>}
    >
      <Grid container spacing={2}>
        <WeldLogDetailsSummary
          name={weldLogDetails?.weldLogName}
          progress={weldLogDetails?.progress}
          weldProgress={weldProgress}
          htProgress={htProgress}
          isHtRequired={isHtRequired}
          weldLogDetails={weldLogDetails}
        />
      </Grid>
    </CustomCard>
  );
};

WeldLogDetailsBar.propTypes = {
  weldLogDetails: PropTypes.object.isRequired,
};

export default WeldLogDetailsBar;
