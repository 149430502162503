import React from "react";

/**
 * This hook is used to fetch a collection from firebase.
 * It exposes functions to get data once or listen to updates.
 *
 * @param {firebase.firestore.CollectionReference} collectionRef - The collection reference to fetch
 * @returns {Object} - An object with properties: data, fetching, error, fetchCollectionOnce, listenToCollectionUpdates
 */

// TODO: This hook should replace the useFetchCollection hook in the doc-weld-web project
const useFetchCollection = () => {
  const [fetching, setFetching] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState([]);

  const fetchCollectionOnce = React.useCallback(async (collectionRef) => {
    try {
      setFetching(true);      
      const snapshot = await collectionRef.get();
      const docsArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(docsArray);
      setFetching(false);
    } catch (err) {
      setError(err);
    } finally {
      setFetching(false);
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listenToCollectionUpdates = React.useCallback((collectionRef) => {
    setFetching(true);
    const unsubscribe = collectionRef.onSnapshot((snapshot) => {
      let docsArray = [];
      try {
        docsArray = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setError(null);
      } catch (error) {
        setError(error);
      }
      setData(docsArray);
      setFetching(false);
    });

    return unsubscribe;
  }, []);

  return {
    fetching,
    data,
    error,
    fetchCollectionOnce,
    listenToCollectionUpdates,
  };
};

export default useFetchCollection;
