import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";


import PersonAddDisabledOutlinedIcon from "@material-ui/icons/PersonAddDisabledOutlined";
import SupervisorAccountOutlinedIcon from "@material-ui/icons/SupervisorAccountOutlined";

import { updateExtraUserInfo } from "../../../common/utils/auth";
import { NOTIFICATION_POSITION } from "../../../constants";

import messages from '../../../i18n/messages';

const useStyles = makeStyles(({ spacing }) => ({
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuItemHeight: {
    height: 45,
  },
  iconNormal: {
    fontSize: spacing(2.5),
    marginRight: spacing(3),
  },
  iconSmaller: {
    fontSize: spacing(2.2),
    marginRight: spacing(3),
  },
}));

const UserManagementMenu = (props) => {
  const intl = useIntl();

  const DEACTIVATE_ACCOUNT_SUCCESS_MESSAGE = intl.formatMessage(messages.accountDeactivated);
  const DEACTIVATE_ACCOUNT_FAILURE_MESSAGE = intl.formatMessage(messages.unableToDeactivateAccount);
  const ACTIVATE_ACCOUNT_SUCCESS_MESSAGE = intl.formatMessage(messages.accountActivated);
  const ACTIVATE_ACCOUNT_FAILURE_MESSAGE = intl.formatMessage(messages.unableToActivateAccount);
  const MAKE_ACCOUNT_ADMIN_SUCCESS_MESSAGE = intl.formatMessage(messages.accountUpgradedToAdmin);
  const MAKE_ACCOUNT_ADMIN_FAILURE_MESSAGE = intl.formatMessage(messages.unableToMakeAccountAdmin);
  const MAKE_ACCOUNT_USER_SUCCESS_MESSAGE = intl.formatMessage(messages.accountDemotedToUser);
  const MAKE_ACCOUNT_USER_FAILURE_MESSAGE = intl.formatMessage(messages.unableToDemoteAccountUser);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { uid, isUserActive, isAdmin } = props;
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleAccountStatus = () => {
    updateExtraUserInfo({ uid, active: !isUserActive }, intl)
      .then(() => {
        toast.success(
          isUserActive
            ? DEACTIVATE_ACCOUNT_SUCCESS_MESSAGE
            : ACTIVATE_ACCOUNT_SUCCESS_MESSAGE,
          {
            position: NOTIFICATION_POSITION,
          }
        );
      })
      .catch((error) => {
        console.log("ToggleAccountStatus::", error);
        toast.error(
          !isUserActive
            ? DEACTIVATE_ACCOUNT_FAILURE_MESSAGE
            : ACTIVATE_ACCOUNT_FAILURE_MESSAGE,
          {
            position: NOTIFICATION_POSITION,
          }
        );
      });
    handleClose();
  };

  const makeAccountAdmin = () => {
    updateExtraUserInfo({ uid, userRole: isAdmin ? ["user"] : ["admin"] })
      .then(() => {
        toast.success(
          isAdmin
            ? MAKE_ACCOUNT_USER_SUCCESS_MESSAGE
            : MAKE_ACCOUNT_ADMIN_SUCCESS_MESSAGE,
          {
            position: NOTIFICATION_POSITION,
          }
        );
      })
      .catch((err) => {
        console.log("ToggleAccountStatus::", err);
        toast.error(
          !isAdmin
            ? MAKE_ACCOUNT_USER_FAILURE_MESSAGE
            : MAKE_ACCOUNT_ADMIN_FAILURE_MESSAGE,
          {
            position: NOTIFICATION_POSITION,
          }
        );
      });
    handleClose();
  };

  // Commented based on client's request. Deleting does the same thing as deactivating an account.
  // TODO: User Firebase Admin SDK to properly perform user deletion

  // const deleteAccount = () => {
  //   updateExtraUserInfo({ uid, active: false })
  //     .then(() => {
  //       toast.success(DELETE_ACCOUNT_SUCCESS_MESSAGE, {
  //         position: NOTIFICATION_POSITION,
  //       });
  //     })
  //     .catch((err) => {
  //       console.log("DeleteAccount::", err);
  //       toast.error(DELETE_ACCOUNT_FAILURE_MESSAGE, {
  //         position: NOTIFICATION_POSITION,
  //       });
  //     });
  //   handleClose();
  // };

  return (
    (<div>
      <IconButton
        aria-label={intl.formatMessage(messages.more)}
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: "0px" }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        open={open}
        keepMounted
        elevation={5}
        anchorEl={anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            width: 240,
            boxShadow: "none",
            border: "1px solid #e3e3e3",
          },
        }}
        classes={{ list: classes.menuList }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          classes={{ root: classes.menuItemHeight }}
          onClick={toggleAccountStatus}
        >
          <PersonAddDisabledOutlinedIcon
            color="secondary"
            classes={{ root: classes.iconSmaller }}
          />
          <Box>
            <Typography display="block" gutterBottom>
              {
                isUserActive
                  ? intl.formatMessage(messages.deactivateAccount)
                  : intl.formatMessage(messages.activateAccount)
              }
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          classes={{ root: classes.menuItemHeight }}
          onClick={makeAccountAdmin}
        >
          <SupervisorAccountOutlinedIcon
            color="secondary"
            classes={{ root: classes.iconNormal }}
          />
          <Box>
            <Typography display="block" gutterBottom>
              {
                isAdmin
                  ? intl.formatMessage(messages.makeUser)
                  : intl.formatMessage(messages.makeAdmin)
              }
            </Typography>
          </Box>
        </MenuItem>
        <Divider />
        {/* <MenuItem
          classes={{ root: classes.menuItemHeight }}
          onClick={deleteAccount}
        >
          <DeleteOutlinedIcon
            color="primary"
            classes={{ root: classes.iconNormal }}
          />
          <Box>
            <Typography display="block" gutterBottom>
              Delete account
            </Typography>
          </Box>
        </MenuItem> */}
      </Menu>
    </div>)
  );
};

UserManagementMenu.propTypes = {
  uid: PropTypes.string.isRequired,
  isUserActive: PropTypes.bool.isRequired,
};

export default UserManagementMenu;
