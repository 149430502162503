import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

// Icons
import { MdOutlineDelete } from "react-icons/md";
import { CgRename, CgLink } from "react-icons/cg";
import { RxOpenInNewWindow } from "react-icons/rx";

// Hooks
import useUpdateDocument from "../../common/utils/use-update-document";

// Utilities and constants
import messages from "../../i18n/messages";

const useStyles = makeStyles(({ spacing, palette }) => ({
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuItemHeight: {
    height: 45,
  },
  menuIcon: {
    fontSize: spacing(2.5),
    marginRight: spacing(2),
    color: palette.secondary.dark,
  },
  menuIconSmaller: {
    fontSize: spacing(2.3),
    marginRight: spacing(2),
    color: palette.secondary.dark,
  },
  icon: {
    color: "rgba(255, 255, 255, 1)",
  },
}));

const DocumentActionMenu = ({
  imageUrl,
  fileData,
  documentRef,
  showOpenMenu,
  showLinkMenu,
  showRenameMenu,
  showDeleteMenu,
  onDocumentSelect,
  onEditMenuItemClick,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleDocumentActionMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRenameDocumentOpen = () => {
    onEditMenuItemClick();
    handleDocumentActionMenuClose();
  };

  const { updateDocument } = useUpdateDocument(documentRef);
  const handleDeleteDocument = () => {
    updateDocument({
      documentRef,
      payload: { status: "deleted" },
    });
    handleDocumentActionMenuClose();
  };

  const handleLinkDocument = () => {
    onDocumentSelect({
      name: fileData.filename,
      storageRef: fileData.storageRef,
    });
  };

  const linkDocumentFunctionDefined = typeof onDocumentSelect !== "undefined";
  const intl = useIntl();

  return (
    <div>
      <IconButton size="medium" onClick={handleClick} className={classes.icon}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        open={open}
        keepMounted
        elevation={5}
        anchorEl={anchorEl}
        onClose={handleDocumentActionMenuClose}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            width: 160,
            boxShadow: "none",
            border: "1px solid #e3e3e3",
          },
        }}
        classes={{ list: classes.menuList }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {showOpenMenu && (
          <MenuItem
            classes={{ root: classes.menuItemHeight }}
            onClick={handleDocumentActionMenuClose}
          >
            <a
              style={{ display: "flex", color: "inherit" }}
              href={imageUrl}
              target="_blank"
              rel="noreferrer"
            >
              <RxOpenInNewWindow className={classes.menuIcon} />
              <Typography display="block">{intl.formatMessage(messages.open)}</Typography>
            </a>
          </MenuItem>
        )}

        {showLinkMenu && linkDocumentFunctionDefined && (
          <Box>
            <MenuItem classes={{ root: classes.menuItemHeight }} onClick={handleLinkDocument}>
              <CgLink className={classes.menuIconSmaller} />
              <Typography display="block">{intl.formatMessage(messages.link)}</Typography>
            </MenuItem>
            <Divider />
          </Box>
        )}

        {showRenameMenu && (
          <Box>
            <MenuItem classes={{ root: classes.menuItemHeight }} onClick={handleRenameDocumentOpen}>
              <CgRename className={classes.menuIconSmaller} />
              <Typography display="block">{intl.formatMessage(messages.rename)}</Typography>
            </MenuItem>
            <Divider />
          </Box>
        )}

        {showDeleteMenu && (
          <Box>
            <MenuItem classes={{ root: classes.menuItemHeight }} onClick={handleDeleteDocument}>
              <MdOutlineDelete className={classes.menuIcon} />
              <Typography display="block">{intl.formatMessage(messages.delete)}</Typography>
            </MenuItem>
          </Box>
        )}
      </Menu>
    </div>
  );
};

DocumentActionMenu.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  fileData: PropTypes.object.isRequired,
  showOpenMenu: PropTypes.bool.isRequired,
  showLinkMenu: PropTypes.bool.isRequired,
  showRenameMenu: PropTypes.bool.isRequired,
  showDeleteMenu: PropTypes.bool.isRequired,
  onDocumentSelect: PropTypes.func,
  onEditMenuItemClick: PropTypes.func.isRequired,
};

export default DocumentActionMenu;
