import React from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import Box from "@material-ui/core/Box";

// Components
import Title from "../../components/title/title";
import StateCard from "../../components/state-card/state-card";
import LoadingStateCard from "../../components/loading-state-card/loading-state-card";
import WeldLogListTable from "./partials/weld-log-list-table";

// Hooks
import useStoreProvider from "../../common/providers/store/use-app-context";
import useFetchCollection from "../../common/utils/use-fetch-collection-v2";
import useFetchDocument from "../../common/utils/use-fetch-document-v2";

// Utilities and constants
import { db } from "../../constants";
import messages from "../../i18n/messages";

const WeldLogList = () => {
  const intl = useIntl();
  const { pid } = useParams();
  const { data: projectDetails, getDocument } = useFetchDocument();
  const { selectedProject, setSelectedProject } = useStoreProvider();

  const {
    fetching: weldLogsFetching,
    error: weldLogsError,
    data: weldLogsData,
    listenToCollectionUpdates,
  } = useFetchCollection();

  const weldLogCollectionRef = React.useMemo(
    () =>
      db
        .collection("projects")
        .doc(pid)
        .collection("weld-logs")
        .orderBy("createdAt", "asc")
        .where("status", "==", "active"),
    [pid]
  );

  const addWeldLogButtonProps = {
    color: "primary",
    variant: "contained",
    label: intl.formatMessage(messages.addWeldLog),
    to: `/projects/${pid}/weld-logs/add`,
  };

  const breadcrumbsNav = [
    { label: "Projects", to: "/" },
    { label: selectedProject?.projectName, to: `/projects/${pid}` },
    { label: "Weld logs", to: `/projects/${pid}/weld-logs` },
  ];

  const getProjectDetails = React.useCallback(async () => {
    if (!selectedProject) {
      getDocument(db.collection("projects").doc(pid));
      setSelectedProject(projectDetails);
    }
  }, [getDocument, pid, projectDetails, selectedProject, setSelectedProject]);

  React.useEffect(() => {
    getProjectDetails();
  }, [getProjectDetails]);

  React.useEffect(() => {
    const unsubscribe = listenToCollectionUpdates(weldLogCollectionRef);
    return () => unsubscribe();
  }, [listenToCollectionUpdates, weldLogCollectionRef]);

  return (
    <Box width="100%">
      {weldLogsFetching && weldLogsData && <LoadingStateCard />}
      {!weldLogsFetching && weldLogsError && (
        <StateCard
          title={intl.formatMessage(messages.errorFetchingData)}
          description={weldLogsError.message || intl.formatMessage(messages.unknownErrorOccurred)}
        />
      )}
      {!weldLogsFetching && weldLogsData?.length > 0 && (
        <>
          <Title title={intl.formatMessage(messages.weldLogs)} breadcrumbsNav={breadcrumbsNav} />
          <WeldLogListTable
            projectId={pid}
            rows={weldLogsData}
            fetching={weldLogsFetching}
            error={weldLogsError}
            buttonProps={addWeldLogButtonProps}
          />
        </>
      )}
      {!weldLogsFetching && weldLogsData.length === 0 && (
        <StateCard
          title={intl.formatMessage(messages.noWeldLogs)}
          description={intl.formatMessage(messages.youCanStartByCreatingWeldLog)}
          buttonProps={addWeldLogButtonProps}
        />
      )}
    </Box>
  );
};

export default WeldLogList;
