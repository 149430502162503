import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import CustomCard from "../../components/custom-card/custom-card";
import CompanyProfileImage from "../../components/user-profile-image/user-profile-image";

import { db } from "../../constants";

import { useIntl } from "react-intl";
import messages from '../../i18n/messages';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  profileImageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  userBriefContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
  },
  userDetailsContainer: {
    placeContent: "center",
    marginTop: 20,
  },
  userDetailItem: {
    flex: "1 1 0px",
  },
  formStatusAndActionContainer: {
    display: "flex",
    placeContent: "end",
    paddingTop: spacing(2),
  },
}));

const UserProfile = () => {
  const classes = useStyles();

  const [companyInfo, setCompanyInfo] = React.useState([]);
  const companyInfoCollectionRef = db.collection("company");

  const onCollectionUpdate = (querySnapshot) => {
    let companyInfo = {};
    querySnapshot.forEach((doc) => {
      const { name, address, logo, contact } = doc.data();

      companyInfo = {
        name,
        address,
        logo,
        contact
      };
    });

    setCompanyInfo(companyInfo);
  };

  React.useEffect(() => {
    companyInfoCollectionRef.onSnapshot(onCollectionUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const intl = useIntl();

  return (
    (<Box className={classes.root}>
      <Box className={classes.profileImageContainer}>
        <CompanyProfileImage
          borderRadius="8px"
          width="300px"
          height="auto"
          imagePath={companyInfo.logo}
          placeholder="/images/company-logo-placeholder.png"
        />
      </Box>
      <Grid container className={classes.userDetailsContainer}>
        <Grid item xs={12} lg={10}>
          <CustomCard>
            <Box display="flex" mb={4}>
              <Box className={classes.userDetailItem}>
                <Typography variant="caption">
                  {intl.formatMessage(messages.companyName)}
                </Typography>
                <Typography variant="body2">{companyInfo.name}</Typography>
              </Box>
              <Box className={classes.userDetailItem}>
                <Typography variant="caption">
                  {intl.formatMessage(messages.address)}
                </Typography>
                <Typography variant="body2">{companyInfo.address}</Typography>
              </Box>
              <Box className={classes.userDetailItem}>
                <Typography variant="caption">
                  {intl.formatMessage(messages.contactPerson)}
                </Typography>
                <Typography variant="body2">{companyInfo.contact || "n/a"}</Typography>
              </Box>
            </Box>
            <Divider />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className={classes.formStatusAndActionContainer}
            >
              <Button
                variant="contained"
                disableElevation
                component={Link}
                to={"/company-setting/edit"}
              >
                {intl.formatMessage(messages.edit)}
              </Button>
            </Box>
          </CustomCard>
        </Grid>
      </Grid>
    </Box>)
  );
};

UserProfile.propTypes = {};

export default UserProfile;
