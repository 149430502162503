import React from "react";
import firebase from "firebase";
import { useIntl } from "react-intl";
import { useDropzone } from "react-dropzone";

import { Link } from "react-router-dom";
import { Formik, Form } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";

import CustomCard from "../../../components/custom-card/custom-card";
import useStoreProvider from "../../../common/providers/store/use-app-context";
import { updateExtraUserInfo } from "../../../common/utils/auth";
import messages from "../../../i18n/messages";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  profileImageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  profileImageWrapper: {
    display: "flex",
    alignItems: "center",
    placeContent: "center",
  },
  profileImage: {
    width: 100,
    height: 100,
    objectFit: "cover",
    borderRadius: "100%",
  },
  uploadProgress: {
    position: "absolute",
  },
  userBriefContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
  },
  userDetailsContainer: {
    placeContent: "center",
    marginTop: 20,
  },
  formContainer: {
    marginBottom: spacing(3),
  },
  submit: {
    margin: spacing(6, 0, 4),
    padding: spacing(1.5),
  },
  formStatusAndActionContainer: {
    paddingTop: spacing(2),
  },
  actionContainer: {
    "& :last-child": {
      marginLeft: spacing(3),
    },
  },
  progressIndicator: {
    display: "flex",
    alignItems: "center",
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  dropzone: {
    width: 108,
    height: 108,
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    placeContent: "center",
    border: "1px dashed #9d9d9d",
  },
}));

const ProfileImage = ({ imageSrc, uploadProgress, classes }) => {
  const image = imageSrc || "/images/user.png";
  return (
    <Box className={classes.profileImageWrapper}>
      <img alt="" className={classes.profileImage} src={image} />
      <Typography className={classes.uploadProgress}>
        {uploadProgress}
      </Typography>
    </Box>
  );
};

export default function EditUserProfile() {
  const classes = useStyles();
  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [profileImage, setProfileImage] = React.useState("");
  const [uploadProgress, setUploadProgress] = React.useState();

  const { loggedInUser, setLoggedInUser } = useStoreProvider();

  const intl = useIntl();

  const userRole = loggedInUser?.userRole
    ? loggedInUser.userRole[0]
    : intl.formatMessage(messages.noRole);

  const getImageDownloadUrl = React.useCallback(async () => {
    const { photo } = loggedInUser;
    if (!photo) return;

    const url = await firebase
      .storage()
      .ref()
      .child(loggedInUser.photo)
      .getDownloadURL();
    setProfileImage(url);
  }, [loggedInUser]);

  const handleUpload = (file) => {
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const uploadTask = storageRef
      .child(`profileImages/${loggedInUser.uid}/${file.name}`)
      .put(file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const progress = parseInt(
          (snapshot.bytesTransferred * 100) / snapshot.totalBytes
        );
        setUploadProgress(progress);
      },
      (error) => {
        throw error;
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          setProfileImage(url);
          updateExtraUserInfo({
            photo: `profileImages/${loggedInUser.uid}/${file.name}`,
            uid: loggedInUser.uid,
          });
        });
      }
    );
  };

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      handleUpload(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: "image/*",
  });

  React.useEffect(() => {
    getImageDownloadUrl();
  }, [getImageDownloadUrl]);

  return (
    <Box className={classes.root}>
      <Box className={classes.profileImageContainer}>
        <Box
          {...getRootProps({ className: "dropzone" })}
          className={classes.dropzone}
        >
          <input {...getInputProps()} id="file" />
          <ProfileImage
            imageSrc={profileImage}
            uploadProgress={uploadProgress}
            classes={classes}
          />
        </Box>
        <Box className={classes.userBriefContainer}>
          <Typography variant="subtitle2" align="center">
            {loggedInUser?.fname} {loggedInUser?.lname}
          </Typography>
          <Typography variant="caption" align="center">
            {loggedInUser?.email} | {userRole}
          </Typography>
        </Box>
      </Box>
      <Grid container className={classes.userDetailsContainer}>
        <Grid container item xs={12} lg={10}>
          <CustomCard>
            <Formik
              initialValues={loggedInUser}
              onSubmit={(values, { resetForm }) => {
                const updatedValues = {
                  fname: values?.fname,
                  lname: values?.lname,
                  updatedBy: loggedInUser.uid,
                  uid: loggedInUser.uid,
                  updatedAt: firebase.firestore.Timestamp.now(),
                };
                setSubmitting(true);
                setSubmitted(false);
                updateExtraUserInfo(updatedValues)
                  .then(() => {
                    // Update currently displayed information
                    setLoggedInUser({
                      ...loggedInUser,
                      fname: updatedValues.fname,
                      lname: updatedValues.lname,
                      updatedAt: updatedValues.updatedAt,
                    });
                    resetForm({});
                    setSubmitting(false);
                    setSubmitted(true);
                  })
                  .catch((error) => {
                    setSubmitting(false);
                    setErrorMessage(
                      intl.formatMessage(messages.somethingWentWrong)
                    );
                  });
              }}
            >
              {(formik) => (
                <Form>
                  <Grid
                    container
                    spacing={10}
                    className={classes.formContainer}
                  >
                    <Grid item xs={12} lg={4}>
                      <TextField
                        required
                        autoFocus
                        fullWidth
                        id="fname"
                        name="fname"
                        label={intl.formatMessage(messages.firstName)}
                        margin="normal"
                        autoComplete="off"
                        value={formik.values?.fname}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <TextField
                        required
                        autoFocus
                        fullWidth
                        id="lname"
                        name="lname"
                        label={intl.formatMessage(messages.lastName)}
                        margin="normal"
                        autoComplete="off"
                        value={formik.values?.lname}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <TextField
                        required
                        autoFocus
                        fullWidth
                        disabled
                        id="email"
                        name="email"
                        label={intl.formatMessage(messages.emailAddress)}
                        margin="normal"
                        autoComplete="off"
                        value={formik.values?.email}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    className={classes.formStatusAndActionContainer}
                  >
                    <Box>
                      {submitting && (
                        <Box className={classes.progressIndicator}>
                          <CircularProgress size={25} />
                          <Box ml={2}>
                            {intl.formatMessage(
                              messages.updatingUserProfileData
                            )}
                          </Box>
                        </Box>
                      )}
                      {submitted && (
                        <Alert
                          severity="success"
                          icon={<CheckIcon fontSize="inherit" />}
                        >
                          {intl.formatMessage(messages.updatedSuccessfully)}
                        </Alert>
                      )}
                      {!submitting && !submitted && errorMessage && (
                        <Alert severity="error" mt={10}>
                          {errorMessage}
                        </Alert>
                      )}
                    </Box>
                    <Box className={classes.actionContainer}>
                      <Button
                        disableElevation
                        variant="contained"
                        component={Link}
                        to="/user/profile"
                      >
                        {intl.formatMessage(messages.close)}
                      </Button>
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {intl.formatMessage(messages.save)}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </CustomCard>
        </Grid>
      </Grid>
    </Box>
  );
}
