import React from "react";
import firebase from "firebase";
import { useDropzone } from "react-dropzone";

import { Link } from "react-router-dom";
import { Formik, Form } from "formik";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";

// Src
import CustomCard from "../../../components/custom-card/custom-card";
import { db } from "../../../constants";

import { useIntl } from "react-intl";
import messages from '../../../i18n/messages';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  profileImageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  profileImageWrapper: {
    display: "flex",
    alignItems: "center",
    placeContent: "center",
  },
  profileImage: {
    width: 300,
    objectFit: "cover",
    borderRadius: "10px",
    opacity: (props) => (props.editMode ? 0.2 : 1),
  },
  uploadProgress: {
    position: "absolute",
  },
  userBriefContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
  },
  userDetailsContainer: {
    placeContent: "center",
    marginTop: 20,
  },
  formContainer: {
    marginBottom: spacing(3),
  },
  submit: {
    margin: spacing(6, 0, 4),
    padding: spacing(1.5),
  },
  formStatusAndActionContainer: {
    paddingTop: spacing(2),
  },
  actionContainer: {
    "& :last-child": {
      marginLeft: spacing(3),
    },
  },
  progressIndicator: {
    display: "flex",
    alignItems: "center",
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  dropzone: {
    width: 304,
    // height: 154,
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    placeContent: "center",
    border: "1px dashed #9d9d9d",
  },
}));

export default function EditCompanyProfile() {
  const classes = useStyles();

  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [profileImage, setProfileImage] = React.useState("");
  const [uploadProgress, setUploadProgress] = React.useState();
  const [companyInfo, setCompanyInfo] = React.useState();

  const ProfileImage = ({ editMode }) => {
    const classes = useStyles({ editMode });
    const image = profileImage || "/images/company-logo-placeholder.png";

    const intl = useIntl();

    return (
      (<Box className={classes.profileImageWrapper}>
        <img alt="" className={classes.profileImage} src={image} />
        {editMode && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "absolute",
              color: "gray",
            }}
          >
            <svg
              stroke="currentColor"
              fill="none"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke-linecap="round"
              stroke-linejoin="round"
              height="3em"
              width="3em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M15 8h.01"></path>
              <path d="M11 20h-4a3 3 0 0 1 -3 -3v-10a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v4"></path>
              <path d="M4 15l4 -4c.928 -.893 2.072 -.893 3 0l3 3"></path>
              <path d="M14 14l1 -1c.31 -.298 .644 -.497 .987 -.596"></path>
              <path d="M18.42 15.61a2.1 2.1 0 0 1 2.97 2.97l-3.39 3.42h-3v-3l3.42 -3.39z"></path>
            </svg>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="caption">
                {intl.formatMessage(messages.clickToReplaceImage)}
              </Typography>
              <Typography variant="caption">
                {uploadProgress < 100 && uploadProgress}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>)
    );
  };

  const getImageDownloadUrl = React.useCallback(async () => {
    if (!companyInfo?.logo) return;

    const url = await firebase
      .storage()
      .ref()
      .child(companyInfo?.logo)
      .getDownloadURL();
    setProfileImage(url);
  }, [companyInfo?.logo]);

  // DRU: Unify into some util function
  const handleUpload = (file) => {
    var storage = firebase.storage();
    var storageRef = storage.ref();
    var uploadTask = storageRef.child(`companyLogo/${file.name}`).put(file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const progress = parseInt(
          (snapshot.bytesTransferred * 100) / snapshot.totalBytes
        );
        setUploadProgress(progress);
      },
      (error) => {
        throw error;
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          setProfileImage(url);
          updateCompanyInfo({
            logo: `companyLogo/${file.name}`,
          });
        });
      }
    );
  };

  const updateCompanyInfo = async (updatedCompanyInfo) => {
    const companyId = companyInfo?.id;
    try {
      const docRef = await db
        .collection("company")
        .doc(companyId)
        .update({
          ...updatedCompanyInfo,
        });
      setCompanyInfo({ ...companyInfo, ...updatedCompanyInfo });
      return docRef;
    } catch (error) {
      throw new Error(error);
    }
  };

  const getCompanyInfo = React.useCallback(async () => {
    let data = [];
    await db
      .collection("company")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        if (data.length) {
          setCompanyInfo(data[0]);
          getImageDownloadUrl(data[0]);
        }
      });
  }, [getImageDownloadUrl]);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      handleUpload(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: "image/*",
  });

  React.useEffect(() => {
    getCompanyInfo();
  }, [getCompanyInfo]);

  const intl = useIntl();

  return (
    (<Box className={classes.root}>
      <Box className={classes.profileImageContainer}>
        <Box
          {...getRootProps({ className: "dropzone" })}
          className={classes.dropzone}
        >
          <input {...getInputProps()} id="file" />
          <ProfileImage profileImage={profileImage} editMode />
        </Box>
        <Box className={classes.userBriefContainer}>
          <Typography variant="subtitle2" align="center">
            { }
          </Typography>
        </Box>
      </Box>
      <Grid container className={classes.userDetailsContainer}>
        <Grid container item xs={12} lg={10}>
          <CustomCard>
            <Formik
              enableReinitialize
              initialValues={{
                name: companyInfo?.name,
                logo: companyInfo?.logo,
                address: companyInfo?.address,
                contact: companyInfo?.contact,
              }}
              onSubmit={(values, { resetForm }) => {
                const updatedValues = {
                  name: values?.name,
                  address: values?.address,
                  contact: values?.contact || "",
                  updatedAt: firebase.firestore.Timestamp.now(),
                };
                setSubmitting(true);
                setSubmitted(false);
                updateCompanyInfo(updatedValues)
                  .then(() => {
                    resetForm({});
                    setSubmitting(false);
                    setSubmitted(true);
                  })
                  .catch((error) => {
                    setSubmitting(false);
                    setErrorMessage(
                      `Something went wrong while updating company profile::${error}`
                    );
                  });
              }}
            >
              {(formik) => (
                <Form>
                  <Grid
                    container
                    spacing={10}
                    className={classes.formContainer}
                  >
                    <Grid item xs={12} lg={4}>
                      <TextField
                        required
                        autoFocus
                        fullWidth
                        id="name"
                        name="name"
                        label={intl.formatMessage(messages.companyName)}
                        margin="normal"
                        autoComplete="off"
                        value={formik.values?.name}
                        onChange={formik.handleChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <TextField
                        required
                        autoFocus
                        fullWidth
                        id="address"
                        name="address"
                        label={intl.formatMessage(messages.address)}
                        margin="normal"
                        autoComplete="off"
                        value={formik.values?.address}
                        onChange={formik.handleChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <TextField
                        autoFocus
                        fullWidth
                        id="contact"
                        name="contact"
                        label={intl.formatMessage(messages.contactPerson)}
                        margin="normal"
                        autoComplete="off"
                        value={formik.values?.contact}
                        onChange={formik.handleChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    className={classes.formStatusAndActionContainer}
                  >
                    <Box>
                      {submitting && (
                        <Box className={classes.progressIndicator}>
                          <CircularProgress size={25} />
                          <Box ml={2}>
                            {intl.formatMessage(messages.updatingCompanyData)}
                          </Box>
                        </Box>
                      )}
                      {submitted && (
                        <Alert
                          severity="success"
                          icon={<CheckIcon fontSize="inherit" />}
                        >
                          {intl.formatMessage(messages.companyDataUpdated)}
                        </Alert>
                      )}
                      {!submitting && !submitted && errorMessage && (
                        <Alert severity="error" mt={10}>
                          {errorMessage}
                        </Alert>
                      )}
                    </Box>
                    <Box className={classes.actionContainer}>
                      <Button
                        disableElevation
                        variant="contained"
                        component={Link}
                        to="/company-setting"
                      >
                        {intl.formatMessage(messages.close)}
                      </Button>
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {intl.formatMessage(messages.save)}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </CustomCard>
        </Grid>
      </Grid>
    </Box>)
  );
}
