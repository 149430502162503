import React from "react";
import firebase from "firebase";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { useIntl } from "react-intl";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import { RxOpenInNewWindow } from "react-icons/rx";

// Utilities and constants
import messages from "../../i18n/messages";

const useStyles = makeStyles(({ spacing, palette }) => ({
  externalLinkIcon: {
    fontSize: spacing(2.5),
    marginRight: spacing(1),
    color: palette.grey[500],
  },
  skeleton: {
    width: 200,
  },
  tooltip: {
    background: "#001B33",
  },
  tooltipArrow: {
    color: "#001B33",
  },
  tooltipText: {
    color: "#FFFFFF",
  },
}));

const ExternalDocumentLink = ({ fileData }) => {
  const classes = useStyles();
  const [fileUrl, setFileUrl] = React.useState(null);
  const [isFetching, setIsFetching] = React.useState(false);

  const getDocumentUrl = React.useCallback(async () => {
    if (isEmpty(fileData) || isEmpty(fileData.storageRef)) {
      return;
    }

    try {
      setIsFetching(true);
      const url = await firebase.storage().ref().child(fileData.storageRef).getDownloadURL();

      setFileUrl(url);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      console.log("ExternalDocumentLink:: Something went wrong when trying to fetch document");
    }
  }, [fileData]);

  React.useEffect(() => {
    getDocumentUrl();
  }, [getDocumentUrl]);

  const intl = useIntl();

  return (
    <>
      {isFetching && <Skeleton animation="wave" className={classes.skeleton} />}
      {fileUrl && (
        <a
          style={{ display: "flex", width: "200px", overflow: "hidden" }}
          href={fileUrl}
          target="_blank"
          rel="noreferrer"
        >
          <RxOpenInNewWindow className={classes.externalLinkIcon} />
          <Tooltip
            arrow
            classes={{
              arrow: classes.tooltipArrow,
              tooltip: classes.tooltip,
            }}
            title={<Typography className={classes.tooltipText}>{fileData?.name}</Typography>}
            placement="top"
            component={"span"}
          >
            <Box
              style={{
                overflow: "hidden",
                width: "200px",
                textOverflow: "ellipsis",
              }}
            >
              <Typography noWrap display="block">
                {fileData?.name}
              </Typography>
            </Box>
          </Tooltip>
        </a>
      )}
      {!isFetching && !fileUrl && intl.formatMessage(messages.na)}
    </>
  );
};

ExternalDocumentLink.propTypes = {
  fileData: PropTypes.object.isRequired,
};

export default ExternalDocumentLink;
