import React from "react";
import { toast } from "react-toastify";

import { db, NOTIFICATION_POSITION } from "../../constants";

export const useDeleteDocuments = (
  collectionRef,
  documentIds,
  setSelectedIds,
  successMessage,
  failureMessage
) => {
  const batch = db.batch();
  const [state, setState] = React.useState({
    error: false,
    completed: false,
  });

  const deleteDocuments = React.useCallback(async () => {
    if (!collectionRef) {
      setState({
        error: true,
        completed: false,
      });
      toast.error(failureMessage, {
        position: NOTIFICATION_POSITION,
      });
      return;
    }

    try {
      documentIds.forEach((uid) => {
        batch.delete(collectionRef.doc(uid));
      });
      await batch.commit();
      setSelectedIds([]);
      setState({ error: false, completed: true });
      toast.success(successMessage, {
        position: NOTIFICATION_POSITION,
      });
    } catch (error) {
      console.error("useDeleteDocuments::", error);
      setState({ error: true, completed: false });
      toast.error(failureMessage, {
        position: NOTIFICATION_POSITION,
      });
    }
  }, [
    batch,
    documentIds,
    collectionRef,
    setSelectedIds,
    failureMessage,
    successMessage,
  ]);

  return {
    status: state,
    deleteDocuments,
  };
};

export default useDeleteDocuments;
