import { NDT_METHODS, NDT_ORDER_STATUSES } from "../../../constants";

/**
 * Extracts NDT details from the given order.
 *
 * @param {Object} ndtOrder - The NDT order object.
 * @returns {Object} - An object containing inspectionMethods, ndtCoverageRates, and ndtSampleRates.
 */
export const extractNdtDetails = (ndtOrder) => {
  const ndtTypes = [];
  const ndtMethods = [];
  const ndtInspectionRatesList = [];

  for (let key in ndtOrder?.ndtRequirements?.inspectionRates) {
    const ndtMethodCode = NDT_METHODS[key]?.code;
    const ndtMethod = NDT_METHODS[key]?.name;

    ndtMethods.push(ndtMethod);
    ndtTypes.push(ndtMethodCode);
    ndtInspectionRatesList.push(
      `${ndtMethodCode}-${ndtOrder.ndtRequirements.inspectionRates[key]}%`
    );
  }

  return {
    requiredNdtMethods: ndtMethods,
    inspectionMethods: ndtTypes.join(", "),
    ndtInspectionRates: ndtInspectionRatesList.join(", "),
  };
};

/**
 * Calculates NDT inspections status based on selected NDT order and weld information.
 *
 * @param {Object} selectedNdtOrder - The selected NDT order.
 * @param {Array} welds - Array of weld information.
 * @returns {Object} - An object containing ndtInspectionStatus, completedInspectionPerNdtMethod, and completedInspectionPerNdtOrder.
 */
export const calculateNdtInspections = (selectedNdtOrder, welds) => {
  let totalRequired = 0;
  let totalCompleted = 0;

  const totalWelds = welds.length;
  const ndtMethodStatuses = {};
  const completedInspectionPerNdtMethod = {};
  const {
    ndtRequirements: { inspectionRates = {} },
  } = selectedNdtOrder || {};

  if (totalWelds === 0 || !inspectionRates) {
    return {
      completedInspectionPerNdtMethod,
      ndtInspectionStatus: NDT_ORDER_STATUSES.compiling.status,
    };
  }

  Object.keys(inspectionRates).forEach((ndtMethodName) => {
    if (inspectionRates[ndtMethodName] > 0) {
      const ndtMethod = Object.values(NDT_METHODS).find((method) => method.name === ndtMethodName);

      if (ndtMethod) {
        let completedInspections = 0;
        let toBeInspectedCount = 0;
        let rejectedCount = 0;
        let approvedCount = 0;
        const numOfRequiredInspections = Math.ceil(
          (inspectionRates[ndtMethodName] / 100) * totalWelds
        );

        welds.forEach((weld) => {
          const ndtStatus = weld.ndtStatus?.ndtResultsForReporting || {};
          switch (ndtStatus[ndtMethodName]) {
            case NDT_ORDER_STATUSES.rejected.status:
              rejectedCount++;
              break;
            case NDT_ORDER_STATUSES.approved.status:
              approvedCount++;
              completedInspections++;
              break;
            case NDT_ORDER_STATUSES.toBeInspection.status:
            default:
              toBeInspectedCount++;
              break;
          }
        });

        completedInspectionPerNdtMethod[ndtMethodName] = {
          completed: completedInspections,
          numOfRequiredInspections,
        };

        totalCompleted += Math.min(completedInspections, numOfRequiredInspections);
        totalRequired += numOfRequiredInspections;

        if (rejectedCount > 0) {
          ndtMethodStatuses[ndtMethod.code] = NDT_ORDER_STATUSES.rejected.status;
        } else if (approvedCount > 0 && approvedCount === numOfRequiredInspections) {
          ndtMethodStatuses[ndtMethod.code] = NDT_ORDER_STATUSES.approved.status;
        } else if (toBeInspectedCount === totalWelds) {
          ndtMethodStatuses[ndtMethod.code] = NDT_ORDER_STATUSES.waitingInspection.status;
        } else if (approvedCount < numOfRequiredInspections) {
          ndtMethodStatuses[ndtMethod.code] = NDT_ORDER_STATUSES.inProgress.status;
        }
      }
    }
  });

  // Evaluate the overall NDT inspection status
  const isAnyMethodRejected = Object.values(ndtMethodStatuses).some(
    (status) => status === NDT_ORDER_STATUSES.rejected.status
  );

  const areAllMethodsApproved = Object.values(ndtMethodStatuses).every(
    (status) => status === NDT_ORDER_STATUSES.approved.status
  );

  const areAllMethodsWaitingInspection = Object.values(ndtMethodStatuses).every(
    (status) => status === NDT_ORDER_STATUSES.waitingInspection.status
  );

  // TODO: Remove, not used
  // Count number of welds that have greater or equal number of completed inspections than required
  const weldsWithCompletedInspections = Object.values(completedInspectionPerNdtMethod).filter(
    (method) => method.completed >= method.numOfRequiredInspections
  ).length;

  // If any method is rejected, the overall status is rejected. But based on new
  // NDT flow, overall status should remain in progress if any method is rejected
  // or all are approved.
  let ndtInspectionStatus = NDT_ORDER_STATUSES.inProgress.status;
  if (isAnyMethodRejected || areAllMethodsApproved) {
    ndtInspectionStatus = NDT_ORDER_STATUSES.inProgress.status;
  } else if (areAllMethodsWaitingInspection) {
    ndtInspectionStatus = NDT_ORDER_STATUSES.waitingInspection.status;
  }

  const overallCompletionPercentage = totalRequired ? (totalCompleted / totalRequired) * 100 : 0;

  return {
    isAnyMethodRejected,
    ndtInspectionStatus,
    completedInspectionPerNdtMethod,
    completedInspectionPerNdtOrder: { completed: weldsWithCompletedInspections, total: totalWelds },
    overallCompletionPercentage,
    overallCompletion: { completed: totalCompleted, total: totalRequired },
  };
};

/**
 * Format the NDT inspection result object into a string representation.
 *
 * @param {Object} ndtOrder - The NDT order object containing inspection progress and weld information.
 * @returns {Object} - An object with formatted strings representing NDT inspection progress per method and per weld.
 */
export const formatNdtResult = (ndtOrder) => {
  let totalRequired = 0;
  let totalCompleted = 0;
  const { ndtProgress = {}, welds = [] } = ndtOrder;

  if (!ndtProgress || !welds) {
    return null;
  }

  Object.entries(ndtProgress).map(([_key, value]) => {
    totalCompleted += Math.min(value?.completed, value?.numOfRequiredInspections);
    totalRequired += value?.numOfRequiredInspections;
    return null;
  });

  return {
    overallCompletion: { completed: totalCompleted, total: totalRequired },
  };
};
