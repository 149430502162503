import React from "react";
import { useIntl } from "react-intl";
import messages from "../../../i18n/messages";
import firebase from "firebase";
import { useFormik } from "formik";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

// Material
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// Icons
import LinkIcon from "@material-ui/icons/Link";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";

// Src
import useStoreProvider from "../../../common/providers/store/use-app-context";
import FormFooter from "../../../components/form-footer/form-footer";
import DocumentLinkDialog from "../../../components/document-link-dialog/document-link-dialog";
import { db, NOTIFICATION_POSITION, WELD_EVENT_TYPES } from "../../../constants";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: "100%",
  },
  formContainer: {
    marginBottom: spacing(3),
  },
  formStatusAndActionContainer: {
    paddingTop: spacing(1),
  },
  menuPaper: {
    maxHeight: 400,
  },
  progressIndicator: {
    display: "flex",
    alignItems: "center",
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  weldersMultiSelect: {
    margin: spacing(1),
    minWidth: 350,
    maxWidth: 350,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const LogWeldForm = ({
  projectDocumentRef,
  weldLogDocumentRef,
  usersInvolved,
  weldEventToEdit,
  indexOfWeldEventToEdit,
  onEventLogged,
  onClose,
  selectedWelds,
}) => {
  const intl = useIntl();

  const LOGGED_WELD_SUCCESS_MESSAGE = intl.formatMessage(messages.loggedWeldSuccessfully);
  const LOGGED_WELD_FAILURE_MESSAGE = intl.formatMessage(messages.failedToLogWeld);

  const batch = db.batch();
  const classes = useStyles();
  const { pid, weldLogId } = useParams();
  const { loggedInUser, selectedProject } = useStoreProvider();
  const { events = [] } = indexOfWeldEventToEdit ? selectedWelds[0] : {};
  const isAdmin = loggedInUser?.userRole[0] === "admin";

  const { parentMaterials = [], fillerMaterials = [] } = selectedWelds[0] || {};

  const { parentMaterialTraceable, fillerMaterialTraceable } = selectedProject;

  const [open, setOpen] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [selectedParentMaterial, setSelectedParentMaterial] = React.useState({});
  const [updatedParentMaterials, setParentMaterialsWithCert] = React.useState(parentMaterials);
  const [updatedFillerMaterials, setFillerMaterialsWithCert] = React.useState(fillerMaterials);

  const [weldersInfo, setWeldersInfo] = React.useState(weldEventToEdit?.doneBy || []);

  const initialValues = {
    welder: weldersInfo,
    doneAt: weldEventToEdit?.doneAt
      ? format(new Date(weldEventToEdit?.doneAt), "yyyy-MM-dd'T'HH:mm")
      : format(new Date(), "yyyy-MM-dd'T'HH:mm"),
  };

  const handleClickOpen = (parentMaterial) => {
    setOpen(true);
    setSelectedParentMaterial(parentMaterial);
  };

  const handleMultipleWelderSelectionChange = (event, uid) => {
    event.stopPropagation();

    const currentlySelected = weldersInfo.find((info) => info.uid === uid);

    if (currentlySelected) {
      // Remove the welder from the selection.
      setWeldersInfo(weldersInfo.filter((info) => info.uid !== uid));
    } else {
      const userData = usersInvolved.find((user) => user?.userInfo?.uid === uid);
      if (userData) {
        const { uid, fname, lname } = userData.userInfo;
        setWeldersInfo([...weldersInfo, { uid, fname, lname }]);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) => {
      setSubmitting(true);
      setSubmitted(false);

      const editedWeldEvent = {
        ...events[indexOfWeldEventToEdit],
        loggedBy: `${loggedInUser.fname} ${loggedInUser.lname}`,
        editedAt: firebase.firestore.Timestamp.now(),
        doneAt: values.doneAt,
        doneBy: isAdmin
          ? weldersInfo
          : [
              {
                uid: loggedInUser.uid,
                fname: loggedInUser.fname,
                lname: loggedInUser.lname,
              },
            ],
      };

      // Update event object by selected index
      events[indexOfWeldEventToEdit] = editedWeldEvent;

      const weldEvent = {
        doneAt: values.doneAt,
        doneBy: isAdmin
          ? weldersInfo
          : [
              {
                uid: loggedInUser.uid,
                fname: loggedInUser.fname,
                lname: loggedInUser.lname,
              },
            ],
        loggedAt: firebase.firestore.Timestamp.now(),
        loggedBy: `${loggedInUser.fname} ${loggedInUser.lname}`,
        what: WELD_EVENT_TYPES.weldLogged,
      };

      const activitiesDocumentRef = projectDocumentRef.collection("activities").doc();

      selectedWelds.forEach((selectedWeld) => {
        let weldDocumentRef = db
          .collection("projects")
          .doc(pid)
          .collection("weld-logs")
          .doc(weldLogId)
          .collection("welds")
          .doc(selectedWeld?.id);

        batch.update(weldDocumentRef, {
          weldStatus: {
            ...selectedWeld?.weldStatus,
            done: true,
            redo: false,
            welder: weldersInfo,
            eventTimestamp: firebase.firestore.Timestamp.fromDate(new Date(values.doneAt)),
            logger: `${loggedInUser.fname} ${loggedInUser.lname}`,
          },
          events:
            indexOfWeldEventToEdit > 0
              ? events
              : firebase.firestore.FieldValue.arrayUnion(weldEvent),
          parentMaterials: updatedParentMaterials,
          fillerMaterials: updatedFillerMaterials,
        });

        // Add weld event to activities collection of a project
        batch.set(activitiesDocumentRef, {
          ...weldEvent,
          weldLogId,
          weldId: selectedWeld?.id,
        });
      });

      // Get the weldId of the last weld in selectedWelds.
      let lastWeld = selectedWelds[selectedWelds.length - 1];

      // Update project with latest event
      batch.update(projectDocumentRef, {
        latestActivity: { ...weldEvent, weldLogId, weldId: lastWeld?.id },
      });

      // Update weld log with latest weld event
      batch.update(weldLogDocumentRef, {
        latestActivity: { ...weldEvent, weldLogId, weldId: lastWeld?.id },
      });

      batch
        .commit()
        .then(() => {
          resetForm({});
          setSubmitting(false);
          setSubmitted(true);
          onEventLogged(true);
          toast.success(LOGGED_WELD_SUCCESS_MESSAGE, {
            position: NOTIFICATION_POSITION,
          });
        })
        .catch((error) => {
          setSubmitting(false);
          setErrorMessage(intl.formatMessage(messages.somethingWentWrong));
          toast.error(LOGGED_WELD_FAILURE_MESSAGE, {
            position: NOTIFICATION_POSITION,
          });
          console.log("LogWeldForm::", error);
        });
    },
  });

  const handleClose = (value, materialId) => {
    setOpen(false);
    const parentMaterialsWithCerts = updatedParentMaterials.map((updatedParentMaterial) => {
      if (updatedParentMaterial.value === materialId) {
        return {
          ...updatedParentMaterial,
          certificateName: value?.name,
          storageRef: value?.storageRef,
        };
      } else {
        return updatedParentMaterial;
      }
    });

    const fillerMaterialsWithCerts = updatedFillerMaterials.map((updatedFillerMaterial) => {
      if (updatedFillerMaterial.value === materialId) {
        return {
          ...updatedFillerMaterial,
          certificateName: value?.name,
          storageRef: value?.storageRef,
        };
      } else {
        return updatedFillerMaterial;
      }
    });

    setParentMaterialsWithCert(parentMaterialsWithCerts);
    setFillerMaterialsWithCert(fillerMaterialsWithCerts);
  };

  const CustomInputText = (props) => {
    const { material, formik } = props;
    return (
      <>
        <TextField
          fullWidth
          margin="normal"
          id={material.value}
          name="test"
          autoComplete="off"
          label={material.label}
          InputLabelProps={{ style: { fontSize: 14 } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={intl.formatMessage(messages.togglePasswordVisibility)}
                  onClick={() => handleClickOpen(material)}
                >
                  <LinkIcon color="primary" />
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <ImageOutlinedIcon />
              </InputAdornment>
            ),
          }}
          onChange={formik.handleChange}
          disabled
          value={
            material?.certificateName
              ? material?.certificateName
              : intl.formatMessage(messages.noDocumentLinked)
          }
        />
      </>
    );
  };

  const CertificatesToParentMaterials = ({ formik }) => (
    <>
      {!!updatedParentMaterials.length && (
        <Typography>{intl.formatMessage(messages.parentMaterialCertificates)}</Typography>
      )}
      {updatedParentMaterials?.map((updatedParentMaterial, _index) => {
        return (
          <CustomInputText
            key={updatedParentMaterial.value}
            material={updatedParentMaterial}
            formik={formik}
          />
        );
      })}
    </>
  );

  const CertificatesToFillerMaterials = ({ formik }) => (
    <>
      {!!updatedFillerMaterials.length && (
        <Typography>{intl.formatMessage(messages.fillerMaterialCertificates)}</Typography>
      )}
      {updatedFillerMaterials?.map((updatedFillerMaterial, _index) => {
        return (
          <CustomInputText
            key={updatedFillerMaterial.value}
            material={updatedFillerMaterial}
            formik={formik}
          />
        );
      })}
    </>
  );

  const handleSubmit = () => {
    formik.submitForm();
  };

  const pmWithoutCertificate = updatedParentMaterials?.filter(
    (updatedParentMaterial) => updatedParentMaterial.storageRef === undefined
  );

  const fmWithoutCertificate = updatedFillerMaterials?.filter(
    (updatedFillerMaterial) => updatedFillerMaterial.storageRef === undefined
  );

  const parentMaterialsCertificatesLinked = parentMaterialTraceable
    ? pmWithoutCertificate.length === 0
    : true;
  const fillerMaterialsCertificatesLinked = fillerMaterialTraceable
    ? fmWithoutCertificate.length === 0
    : true;

  const disableWeldLogButton =
    weldersInfo?.length === 0 ||
    !parentMaterialsCertificatesLinked ||
    !fillerMaterialsCertificatesLinked;

  return (
    <>
      <DialogContent dividers>
        <form className={classes.form}>
          <Grid container spacing={4} className={classes.formContainer}>
            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                margin="normal"
                name="doneAt"
                label={intl.formatMessage(messages.eventTimestamp)}
                type="datetime-local"
                defaultValue={initialValues?.doneAt}
                className={classes.textField}
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
              />
              {parentMaterialTraceable && <CertificatesToParentMaterials formik={formik} />}
              {fillerMaterialTraceable && <CertificatesToFillerMaterials formik={formik} />}
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth margin="normal" size="small">
                {!isAdmin && (
                  <TextField
                    required
                    fullWidth
                    name="welder"
                    label={intl.formatMessage(messages.welder)}
                    margin="normal"
                    autoComplete="off"
                    value={`${loggedInUser.fname} ${loggedInUser.lname}`}
                    InputProps={{
                      readOnly: true,
                    }}
                  >
                    {loggedInUser.fname} {loggedInUser.lname}
                  </TextField>
                )}
                {isAdmin && (
                  <>
                    <InputLabel>{intl.formatMessage(messages.welders)}</InputLabel>
                    <Select
                      multiple
                      required
                      fullWidth
                      name="welder"
                      label={intl.formatMessage(messages.welders)}
                      margin="normal"
                      autoComplete="off"
                      value={weldersInfo?.map((info) => info.uid)}
                      input={<Input />}
                      renderValue={(selected) => {
                        // 'selected' is an array of the 'uid's of the selected users.
                        // For each 'uid', find the corresponding user and return their 'fname' and 'lname'.
                        return selected
                          .map((uid) => {
                            const user = usersInvolved.find((user) => user?.userInfo.uid === uid);
                            return `${user?.userInfo?.fname} ${user?.userInfo?.lname}`;
                          })
                          .join(", ");
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="">
                        <em>{intl.formatMessage(messages.none)}</em>
                      </MenuItem>
                      {usersInvolved?.map((user, index) => (
                        <MenuItem key={index} value={user?.userInfo?.uid}>
                          <Checkbox
                            checked={weldersInfo.some((info) => info.uid === user.userInfo.uid)}
                            onClick={(event) =>
                              handleMultipleWelderSelectionChange(event, user.userInfo.uid)
                            }
                          />
                          <ListItemText primary={`${user.userInfo.fname} ${user.userInfo.lname}`} />
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <DocumentLinkDialog
            open={open}
            onClose={handleClose}
            identifier={selectedParentMaterial.value}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <FormFooter
          submitting={submitting}
          submitted={submitted}
          submitButtonText={intl.formatMessage(messages.log)}
          cancelButtonText={intl.formatMessage(messages.close)}
          cancelButtonProps={{
            onClick: () => onClose(),
          }}
          submitButtonProps={{
            onClick: () => handleSubmit(),
            disabled: disableWeldLogButton,
          }}
          progressMessage={intl.formatMessage(messages.loggingWeld)}
          successMessage={intl.formatMessage(messages.weldLogged)}
          errorMessage={errorMessage}
        />
      </DialogActions>
    </>
  );
};

export default LogWeldForm;
