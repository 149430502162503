import React from "react";
import * as yup from "yup";
import firebase from "firebase";
import { isEmpty } from "lodash";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useLocation, useHistory } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// Components
import CustomCard from "../../../components/custom-card/custom-card";
import FormFooter from "../../../components/form-footer/form-footer";
import Title from "../../../components/title/title";

// Hooks
import useStoreProvider from "../../../common/providers/store/use-app-context";

// Utilities and constants
import { db, NOTIFICATION_POSITION } from "../../../constants";
import { useIntl } from "react-intl";
import messages from "../../../i18n/messages";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  form: {
    width: "100%",
    marginTop: spacing(5),
  },
  formContainer: {
    marginBottom: spacing(3),
  },
  gridItem: {
    [breakpoints.down("md")]: {
      padding: `${spacing(0, 4)} !important`,
    },
  },
}));

const ProjectAdd = () => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const validationSchema = yup.object({
    projectName: yup.string().required(intl.formatMessage(messages.projectRequired)),
    projectNumber: yup.string().required(intl.formatMessage(messages.projectNumberRequired)),
  });

  const projectData = location?.state?.projectData ? location?.state?.projectData : null;

  const { setSelectedProject } = useStoreProvider();
  const backPath = projectData ? `/projects/${projectData?.id}` : `/`;

  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  let projectCollectionRef = db.collection("projects");
  let documentId = projectCollectionRef.doc().id;

  let initialValues = {
    id: "",
    projectName: "",
    projectNumber: "",
    customer: "",
    externalReference: "",
    description: "",
    status: "active",
    documentTemplate: null,
    parentMaterialTraceable: false,
    fillerMaterialTraceable: false,
  };

  // This happens when editing a project
  if (!isEmpty(projectData)) {
    initialValues = projectData;
    documentId = projectData?.id;
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setSubmitting(true);
      setSubmitted(false);
      projectCollectionRef
        .doc(documentId)
        .set({
          ...values,
          ...(isEmpty(projectData) && {
            createdAt: firebase.firestore.Timestamp.now(),
          }),
          ...(!isEmpty(projectData) && {
            updatedAt: firebase.firestore.Timestamp.now(),
          }),
          ...(isEmpty(projectData) && {
            id: documentId,
          }),
        })
        .then(() => {
          setSelectedProject(values);
          resetForm({});
          setSubmitting(false);
          setSubmitted(true);
          toast.success(successMessage, {
            position: NOTIFICATION_POSITION,
          });
        })
        .catch((error) => {
          const errorMessage = intl.formatMessage(messages.somethingWentWrong);
          setSubmitting(false);
          setErrorMessage(errorMessage);
          toast.error(errorMessage, {
            position: NOTIFICATION_POSITION,
          });
          console.log(error);
        });
    },
  });

  /**
   * Navigate to project list view if adding project is completed successfully
   */
  const navigateBackOnSuccess = () => {
    validationSchema
      .validate(formik.values)
      .then(function () {
        setTimeout(function () {
          history.push(backPath);
        }, 2000);
      })
      .catch(function (err) {
        console.log("Project add form::", err);
      });
  };

  const handleSubmit = () => {
    formik.submitForm();
    navigateBackOnSuccess();
  };

  const titleAndButtonText = projectData
    ? intl.formatMessage(messages.updateProject)
    : intl.formatMessage(messages.addProject);
  const progressMessage = projectData
    ? intl.formatMessage(messages.updatingProject)
    : intl.formatMessage(messages.addingProject);
  const successMessage = projectData
    ? intl.formatMessage(messages.projectUpdatedSuccessfully)
    : intl.formatMessage(messages.projectAddedSuccessfully);

  return (
    <Box width="100%">
      <Title title={titleAndButtonText} />
      <CustomCard
        footer={
          <FormFooter
            submitting={submitting}
            submitted={submitted}
            submitButtonText={titleAndButtonText}
            cancelButtonText={intl.formatMessage(messages.close)}
            handleSubmit={handleSubmit}
            cancelButtonProps={{
              to: backPath,
            }}
            progressMessage={progressMessage}
            successMessage={successMessage}
            errorMessage={errorMessage}
          />
        }
      >
        <form className={classes.form}>
          <Grid container spacing={5} className={classes.formContainer}>
            <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={4}>
              <TextField
                fullWidth
                autoFocus
                margin="normal"
                id="projectName"
                label={intl.formatMessage(messages.projectName)}
                name="projectName"
                autoComplete="off"
                value={formik.values.projectName}
                onChange={formik.handleChange}
                error={formik.touched.projectName && Boolean(formik.errors.projectName)}
                helperText={formik.touched.projectName && formik.errors.projectName}
                InputLabelProps={{ style: { fontSize: 14 } }}
              />
              <TextField
                margin="normal"
                fullWidth
                name="projectNumber"
                label={intl.formatMessage(messages.projectNumber)}
                id="projectNumber"
                autoComplete="off"
                value={formik.values.projectNumber}
                onChange={formik.handleChange}
                error={formik.touched.projectNumber && Boolean(formik.errors.projectNumber)}
                helperText={formik.touched.projectNumber && formik.errors.projectNumber}
                InputLabelProps={{ style: { fontSize: 14 } }}
              />
              <TextField
                margin="normal"
                fullWidth
                name="customer"
                label={intl.formatMessage(messages.customer)}
                id="customer"
                autoComplete="off"
                value={formik.values.customer}
                onChange={formik.handleChange}
                error={formik.touched.customer && Boolean(formik.errors.customer)}
                helperText={formik.touched.customer && formik.errors.customer}
                InputLabelProps={{ style: { fontSize: 14 } }}
              />
            </Grid>
            <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={4}>
              <TextField
                margin="normal"
                fullWidth
                name="externalReference"
                label={intl.formatMessage(messages.externalReference)}
                id="externalReference"
                autoComplete="off"
                value={formik.values.externalReference}
                onChange={formik.handleChange}
                error={formik.touched.externalReference && Boolean(formik.errors.externalReference)}
                helperText={formik.touched.externalReference && formik.errors.externalReference}
                InputLabelProps={{ style: { fontSize: 14 } }}
              />
              <TextField
                margin="normal"
                fullWidth
                multiline
                id="description"
                label={intl.formatMessage(messages.description)}
                name="description"
                autoComplete="off"
                value={formik.values.description}
                onChange={formik.handleChange}
                InputLabelProps={{ style: { fontSize: 14 } }}
              />
            </Grid>
            <Grid item className={classes.gridItem} xs={12} sm={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.parentMaterialTraceable}
                    onChange={formik.handleChange}
                    name="parentMaterialTraceable"
                  />
                }
                style={{ marginTop: "24px" }}
                label={intl.formatMessage(messages.traceabilityOfParentMaterial)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.fillerMaterialTraceable}
                    onChange={formik.handleChange}
                    name="fillerMaterialTraceable"
                  />
                }
                style={{ marginTop: "24px" }}
                label={intl.formatMessage(messages.traceabilityOfFillerMaterial)}
              />
            </Grid>
          </Grid>
        </form>
      </CustomCard>
    </Box>
  );
};

export default ProjectAdd;
