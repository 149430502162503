import React from "react";
import isEmpty from "lodash/isEmpty";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import classNames from "classnames";
import {
  object,
  string,
  number,
  elementType,
  oneOfType,
  node,
  bool,
} from "prop-types";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    placeContent: "space-around",
    width: props => props.width || "100%",
    minHeight: 225,
    maxHeight: 300,
    borderRadius: spacing(0.5),
    border: props => props.border ? "1px solid #e3e3e3" : "none",
  },
  cardContent: {
    display: "flex",
    placeContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: spacing(10, 6),
    maxWidth: 600,

    "&:last-child": {
      padding: spacing(6),
    },
  },
  cardTitle: {
    marginBottom: spacing(2),

    "&:last-child": {
      marginBottom: 0,
    },
  },
  cardDescription: {
    marginBottom: spacing(4),

    "&:last-child": {
      marginBottom: 0,
    },
  },
}));

export const StateCard = ({
  title,
  className,
  elevation,
  description,
  buttonProps,
  ButtonComponent,
  children,
  showButton,
  width,
  border,
  ...rest
}) => {
  const classes = useStyles({width, border});

  const { to } = buttonProps;

  const titleElement = (
    <Typography
      variant="subtitle2"
      className={classes.cardTitle}
      component="h5"
      align="center"
    >
      {title}
    </Typography>
  );

  const descriptionElement = (
    <Typography
      variant="body2"
      color="textSecondary"
      className={classes.cardDescription}
      align="center"
    >
      {description}
    </Typography>
  );

  const buttonElement = to ? (
    <ButtonComponent to={to} component={Link} {...buttonProps} disableElevation>
      {buttonProps.label}
    </ButtonComponent>
  ) : (
    <ButtonComponent
      {...buttonProps}
      onClick={() => buttonProps.onClick()}
      disableElevation
    >
      {buttonProps.label}
    </ButtonComponent>
  );

  const rootClassNames = classNames(classes.root, className);

  return (
    <Paper {...rest} classes={{ root: rootClassNames }} elevation={0}>
      <CardContent className={classes.cardContent}>
        {title && titleElement}
        {description && descriptionElement}
        {children}
        {!isEmpty(buttonProps) && showButton && buttonElement}
      </CardContent>
    </Paper>
  );
};

StateCard.propTypes = {
  className: string,
  title: string,
  description: oneOfType([string, node]),
  elevation: number,
  buttonProps: object,
  ButtonComponent: elementType,
  showButton: bool,
  border: bool
};

StateCard.defaultProps = {
  className: undefined,
  title: undefined,
  description: undefined,
  buttonProps: {},
  elevation: undefined,
  ButtonComponent: Button,
  showButton: true,
  border: true
};

export default StateCard;
