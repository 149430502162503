import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom';

function PublicRoute({ component: ComponentToRender, authenticated, ...rest }) {
    if (!authenticated) {
        return (
          <Route {...rest} render={(routeProps) => <ComponentToRender {...routeProps} {...rest} />} />
        );
    }
    return (
        <Redirect to={{ pathname: '/' }} />
    )
}

PublicRoute.propTypes = {
    component: PropTypes.func.isRequired,
}

export default PublicRoute;