import * as React from "react";
import { useIntl } from "react-intl";

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Typography } from "@material-ui/core";

import { ALL_NDTS } from "../../../constants";
import messages from "../../../i18n/messages";

const NdtRequirements = ({ formik }) => {
  const [isDisabled, setIsDisabled] = React.useState(
    ALL_NDTS.reduce((acc, ndtItem) => {
      acc[ndtItem.inspectionRate] = !formik.values.ndtRequirements[ndtItem.inspectionRate];
      return acc;
    }, {})
  );

  const handleChange = (event) => {
    const ndtItem = ALL_NDTS.find((item) => item.code === event.target.name);
    const isChecked = event.target.checked;
    setIsDisabled((prevState) => ({
      ...prevState,
      [ndtItem.inspectionRate]: !isChecked,
    }));

    if (!isChecked) {
      formik.setFieldValue(`ndtRequirements.inspectionRates.${ndtItem.name}`, "");
    }
  };

  const intl = useIntl();

  return (
    <FormControl component="fieldset" margin="normal">
      <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
        <Grid container spacing={4}>
          <Grid item xs={8} lg={8}>
            <FormLabel component="legend">{intl.formatMessage(messages.method)}</FormLabel>
          </Grid>
          <Grid item xs={4} lg={4}>
            {intl.formatMessage(messages.inspectionRate)}
          </Grid>
        </Grid>
        <>
          {ALL_NDTS.map((ndtItem, index) => {
            return (
              <Grid container key={index} spacing={4}>
                <Grid item xs={8} lg={8}>
                  <FormControlLabel
                    label={ndtItem.label}
                    control={
                      <Checkbox
                        defaultChecked={!!formik.values.ndtRequirements[ndtItem.inspectionRate]}
                        name={ndtItem.code}
                        onChange={handleChange}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <TextField
                    autoFocus
                    type="number"
                    autoComplete="off"
                    name={`ndtRequirements.inspectionRates.${ndtItem.name}`}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.ndtRequirements.inspectionRates[ndtItem.name]}
                    disabled={isDisabled[ndtItem.inspectionRate]}
                  />
                </Grid>
              </Grid>
            );
          })}
          {formik.errors.ndtRequirements &&
            formik.errors.ndtRequirements.split("\n").map((err, idx) => (
              <Typography variant="caption" color="error" key={idx}>
                {err}
              </Typography>
            ))}
        </>
      </Box>
    </FormControl>
  );
};

export default NdtRequirements;
