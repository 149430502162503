import React from "react";
import firebase from "firebase";
import { toast } from "react-toastify";

import { db, WELD_EVENT_TYPES } from "../../../constants";
import useFetchCollection from "../../../common/utils/use-fetch-collection";
import useStoreProvider from "../../../common/providers/store/use-app-context";

import { useIntl } from "react-intl";
import messages from "../../../i18n/messages";

export const useDuplicateWeldLog = (pid, weldLogData) => {
  const intl = useIntl();

  const DUPLICATE_SUCCESS_MESSAGE = intl.formatMessage(
    messages.weldLogDuplicatedSuccessfully
  );

  const DUPLICATE_FAILURE_MESSAGE = intl.formatMessage(
    messages.somethingWentWrong
  );

  const weldLogCollectionRef = db
    .collection("projects")
    .doc(pid)
    .collection("weld-logs");

  let weldLogId = weldLogCollectionRef.doc().id;

  const weldCollectionRef = db
    .collection("projects")
    .doc(pid)
    .collection("weld-logs")
    .doc(weldLogData?.id)
    .collection("welds");

  const weldCollectionToCreateRef = db
    .collection("projects")
    .doc(pid)
    .collection("weld-logs")
    .doc(weldLogId)
    .collection("welds");

  const { getCollection } = useFetchCollection();
  const { loggedInUser } = useStoreProvider();
  const [state, setState] = React.useState({
    error: false,
    completed: false,
  });

  const duplicateWeldLog = React.useCallback(async () => {
    if (!weldLogData || !weldLogCollectionRef) {
      setState({ error: true, completed: false });
      return;
    }

    try {
      await weldLogCollectionRef.doc(weldLogId).set({
        ...weldLogData,
        id: weldLogId,
        latestActivity: {},
        progress: { weldProgress: 0, htProgress: 0 },

        weldLogName: `${intl.formatMessage(messages.copyPrefix, {
          weldLogName: weldLogData.weldLogName,
        })}`,
        createdAt: firebase.firestore.Timestamp.now(),
        updatedAt: firebase.firestore.Timestamp.now(),
      });

      const welds = await getCollection(weldCollectionRef);
      welds.docs?.map(async (doc) => {
        let weldId = weldCollectionToCreateRef.doc().id;
        const logCreationEvent = {
          what: WELD_EVENT_TYPES.weldCreated,
          when: firebase.firestore.Timestamp.now(),
          loggedAt: firebase.firestore.Timestamp.now(),
          doneAt: firebase.firestore.Timestamp.now(),
          doneBy: {
            fname: loggedInUser.fname,
            lname: loggedInUser.lname,
          },
          loggedBy: `${loggedInUser.fname} ${loggedInUser.lname}`,
        };
        await weldCollectionToCreateRef.doc(weldId).set({
          ...doc.data(),
          id: weldId,
          weldLogId,
          remark: "",
          position: "",
          ndtOrder: {},
          ndtStatus: { ndtResults: {}, ndtResultsForReporting: {} },
          weldStatus: {},
          heatTreatmentStatus: {},
          events: [logCreationEvent],
          createdAt: firebase.firestore.Timestamp.now(),
          updatedAt: firebase.firestore.Timestamp.now(),
        });
      });

      setState({ error: false, completed: true });
      toast.success(DUPLICATE_SUCCESS_MESSAGE, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } catch (e) {
      console.log("DuplicateWeldLog::", e);
      setState({ error: true, completed: false });
      toast.error(DUPLICATE_FAILURE_MESSAGE, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  }, [
    weldLogData,
    weldLogCollectionRef,
    weldLogId,
    getCollection,
    weldCollectionRef,
    weldCollectionToCreateRef,
    loggedInUser.fname,
    loggedInUser.lname,
    DUPLICATE_FAILURE_MESSAGE,
    DUPLICATE_SUCCESS_MESSAGE,
    intl,
  ]);

  return {
    status: state,
    duplicateWeldLog,
  };
};

export default useDuplicateWeldLog;
