// React and Third-Party Libraries
import React from "react";
import { useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { FcInspection } from "react-icons/fc";

// Material-UI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

// Components
import LogNdtForm from "../../../pages/weld-details/partials/log-ndt-form";
import GenericTableHead from "../../../components/generic-table/generic-table-head";
import GenericTableToolbar from "../../../components/generic-table/generic-table-toolbar";
import Indicator from "../../../components/indicator/indicator";
import StateCard from "../../../components/state-card/state-card";
import LoadingStateCard from "../../../components/loading-state-card/loading-state-card";
import RoleAccess from "../../../components/role-access/role-access";
import WeldEventLoggingDialog from "../../weld-details/partials/weld-event-logging-dialog";

// Utilities and Constants
import { extractNdtDetails } from "../utils/calculate-ndt-inspections";
import getHeadCells from "./ndt-order-welds-table-head-cells";
import { getWeldStatus } from "../../../common/utils/evaluate-weld-status";
import preventRowSelection from "../../../common/utils/prevent-row-selection";
import useRemoveWeldFromNdtOrder from "../../../common/utils/use-remove-weld-from-ndt-order";
import useStoreProvider from "../../../common/providers/store/use-app-context";
import useTablePaginationSettings from "../../../common/utils/use-table-pagination-settings";
import useWeldEventLoggingForm from "../../../common/utils/use-weld-event-logging-form";
import { NDT_METHODS } from "../../../constants";
import messages from "../../../i18n/messages";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
    boxShadow: "none",
    border: "1px solid #e3e3e3",
  },
  table: {
    minWidth: 750,
  },
  tableCell: {
    whiteSpace: "nowrap",
  },
  actions: {
    marginLeft: 0,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  approvalButton: {
    display: "flex",
    width: 35,
    height: 35,
    marginLeft: 16,
    background: "#28b87c20",
    border: "2px solid #28b87c40",
    alignItems: "center",
    placeContent: "center",
    borderRadius: 6,
    transition: "all 0.2s",
    minWidth: "auto",
    padding: 0,
    "&:hover": {
      cursor: "pointer",
      background: "#28b87c30",
      border: "2px solid #28b87c50",
    },
  },
  approvalButtonDisabled: {
    background: "#F7F6F9",
    border: "2px solid #EBEBED",
  },
}));

const NdtOrderWeldsTable = ({ fetching, error, ndtOrder, weldsInNdtOrder, onWeldRemove }) => {
  const classes = useStyles();
  const { pid } = useParams();
  const { setSelectedWeld } = useStoreProvider();
  const { removeWeldsFromNdtOrder } = useRemoveWeldFromNdtOrder(pid);
  const { rowsPerPageOptions, rowsPerPage, setRowsPerPage } =
    useTablePaginationSettings(weldsInNdtOrder);

  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("createdAt.seconds");
  const [selectedWelds, setSelectedWelds] = React.useState([]);
  const [selectedWeldState, setSelectedWeldState] = React.useState(null);
  const [selectedWeldIds, setSelectedWeldIds] = React.useState([]);
  const [ndtLoggingDialogOpen, setNdtLoggingDialogOpen] = React.useState(false);
  const [ndtConfirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const intl = useIntl();

  const dialogMessages = {
    title: intl.formatMessage(messages.removeWeldsFromNdtOrder),
    content: intl.formatMessage(messages.areYouSureYouWantToRemove, {
      count: selectedWeldIds.length,
      weldText:
        selectedWeldIds.length > 1
          ? intl.formatMessage(messages.lowerCaseWelds)
          : intl.formatMessage(messages.lowerCaseWeld),
    }),
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = weldsInNdtOrder.map((n) => n.id);
      setSelectedWeldIds(newSelected);
      setSelectedWelds(weldsInNdtOrder);
      return;
    }
    setSelectedWeldIds([]);
  };

  const handleTableRowClick = (weldId) => {
    const selectedIndex = selectedWeldIds.indexOf(weldId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedWeldIds, weldId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedWeldIds.slice(1));
    } else if (selectedIndex === selectedWeldIds.length - 1) {
      newSelected = newSelected.concat(selectedWeldIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedWeldIds.slice(0, selectedIndex),
        selectedWeldIds.slice(selectedIndex + 1)
      );
    }

    const selectedWelds = weldsInNdtOrder.filter((weld) => newSelected.includes(weld.id));

    setSelectedWeldIds(newSelected);
    setSelectedWelds(selectedWelds);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRemoveWeldsFromNdtOrder = async () => {
    const weldsToRemoveFromNdtOrder = selectedWelds.map((weld) => ({
      weldId: weld.id,
      weldLogId: weld.weldLogId,
      ndtOrderId: ndtOrder?.id,
    }));

    const state = await removeWeldsFromNdtOrder(weldsToRemoveFromNdtOrder);

    if (state.completed) {
      onWeldRemove(weldsToRemoveFromNdtOrder.map((weld) => weld.weldId));
    }

    setSelectedWeldIds([]);
  };

  const handleClose = () => {
    setNdtLoggingDialogOpen(false);
  };

  const handleNdtLoggingDialogOpen = () => {
    setNdtLoggingDialogOpen(true);
  };

  const weldStates = useWeldEventLoggingForm({
    selectedWelds: weldsInNdtOrder,
    weldEventToEdit: null,
    onClickHandler: handleNdtLoggingDialogOpen,
  });

  const isSelected = (name) => selectedWeldIds.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, weldsInNdtOrder.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <GenericTableToolbar
          classes={classes}
          withAddButton={false}
          withDeleteButton={true}
          withTitle={true}
          title={intl.formatMessage(messages.weldsInNdtOrder)}
          dialogMessages={dialogMessages}
          dialogOpen={ndtConfirmDialogOpen}
          setDialogOpen={setConfirmDialogOpen}
          onDialogConfirm={handleRemoveWeldsFromNdtOrder}
          numberOfSelectedItems={selectedWeldIds.length}
        />
        {error ? (
          <StateCard
            title={intl.formatMessage(messages.errorFetchingData)}
            description={error.message || intl.formatMessage(messages.unknownErrorOccurred)}
          />
        ) : fetching ? (
          <LoadingStateCard border={false} />
        ) : (
          <>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <GenericTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  headCells={getHeadCells(intl)}
                  rowCount={weldsInNdtOrder.length}
                  numberOfSelectedItems={selectedWeldIds.length}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(weldsInNdtOrder, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((weldInNdtOrder, index) => {
                      const isItemSelected = isSelected(weldInNdtOrder.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      const { requiredNdtMethods } = extractNdtDetails(ndtOrder);

                      const weldStatus = getWeldStatus(weldInNdtOrder, intl);

                      let weldState = weldStates?.length
                        ? weldStates.find((item) => item.weldId === weldInNdtOrder?.id)
                        : [];

                      const requiresHeatTreatment = weldInNdtOrder?.htRequired;
                      const hasPendingHeatTreatment =
                        !weldInNdtOrder?.heatTreatmentStatus?.done ||
                        weldInNdtOrder?.heatTreatmentStatus?.redo;
                      const weldNotDone = !weldInNdtOrder?.weldStatus?.done;
                      const weldRedo = weldInNdtOrder?.weldStatus?.redo;

                      const isNdtButtonDisabled =
                        weldRedo || (requiresHeatTreatment ? hasPendingHeatTreatment : weldNotDone);

                      const linkToWeldDetails = `/projects/${pid}/weld-logs/${weldInNdtOrder.weldLogId}/welds/${weldInNdtOrder.id}`;

                      return (
                        <TableRow
                          hover
                          key={weldInNdtOrder.id}
                          tabIndex={-1}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          onClick={() => handleTableRowClick(weldInNdtOrder.id)}
                        >
                          <TableCell className={classes.tableCell} padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            id={labelId}
                            scope="row"
                            component={Link}
                            style={{ whiteSpace: "nowrap" }}
                            onClick={() => setSelectedWeld(weldInNdtOrder)}
                            to={linkToWeldDetails}
                          >
                            {weldInNdtOrder.weldNumber}
                          </TableCell>
                          <TableCell className={classes.tableCell} align="left">
                            {weldInNdtOrder.weldLogName}
                          </TableCell>
                          <TableCell className={classes.tableCell} align="left">
                            <Box display="flex" alignItems="baseline">
                              <Indicator state={weldStatus.status} />
                              <Typography variant="body2">{weldStatus.label}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell className={classes.tableCell} align="left">
                            {/* This could be extracted into a separate component */}
                            <Box display="flex" alignItems="baseline">
                              {requiredNdtMethods?.map((method) => {
                                let state = "todo";

                                const ndtResultsForReporting =
                                  weldInNdtOrder?.ndtStatus?.ndtResultsForReporting;

                                if (ndtResultsForReporting) {
                                  if (ndtResultsForReporting[method] === "approved") {
                                    state = "done";
                                  } else if (
                                    (ndtResultsForReporting[method] === "rejected" &&
                                      !weldInNdtOrder?.weldStatus?.redone) ||
                                    (ndtResultsForReporting[method] === "rejected" &&
                                      weldInNdtOrder?.weldStatus?.done === "failed")
                                  ) {
                                    state = "failed";
                                  }
                                }

                                return (
                                  <Box
                                    key={method}
                                    style={{
                                      marginRight: "10px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Indicator state={state} />
                                    <Typography variant="body2" style={{ marginRight: "5px" }}>
                                      {NDT_METHODS[method]?.code}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </Box>
                          </TableCell>
                          <TableCell className={classes.tableCell} align="left">
                            {weldInNdtOrder?.ndtStatus?.ndtComment}
                          </TableCell>
                          <TableCell align="left" padding="checkbox" disabled={true}>
                            <RoleAccess roles={weldState?.buttonProps?.roles || []}>
                              <Tooltip title={intl.formatMessage(messages.manageNdt)}>
                                <span>
                                  <Button
                                    className={`${classes.approvalButton} ${
                                      isNdtButtonDisabled ? classes.approvalButtonDisabled : ""
                                    }`}
                                    onClick={(e) => {
                                      weldState?.buttonProps.onClick();
                                      setSelectedWeld(weldInNdtOrder);
                                      setSelectedWeldState(weldState);
                                      preventRowSelection(e);
                                    }}
                                    disabled={isNdtButtonDisabled}
                                  >
                                    <FcInspection
                                      size={18}
                                      className={classes.approvalButtonIcon}
                                    />
                                  </Button>
                                </span>
                              </Tooltip>
                            </RoleAccess>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell className={classes.tableCell} colSpan={12} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              page={page}
              rowsPerPage={rowsPerPage}
              count={weldsInNdtOrder.length}
              classes={{ actions: classes.actions }}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
        {!!selectedWeldState?.form && (
          <WeldEventLoggingDialog
            open={ndtLoggingDialogOpen}
            Form={LogNdtForm}
            onClose={handleClose}
            onEventLogged={() => {}}
            title={selectedWeldState?.formTitle}
            disabled={selectedWeldState?.disabled}
            usersInvolved={selectedWeldState?.usersInvolved}
          />
        )}
      </Paper>
    </div>
  );
};
export default NdtOrderWeldsTable;
