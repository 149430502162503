import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: spacing(10),
    marginBottom: spacing(3),
    borderRadius: spacing(0.5),
    border: `1px solid ${palette.divider}`,
    padding: `${spacing(0)} !important`,
  },
  cardContent: {
    padding: spacing(3),
    [breakpoints.down("md")]: {
      padding: spacing(2),
    },
  },
  header: {
    minHeight: 57.5,
    padding: spacing(1.5, 4),
    [breakpoints.down("md")]: {
      paddingLeft: spacing(1.5),
      paddingRight: spacing(1.5),
    },
    borderBottom: `1px solid ${palette.divider}`,
  },
}));

export const CustomCard = ({ children, className, header, footer }) => {
  const classes = useStyles();
  const rootClassNames = classNames(classes.root, className);

  return (
    <Paper classes={{ root: rootClassNames }} elevation={0}>
      {header && <Box className={classes.header}>{header}</Box>}
      <CardContent className={classes.cardContent}>{children}</CardContent>
      {footer && <Box className={classes.header}>{footer}</Box>}
    </Paper>
  );
};

CustomCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
};

CustomCard.defaultProps = {
  className: undefined,
  children: null,
  header: null,
  footer: null,
};

export default CustomCard;
