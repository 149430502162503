import React from "react";
import Box from "@material-ui/core/Box";

import Title from "../../components/title/title";
import TemplateList from "./partials/template-list";
import RoleAccess from "../../components/role-access/role-access";
import PageNotFound from "../../components/page-not-found/page-not-found";
import { db, USER_ROLES } from "../../constants";

import { useIntl } from "react-intl";
import messages from '../../i18n/messages';

const TemplateManagement = () => {
  const intl = useIntl();
  const [documentTemplates, setDocumentTemplate] = React.useState([]);
  const [fetching, setFetching] = React.useState(true);
  const customTemplateRef = db.collection("project-templates/customs/english");

  const onCollectionUpdate = (querySnapshot) => {
    const documentTemplates = [];
    setFetching(true);
    querySnapshot.forEach((doc) => {
      const { id, templateName, documentSections, createdBy, createdAt } = doc.data();
      documentTemplates.push({
        id,
        templateName,
        numOfSections: Object.keys(documentSections)?.length || 0,
        documentSections,
        createdBy,
        createdAt
      });
    });

    setDocumentTemplate(documentTemplates);
    setFetching(false);
  };

  React.useEffect(() => {
    customTemplateRef.onSnapshot(onCollectionUpdate);

    setDocumentTemplate([]);
    setFetching(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    (<RoleAccess roles={[USER_ROLES.ADMIN]} fallback={<PageNotFound />}>
      <Box width="100%">
        <Title title={intl.formatMessage(messages.templates)} />
        <TemplateList rows={documentTemplates} fetching={fetching} />
      </Box>
    </RoleAccess>)
  );
};

export default TemplateManagement;
