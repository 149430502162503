import React from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { orderBy, last } from "lodash";
import firebase from "firebase";
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-dnd";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//Custom
import Image from "../../../components/image/image";
import { db, NOTIFICATION_POSITION } from "../../../constants";
import ImageUploadArea from "../../../components/image-upload-area/image-upload-area";
import UploadPreviewList from "../../../components/upload-preview-list/upload-preview-list";
import messages from "../../../i18n/messages";

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    display: "flex",
    touchAction: "none",
    width: "100%",
    height: "100%",
    margin: "1rem auto",
  },
  dropzone: {
    flex: 1,
    height: "750px",
    overflow: "auto",
    borderRadius: spacing(0.6),
    border: "1px dashed rgba(0, 0, 0, 0.1)",
  },
  gridItem: {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    padding: "0.5em",
  },
}));

const WeldDocuments = () => {
  const intl = useIntl();

  const REORDER_SUCCESS_MESSAGE = intl.formatMessage(messages.photosReorderedSuccessfully);
  const REORDER_FAILURE_MESSAGE = intl.formatMessage(messages.somethingWentWrong);

  const classes = useStyles();
  const { pid, weldLogId, weldId } = useParams();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.between("xs", "xs"));
  const matchesSM = useMediaQuery(theme.breakpoints.between("sm", "sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.between("md", "md"));
  const matchesLG = useMediaQuery(theme.breakpoints.between("lg", "lg"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));

  const [weldDocuments, setWeldDocuments] = React.useState([]);
  const [imageList, setImageList] = React.useState([]);

  const acceptedFiles = "image/png, image/jpeg, image/jpg";
  let boxesPerRow, rowHeight;

  if (matchesXL) {
    boxesPerRow = 3;
    rowHeight = 280;
  }
  if (matchesLG) {
    boxesPerRow = 3;
    rowHeight = 280;
  }
  if (matchesMD) {
    boxesPerRow = 3;
    rowHeight = 240;
  }
  if (matchesSM) {
    boxesPerRow = 4;
    rowHeight = 210;
  }
  if (matchesXS) {
    boxesPerRow = 2;
    rowHeight = 210;
  }

  const weldPhotosCollectionRef = db
    .collection("projects")
    .doc(pid)
    .collection("weld-logs")
    .doc(weldLogId)
    .collection("welds")
    .doc(weldId)
    .collection("documents");

  const activeWeldPhotosCollectionRef = db
    .collection("projects")
    .doc(pid)
    .collection("weld-logs")
    .doc(weldLogId)
    .collection("welds")
    .doc(weldId)
    .collection("documents")
    .where("status", "==", "active");

  const changeImageField = React.useCallback(
    (index, parameter, value) => {
      const newArray = [...imageList];
      newArray[index][parameter] = value;
      setImageList(newArray);
    },
    [imageList]
  );

  const getWeldDocuments = (querySnapshot) => {
    let docWeldPhotos = [];
    querySnapshot.forEach((doc) => {
      const { id, createdAt, createdBy, filename, section, storageRef, position } = doc.data();

      docWeldPhotos.push({
        id,
        section,
        createdAt,
        createdBy,
        filename,
        storageRef,
        position,
      });
    });
    setWeldDocuments(orderBy(docWeldPhotos, ["position"], ["asc"]));
  };

  const updateWeldPhotoPositions = async (reorderedWeldPhotos) => {
    const batch = db.batch();
    try {
      reorderedWeldPhotos.forEach((file, index) => {
        batch.update(weldPhotosCollectionRef.doc(file.id), { position: index });
      });
      await batch.commit();
      toast.success(REORDER_SUCCESS_MESSAGE, {
        position: NOTIFICATION_POSITION,
      });
    } catch (error) {
      console.error("Error while ordering weld photos::", error);
      toast.error(REORDER_FAILURE_MESSAGE, {
        position: NOTIFICATION_POSITION,
      });
    }
  };

  /**
   * Reorder position of photo list item in a grid
   * @param {string} sourceId not required
   * @param {string} sourceIndex
   * @param {string} targetIndex
   * @param {string} targetId not required
   */
  const handleOrderChange = (_sourceId, sourceIndex, targetIndex, _targetId) => {
    const reorderedWeldPhotos = swap(weldDocuments, sourceIndex, targetIndex);
    updateWeldPhotoPositions(reorderedWeldPhotos);
    setWeldDocuments(reorderedWeldPhotos);
  };

  // TODO: Change image upload into hook (DRY)
  /**
   * Add weld photo related document (meta data) when photo upload is completed
   */
  const addFileRelatedDocument = React.useCallback(
    (image, documentId, position) => {
      const documentInfo = {
        createdAt: firebase.firestore.Timestamp.now(),
        createdBy: "",
        updatedAt: null,
        updatedBy: null,
        filename: image?.fileName,
        status: "active",
        position: position,
      };

      weldPhotosCollectionRef
        .doc(`${documentId}`)
        .set({
          ...documentInfo,
          id: documentId,
          createdAt: firebase.firestore.Timestamp.now(),
          storageRef: `documents/${pid}/weld-logs/${weldLogId}/welds/${weldId}/${image?.file.name}`,
        })
        .then(() => {
          console.log("Document created");
        })
        .catch((error) => {
          console.log("Error::", error);
        });
    },
    [weldPhotosCollectionRef, pid, weldId, weldLogId]
  );

  React.useEffect(() => {
    const maxPosition = last(weldDocuments)?.position;

    imageList.forEach((image, index) => {
      if (image.status === "UPLOADED" || image.status === "UPLOADING") {
        return;
      }

      const documentId = weldPhotosCollectionRef.doc().id;
      image.storageRef = firebase
        .storage()
        .ref()
        .child(`documents/${pid}/weld-logs/${weldLogId}/welds/${weldId}/${image.file.name}`);

      changeImageField(index, "status", "UPLOADING");

      let position = maxPosition > 0 ? maxPosition + index : index;
      const uploadTask = image.storageRef.put(image?.file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = parseInt((snapshot.bytesTransferred * 100) / snapshot.totalBytes);
          changeImageField(index, "progress", progress);
        },
        (error) => {
          throw error;
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadUrl) => {
            changeImageField(index, "status", "UPLOADED");
            changeImageField(index, "downloadUrl", downloadUrl);
            addFileRelatedDocument(image, documentId, position);
          });
        }
      );
    });
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    // return () => imageList.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [
    weldId,
    weldLogId,
    imageList,
    pid,
    weldPhotosCollectionRef,
    changeImageField,
    addFileRelatedDocument,
    weldDocuments,
  ]);

  React.useEffect(() => {
    activeWeldPhotosCollectionRef.onSnapshot(getWeldDocuments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      <ImageUploadArea
        acceptedFiles={acceptedFiles}
        setImageList={setImageList}
        imageList={imageList}
        ProgressThumbnails={() => <UploadPreviewList imageList={imageList} />}
      />
      <GridContextProvider onChange={handleOrderChange}>
        <div className={classes.container}>
          <GridDropZone
            className={classes.dropzone}
            boxesPerRow={boxesPerRow}
            rowHeight={rowHeight}
          >
            {weldDocuments.map((item, index) => (
              <GridItem key={item?.position}>
                <Image
                  index={index}
                  fileData={item}
                  documentRef={weldPhotosCollectionRef.doc(item?.id)}
                  documentId={item?.id}
                  showActionBar
                  showOpenMenu
                  showRenameMenu
                  showDeleteMenu
                />
              </GridItem>
            ))}
          </GridDropZone>
        </div>
      </GridContextProvider>
    </Box>
  );
};

export default WeldDocuments;
