import React from "react";
import { filter } from "lodash";
import { useIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, withRouter, Link, Route } from "react-router-dom";

// Material UI
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// Components
import AccountMenu from "../account-menu/account-menu";
import AppMenu from "../app-menu/app-menu";
import RoleAccess from "../role-access/role-access";

// Utilities and constants
import { USER_ROLES } from "../../constants";
import { logout } from "../../common/utils/auth";
import messages from "../../i18n/messages";
import logo from "../../assets/logo-1.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(4),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  menu: {
    anchor: "pointer",
    textDecoration: "none",
    marginLeft: theme.spacing(2),
    color: theme.palette.secondary.contrastText,
  },
  verticalDivider: {
    background: "#0079db",
  },
}));

function useProjectId() {
  const location = useLocation();
  // This regex matches /projects/ followed by any character sequence that is not a slash
  const match = location.pathname.match(/\/projects\/([^/]+)/);
  return match ? match[1] : null;
}

const Header = ({ authenticated }) => {
  const classes = useStyles();
  const pid = useProjectId();

  const projectRoutes = [
    `/projects/${pid}`,
    `/projects/${pid}/weld-logs`,
    `/projects/${pid}/documents`,
    `/projects/${pid}/ndt-orders`,
    `/projects/${pid}/reports`,
    `/projects/${pid}/trash-bin`,
  ];

  const intl = useIntl();

  const authButtons = authenticated ? (
    <Box display="flex">
      <RoleAccess roles={[USER_ROLES.ADMIN]}>
        <AppMenu />
      </RoleAccess>
      <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
      <AccountMenu logout={logout} />
    </Box>
  ) : (
    <Box display="flex">
      <Link className={classes.menu} to="/login">
        {intl.formatMessage(messages.login)}
      </Link>
    </Box>
  );

  const projectMenus = authenticated && pid && (
    <Route
      path="/"
      render={(history) => {
        const currentRoute = filter(projectRoutes, (route) =>
          history.location.pathname.includes(route)
        ).pop();

        return (
          <Tabs value={currentRoute} indicatorColor="primary" className={classes.title}>
            <Tab
              component={Link}
              label={intl.formatMessage(messages.overview)}
              value={`/projects/${pid}`}
              to={`/projects/${pid}`}
            />
            <Tab
              component={Link}
              label={intl.formatMessage(messages.documents)}
              value={`/projects/${pid}/documents`}
              to={`/projects/${pid}/documents`}
            />
            <Tab
              component={Link}
              label={intl.formatMessage(messages.weldLogs)}
              value={`/projects/${pid}/weld-logs`}
              to={`/projects/${pid}/weld-logs`}
            />
            <Tab
              component={Link}
              label={intl.formatMessage(messages.ndtOrders)}
              value={`/projects/${pid}/ndt-orders`}
              to={`/projects/${pid}/ndt-orders`}
            />
            <Tab
              component={Link}
              label={intl.formatMessage(messages.reports)}
              value={`/projects/${pid}/reports`}
              to={`/projects/${pid}/reports`}
            />
            {/* Disabled until the trash bin concept is sorted out in v2
            <Tab
              component={Link}
              label="Trash bin"
              value={`/projects/${pid}/trash-bin`}
              to={`/projects/${pid}/trash-bin`}
            /> */}
          </Tabs>
        );
      }}
    />
  );

  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={0}>
        <Toolbar>
          <Box component={Link} to="/" style={{ display: "flex", alignItems: "center" }}>
            <img src={logo} width={130} alt="logo" />
          </Box>
          <Container component="main" maxWidth="lg" className={classes.container}></Container>
          {authButtons}
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="lg" className={classes.container}>
        {projectMenus}
      </Container>
      <Divider />
    </div>
  );
};

export default withRouter(Header);
