import React from "react";
import firebase from "firebase";
import PropTypes from "prop-types";
import LazyLoad from "react-lazy-load";

// Material
import { makeStyles } from "@material-ui/core/styles";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";

// Src
import DocumentActionMenu from "../document-action-menu/document-action-menu";
import EditDocumentNameDialog from "../edit-document-name-dialog/edit-document-name-dialog";
import SinglePagePdfViewer from "../pdf-viewer/single-page-pdf-viewer";
import useUpdateDocument from "../../common/utils/use-update-document";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  imageListItem: {
    display: "flex",
    overflow: "hidden",
    listStyle: "none",
    borderRadius: spacing(0.6),
    backgroundColor: "#eeeeee",
    [breakpoints.down("xl")]: {
      width: 200,
      height: 270,
    },
    [breakpoints.down("lg")]: {
      width: 200,
      height: 270,
    },
    [breakpoints.down("md")]: {
      width: 140,
      height: 200,
    },
    [breakpoints.down("sm")]: {
      width: 150,
      height: 200,
    },
    [breakpoints.down("xs")]: {
      width: 140,
      height: 200,
    },
    "& .MuiImageListItem-item": {
      width: "100%",
    },
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  titleBar: {
    background: "rgb(0, 0, 0, 0.5)",
  },
}));

const Image = ({
  index,
  fileData,
  onDocumentSelect,
  documentRef,
  showActionBar,
  showOpenMenu = false,
  showRenameMenu = false,
  showDeleteMenu = false,
  showLinkMenu = false,
}) => {
  const classes = useStyles();
  const [fileUrl, setFileUrl] = React.useState("");
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [thumbnailUrl, setThumbnailUrl] = React.useState("");
  const [editDocumentDialogState, setEditDocumentDialogState] = React.useState(false);

  const getImageDownloadUrl = React.useCallback(async () => {
    if (!fileData?.storageRef && !fileData.thumbnailStorageRef) return;

    if (!fileData.thumbnailStorageRef) {
      fileData.thumbnailStorageRef = fileData?.storageRef;
    }

    const url = await firebase.storage().ref().child(fileData.storageRef).getDownloadURL();

    const thumbnailUrl = await firebase
      .storage()
      .ref()
      .child(fileData.thumbnailStorageRef)
      .getDownloadURL();

    setFileUrl(url);
    setThumbnailUrl(thumbnailUrl);
  }, [fileData]);

  const { updateDocument } = useUpdateDocument();
  const handleEditDocumentDialogClose = (value) => {
    if (value) {
      updateDocument({ documentRef, payload: { filename: value } });
    }
    setEditDocumentDialogState(false);
  };

  const handleEditDocumentDialogOpen = () => {
    setEditDocumentDialogState(true);
  };

  const isPdf = !fileData?.thumbnailStorageRef && fileData?.storageRef?.split(".").pop() === "pdf";

  React.useEffect(() => {
    getImageDownloadUrl();
  }, [getImageDownloadUrl]);

  return (
    <ImageListItem key={index} classes={{ root: classes.imageListItem }}>
      {isPdf ? (
        <SinglePagePdfViewer pdf={fileUrl} />
      ) : (
        <LazyLoad height="100%" width="100%">
          {/* Used absolute positioned div to allow drag-n-drop on image containers. It did not work on img. */}
          <>
            <div
              style={{
                position: "absolute",
                background: "rgb(0, 0, 0, 0.1)",
                height: "100%",
                width: "100%",
              }}
            ></div>
            <>
              {!isLoaded && (
                <img
                  src="/images/image-placeholder.png"
                  alt="Placeholder"
                  className={classes.image}
                />
              )}
              <img
                src={thumbnailUrl}
                alt={fileData.filename}
                className={`${classes.image} ${!isLoaded ? classes.hidden : ""}`}
                onLoad={() => setIsLoaded(true)}
              />
            </>
          </>
        </LazyLoad>
      )}
      <ImageListItemBar
        title={fileData.filename}
        classes={{
          root: classes.titleBar,
          title: classes.title,
        }}
        actionIcon={
          showActionBar && (
            <DocumentActionMenu
              imageUrl={fileUrl}
              fileData={fileData}
              documentRef={documentRef}
              showOpenMenu={showOpenMenu}
              showLinkMenu={showLinkMenu}
              showRenameMenu={showRenameMenu}
              showDeleteMenu={showDeleteMenu}
              onDocumentSelect={onDocumentSelect}
              onEditMenuItemClick={handleEditDocumentDialogOpen}
            />
          )
        }
      />
      <EditDocumentNameDialog
        open={editDocumentDialogState}
        onClose={handleEditDocumentDialogClose}
      />
    </ImageListItem>
  );
};

Image.propTypes = {
  fileData: PropTypes.object.isRequired,
  handleDocumentSelect: PropTypes.func,
  showActionBar: PropTypes.bool,
};

Image.defaultProps = {
  handleDocumentSelect: () => {},
  showActionBar: false,
};

export default Image;
