import { createTheme } from "@material-ui/core/styles";

var primary = {
  50: "#E4F2FE",
  100: "#BCE0FB",
  200: "#90CBF9",
  300: "#64B6F7",
  400: "#42A6F5",
  500: "rgb(0, 122, 255)",
  600: "#1D8EF1",
  700: "#1883EF",
  800: "#1479ED",
  900: "#0B68EA",
  A100: "#FFFFFF",
  A200: "#E1ECFF",
  A400: "#AECCFF",
  A700: "#95BCFF",
  contrastDefaultColor: "light",
};
var secondary = {
  100: "#CFD8DC",
  200: "#B0BEC5",
  300: "#90A4AE",
  400: "#78909C",
  500: "#607D8B",
  600: "#546E7A",
  700: "#455A64",
  800: "#37474F",
  900: "#263238",
  A100: "#ffffff",
  A200: "#ffffff",
  A400: "#ffffff",
  A700: "#ffffff",
  contrastDefaultColor: "light",
};
var error = {
  50: "#fce5e4",
  100: "#f7beba",
  200: "#f2938d",
  300: "#ed685f",
  400: "#e9473c",
  500: "#e5271a",
  600: "#e22317",
  700: "#de1d13",
  800: "#da170f",
  900: "#d30e08",
  A100: "#fffcfc",
  A200: "#ffcac9",
  A400: "#ff9896",
  A700: "#ff7f7d",
  contrastDefaultColor: "light",
};
var warn = {
  50: "#FCF5E7",
  100: "#F8E7C3",
  200: "#F4D79B",
  300: "#F0C772",
  400: "#ECBB54",
  500: "#E9AF36",
  600: "#E6A830",
  700: "#E39F29",
  800: "#DF9622",
  900: "#D98616",
  A100: "#FFFFFF",
  A200: "#FFEDD7",
  A400: "#FFD5A4",
  A700: "#FFCA8B",
};
var success = {
  50: "#F0FAE4",
  100: "#D8F2BC",
  200: "#BFE990",
  300: "#A5E064",
  400: "#91DA42",
  500: "#4EB70B",
  600: "#76CE1D",
  700: "#6BC818",
  800: "#61C214",
  900: "#4EB70B",
  A100: "#EDFFE4",
  A200: "#CBFFB1",
  A400: "#AAFF7E",
  A700: "#99FF64",
};

var typography = {
  headline: {
    fontWeight: 600,
    fontSize: "1.6875rem",
    lineHeight: "2.1rem",
    letterSpacing: "-0.021rem",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: '"Poppins", sans-serif',
  },
  titleBig: {
    fontWeight: 600,
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
    color: "rgba(0, 0, 0, 0.87)",
    letterSpacing: "-0.014rem",
    fontFamily: '"Poppins", sans-serif',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: "1.2rem",
    lineHeight: "1.5rem",
    color: "rgba(0, 0, 0, 0.87)",
    letterSpacing: "-0.011rem",
    fontFamily: '"Poppins", sans-serif',
  },
  body: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1.25rem",
    letterSpacing: "-0.006rem",
    fontFamily: '"Poppins", sans-serif',
  },
  body1: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1.25rem",
    letterSpacing: "-0.006rem",
    fontFamily: '"Poppins", sans-serif',
  },
  bodyLight: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1.25rem",
    letterSpacing: "-0.006rem",
    fontFamily: '"Poppins", sans-serif',
  },
  bodyStrong: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.875rem",
    fontWeight: 600,
    lineHeight: "1.25rem",
    letterSpacing: "-0.006rem",
    fontFamily: '"Poppins", sans-serif',
  },
  caption: {
    color: "rgba(0, 0, 0, 0.55)",
    letterSpacing: 0,
    lineHeight: 1.66,
    fontSize: "0.75rem",
    fontWeight: 400,
    fontFamily: '"Poppins", sans-serif',
  },
  overline: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.75rem",
    fontFamily: '"Poppins", sans-serif',
    lineHeight: 2.66,
    fontWeight: 400,
    letterSpacing: "0.08333rem",
    textTransform: "uppercase",
  },
  button: {
    textTransform: "camelcase",
  },
};

const primaryColors = {
  extraLight: primary[50],
  light: primary[300],
  main: primary[500],
  dark: primary[700],
  extraDark: primary[900],
};

const secondaryColors = {
  extraLight: secondary[50],
  light: secondary[300],
  main: secondary[800],
  dark: secondary[700],
  extraDark: secondary[900],
};

const theme = createTheme({
  palette: {
    primary: { ...primaryColors },
    secondary: { ...secondaryColors },
    error: { ...error },
    warn: { ...warn },
    success: { ...success },
  },
  spacing: 8,
  shape: {
    borderRadius: 3,
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    ...typography,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1430,
      xl: 1920,
    },
  },
});
export default theme;
