import React from "react";
import Box from "@material-ui/core/Box";

import Title from "../../components/title/title";
import UsersList from "./partials/users-list";
import Registration from "./partials/register";
import RoleAccess from "../../components/role-access/role-access";
import PageNotFound from "../../components/page-not-found/page-not-found";
import { db, USER_ROLES } from "../../constants";

import { useIntl } from "react-intl";
import messages from "../../i18n/messages";

const UserManagement = () => {
  const intl = useIntl();

  const [usersList, setUsersList] = React.useState([]);
  const [fetching, setFetching] = React.useState(true);

  React.useEffect(() => {
    const userInfoCollectionRef = db
      .collection("user-info")
      .orderBy("fname", "asc")
      .where("active", "==", true);

    const unsubscribe = userInfoCollectionRef.onSnapshot(
      (querySnapshot) => {
        const users = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsersList(users);
        setFetching(false);
      },
      (error) => {
        console.error("Failed fetching user data:", error);
        setFetching(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <RoleAccess roles={[USER_ROLES.ADMIN]} fallback={<PageNotFound />}>
      <Box width="100%">
        <Title title={intl.formatMessage(messages.users)} />
        <Registration />
        <UsersList rows={usersList} fetching={fetching} />
      </Box>
    </RoleAccess>
  );
};

export default UserManagement;

