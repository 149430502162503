import React from "react";
import Slider from "react-slick";
import { useIntl } from "react-intl";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

// Assets
import slideItem1 from "../../assets/login-slide-item-1.svg";
import slideItem2 from "../../assets/login-slide-item-2.svg";

// Constants
import messages from "../../i18n/messages";

const useStyles = makeStyles(({ spacing, palette }) => ({
  contentWrapper: {
    display: "flex !important",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: spacing(2),
  },
  textContentWrapper: {
    display: "flex",
    width: "90%",
    flexDirection: "column",
    alignItems: "left",
    marginTop: spacing(2),
    padding: spacing(1.5),      
  },
  imageWrapper: {
    width: "90%",
  },
  title: {
    color: "white",
    fontSize: spacing(3),
  },
  caption: {
    color: "white",
  },
}));

const SimpleSlider = () => {
  const classes = useStyles();

  const settings = {
    dots: true,
    fade: true,
    speed: 500,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
  };

  const intl = useIntl();

  return (
    <Slider {...settings}>
      <Box className={classes.contentWrapper}>
        <Box className={classes.imageWrapper}>
          <img src={slideItem1} alt="Slider item" width="100%" />
        </Box>
        <Box className={classes.textContentWrapper}>
          <Typography gutterBottom className={classes.title}>
            {intl.formatMessage(messages.realTimeDocumentation)}
          </Typography>
          <Typography variant="body2" gutterBottom className={classes.caption}>
            {intl.formatMessage(messages.docweldEnables)}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.contentWrapper}>
        <Box className={classes.imageWrapper}>
          <img src={slideItem2} alt="Slider item" width="100%" />
        </Box>
        <Box className={classes.textContentWrapper}>
          <Typography gutterBottom className={classes.title}>
            {intl.formatMessage(messages.followProjectProgress)}
          </Typography>
          <Typography variant="body2" gutterBottom className={classes.caption}>
            {intl.formatMessage(messages.followProjectProgress)}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.contentWrapper}>
        <Box className={classes.imageWrapper}>
          <img src={slideItem1} alt="Slider item" width="100%" />
        </Box>
        <Box className={classes.textContentWrapper}>
          <Typography gutterBottom className={classes.title}>
            {intl.formatMessage(messages.easyReport)}
          </Typography>
          <Typography variant="body2" gutterBottom className={classes.caption}>
            {intl.formatMessage(messages.withDocweld)}
          </Typography>
        </Box>
      </Box>
    </Slider>
  );
};

export default SimpleSlider;
