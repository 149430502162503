import React from "react";
import { IntlProvider } from "react-intl";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Switch } from "react-router-dom";

// Material UI
import CircularProgress from "@material-ui/core/CircularProgress";

// Components
import ProtectedRoute from "../routes/protected-routes/protected-routes";
import PublicRoute from "../routes/public-routes/public-routes";
import PageContainer from "../../components/page-container/page-container";
import Notification from "../../components/notifications/notification";
import PageNotFound from "../../components/page-not-found/page-not-found";

// Page components
import MaterialManagement from "../../pages/material-management/material-management";
import Projects from "../../pages/project-list/projects";
import ProjectAddForm from "../../pages/project-list/partials/project-add-form";
import ProjectDetails from "../../pages/project-details/project-details-wrapper";
import ProjectDocuments from "../../pages/project-documents/project-documents";
import WeldLogList from "../../pages/weld-log-list/weld-logs";
import AddWeldLog from "../../pages/weld-log-list/partials/add-weld-log";
import WeldLogDetails from "../../pages/weld-log-details/weld-log-details";
import AddWeld from "../../pages/weld-log-details/partials/add-weld";
import EditWeldForm from "../../pages/weld-log-details/partials/edit-weld";
import WeldDetails from "../../pages/weld-details/weld-details";
import NdtOrders from "../../pages/ndt-orders/ndt-orders";
import NdtOrderForm from "../../pages/ndt-orders/partials/ndt-order-form";
import NdtOrderDetails from "../../pages/ndt-orders/partials/ndt-order-details";
import TrashBin from "../../pages/trash-bin/trash-bin";
import Login from "../../pages/login/login";
import ResetPassword from "../../pages/reset-password/reset-password";
import UserManagement from "../../pages/user-management/user-management";
import TemplateManagement from "../../pages/template-management/template-management";
import UserProfileSettings from "../../pages/user-profile/user-profile";
import EditUserProfile from "../../pages/user-profile/partials/edit-user-pro";
import CompanyProfile from "../../pages/company-profile/company-profile";
import EditCompanyProfile from "../../pages/company-profile/partials/edit-company-profile";
import ProjectReports from "../../pages/project-reports/report-list";

// Hooks
import useStoreProvider from "../../common/providers/store/use-app-context";

// Utilities and constants
import enMessages from "../../i18n/locales/en.json";
import dkMessages from "../../i18n/locales/dk.json";
import { db, firebaseAuth } from "../../constants";

// Styles
import "../../App.css";

const App = React.memo(() => {
  const [loading, setLoading] = React.useState(true);
  const [authStatus, setAuthStatus] = React.useState(false);

  const { setLoggedInUser, loggedInUser } = useStoreProvider();

  const getUserInformation = React.useCallback(
    async (user) => {
      const extraUserInfoDoc = await db.collection("user-info").doc(user?.uid).get();

      if (extraUserInfoDoc.exists) {
        const loggedInUserInfo = {
          uid: user.uid,
          email: user.email,
          userRole: user.userRole,
          metadata: user.metadata,
          ...extraUserInfoDoc.data(),
        };
        setLoggedInUser(loggedInUserInfo);
        return loggedInUserInfo;
      }
    },
    [setLoggedInUser]
  );

  const authenticateUser = React.useCallback(() => {
    firebaseAuth().onAuthStateChanged(async (user) => {
      if (user) {
        const loggedInUserInfo = await getUserInformation(user);
        if (loggedInUserInfo.active) {
          setAuthStatus(true);
          setLoading(false);
        } else {
          setAuthStatus(false);
          setLoading(false);
        }
      } else {
        setAuthStatus(false);
        setLoading(false);
      }
    });
  }, [getUserInformation]);

  React.useEffect(() => {
    authenticateUser();
  }, [authenticateUser]);

  const messages = {
    en: enMessages,
    dk: dkMessages,
  };

  return (
    <IntlProvider
      locale={loggedInUser?.language || "en"}
      messages={messages[loggedInUser?.language] || messages["en"]}
    >
      <ToastContainer />
      {loading ? (
        <Router>
          <PageContainer contentAlignment="center">
            <CircularProgress size={50} />
          </PageContainer>
        </Router>
      ) : (
        <Router>
          <PageContainer authenticated={authStatus}>
            <Switch>
              <ProtectedRoute exact path="/" component={Projects} authenticated={authStatus} />
              <ProtectedRoute
                exact
                path="/projects/add"
                component={ProjectAddForm}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/projects/:pid"
                component={ProjectDetails}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/projects/:pid/edit"
                component={ProjectAddForm}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/projects/:pid/weld-logs"
                component={WeldLogList}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/projects/:pid/documents"
                component={ProjectDocuments}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/projects/:pid/trash-bin"
                component={TrashBin}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/projects/:pid/weld-logs/add"
                component={AddWeldLog}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/projects/:pid/weld-logs/:weldLogId"
                component={WeldLogDetails}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/projects/:pid/weld-logs/:weldLogId/edit"
                component={AddWeldLog}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/projects/:pid/weld-logs/:weldLogId/welds/add"
                component={AddWeld}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/projects/:pid/weld-logs/:weldLogId/welds/:weldId/edit"
                component={EditWeldForm}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/projects/:pid/weld-logs/:weldLogId/welds/:weldId"
                component={WeldDetails}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/projects/:pid/reports"
                component={ProjectReports}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/projects/:pid/ndt-orders"
                component={NdtOrders}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/projects/:pid/ndt-orders/add"
                component={NdtOrderForm}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/projects/:pid/ndt-orders/:ndtOrderId/details"
                component={NdtOrderDetails}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/material-management"
                component={MaterialManagement}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/user/profile"
                component={UserProfileSettings}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/user/profile/edit"
                component={EditUserProfile}
                authenticated={authStatus}
              />
              <ProtectedRoute
                path="/user-management"
                component={UserManagement}
                authenticated={authStatus}
              />
              <ProtectedRoute
                path="/template-management"
                component={TemplateManagement}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/company-setting"
                component={CompanyProfile}
                authenticated={authStatus}
              />
              <ProtectedRoute
                exact
                path="/company-setting/edit"
                component={EditCompanyProfile}
                authenticated={authStatus}
              />
              <PublicRoute path="/login" component={Login} authenticated={authStatus} />
              <PublicRoute
                path="/reset-password"
                component={ResetPassword}
                authenticated={authStatus}
              />
              <PublicRoute component={PageNotFound} />
            </Switch>
            <Notification />
          </PageContainer>
        </Router>
      )}
    </IntlProvider>
  );
});

export default App;
