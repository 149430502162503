import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

// Material UI
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

// Icons
import { MdOutlineDelete } from "react-icons/md";

// Components
import LoadingStateCard from "../../../components/loading-state-card/loading-state-card";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import RoleAccess from "../../../components/role-access/role-access";

// Hooks
import useArchiveProject from "../../../common/utils/archive-project";
import useStoreProvider from "../../../common/providers/store/use-app-context";
import useTablePaginationSettings from "../../../common/utils/use-table-pagination-settings";

// Utilities and constants
import { formatDate } from "../../../common/utils/format-date";
import ProjectActionMenu from "./project-action-menu";
import { USER_ROLES } from "../../../constants";
import messages from "../../../i18n/messages";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function ProjectListTableHead({
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) {
  const intl = useIntl();

  const headCells = [
    {
      id: "projectName",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.projectName),
    },
    {
      id: "projectNumber",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.projectNumber),
    },
    {
      id: "customer",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.customer),
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.description),
    },
    {
      id: "lastActivity",
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage(messages.lastActivity),
    },
    {
      id: "actions",
      numeric: false,
      label: "",
      align: "right",
    },
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: "#e9e9e9" }}>
      <TableRow>
        <TableCell className={classes.tableCell} padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": intl.formatMessage(messages.selectAllProjects) }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tableCell}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ProjectListTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1",
  },
  addButton: {
    padding: theme.spacing(0.5, 4),
  },
  menuIcon: {
    fontSize: theme.spacing(3),
    color: theme.palette.secondary.dark,
  },
}));

const ProjectListTableToolbar = ({
  selectedProjectIds,
  numSelected,
  buttonProps,
  projectListType,
  setSelected,
}) => {
  const intl = useIntl();
  const classes = useToolbarStyles();
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  const { archiveProjects } = useArchiveProject(selectedProjectIds);

  const showAddButton = projectListType === "active";

  const handleDeleteProject = () => {
    archiveProjects();
    setSelected([]);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} variant="subtitle1" component="div">
          {numSelected}
          {intl.formatMessage(messages.selected)}
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      )}
      {numSelected > 0 ? (
        <RoleAccess roles={[USER_ROLES.ADMIN]}>
          <Tooltip title={intl.formatMessage(messages.delete)}>
            <IconButton
              aria-label={intl.formatMessage(messages.delete)}
              onClick={() => setConfirmOpen(true)}
            >
              <MdOutlineDelete className={classes.menuIcon} />
            </IconButton>
          </Tooltip>
        </RoleAccess>
      ) : (
        showAddButton && (
          <RoleAccess roles={[USER_ROLES.ADMIN]}>
            <Button
              className={classes.addButton}
              disableElevation
              component={Link}
              to={buttonProps.to}
              {...buttonProps}
            >
              {intl.formatMessage(messages.add)}
            </Button>
          </RoleAccess>
        )
      )}
      <Divider />
      <ConfirmDialog
        title={intl.formatMessage(messages.deleteProject)}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDeleteProject}
      >
        {`${intl.formatMessage(messages.areYouSureToDeleteSelected)} ${numSelected} ${
          numSelected > 1
            ? intl.formatMessage(messages.lowerCaseProjects)
            : intl.formatMessage(messages.lowerCaseProject)
        }?`}
      </ConfirmDialog>
    </Toolbar>
  );
};

ProjectListTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
    boxShadow: "none",
    border: "1px solid #e3e3e3",
  },
  table: {
    minWidth: 750,
  },
  tableCell: {
    whiteSpace: "nowrap",
  },
  actions: {
    marginLeft: 0,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function ProjectListTable({ rows, buttonProps, fetching, projectListType }) {
  const intl = useIntl();
  const classes = useStyles();
  const { rowsPerPageOptions, rowsPerPage, setRowsPerPage } = useTablePaginationSettings(rows);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (weldId) => {
    const selectedIndex = selected.indexOf(weldId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, weldId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const { setSelectedProject } = useStoreProvider();
  return (
    <div className={classes.root}>
      {fetching ? (
        <LoadingStateCard />
      ) : (
        <Paper className={classes.paper}>
          <ProjectListTableToolbar
            selectedProjectIds={selected}
            numSelected={selected.length}
            buttonProps={buttonProps}
            projectListType={projectListType}
            setSelected={setSelected}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <ProjectListTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                projectListType={projectListType}
              />

              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const projectData = rows.find((item) => item.id === row.id);

                    return (
                      <TableRow
                        hover
                        key={row.id}
                        tabIndex={-1}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        onClick={() => handleClick(row.id)}
                      >
                        <TableCell className={classes.tableCell} padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          id={labelId}
                          scope="row"
                          component={Link}
                          onClick={() => setSelectedProject(projectData)}
                          to={`/projects/${projectData.id}`}
                        >
                          {row.projectName}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {row.projectNumber}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {row.customer}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {row.description || (
                            <Typography>{intl.formatMessage(messages.na)}</Typography>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {!row?.latestActivity?.doneAt && (
                            <Typography>{intl.formatMessage(messages.na)}</Typography>
                          )}
                          {row?.latestActivity?.doneAt &&
                            formatDate(row?.latestActivity?.doneAt, intl)}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          align="left"
                          style={{ width: 50 }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <RoleAccess roles={[USER_ROLES.ADMIN]}>
                            <ProjectActionMenu projectData={projectData} />
                          </RoleAccess>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell className={classes.tableCell} colSpan={12} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            classes={{ actions: classes.actions }}
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  );
}
