import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { concat } from "lodash";

import useStoreProvider from "../../common/providers/store/use-app-context";

const hasRequiredRole = (roles, currentRoles) =>
  currentRoles.some((role) => roles?.includes(role));

const cloneElementWithSiblings = (el, { className, ...props }) =>
  React.Children.map(el, (child) =>
    React.cloneElement(child, {
      className: clsx(className, child.props.className),
      ...props,
    })
  );

const getLoggedInUserRoles = (loggedInUser, projectParticipants) => {
  const loggedInUserProjectParticipant = projectParticipants?.find(
    (projectParticipant) => {
      return projectParticipant?.userInfo?.uid === loggedInUser.uid;
    }
  );

  return loggedInUserProjectParticipant?.roles || [];
};

export const RoleAccess = ({ children, fallback, roles, ...props }) => {
  const { loggedInUser, projectParticipants } = useStoreProvider();

  const rolesAsProjectParticipant = React.useMemo(
    () => getLoggedInUserRoles(loggedInUser, projectParticipants),
    [loggedInUser, projectParticipants]
  );

  const currentRoles = React.useMemo(() => {
    const baseRoles = loggedInUser?.userRole || ["user"];
    return concat(baseRoles, rolesAsProjectParticipant);
  }, [loggedInUser, rolesAsProjectParticipant]);

  const hasRole = React.useMemo(
    () => hasRequiredRole(roles, currentRoles),
    [roles, currentRoles]
  );

  return hasRole
    ? cloneElementWithSiblings(children, props)
    : cloneElementWithSiblings(fallback, props);
};

RoleAccess.propTypes = {
  roles: PropTypes.array.isRequired,
  children: PropTypes.node,
  fallback: PropTypes.node,
};

RoleAccess.defaultProps = {
  children: null,
  fallback: null,
};

export default RoleAccess;
