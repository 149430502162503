import React from "react";

/**
 * Hook to get collection
 * @returns 
 */
const useFetchCollection = () => {
  const getCollection = React.useCallback(async (collectionRef) => {
    const selectedCollection = await collectionRef.get();    
    if (!selectedCollection.empty) {
      return selectedCollection;
    }

    return {};
  }, []);

  return { getCollection };
};

export default useFetchCollection