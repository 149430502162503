import React from "react";
import firebase from "firebase";
import { toast } from "react-toastify";
import { object, array, string } from "yup";
import { useLocation } from "react-router-dom";
import { useFormik, Field, FieldArray, FormikProvider } from "formik";
import { isEmpty, sortBy, range, flatten, pickBy, keys, intersection } from "lodash";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

// Icons
import IconButton from "@material-ui/core/IconButton";
import LinkIcon from "@material-ui/icons/Link";
import { HiOutlineDocumentText } from "react-icons/hi";
import { MdOutlineDelete } from "react-icons/md";

// Components
import CustomCard from "../../../components/custom-card/custom-card";
import Title from "../../../components/title/title";
import InputAutocomplete from "../../../components/input-autocomplete/input-autocomplete";
import DocumentLinkDialog from "../../../components/document-link-dialog/document-link-dialog";
import AddMaterialDailog from "../../material-management/partials/add-material-dialog";
import AddFillerMaterialForm from "../../material-management/partials/add-filler-material-form";
import AddParentMaterialForm from "../../material-management/partials/add-parent-material-form";
import FormFooter from "../../../components/form-footer/form-footer";

// Hooks
import useStoreProvider from "../../../common/providers/store/use-app-context";

// Utilities and constants
import { db, NOTIFICATION_POSITION, WELD_EVENT_TYPES } from "../../../constants";
import { findMissingNumbers } from "../../../common/utils/find-missing-numbers";

import { useIntl } from "react-intl";
import messages from "../../../i18n/messages";

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  form: {
    width: "100%",
    marginTop: spacing(5),
  },
  formContainer: {
    marginBottom: spacing(3),
  },
  gridItem: {
    [breakpoints.down("md")]: {
      padding: `${spacing(0, 4)} !important`,
    },
  },
  documentIcon: {
    fontSize: spacing(2.4),
  },
  menuIcon: {
    fontSize: spacing(2.5),
    color: palette.secondary.dark,
  },
}));

const AddWeld = () => {
  const classes = useStyles();
  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openMaterialDialog, setOpenMaterialDialog] = React.useState(false);
  const [parentMaterials, setParentMaterials] = React.useState([]);
  const [fillerMaterials, setFillerMaterials] = React.useState([]);
  const [materialForm, setMaterialForm] = React.useState(null);
  const [shouldFetchMaterials, setShouldFetchMaterials] = React.useState(false);
  const [addMultipleWelds, setAddMultipleWelds] = React.useState(false);
  const [existingWeldNumbers, setExistingWeldNumbers] = React.useState([]);
  const [maxExistingWeldNumber, setMaxExistingWeldNumber] = React.useState(0);

  const intl = useIntl();

  const [numberFromLabel, setNumberFromLabel] = React.useState(
    intl.formatMessage(messages.weldNumber)
  );
  const [weldNumberFrom, setWeldNumberFrom] = React.useState(null);
  const [weldNumberTo, setWeldNumberTo] = React.useState(null);
  const [indexOfDocumentToLink, setIndexOfDocumentToLink] = React.useState();
  const emptyPayload = {
    id: "",
    fms: [],
    pms: [],
    wps: "",
    remark: "",
    position: "",
    description: "",
    status: "active",
    weldNumberTo: "",
    htRequired: false,
    weldNumberFrom: "",
    linkedDocuments: [{ name: "", storageRef: "" }],
  };
  const [initialValues, setInitialValues] = React.useState(emptyPayload);

  const { selectedProject, selectedWeldLog, loggedInUser } = useStoreProvider();
  const { parentMaterialTraceable, fillerMaterialTraceable } = selectedProject || {};

  const validationSchema = object().shape({
    weldNumberFrom: string().required(intl.formatMessage(messages.weldNumberIsRequired)),
    pms: parentMaterialTraceable
      ? array().min(1, intl.formatMessage(messages.atLeastOneParentMaterialRequired))
      : null,
    fms: fillerMaterialTraceable
      ? array().min(1, intl.formatMessage(messages.atLeastOneFillerMaterialRequired))
      : null,
  });

  const location = useLocation();
  const weldData = location?.state?.weldData ? location?.state?.weldData : {};
  const errorText = intl.formatMessage(messages.somethingWentWrong);
  const projectDocumentRef = db.collection("projects").doc(selectedProject?.id);
  const weldLogDocumentRef = db
    .collection("projects")
    .doc(selectedProject?.id)
    .collection("weld-logs")
    .doc(selectedWeldLog?.id);

  const weldCollectionRef = db
    .collection("projects")
    .doc(selectedProject?.id)
    .collection("weld-logs")
    .doc(selectedWeldLog?.id)
    .collection("welds");

  const parentMaterialCollectionRef = db
    .collection("materials")
    .doc("parent-materials")
    .collection("items");

  const fillerMaterialCollectionRef = db
    .collection("materials")
    .doc("filler-materials")
    .collection("items");

  let weldId = weldCollectionRef.doc().id;

  const handleMaterialDialogOpen = (value) => {
    setOpenMaterialDialog(true);
    setMaterialForm(value);
    setShouldFetchMaterials(false);
  };

  const handleMaterialDialogClose = () => {
    setOpenMaterialDialog(false);
    setShouldFetchMaterials(true);
  };

  const handleClickOpen = (documentIndex) => {
    // Get index of the document to update its content with document name and storageRef
    setIndexOfDocumentToLink(documentIndex);
    setOpen(true);
  };

  const handleClose = (value, _id) => {
    setOpen(false);
    if (value) {
      formik.values.linkedDocuments[indexOfDocumentToLink] = value;
    }
  };

  /**
   * Get all welds to get the weld numbers and use them for weld number
   * validations.
   */
  const getAllWelds = React.useCallback(async () => {
    const weldsSnapshot = await weldCollectionRef.get();
    const weldsData = weldsSnapshot.docs.map((doc) => doc.data());

    let existingWeldNumbers = weldsData.map((weld) => {
      return weld.weldNumber;
    });

    existingWeldNumbers = sortBy(flatten(existingWeldNumbers));
    setExistingWeldNumbers(existingWeldNumbers);

    const maxExistingWeldNumber = existingWeldNumbers.length ? Math.max(...existingWeldNumbers) : 0;

    setMaxExistingWeldNumber(maxExistingWeldNumber);
    setInitialValues({
      ...initialValues,
      weldNumberFrom: maxExistingWeldNumber + 1,
      remark: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Get list of parent materials used in parent material selection
   */
  const getParentMaterials = React.useCallback(async () => {
    const alloyCollectionSnapshot = await db
      .collection("materials")
      .doc("parent-materials")
      .collection("items")
      .get();

    const parentMaterialsData = alloyCollectionSnapshot.docs.map((doc) => doc.data());

    const parentMaterials = parentMaterialsData.map((pm) => ({
      value: `${pm.id}`,
      label: `${pm.alloy} | ${pm.type} | ${pm.dimension} ${pm.thickness}`,
    }));

    setParentMaterials(parentMaterials);
  }, []);

  /**
   * Get list of filler materials used in filler material selection
   */
  const getFillerMaterials = React.useCallback(async () => {
    const alloyCollectionSnapshot = await db
      .collection("materials")
      .doc("filler-materials")
      .collection("items")
      .get();

    const fillerMaterialsData = alloyCollectionSnapshot.docs.map((doc) => doc.data());

    const fillerMaterials = fillerMaterialsData.map((fm) => ({
      value: `${fm.id}`,
      label: `${fm.name}`,
    }));

    setFillerMaterials(fillerMaterials);
  }, []);

  /**
   * Update label of weldNumber text input based on the number of
   * welds to be added
   */
  const handleMultipleWeldSelection = (event) => {
    setAddMultipleWelds(event.target.checked);
    if (event.target.checked === true) {
      setNumberFromLabel(intl.formatMessage(messages.weldNumberFrom));
    } else {
      setNumberFromLabel(intl.formatMessage(messages.weldNumber));
    }
  };

  /**
   * Validate weld number value when entering single or multiple weld numbers
   * @param {string} value
   * @returns error
   */
  const validateWeldNumberFrom = (value) => {
    let error;
    value = typeof value !== "string" ? Number(value) : value;

    setWeldNumberFrom(value);

    // Empty weld number
    if (!value) {
      error = intl.formatMessage(messages.weldNumberCannotBeEmpty, {
        nextWeldNumber: maxExistingWeldNumber + 1,
      });
    }

    // Single weld number input check
    if (existingWeldNumbers.includes(Number(value))) {
      error = intl.formatMessage(messages.weldNumberAlreadyAdded, {
        value: value,
        nextWeldNumber: maxExistingWeldNumber + 1,
      });
    }

    if (addMultipleWelds) {
      const missingWeldNumbers = findMissingNumbers(existingWeldNumbers);
      if (existingWeldNumbers.includes(Number(value)) && missingWeldNumbers.length) {
        error = intl.formatMessage(messages.weldNumberAlreadyAddedMissingWeldNumbers, {
          value: value,
          missingNumbers: missingWeldNumbers,
          nextWeldNumber: maxExistingWeldNumber + 1,
        });
      }
    }

    // Range weld number input check
    if (!addMultipleWelds && typeof value === "string" && value.includes("-")) {
      const weldNumberFrom = value.split("-")[0];
      const weldNumberTo = value.split("-")[1];
      if (
        existingWeldNumbers.includes(Number(weldNumberFrom)) ||
        existingWeldNumbers.includes(Number(weldNumberTo))
      ) {
        error = intl.formatMessage(messages.rangeWeldNumberError, {
          nextWeldNumber: maxExistingWeldNumber + 1,
        });
      }
    }

    return error;
  };

  function generateNumberString(numbers) {
    // If the array is empty
    if (numbers.length === 0) return "";

    // If there's only one number in the array
    if (numbers.length === 1) return String(numbers[0]);

    return numbers.slice(0, -1).join(", ") + ", and " + numbers[numbers.length - 1];
  }

  /**
   * Validate weld number to value
   * @param {*} value
   * @returns
   */
  const validateWeldNumberTo = (value) => {
    let error;
    setWeldNumberTo(value);
    const newRange = range(weldNumberFrom, weldNumberTo + 1);
    const missingWeldNumbers = findMissingNumbers(existingWeldNumbers);
    const alreadyUsedWeldNumbers = intersection(existingWeldNumbers, newRange);

    if (value <= weldNumberFrom) {
      error = intl.formatMessage(messages.valueGreaterThanRangeStart);
    } else if (existingWeldNumbers.includes(Number(value)) && missingWeldNumbers.length) {
      error = intl.formatMessage(messages.weldNumberAlreadyAddedMissingWeldNumbers, {
        value: value,
        missingNumbers: missingWeldNumbers,
        nextWeldNumber: maxExistingWeldNumber + 1,
      });
    } else if (alreadyUsedWeldNumbers.length) {
      error = intl.formatMessage(messages.weldNumbersAlreadyAdded, {
        count: alreadyUsedWeldNumbers.length,
        alreadyUsedNumbers: generateNumberString(alreadyUsedWeldNumbers),
      });
    }

    return error;
  };

  // When editing existing weld, assign weldData to initialValues.
  // Currently editing is not needed.
  if (!isEmpty(weldData)) {
    setInitialValues(weldData);
    weldId = weldData?.id;
  }

  // Get required NDTs, which are with values other than 0
  let requiredNdts = keys(pickBy(selectedWeldLog?.ndtRequirements, (value, key) => value !== 0));

  // Set the status of all required NDTs to toBeInspected
  requiredNdts = requiredNdts.reduce(function (acc, curr) {
    acc[curr] = "toBeInspected";
    return acc;
  }, {});

  const handleSubmit = () => {
    formik.submitForm();
  };

  const successMessage = intl.formatMessage(messages.weldCreatedSuccessfully);

  // Handle submit either as batch or single operation.
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const batch = db.batch();
      let weldNumbersToAdd = [];

      setSubmitting(true);
      setSubmitted(false);
      // setErrorMessage();

      if (addMultipleWelds) {
        weldNumbersToAdd = range(weldNumberFrom, weldNumberTo + 1);
      } else {
        weldNumbersToAdd.push(weldNumberFrom);
      }

      const logCreationEvent = {
        what: WELD_EVENT_TYPES.weldCreated,
        when: firebase.firestore.Timestamp.now(),
        loggedAt: firebase.firestore.Timestamp.now(),
        doneAt: firebase.firestore.Timestamp.now(),
        doneBy: {
          fname: loggedInUser.fname,
          lname: loggedInUser.lname,
        },
        loggedBy: `${loggedInUser.fname} ${loggedInUser.lname}`,
      };

      try {
        weldNumbersToAdd.forEach((weldNumber) => {
          weldId = weldCollectionRef.doc().id;
          batch.set(weldCollectionRef.doc(`${weldId}`), {
            weldNumber: Number(weldNumber),
            parentMaterials: values?.pms,
            fillerMaterials: values?.fms,
            linkedDocuments: values?.linkedDocuments,
            htRequired: values?.htRequired,
            remark: values?.remark,
            status: values?.status || "active",
            position: values?.position,
            createdByUid: loggedInUser.uid,
            createdByFname: loggedInUser.fname,
            createdByLname: loggedInUser.lname,
            weldStatus: { done: false },
            heatTreatmentStatus: { done: false },
            weldLogId: selectedWeldLog?.id,
            ndtStatus: { done: "toBeInspected", ndtResults: requiredNdts },
            events: [logCreationEvent],
            // Update following lines once edit functionality is not needed no more.
            ...(isEmpty(weldData) && {
              createdAt: firebase.firestore.Timestamp.now(),
            }),
            ...(!isEmpty(weldData) && {
              updatedAt: firebase.firestore.Timestamp.now(),
            }),
            ...(isEmpty(weldData) && {
              id: weldId,
            }),
          });
        });
      } catch (error) {
        setSubmitted(false);
        setSubmitting(false);
        setErrorMessage(errorText);
        return;
      }

      const activitiesDocumentRef = projectDocumentRef.collection("activities").doc();

      // Update project with latest event
      batch.update(projectDocumentRef, {
        latestActivity: {
          ...logCreationEvent,
          weldLogId: selectedWeldLog?.id,
          weldId,
        },
      });

      // Update weld log with latest weld event
      batch.update(weldLogDocumentRef, {
        latestActivity: {
          ...logCreationEvent,
          weldLogId: selectedWeldLog?.id,
          weldId,
        },
      });

      // Add weld event to activities collection of a project
      batch.set(activitiesDocumentRef, {
        ...logCreationEvent,
        weldLogId: selectedWeldLog?.id,
        weldId,
      });

      batch
        .commit()
        .then(() => {
          resetForm();
          // Reset not functioning as expected hence resetting initial values
          setInitialValues(emptyPayload);
          const existingWeldsNumbers = [...existingWeldNumbers, ...weldNumbersToAdd];
          const maxNumber = Math.max(...existingWeldsNumbers);
          setExistingWeldNumbers(existingWeldsNumbers);
          setMaxExistingWeldNumber(maxNumber);
          setSubmitting(false);
          setSubmitted(true);
          toast.success(successMessage, {
            position: NOTIFICATION_POSITION,
          });
        })
        .catch((error) => {
          setSubmitting(false);
          setSubmitted(false);
          setErrorMessage(errorText);
          toast.error(errorMessage, {
            position: NOTIFICATION_POSITION,
          });
        });
    },
  });

  React.useEffect(() => {
    getAllWelds();
    getParentMaterials();
    getFillerMaterials();
  }, [getAllWelds, getFillerMaterials, getParentMaterials, shouldFetchMaterials]);

  const CustomWpsInput = ({ formik }) => (
    <FieldArray
      name="linkedDocuments"
      render={(arrayHelpers) => (
        <div>
          {formik.values.linkedDocuments?.length > 0 &&
            formik.values.linkedDocuments.map((ld, index) => {
              const color = formik.values.linkedDocuments[index].name
                ? "#000"
                : "rgba(0, 0, 0, 0.38)";
              return (
                <div className="row" key={index}>
                  <TextField
                    fullWidth
                    margin="normal"
                    name={`linkedDocuments.[${index}].name`}
                    autoComplete="off"
                    label={intl.formatMessage(messages.linkedWPS)}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    InputProps={{
                      style: { color: color },
                      endAdornment: (
                        <>
                          <InputAdornment position="end">
                            <IconButton onClick={() => handleClickOpen(index)}>
                              <LinkIcon color="primary" />
                            </IconButton>
                          </InputAdornment>
                          <Divider orientation="vertical" flexItem />
                          <InputAdornment position="end">
                            <IconButton onClick={() => arrayHelpers.remove(index)}>
                              <MdOutlineDelete className={classes.menuIcon} />
                            </IconButton>
                          </InputAdornment>
                        </>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <HiOutlineDocumentText className={classes.documentIcon} />
                        </InputAdornment>
                      ),
                    }}
                    disabled
                    style={{ color: color }}
                    onChange={formik.handleChange}
                    value={ld.name ? ld.name : intl.formatMessage(messages.noDocumentLinked)}
                  />
                </div>
              );
            })}
          <Button
            color="primary"
            variant="contained"
            disableElevation
            className={classes.submit}
            onClick={() => arrayHelpers.push({ name: "", storageRef: "" })}
          >
            {intl.formatMessage(messages.add)}
          </Button>

          <DocumentLinkDialog open={open} onClose={handleClose} />
        </div>
      )}
    />
  );

  let notification =
    addMultipleWelds && !submitting && !submitted && !errorMessage
      ? intl.formatMessage(messages.weldNumbersWillBeAutomaticallyGenerated)
      : "";

  return (
    <Box width="100%">
      <Title title={intl.formatMessage(messages.addWeld)} />
      <CustomCard
        header={
          <FormControlLabel
            control={
              <Switch
                id="multipleWelds"
                name="multipleWelds"
                checked={addMultipleWelds}
                onChange={handleMultipleWeldSelection}
              />
            }
            label={intl.formatMessage(messages.multiple)}
          />
        }
        footer={
          <FormFooter
            submitting={submitting}
            submitted={submitted}
            submitButtonText={intl.formatMessage(messages.addWeld)}
            cancelButtonText={intl.formatMessage(messages.close)}
            handleSubmit={handleSubmit}
            cancelButtonProps={{
              to: `/projects/${selectedProject?.id}/weld-logs/${selectedWeldLog?.id}`,
            }}
            progressMessage={intl.formatMessage(messages.creatingWeld)}
            successMessage={intl.formatMessage(messages.weldCreatedSuccessfully)}
            errorMessage={errorMessage}
            notification={notification}
          />
        }
      >
        <FormikProvider value={formik}>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <Grid container spacing={5} className={classes.formContainer}>
              <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={4}>
                <Field
                  fullWidth
                  autoFocus
                  name="weldNumberFrom"
                  autoComplete="off"
                  value={formik.values.weldNumberFrom}
                  onChange={formik.handleChange}
                  validate={validateWeldNumberFrom}
                >
                  {({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="number"
                      margin="normal"
                      label={numberFromLabel}
                    />
                  )}
                </Field>
                {formik.errors.weldNumberFrom && formik.touched.weldNumberFrom && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.weldNumberFrom}
                  </div>
                )}
                {addMultipleWelds && (
                  <Field
                    fullWidth
                    autoFocus
                    name="weldNumberTo"
                    autoComplete="off"
                    value={formik.values.weldNumberTo}
                    onChange={formik.handleChange}
                    validate={validateWeldNumberTo}
                  >
                    {({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="number"
                        margin="normal"
                        label={intl.formatMessage(messages.weldNumberTo)}
                      />
                    )}
                  </Field>
                )}
                {addMultipleWelds && formik.errors.weldNumberTo && formik.touched.weldNumberTo && (
                  <div style={{ color: "red", fontSize: "12px" }}>{formik.errors.weldNumberTo}</div>
                )}
                {!addMultipleWelds && (
                  <TextField
                    fullWidth
                    margin="normal"
                    label={intl.formatMessage(messages.position)}
                    name="position"
                    autoComplete="off"
                    value={formik.values.position}
                    onChange={formik.handleChange}
                    error={formik.touched.position && Boolean(formik.errors.position)}
                    helperText={formik.touched.position && formik.errors.position}
                  />
                )}
              </Grid>
              <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={4}>
                <Field
                  name="pms"
                  component={InputAutocomplete}
                  options={parentMaterials}
                  textFieldProps={{
                    fullWidth: true,
                    label: intl.formatMessage(messages.parentMaterial),
                    margin: "normal",
                    variant: "standard",
                  }}
                  onClickHandler={handleMaterialDialogOpen}
                  materialRegForm="parentMaterialForm"
                  label={intl.formatMessage(messages.createParentMaterial)}
                  multiple
                />
                {/* <CustomParentMaterialInput formik={formik} /> */}
                <Field
                  name="fms"
                  component={InputAutocomplete}
                  options={fillerMaterials}
                  textFieldProps={{
                    fullWidth: true,
                    label: intl.formatMessage(messages.fillerMaterial),
                    margin: "normal",
                    variant: "standard",
                  }}
                  onClickHandler={handleMaterialDialogOpen}
                  materialRegForm="fillerMaterialForm"
                  label={intl.formatMessage(messages.createFillerMaterial)}
                  multiple
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={12} sm={6} md={4} lg={4}>
                <CustomWpsInput formik={formik} />
                <TextField
                  fullWidth
                  margin="normal"
                  label={intl.formatMessage(messages.remark)}
                  name="remark"
                  autoComplete="off"
                  value={formik.values.remark}
                  onChange={formik.handleChange}
                  error={formik.touched.remark && Boolean(formik.errors.remark)}
                  helperText={formik.touched.remark && formik.errors.remark}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                />
                <FormControlLabel
                  control={
                    <Switch
                      id="htRequired"
                      name="htRequired"
                      checked={formik.values?.htRequired}
                      onChange={formik.handleChange}
                    />
                  }
                  label={intl.formatMessage(messages.heatTreatment)}
                />
              </Grid>
            </Grid>
          </form>
        </FormikProvider>
      </CustomCard>
      <AddMaterialDailog
        open={openMaterialDialog}
        onClose={handleMaterialDialogClose}
        Form={materialForm === "parentMaterialForm" ? AddParentMaterialForm : AddFillerMaterialForm}
        title={
          materialForm === "parentMaterialForm"
            ? intl.formatMessage(messages.createParentMaterial)
            : intl.formatMessage(messages.createFillerMaterial)
        }
        collectionRef={
          materialForm === "parentMaterialForm"
            ? parentMaterialCollectionRef
            : fillerMaterialCollectionRef
        }
      />
    </Box>
  );
};

export default AddWeld;
