import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import ReportSectionSelectionForm from "./report-section-selection-form";

const useStyles = makeStyles(({ breakpoints }) => ({
  dialogPaper: {
    [`${breakpoints.up("sm")}`]: {
      minWidth: 700,
    },
  },
}));

function ReportGenerationDialog({ open, onClose, generateReport, setIsGeneratingReport }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullScreen={fullScreen}
      classes={{ paper: classes.dialogPaper }}
    >
        <ReportSectionSelectionForm
          onClose={handleCancel}
          generateReport={generateReport}
          setIsGeneratingReport={setIsGeneratingReport}
        />
    </Dialog>
  );
}

ReportGenerationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ReportGenerationDialog;
