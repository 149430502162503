import React from "react";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(({ spacing, palette }) => ({
  indicator: {
    width: spacing(1),
    height: spacing(1),
    borderRadius: "100%",
    marginRight: spacing(1.2),
  },
  todo: {
    background: palette.secondary.light,
  },
  progressing: {
    background: palette.info.main,
  },
  done: {
    background: palette.success.main,
  },
  toRedo: {
    background: palette.warning.main,
  },
  failed: {
    background: palette.error.light,
  },
}));

const Indicator = ({ state }) => {
  const classes = useStyles();

  if (state === "rejected") state = "failed";
  if (state === "approved") state = "done";

  return <div className={clsx(classes.indicator, classes[state])}></div>;
};

export default Indicator;
