import { useContext } from "react";
import { NotificationContext } from "./notification-provider";

function useNotification() {
  const { notification, addNotification, removeNotification } =
    useContext(NotificationContext);
  return { notification, addNotification, removeNotification };
}

export default useNotification;
