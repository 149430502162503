import React from "react";
import { useIntl } from "react-intl";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

// Icons
import { HiOutlineBadgeCheck, HiOutlineExclamationCircle } from "react-icons/hi";
import { CgOpenCollective } from "react-icons/cg";
import { TbProgressCheck } from "react-icons/tb";

// Constants and utilities
import messages from "../../../i18n/messages";
import { NDT_ORDER_STATUSES } from "../../../constants";

const useStyles = makeStyles(({ spacing, palette }) => ({
  icon: {
    fontSize: spacing(2.5),
    marginLeft: spacing(0.5),
  },
  approved: { color: "#4EB70B" },
  rejected: { color: "#FF0000" },
  inProgress: { color: palette.warning.main },
  waitingInspection: { color: palette.primary.light },
  compiling: { color: palette.secondary.light },
  statusChip: {
    display: "flex",
    alignItems: "center",
  },
}));

const ICON_MAP = {
  compiling: CgOpenCollective,
  waitingInspection: CgOpenCollective,
  approved: HiOutlineBadgeCheck,
  rejected: HiOutlineExclamationCircle,
  inProgress: TbProgressCheck,
};

const NdtInspectionStatus = ({ ndtOrder }) => {
  const intl = useIntl();
  const classes = useStyles();
  const Icon = ICON_MAP[ndtOrder?.ndtOrderStatus];
  const status = NDT_ORDER_STATUSES[ndtOrder?.ndtOrderStatus];  
  const label = status ? intl.formatMessage(messages[status.status]) : "";
  const className = `${classes.icon} ${classes[ndtOrder?.ndtOrderStatus]}`;

  return status ? (
    <Box display="flex" alignItems="baseline">
      <Box className={classes.statusChip}>
        <Icon className={className} />
        <Typography variant="body2">{label}</Typography>
      </Box>
    </Box>
  ) : null;
};

export default NdtInspectionStatus;
