import * as React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { RiMenuAddFill } from "react-icons/ri";
import { FiSave } from "react-icons/fi";

import { useIntl } from "react-intl";
import messages from "../../../i18n/messages";

const useStyles = makeStyles(({ spacing, palette }) => ({
  menuList: {
    minWidth: 180,
    paddingTop: 0,
    paddingBottom: 0,
  },
  small: {
    width: spacing(4),
    height: spacing(4),
  },
  large: {
    width: spacing(7),
    height: spacing(7),
  },
  nameContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  menuItemHeight: {
    minHeight: 45,
  },
  menuItemPadding: {
    padding: 15,
  },
  menuIconSmaller: {
    fontSize: spacing(2.3),
    marginRight: spacing(2),
    color: palette.secondary.dark,
  },
}));

const DocumentActionMenu = ({ handleClickAddSectionItem, handleSaveAsSectionItemClick }) => {
  const intl = useIntl();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleAddSectionClick = () => {
    handleClickAddSectionItem();
    handleClose();
  };

  const handleSaveAsTemplateClick = () => {
    handleSaveAsSectionItemClick();
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        getContentAnchorEl={null}
        PaperProps={{
          elevation: 0,
          style: {
            marginTop: 5,
            minWidth: 250,
            boxShadow: "none",
            border: "1px solid #e3e3e3",
          },
        }}
        classes={{ list: classes.menuList }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem classes={{ root: classes.menuItemHeight }} onClick={handleAddSectionClick}>
          <RiMenuAddFill className={classes.menuIconSmaller} />
          {intl.formatMessage(messages.addSection)}
        </MenuItem>

        <MenuItem classes={{ root: classes.menuItemHeight }} onClick={handleSaveAsTemplateClick}>
          <FiSave className={classes.menuIconSmaller} />
          {intl.formatMessage(messages.saveAsTemplate)}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default DocumentActionMenu;
