import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import LogWeldForm from "../../pages/weld-details/partials/log-weld-form";
import LogNdtForm from "../../pages/weld-details/partials/log-ndt-form";
import AddWeldToNdtOrderForm from "../../pages/weld-details/partials/add-weld-to-ndt-order-form";
import LogHeatTreatmentForm from "../../pages/weld-details/partials/log-heat-treatment-form";
import useFetchProjectParticipants from "./use-fetch-project-participants";
import { USER_ROLES } from "../../constants";

import messages from "../../i18n/messages";

const useWeldEventLoggingForm = ({ selectedWelds, weldEventToEdit, onClickHandler }) => {
  const intl = useIntl();
  const { pid } = useParams();
  const [weldStates, setWeldStates] = useState([]);

  const { isLoading, welders, ndtOperators, htOperators } = useFetchProjectParticipants(pid);

  const getInitialState = () => ({
    form: LogWeldForm,
    usersInvolved: welders,
    formTitle: "Log weld",
    buttonProps: {
      color: "primary",
      variant: "contained",
      label: intl.formatMessage(messages.logWeld),
      disabled: false,
      roles: [
        USER_ROLES.ADMIN,
        USER_ROLES.WELDING_COORDINATOR,
        USER_ROLES.RESPONSIBLE_WELDING_COORDINATOR,
        USER_ROLES.PROJECT_LEADER,
        USER_ROLES.WELDER,
      ],
      onClick: onClickHandler,
      showInWeldDetailsPage: true,
    },
  });

  const determineStateForSelectedWeld = (selectedWeld) => {
    const state = getInitialState();

    const isOnWeldStep = !selectedWeld?.weldStatus?.done || selectedWeld?.weldStatus?.redo;
    const isOnHeatTreatmentStep =
      !isOnWeldStep &&
      selectedWeld?.htRequired &&
      (!selectedWeld?.heatTreatmentStatus?.done || selectedWeld?.heatTreatmentStatus?.redo);
    const isWeldProcessCompleted = selectedWeld?.htRequired
      ? selectedWeld?.weldStatus?.done && selectedWeld?.heatTreatmentStatus?.done
      : selectedWeld?.weldStatus?.done;
    const isAddedToNdtOrder = !!selectedWeld?.ndtOrder;

    const eventConfig = {
      "Weld logged": {
        form: LogWeldForm,
        formTitle: intl.formatMessage(messages.editWeldLog),
        usersInvolved: welders,
      },
      "Heat treatment logged": {
        formTitle: intl.formatMessage(messages.editHeatTreatmentLog),
        form: LogHeatTreatmentForm,
        usersInvolved: htOperators,
      },
      "NDT logged": {
        formTitle: intl.formatMessage(messages.editNdtLog),
        form: LogNdtForm,
        usersInvolved: ndtOperators,
      },
      "Added to NDT order": {
        formTitle: intl.formatMessage(messages.editNdtOrder),
        form: AddWeldToNdtOrderForm,
        usersInvolved: [],
      },
    };

    if (weldEventToEdit && eventConfig[weldEventToEdit?.what]) {
      Object.assign(state, eventConfig[weldEventToEdit?.what]);
    } else if (isOnWeldStep) {
      // The initial state is already set for weld step, so no changes needed here.
    } else if (isOnHeatTreatmentStep) {
      Object.assign(state, {
        form: LogHeatTreatmentForm,
        usersInvolved: htOperators,
        formTitle: intl.formatMessage(messages.logHeatTreatment),
        buttonProps: {
          ...state.buttonProps,
          label: intl.formatMessage(messages.logHeatTreatment),
          roles: [
            USER_ROLES.ADMIN,
            USER_ROLES.WELDING_COORDINATOR,
            USER_ROLES.RESPONSIBLE_WELDING_COORDINATOR,
            USER_ROLES.PROJECT_LEADER,
            USER_ROLES.HEAT_TREATMENT_OPERATOR,
          ],
          showInWeldDetailsPage: true,
        },
      });
    } else if (isWeldProcessCompleted && isAddedToNdtOrder) {
      Object.assign(state, {
        form: LogNdtForm,
        usersInvolved: ndtOperators,
        formTitle: intl.formatMessage(messages.logNdt),
        buttonProps: {
          ...state.buttonProps,
          label: intl.formatMessage(messages.logNdt),
          roles: [
            USER_ROLES.ADMIN,
            USER_ROLES.WELDING_COORDINATOR,
            USER_ROLES.RESPONSIBLE_WELDING_COORDINATOR,
            USER_ROLES.PROJECT_LEADER,
            USER_ROLES.NDT_OPERATOR,
          ],
          showInWeldDetailsPage: false,
        },
      });
    } else {
      state.form = null;
      state.buttonProps.showInWeldDetailsPage = false;
    }

    return {
      ...state,
      isWeldProcessCompleted,
      weldId: selectedWeld?.id,
    };
  };

  useEffect(() => {
    if (isLoading) return;

    const newWeldStates = selectedWelds.map(determineStateForSelectedWeld);
    setWeldStates(newWeldStates);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, weldEventToEdit, selectedWelds]);

  return weldStates;
};

export default useWeldEventLoggingForm;
