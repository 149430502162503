import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

// Component
import CustomCard from "../../../components/custom-card/custom-card";
import RoleAccess from "../../../components/role-access/role-access";

// Constants and utilities
import messages from "../../../i18n/messages";
import { USER_ROLES } from "../../../constants";
import { formatDate } from "../../../common/utils/format-date";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  container: {
    display: "flex",
    [breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  editButton: {
    padding: spacing(0.5, 4),
  },
  gridItem: {
    padding: spacing(2),
    height: "100%",
    borderRadius: spacing(1),
    background: "rgb(242 246 251)",
  },
  projectDetailItems: {
    flex: "1 1 0px",
  },
  activityTimeline: {
    minHeight: "40VH",
    maxHeight: "70VH",
    overflow: "scroll",
  },
}));

export default function ProjectDetails({ projectData }) {
  const intl = useIntl();
  const classes = useStyles();

  const ProjectDetailsHeader = () => {
    return (
      <Box className={classes.header}>
        <Typography variant="subtitle1">{intl.formatMessage(messages.projectDetails)}</Typography>
        <RoleAccess
          roles={[
            USER_ROLES.ADMIN,
            USER_ROLES.PROJECT_LEADER,
            USER_ROLES.WELDING_COORDINATOR,
            USER_ROLES.RESPONSIBLE_WELDING_COORDINATOR,
          ]}
        >
          <Button
            className={classes.editButton}
            color="primary"
            variant="contained"
            disableElevation
            component={Link}
            to={{
              pathname: `/projects/${projectData.id}/edit`,
              state: { projectData },
            }}
          >
            {intl.formatMessage(messages.edit)}
          </Button>
        </RoleAccess>
      </Box>
    );
  };

  return (
    <CustomCard header={<ProjectDetailsHeader />}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Paper elevation={0} className={classes.gridItem}>
            <Grid container spacing={2}>
              <Grid container item spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    {intl.formatMessage(messages.projectName)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">{projectData.projectName}</Typography>
                </Grid>
              </Grid>

              <Grid container item spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    {intl.formatMessage(messages.projectNumber)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {projectData.projectNumber || intl.formatMessage(messages.na)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    {intl.formatMessage(messages.createdAt)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">{formatDate(projectData.createdAt, intl)}</Typography>
                </Grid>
              </Grid>

              {projectData.updatedAt && (
                <Grid container item spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {intl.formatMessage(messages.updatedAt)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      {formatDate(projectData.updatedAt, intl)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Paper elevation={0} className={classes.gridItem}>
            <Grid container spacing={2}>
              <Grid container item spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="caption">{intl.formatMessage(messages.customer)}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {projectData.customer || intl.formatMessage(messages.na)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    {intl.formatMessage(messages.reference)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {projectData.externalReference || intl.formatMessage(messages.na)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    {intl.formatMessage(messages.description)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {projectData.description || intl.formatMessage(messages.na)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Paper elevation={0} className={classes.gridItem}>
            <Grid container spacing={2}>
              <Grid container item spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    {intl.formatMessage(messages.parentMaterialTraceable)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {projectData.parentMaterialTraceable
                      ? intl.formatMessage(messages.yes)
                      : intl.formatMessage(messages.no)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    {intl.formatMessage(messages.fillerMaterialTraceable)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {projectData.fillerMaterialTraceable
                      ? intl.formatMessage(messages.yes)
                      : intl.formatMessage(messages.no)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </CustomCard>
  );
}
