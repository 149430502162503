import React from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

// Material UI
import Box from "@material-ui/core/Box";
import Title from "../../components/title/title";
import StateCard from "../../components/state-card/state-card";
import LoadingStateCard from "../../components/loading-state-card/loading-state-card";
import NdtOrdersTable from "./partials/ndt-orders-table";

// Hooks
import useFetchCollection from "../../common/utils/use-fetch-collection-v2";
import useFetchDocument from "../../common/utils/use-fetch-document-v2";
import useStoreProvider from "../../common/providers/store/use-app-context";

// Constants and utilities
import { db } from "../../constants";
import messages from "../../i18n/messages";

const NdtOrders = () => {
  const intl = useIntl();
  const { pid } = useParams();
  const { selectedProject, setSelectedProject } = useStoreProvider();
  const { fetching, data: ndtOrders, error, listenToCollectionUpdates } = useFetchCollection();

  const projectDocumentRef = React.useMemo(() => db.collection("projects").doc(pid), [pid]);
  const ndtOrderCollectionRef = React.useMemo(
    () => db.collection("projects").doc(pid).collection("ndt-orders").orderBy("createdAt", "desc"),
    [pid]
  );

  const { data: projectData, getDocument: fetchProjectDetail } = useFetchDocument();

  const breadcrumbsNav = [
    { label: "Projects", to: "/" },
    { label: selectedProject?.projectName, to: `/projects/${pid}` },
    { label: "NDT Orders", to: `/projects/${pid}/ndt-orders` },
  ];

  const buttonProps = {
    color: "primary",
    variant: "contained",
    label: "Create NDT Order",
    to: `/projects/${pid}/ndt-orders/add`,
  };

  React.useEffect(() => {
    const unsubscribe = listenToCollectionUpdates(ndtOrderCollectionRef);
    return () => unsubscribe();
  }, [listenToCollectionUpdates, ndtOrderCollectionRef]);

  React.useEffect(() => {
    if (!selectedProject) {
      fetchProjectDetail(projectDocumentRef);
      setSelectedProject(projectData);
    }
  }, [fetchProjectDetail, projectData, projectDocumentRef, selectedProject, setSelectedProject]);
  return (
    <>
      {fetching ? (
        <LoadingStateCard />
      ) : error ? (
        <StateCard
          title={intl.formatMessage(messages.noNdtOrderYet)}
          description={error?.errorMessage || intl.formatMessage(messages.anUnknownErrorOccurred)}
        />
      ) : (
        <Box width="100%">
          {ndtOrders && ndtOrders.length ? (
            <>
              <Title
                title={intl.formatMessage(messages.ndtOrders)}
                breadcrumbsNav={breadcrumbsNav}
              />
              <NdtOrdersTable ndtOrders={ndtOrders} fetching={fetching} buttonProps={buttonProps} />
            </>
          ) : (
            <StateCard
              title={intl.formatMessage(messages.noNdtOrderYet)}
              description={intl.formatMessage(messages.startByCreatingNDT)}
              buttonProps={buttonProps}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default NdtOrders;
