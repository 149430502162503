import { useState, useEffect, useCallback } from "react";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { db } from "../../../constants";
import { useIntl } from "react-intl";
import messages from '../../../i18n/messages';

const useFetchWeldsAndLogs = (ndtOrder) => {
  const intl = useIntl();

  const { pid } = useParams();

  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({ weldLogs: [], welds: [] });

  const fetchWeldsAndLogs = useCallback(() => {
    const unsubscribes = [];
    let snapshotsFetched = 0;

    // Temporary arrays to aggregate the data before setting the state
    const tempWeldLogs = [];
    const tempWelds = [];

    try {
      // If there are no welds in the NDT order, set fetching to false and return early
      if (isEmpty(ndtOrder?.welds)) {
        setFetching(false);
        return;
      }

      // Calculate the total number of snapshots we expect to fetch
      const totalSnapshots = ndtOrder.welds.length * 2;

      // Function to check if all snapshots have been fetched
      // Once all snapshots are fetched, we update the state with the aggregated data
      const updateFetchingStateIfAllFetched = () => {
        snapshotsFetched++;
        if (snapshotsFetched === totalSnapshots) {
          setData({ weldLogs: tempWeldLogs, welds: tempWelds });
          setFetching(false);
        }
      };

      // Loop through each weld in the NDT order to set up snapshot listeners
      ndtOrder.welds.forEach((weld) => {
        // Listen for changes in weldLogs
        const weldLogUnsubscribe = db
          .collection("projects")
          .doc(pid)
          .collection("weld-logs")
          .doc(weld.weldLogId)
          .onSnapshot((doc) => {
            const updatedWeldLog = { id: doc.id, ...doc.data() };
            const index = tempWeldLogs.findIndex((w) => w.id === doc.id);
            if (index !== -1) {
              tempWeldLogs[index] = updatedWeldLog;
            } else {
              tempWeldLogs.push(updatedWeldLog);
            }
            updateFetchingStateIfAllFetched();
          });

        // Listen for changes in welds
        const weldUnsubscribe = db
          .collection("projects")
          .doc(pid)
          .collection("weld-logs")
          .doc(weld.weldLogId)
          .collection("welds")
          .doc(weld.weldId)
          .onSnapshot((doc) => {
            const updatedWeld = { id: doc.id, ...doc.data() };
            const index = tempWelds.findIndex((w) => w.id === doc.id);
            if (index !== -1) {
              tempWelds[index] = updatedWeld;
            } else {
              tempWelds.push(updatedWeld);
            }
            updateFetchingStateIfAllFetched();
          });

        // Store the unsubscribe functions to clean up later
        unsubscribes.push(weldLogUnsubscribe, weldUnsubscribe);
      });
    } catch (error) {
      setError(error.message || intl.formatMessage(messages.unknownErrorOccurred));
      setFetching(false);
    }
    // Return the unsubscribe functions to be called on cleanup
    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [ndtOrder?.welds, pid, intl]);

  // Initiate the fetching of welds and logs when the component mounts or when ndtOrder changes
  useEffect(() => {
    const unsubscribe = fetchWeldsAndLogs();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [pid, ndtOrder, fetchWeldsAndLogs]);

  return { fetching, data, error, fetchWeldsAndLogs };
};

export default useFetchWeldsAndLogs;
