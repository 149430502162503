import React from "react";
import firebase from "firebase";
import { isEmpty } from "lodash";
import { useFormik } from "formik";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// Components
import LoadingStateCard from "../../../components/loading-state-card/loading-state-card";
import StateCard from "../../../components/state-card/state-card";
import FormFooter from "../../../components/form-footer/form-footer";

// Hooks
import useStoreProvider from "../../../common/providers/store/use-app-context";
import useFetchCollection from "../../../common/utils/use-fetch-collection-v2";
import useRemoveWeldFromNdtOrder from "../../../common/utils/use-remove-weld-from-ndt-order";

// Utilities and constants
import { db, NOTIFICATION_POSITION } from "../../../constants";
import messages from "../../../i18n/messages";

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: "100%",
  },
  formContainer: {
    marginBottom: spacing(3),
  },
  formStatusAndActionContainer: {
    paddingTop: spacing(1),
  },
  menuPaper: {
    maxHeight: 400,
  },
  progressIndicator: {
    display: "flex",
    alignItems: "center",
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
}));

const AddWeldToNdtOrderForm = ({
  projectDocumentRef,
  weldLogDocumentRef,
  weldEventToEdit,
  indexOfWeldEventToEdit,
  onEventLogged,
  onClose,
  selectedWelds,
}) => {
  const intl = useIntl();
  const batch = db.batch();
  const classes = useStyles();
  const { pid, weldLogId } = useParams();
  const { removeWeldsFromNdtOrder } = useRemoveWeldFromNdtOrder(pid);
  const {
    loggedInUser,
    loggedInUser: { uid, fname, lname },
  } = useStoreProvider();
  const { error, fetching, data: ndtOrders, fetchCollectionOnce } = useFetchCollection();

  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [ndtOrderAvailable, setNdtOrderAvailable] = React.useState(false);

  const initialValues = {
    ndtOrder: "",
    doneAt: weldEventToEdit?.doneAt
      ? format(new Date(weldEventToEdit?.doneAt), "yyyy-MM-dd'T'HH:mm")
      : format(new Date(), "yyyy-MM-dd'T'HH:mm"),
  };

  const { events = [] } =
    typeof indexOfWeldEventToEdit === "number" &&
    Array.isArray(selectedWelds) &&
    typeof selectedWelds[0] === "object" &&
    selectedWelds[0] !== null
      ? selectedWelds[0]
      : {};

  const ndtOrderCollectionRef = React.useMemo(
    () => db.collection("projects").doc(pid).collection("ndt-orders"),
    [pid]
  );

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) => {
      setSubmitting(true);
      setSubmitted(false);

      const ndtEvent = {
        what: "Added to NDT order",
        loggedAt: firebase.firestore.Timestamp.now(),
        doneAt: values.doneAt,
        doneBy: { uid, fname, lname },
        loggedBy: `${loggedInUser.fname} ${loggedInUser.lname}`,
      };

      const activitiesDocumentRef = projectDocumentRef.collection("activities").doc();

      /**
       * Remove welds from ndt order if they are already in an ndt order before adding them
       * to a new ndt order.
       */
      const weldsAlreadyInNdtOrder = selectedWelds
        .filter((weld) => weld.ndtOrder !== null && weld.ndtOrder !== undefined)
        .map((weld) => ({
          ndtOrderId: weld.ndtOrder.ndtOrderId,
          weldId: weld.id,
          weldLogId: weld.weldLogId,
        }));

      removeWeldsFromNdtOrder(weldsAlreadyInNdtOrder);

      selectedWelds.forEach((selectedWeld) => {
        let weldDocumentRef = db
          .collection("projects")
          .doc(pid)
          .collection("weld-logs")
          .doc(weldLogId)
          .collection("welds")
          .doc(selectedWeld?.id);

        batch.update(weldDocumentRef, {
          ndtOrder: values.ndtOrder,
          events:
            indexOfWeldEventToEdit > 0
              ? events
              : firebase.firestore.FieldValue.arrayUnion(ndtEvent),
        });

        // Update ndt order with welds and weld logs. It is used to get weld and weld
        // log details when viewing an ndt order.
        batch.update(ndtOrderCollectionRef.doc(values?.ndtOrder?.ndtOrderId), {
          welds: firebase.firestore.FieldValue.arrayUnion({
            weldId: selectedWeld?.id,
            weldLogId,
          }),
        });

        // Add ndt event to activities collection of a project
        batch.set(activitiesDocumentRef, {
          ...ndtEvent,
          weldLogId,
          weldId: selectedWeld?.id,
        });
      });

      // Get the weldId of the last weld in selectedWelds.
      let lastWeld = selectedWelds[selectedWelds.length - 1];

      // Update project with ndt event
      batch.update(projectDocumentRef, {
        latestActivity: { ...ndtEvent, weldLogId, weldId: lastWeld?.id },
      });

      // Update weld log with ndt event
      batch.update(weldLogDocumentRef, {
        latestActivity: { ...ndtEvent, weldLogId, weldId: lastWeld?.id },
      });

      batch
        .commit()
        .then(() => {
          resetForm({});
          setSubmitting(false);
          setSubmitted(true);
          onEventLogged(true);
          toast.success(intl.formatMessage(messages.weldAddedToNdtOrder), {
            position: NOTIFICATION_POSITION,
          });
        })
        .catch((error) => {
          setSubmitting(false);
          setErrorMessage(intl.formatMessage(messages.failedToAddWeldToNdtOrder));
          toast.error(intl.formatMessage(messages.failedToAddWeldToNdtOrder), {
            position: NOTIFICATION_POSITION,
          });
          console.log("AddWeldToNdtOrder::", error);
        });
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  const buttonProps = {
    color: "primary",
    variant: "contained",
    label: "Create NDT Order",
    to: `/projects/${pid}/ndt-orders/add`,
  };

  const renderEmptyState = () => {
    return (
      <StateCard
        title={intl.formatMessage(messages.noNdtOrderYet)}
        description={intl.formatMessage(messages.startByCreatingNDT)}
        buttonProps={buttonProps}
      />
    );
  };

  const renderErrorState = (errorMessage) => {
    return (
      <StateCard
        title={intl.formatMessage(messages.errorFetchingData)}
        description={errorMessage || intl.formatMessage(messages.anUnknownErrorOccurred)}
      />
    );
  };

  const isNdtOrderIdEmpty = React.useMemo(
    () => isEmpty(formik.values.ndtOrder),
    [formik.values.ndtOrder]
  );

  const ndtOrderMenuItems = React.useMemo(() => {
    return ndtOrderAvailable
      ? ndtOrders?.map((order) => (
          <MenuItem
            key={order.id}
            value={{ ndtOrderId: order.id, ndtOrderName: order.ndtOrderName }}
          >
            {order.ndtOrderName}
          </MenuItem>
        ))
      : "";
  }, [ndtOrderAvailable, ndtOrders]);

  const handleNdtOrderChange = (event) => {
    const selectedValue = event.target.value;
    formik.setFieldValue("ndtOrder", selectedValue);
  };

  React.useEffect(() => {
    const getNdtOrders = async () => {
      await fetchCollectionOnce(ndtOrderCollectionRef);
    };

    getNdtOrders();
    // This effect should only re-run if `ndtOrderCollectionRef` changes, 
    // which is effectively when `pid` changes.
  }, [fetchCollectionOnce, ndtOrderCollectionRef]);

  // Use a separate effect to monitor `ndtOrders` and set `ndtOrderAvailable`
  React.useEffect(() => {
    setNdtOrderAvailable(!isEmpty(ndtOrders));
  }, [ndtOrders]);

  return (
    <>
      {fetching ? (
        <LoadingStateCard />
      ) : error ? (
        renderErrorState(error.message)
      ) : ndtOrderAvailable ? (
        <>
          <DialogContent dividers>
            <form className={classes.form}>
              <Grid
                container
                spacing={4}
                className={classes.formContainer}
                justifyContent="space-between"
              >
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="doneAt"
                    label="Event timestamp"
                    type="datetime-local"
                    defaultValue={initialValues?.doneAt}
                    className={classes.textField}
                    InputLabelProps={{ shrink: true }}
                    value={formik.values?.eventTimestamp}
                    onChange={formik.handleChange}
                  />
                  <TextField
                    fullWidth
                    name="weldingCoordinator"
                    label="Welding coordinator"
                    margin="normal"
                    autoComplete="off"
                    value={`${loggedInUser.fname} ${loggedInUser.lname}`}
                    InputProps={{
                      readOnly: true,
                    }}
                  >
                    {loggedInUser.fname} {loggedInUser.lname}
                  </TextField>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <FormControl fullWidth margin="normal" size="small">
                    <InputLabel>NDT orders</InputLabel>
                    <Select
                      required
                      fullWidth
                      name="ndtOrder"
                      label="NDT orders"
                      margin="normal"
                      autoComplete="off"
                      value={formik.values?.ndtOrder || ""}
                      onChange={handleNdtOrderChange}
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {ndtOrderMenuItems}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <FormFooter
              submitting={submitting}
              submitted={submitted}
              submitButtonText={intl.formatMessage(messages.addToNdtOrder)}
              cancelButtonText={intl.formatMessage(messages.close)}
              cancelButtonProps={{
                onClick: () => onClose(),
              }}
              submitButtonProps={{
                onClick: () => handleSubmit(),
                disabled: isNdtOrderIdEmpty,
              }}
              progressMessage={intl.formatMessage(messages.addingToNdtOrder)}
              successMessage={intl.formatMessage(messages.weldAddedToNdtOrder)}
              errorMessage={errorMessage}
            />
          </DialogActions>
        </>
      ) : (
        renderEmptyState()
      )}
    </>
  );
};

export default AddWeldToNdtOrderForm;
