import React from "react";
import PropTypes from "prop-types";

// Material UI
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// Components
import BreadcrumbsNav from "./breadcrumb";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    marginBottom: spacing(4),
    [breakpoints.down("md")]: {
      marginBottom: spacing(3),
    },
  },
  [breakpoints.down("sm")]: {
    fontSize: spacing(2),
  },
}));

const Title = ({ title, breadcrumbsNav }) => {
  const theme = useTheme();
  const classes = useStyles();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const titleVariant = sm ? "subtitle1" : "subtitle2";

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      <Typography variant={titleVariant} align="left">
        {title}
      </Typography>
      {breadcrumbsNav && <BreadcrumbsNav breadcrumbsNav={breadcrumbsNav} />}
    </Box>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbsNav: PropTypes.array,
};

Title.defaultProps = {
  breadcrumbsNav: null,
};

export default Title;
