import messages from "../../../i18n/messages";

const getHeadCells = (intl) => [
  {
    id: "ndtOrderName",
    numeric: false,
    disablePadding: false,
    label: intl.formatMessage(messages.ndtOrderName),
  },
  {
    id: "inspectionMethods",
    numeric: false,
    disablePadding: false,
    label: intl.formatMessage(messages.inspectionMethods),
  },
  {
    id: "inspectionRate",
    numeric: false,
    disablePadding: false,
    label: intl.formatMessage(messages.inspectionRate),
  },
  {
    id: "progress",
    numeric: false,
    disablePadding: false,
    label: intl.formatMessage(messages.progress),
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: intl.formatMessage(messages.status),
  },
  {
    id: "lastActivity",
    numeric: false,
    disablePadding: true,
    label: intl.formatMessage(messages.lastActivity),
  },
];

export default getHeadCells;
