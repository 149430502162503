import React from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

// Material UI
import Box from "@material-ui/core/Box";

// Components
import { db } from "../../constants";
import Title from "../../components/title/title";
import WeldEvents from "./partials/weld-events";
import WeldDetailsSummary from "./partials/weld-details-summary";

// Hooks
import useStoreProvider from "../../common/providers/store/use-app-context";
import useFetchDocument from "../../common/utils/use-fetch-document";

// Utilities and constants
import { getWeldStatus, getHeatTreatmentStatus } from "../../common/utils/evaluate-weld-status";
import messages from "../../i18n/messages";

const WeldDetails = () => {
  const intl = useIntl();
  const { getDocument } = useFetchDocument();
  const { pid, weldLogId, weldId } = useParams();
  const {
    selectedProject,
    selectedWeldLog,
    selectedWeld,
    setSelectedProject,
    setSelectedWeldLog,
    setSelectedWeld,
  } = useStoreProvider();

  const [eventUpdated, setEventUpdated] = React.useState(false);

  const getProjectDetails = React.useCallback(async () => {
    const docRef = db.collection("projects").doc(pid);
    const project = await getDocument(docRef);
    setSelectedProject(project);
  }, [getDocument, pid, setSelectedProject]);

  const weldLogDocumentRef = db
    .collection("projects")
    .doc(pid)
    .collection("weld-logs")
    .doc(weldLogId);
  const weldDocumentRef = weldLogDocumentRef.collection("welds").doc(weldId);

  const getWeldLog = React.useCallback(async () => {
    const weldLog = await getDocument(weldLogDocumentRef);
    setSelectedWeldLog(weldLog);
  }, [getDocument, weldLogDocumentRef, setSelectedWeldLog]);

  const getWeld = React.useCallback(async () => {
    const weld = await getDocument(weldDocumentRef);
    setSelectedWeld(weld);
  }, [getDocument, weldDocumentRef, setSelectedWeld]);

  const weldStatus = getWeldStatus(selectedWeld, intl);
  const heatTreatmentStatus = getHeatTreatmentStatus(selectedWeld, intl);
  const breadcrumbsNav = [
    { label: "Projects", to: "/" },
    { label: selectedProject?.projectName, to: `/projects/${pid}` },
    { label: "Weld logs", to: `/projects/${pid}/weld-logs` },
    {
      label: selectedWeldLog?.weldLogName,
      to: `/projects/${pid}/weld-logs/${selectedWeldLog?.id}`,
    },
    {
      label: selectedWeld?.weldNumber,
      to: `/projects/${pid}/weld-logs/${selectedWeldLog?.id}/${selectedWeld?.id}`,
    },
  ];

  React.useEffect(() => {
    if (!selectedProject) getProjectDetails();
    if (!selectedWeldLog) getWeldLog();
    if (!selectedWeld || eventUpdated) {
      getWeld();
      setEventUpdated(false);
    }
  }, [
    eventUpdated,
    selectedProject,
    selectedWeldLog,
    selectedWeld,
    getProjectDetails,
    getWeldLog,
    getWeld,
  ]);

  return (
    <Box width="100%">
      <Title title={intl.formatMessage(messages.weldDetails)} breadcrumbsNav={breadcrumbsNav} />
      <WeldDetailsSummary
        weld={selectedWeld}
        weldStatus={weldStatus}
        heatTreatmentStatus={heatTreatmentStatus}
        setEventUpdated={setEventUpdated}
      />
      <WeldEvents />
    </Box>
  );
};

export default WeldDetails;
