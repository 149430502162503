import React from "react";
import firebase from "firebase";
import { useIntl } from "react-intl";

import { useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik, FormikProvider } from "formik";
import { isEmpty } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";

import CustomCard from "../../../components/custom-card/custom-card";
import Title from "../../../components/title/title";
import FormFooter from "../../../components/form-footer/form-footer";
import NdtRequirements from "./ndt-requirements";
import ndtFormValidationSchema from "./ndt-order-form-validation";
import useStoreProvider from "../../../common/providers/store/use-app-context";
import messages from "../../../i18n/messages";

import { db, NOTIFICATION_POSITION, NDT_ORDER_STATUSES } from "../../../constants";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  form: {
    width: "100%",
    marginTop: spacing(5),
  },
  formContainer: {
    marginBottom: spacing(3),
  },
  gridItem: {
    [breakpoints.down("md")]: {
      padding: `${spacing(0, 4)} !important`,
    },
  },
  documentIcon: {
    fontSize: spacing(2.4),
  },
}));

const NdtOrderForm = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { pid } = useParams();
  const { loggedInUser } = useStoreProvider();

  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const location = useLocation();
  const ndtOrderData = location?.state?.ndtOrderData ? location?.state?.ndtOrderData : {};

  const ndtOrderCollectionRef = db.collection("projects").doc(pid).collection("ndt-orders");

  let ndtOrderId = ndtOrderCollectionRef.doc().id;

  let initialValues = {
    id: "",
    ndtOrderName: "",
    ndtOrderMessage: "",
    status: "",
    ndtOrderStatus: NDT_ORDER_STATUSES.compiling.status,
    createdBy: `${loggedInUser.fname} ${loggedInUser.lname}`,
    createdByUid: loggedInUser?.uid,
    ndtRequirements: {
      inspectionRates: {},
    },
  };

  // This happens when editing a project
  if (!isEmpty(ndtOrderData)) {
    initialValues = ndtOrderData;
    ndtOrderId = ndtOrderData?.id;
  }

  const successMessage = intl.formatMessage(messages.ndtCreatedSuccessfully);

  const formik = useFormik({
    initialValues,
    validationSchema: ndtFormValidationSchema(intl),
    onSubmit: (values, { resetForm }) => {
      setSubmitting(true);
      setSubmitted(false);

      ndtOrderCollectionRef
        .doc(ndtOrderId)
        .set({
          ...values,
          ...(isEmpty(ndtOrderData) && {
            id: ndtOrderId,
            ndtOrderStatus: NDT_ORDER_STATUSES.compiling.status,
            createdAt: firebase.firestore.Timestamp.now(),
          }),
          ...(!isEmpty(ndtOrderData) && {
            updatedAt: firebase.firestore.Timestamp.now(),
          }),
        })
        .then(() => {
          resetForm({});
          setSubmitting(false);
          setSubmitted(true);
          toast.success(successMessage, {
            position: NOTIFICATION_POSITION,
          });
        })
        .catch((error) => {
          const errorMessage = "Something went wrong while adding NDT order";
          setSubmitting(false);
          setErrorMessage(errorMessage);
          toast.error(errorMessage, {
            position: NOTIFICATION_POSITION,
          });
          console.log("AddNDTOrder::", error);
        });
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  const title = !isEmpty(ndtOrderData)
    ? intl.formatMessage(messages.save)
    : intl.formatMessage(messages.addNdtOrder);

  return (
    <Box width="100%">
      <Title title={title} />
      <CustomCard
        footer={
          <FormFooter
            submitting={submitting}
            submitted={submitted}
            submitButtonText={title}
            cancelButtonText={intl.formatMessage(messages.close)}
            handleSubmit={handleSubmit}
            cancelButtonProps={{
              to: `/projects/${pid}/ndt-orders`,
            }}
            progressMessage={intl.formatMessage(messages.creatingNdtOrder)}
            successMessage={successMessage}
            errorMessage={errorMessage}
          />
        }
      >
        <FormikProvider value={formik}>
          <form className={classes.form}>
            <Grid container spacing={5} className={classes.formContainer}>
              <Grid item className={classes.gridItem} xs={12} md={3}>
                <TextField
                  fullWidth
                  autoFocus
                  margin="normal"
                  id="ndtOrderName"
                  label={intl.formatMessage(messages.ndtOrderName)}
                  name="ndtOrderName"
                  autoComplete="off"
                  value={formik.values.ndtOrderName}
                  onChange={formik.handleChange}
                  error={formik.touched.ndtOrderName && Boolean(formik.errors.ndtOrderName)}
                  helperText={formik.touched.ndtOrderName && formik.errors.ndtOrderName}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={12} md={3}>
                <TextField
                  fullWidth
                  margin="normal"
                  id="ndtOrderMessage"
                  label={intl.formatMessage(messages.ndtOrderMessage)}
                  name="ndtOrderMessage"
                  autoComplete="off"
                  value={formik.values.ndtOrderMessage}
                  onChange={formik.handleChange}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                />
              </Grid>
              <Grid container item className={classes.gridItem} xs={12} md={6}>
                <NdtRequirements formik={formik} />
              </Grid>
            </Grid>
          </form>
        </FormikProvider>
      </CustomCard>
    </Box>
  );
};

export default NdtOrderForm;
