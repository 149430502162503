import React, { useState } from "react";

import AppContext from "./app-context";

const StoreProvider = ({ children }) => {
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectParticipants, setProjectParticipants] = useState(null);
  const [selectedProjectEvents, setSelectedProjectEvents] = useState(null);
  const [selectedWeldLog, setSelectedWeldLog] = useState(null);
  const [weldLogCollection, setWeldLogCollection] = useState(null);
  const [selectedWeld, setSelectedWeld] = useState(null);
  const [selectedNdtOrder, setSelectedNdtOrder] = useState(null);
  const [ndtOrderCollection, setNdtOrderCollection] = useState(null);

  const contextValue = {
    loggedInUser,
    projectParticipants,
    selectedProjectEvents,
    selectedProject,
    weldLogCollection,
    selectedWeldLog,
    selectedWeld,
    selectedNdtOrder,
    ndtOrderCollection,
    setLoggedInUser,
    setSelectedProject,
    setProjectParticipants,
    setSelectedProjectEvents,
    setWeldLogCollection,
    setSelectedWeldLog,
    setSelectedWeld,
    setSelectedNdtOrder,
    setNdtOrderCollection,
  };

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export default StoreProvider;
