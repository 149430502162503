import React from "react";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { useIntl } from "react-intl";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { CgOpenCollective } from "react-icons/cg";
import { FaRegCommentDots } from "react-icons/fa";

// Components
import LoadingStateCard from "../../../components/loading-state-card/loading-state-card";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import CustomCard from "../../../components/custom-card/custom-card";
import RoleAccess from "../../../components/role-access/role-access";
import StateCard from "../../../components/state-card/state-card";
import NdtInspectionStatus from "./ndt-inspection-status";
import NdtInspectionProgress from "./ndt-inspection-progress";
import CustomCircularProgress from "./ndt-inspection-progress-brief";

// Hooks
import useUpdateDocument from "../../../common/utils/use-update-document";

// Constants and utilities
import { USER_ROLES, NDT_ORDER_STATUSES } from "../../../constants";
import { extractNdtDetails } from "../utils/calculate-ndt-inspections";
import { formatDate } from "../../../common/utils/format-date";
import messages from "../../../i18n/messages";

const useStyles = makeStyles(({ spacing, palette }) => ({
  projectDetailItems: {
    flex: "1 1 0px",
  },
  header: {
    display: "flex",
    placeContent: "space-between",
    alignItems: "center",
  },
  compilingIcon: {
    fontSize: spacing(4),
    color: palette.secondary.light,
  },
  containerMarginBottom: {
    marginBottom: spacing(2),
  },
  noProgressYetContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  gridItem: {
    padding: spacing(2),
    height: "100%",
    borderRadius: spacing(1),
    background: "rgb(242 246 251)",
  },
  messageIcon: {
    fontSize: spacing(3),
    marginRight: spacing(1),
    color: palette.primary.main,
  },
}));

const NdtOrderDetailsContent = ({
  fetching,
  error,
  ndtOrder,
  ndtOrderDocumentRef,
  overallCompletion,
  isAnyMethodRejected,
  completedInspectionPerNdtMethod,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const { updateDocument } = useUpdateDocument();

  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [showDetailedStatus, setShowDetailedStatus] = React.useState(false);

  const { requiredNdtMethods } = extractNdtDetails(ndtOrder);

  const handleNdtCompletion = (ndtOrderStatus) => {
    if (isAnyMethodRejected) {
      setConfirmOpen(true);
    } else {
      updateNdtOrderDetails(ndtOrderStatus);
    }
  };

  const updateNdtOrderDetails = React.useCallback(
    (ndtOrderStatus) => {
      updateDocument({
        documentRef: ndtOrderDocumentRef,
        payload: { ndtOrderStatus: ndtOrderStatus },
      });
    },
    [ndtOrderDocumentRef, updateDocument]
  );

  const handleShowDetailedStatus = (event) => {
    setShowDetailedStatus(event.target.checked);
  };

  return (
    <CustomCard
      header={
        <Box className={classes.header}>
          <Typography variant="subtitle1">{intl.formatMessage(messages.summary)}</Typography>
          {ndtOrder?.ndtOrderStatus === NDT_ORDER_STATUSES.compiling.status && (
            <RoleAccess
              roles={[
                USER_ROLES.ADMIN,
                USER_ROLES.WELDING_COORDINATOR,
                USER_ROLES.RESPONSIBLE_WELDING_COORDINATOR,
              ]}
            >
              <Button
                className={classes.editButton}
                color="primary"
                variant="contained"
                disableElevation
                disabled={isEmpty(ndtOrder?.welds)}
                onClick={() => updateNdtOrderDetails(NDT_ORDER_STATUSES.inProgress.status)}
              >
                {intl.formatMessage(messages.submitForInspection)}
              </Button>
            </RoleAccess>
          )}
          {!fetching && !error && (
            <>
              {ndtOrder?.ndtOrderStatus === NDT_ORDER_STATUSES.inProgress.status && (
                <RoleAccess roles={[USER_ROLES.NDT_OPERATOR]}>
                  <Button
                    className={classes.editButton}
                    color="primary"
                    variant="contained"
                    disableElevation
                    disabled={isEmpty(ndtOrder?.welds)}
                    onClick={() => handleNdtCompletion(NDT_ORDER_STATUSES.approved.status)}
                  >
                    {intl.formatMessage(messages.completeInspection)}
                  </Button>
                </RoleAccess>
              )}
            </>
          )}
        </Box>
      }
    >
      {error ? (
        <StateCard
          border={false}
          title={intl.formatMessage(messages.errorFetchingData)}
          description={error?.message || intl.formatMessage(messages.unknownErrorOccurred)}
        />
      ) : fetching ? (
        <LoadingStateCard border={false} />
      ) : (
        <>
          <Grid container spacing={2} className={classes.containerMarginBottom}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Paper elevation={0} className={classes.gridItem}>
                <Grid container direction="column" spacing={2}>
                  <Grid container item spacing={0}>
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        {intl.formatMessage(messages.ndtOrderName)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">{ndtOrder?.ndtOrderName}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={0}>
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        {intl.formatMessage(messages.createdBy)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        {ndtOrder?.createdBy || intl.formatMessage(messages.na)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={0}>
                    <Grid item xs={12}>
                      <Typography variant="caption" style={{ flexGrow: 1 }}>
                        {intl.formatMessage(messages.ndtOrderedOn)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        {formatDate(ndtOrder?.createdAt, intl)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Paper elevation={0} className={classes.gridItem}>
                <Grid container direction="column" spacing={2}>
                  <Grid container item spacing={0}>
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        {intl.formatMessage(messages.ndtStatus)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <NdtInspectionStatus ndtOrder={ndtOrder} />
                    </Grid>
                  </Grid>

                  <Grid container item spacing={0}>
                    <Grid item xs={12} spacing={0}>
                      <Typography variant="caption" style={{ flexGrow: 1 }}>
                        {intl.formatMessage(messages.inspectionMethods)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {requiredNdtMethods.map((name) => {
                        return (
                          <Typography variant="body2" style={{ marginBottom: "10px" }}>
                            {intl.formatMessage(messages[name])}
                          </Typography>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={5}>
              <Paper elevation={0} className={classes.gridItem}>
                {completedInspectionPerNdtMethod ? (
                  !showDetailedStatus ? (
                    <CustomCircularProgress
                      ndtOrder={ndtOrder}
                      overallCompletion={overallCompletion}
                      completedInspectionPerNdtMethod={completedInspectionPerNdtMethod}
                    />
                  ) : (
                    <NdtInspectionProgress ndtProgressData={completedInspectionPerNdtMethod} />
                  )
                ) : (
                  <Box className={classes.noProgressYetContainer}>
                    <CgOpenCollective className={classes.compilingIcon} />
                    <Typography variant="caption">
                      {intl.formatMessage(messages.noNdtProgressYet)}
                    </Typography>
                  </Box>
                )}
              </Paper>
            </Grid>
          </Grid>
          {ndtOrder?.ndtOrderMessage && (
            <Grid
              container
              item
              xs={12}
              className={clsx(classes.gridItem, classes.containerMarginBottom)}
              justifyContent="left-center"
            >
              <FaRegCommentDots className={classes.messageIcon} />
              <Typography variant="body2">{ndtOrder?.ndtOrderMessage}</Typography>
            </Grid>
          )}
          <FormControlLabel
            control={<Switch onChange={handleShowDetailedStatus} />}
            label={intl.formatMessage(messages.showNdtDetailedStatus)}
          />
        </>
      )}
      <ConfirmDialog
        title={intl.formatMessage(messages.completeInspection)}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() =>
          updateNdtOrderDetails(NDT_ORDER_STATUSES.approved.status)
        }
      >
        {intl.formatMessage(messages.confirmCompleteNdtInspection)}
      </ConfirmDialog>
    </CustomCard>
  );
};

export default NdtOrderDetailsContent;
