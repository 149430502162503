import React from "react";
import PropTypes from "prop-types";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(() => ({
  roundedProgressBar: {
    borderRadius: 10,
    paddingBottom: 0,
  },
}));

const ProgressIndicatorWithLabel = ({ className, rounded, value }) => {
  const classes = useStyles();

  // Inline style for the animated text
  const textStyle = {
    fontSize: "9px",
    fontWeight: "bold",
    textAlign: "right",
    color:"#2E81FF"
  };

  return (
    <Box className={className} width="100%">
      <Box display="flex" flexDirection="column">
        <Typography variant="caption" style={textStyle}>
          {value}%
        </Typography>
        <LinearProgress
          classes={{ root: rounded ? classes.roundedProgressBar : "" }}
          variant="determinate"
          value={value}
        />
      </Box>
    </Box>
  );
};

ProgressIndicatorWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function ProgressIndicator({ className, progress, rounded }) {
  return <ProgressIndicatorWithLabel value={progress} className={className} rounded={rounded} />;
}
