import React from "react";
import { useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

// Components
import GenericTableHead from "../../../components/generic-table/generic-table-head";
import GenericTableToolbar from "../../../components/generic-table/generic-table-toolbar";
import LoadingStateCard from "../../../components/loading-state-card/loading-state-card";
import NdtInspectionStatus from "./ndt-inspection-status";

// Hooks
import useDeleteDocuments from "../../../common/utils/use-delete-documents";
import useStoreProvider from "../../../common/providers/store/use-app-context";
import useUpdateDocument from "../../../common/utils/use-update-document";
import useTablePaginationSettings from "../../../common/utils/use-table-pagination-settings";

// Utilities and constants
import extractEventDetails from "../../../common/utils/extract-event-details";
import { extractNdtDetails } from "../utils/calculate-ndt-inspections";
import { formatNdtResult } from "../utils/calculate-ndt-inspections";
import getHeadCells from "./ndt-orders-table-head-cells";
import messages from "../../../i18n/messages";
import { db } from "../../../constants";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
    boxShadow: "none",
    border: "1px solid #e3e3e3",
  },
  table: {
    minWidth: 750,
  },
  tableCell: {
    whiteSpace: "nowrap",
  },
  actions: {
    marginLeft: 0,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function NdtOrdersTable({ fetching, ndtOrders, buttonProps }) {
  const intl = useIntl();
  const classes = useStyles();
  const { pid } = useParams();
  const { setSelectedNdtOrder } = useStoreProvider();
  const { rowsPerPageOptions, rowsPerPage, setRowsPerPage } = useTablePaginationSettings(ndtOrders);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("createdAt.seconds");
  const [selectedNdtOrderIds, setSelectedNdtOrderIds] = React.useState([]);
  // These are the collection of weld to update when selected NDT orders are deleted
  const [selectedWeldsOnNdtDelete, setSelectedWeldsOnNdtDelete] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const deleteSuccessMessage = intl.formatMessage(messages.ndtOrderDeleted);
  const deleteFailureMessage = intl.formatMessage(messages.failedToDeleteNdtOrder);
  const dialogMessages = {
    title: intl.formatMessage(messages.deleteNdtOrders),

    content: intl.formatMessage(messages.areYouSureYouWantToDeleteNdt, {
      count: selectedNdtOrderIds.length,
      orderText:
        selectedNdtOrderIds.length > 1
          ? intl.formatMessage(messages.lowerCaseOrders)
          : intl.formatMessage(messages.lowerCaseOrder),
    }),
  };
  const ndtOrderCollectionRef = db.collection("projects").doc(pid).collection("ndt-orders");

  const { updateDocumentsBatch } = useUpdateDocument();

  const { deleteDocuments } = useDeleteDocuments(
    ndtOrderCollectionRef,
    selectedNdtOrderIds,
    setSelectedNdtOrderIds,
    deleteSuccessMessage,
    deleteFailureMessage
  );

  /**
   * Update the welds when the NDT order is deleted, i.e the ndtOrderId should be set to null
   */
  const updateWelds = () => {
    const documentRefs = selectedWeldsOnNdtDelete.map((weld) => {
      return db
        .collection("projects")
        .doc(pid)
        .collection("weld-logs")
        .doc(weld.weldLogId)
        .collection("welds")
        .doc(weld.weldId);
    });

    updateDocumentsBatch({ documentRefs, payload: { ndtOrder: null } });
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = ndtOrders.map((n) => n.id);
      const selectedWelds = ndtOrders.flatMap((ndtOrder) => ndtOrder?.welds || []);
      setSelectedNdtOrderIds(newSelected);
      setSelectedWeldsOnNdtDelete(selectedWelds);

      return;
    }
    setSelectedNdtOrderIds([]);
  };

  const handleTableRowClick = (selectedNdtOrder) => {
    const selectedIndex = selectedNdtOrderIds.indexOf(selectedNdtOrder.id);
    let newSelectedNdtOrders = [];

    if (selectedIndex === -1) {
      newSelectedNdtOrders = newSelectedNdtOrders.concat(selectedNdtOrderIds, selectedNdtOrder.id);
    } else if (selectedIndex === 0) {
      newSelectedNdtOrders = newSelectedNdtOrders.concat(selectedNdtOrderIds.slice(1));
    } else if (selectedIndex === selectedNdtOrderIds.length - 1) {
      newSelectedNdtOrders = newSelectedNdtOrders.concat(selectedNdtOrderIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedNdtOrders = newSelectedNdtOrders.concat(
        selectedNdtOrderIds.slice(0, selectedIndex),
        selectedNdtOrderIds.slice(selectedIndex + 1)
      );
    }

    setSelectedNdtOrderIds(newSelectedNdtOrders);

    // selectedWelds the welds under each selected NDT order. The welds are needed
    // to update the welds when the NDT order is deleted, i.e the ndtOrderId should be set to null
    // when the corresponding NDT order is deleted
    const selectedWelds = ndtOrders.flatMap((ndtOrder) =>
      newSelectedNdtOrders.includes(ndtOrder.id) ? ndtOrder.welds || [] : []
    );
    setSelectedWeldsOnNdtDelete(selectedWelds);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteNdtOrders = () => {
    deleteDocuments();
    updateWelds();
    setSelectedNdtOrderIds([]);
  };

  const isSelected = (name) => selectedNdtOrderIds.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, ndtOrders.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      {fetching ? (
        <LoadingStateCard />
      ) : (
        <Paper className={classes.paper}>
          <GenericTableToolbar
            classes={classes}
            buttonProps={buttonProps}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            onDialogConfirm={handleDeleteNdtOrders}
            numberOfSelectedItems={selectedNdtOrderIds.length}
            dialogMessages={dialogMessages}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <GenericTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                headCells={getHeadCells(intl)}
                rowCount={ndtOrders.length}
                numberOfSelectedItems={selectedNdtOrderIds.length}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {stableSort(ndtOrders, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((ndtOrder, _index) => {
                    const isItemSelected = isSelected(ndtOrder.id);

                    const { inspectionMethods, ndtInspectionRates } = extractNdtDetails(ndtOrder);
                    const { eventTime, eventOwner } = extractEventDetails(ndtOrder, intl);
                    const formattedNdtResult = formatNdtResult(ndtOrder);
                    const overallCompletion = formattedNdtResult?.overallCompletion || {};

                    const { total = 0, completed = 0 } = overallCompletion;

                    const inspectionProgress = total ? `${completed}/${total}` : null;
                    return (
                      <TableRow
                        hover
                        key={ndtOrder.id}
                        tabIndex={-1}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        onClick={() => handleTableRowClick(ndtOrder)}
                      >
                        <TableCell className={classes.tableCell} padding="checkbox">
                          <Checkbox checked={isItemSelected} />
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          scope="row"
                          component={Link}
                          style={{ whiteSpace: "nowrap" }}
                          onClick={() => setSelectedNdtOrder(ndtOrder)}
                          to={`/projects/${pid}/ndt-orders/${ndtOrder.id}/details`}
                        >
                          {ndtOrder.ndtOrderName}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {inspectionMethods}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          align="left"
                          style={{ maxWidth: 150, overflow: "hidden" }}
                        >
                          <Typography>{ndtInspectionRates}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {inspectionProgress || intl.formatMessage(messages.na)}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          <NdtInspectionStatus ndtOrder={ndtOrder} />
                        </TableCell>
                        <TableCell
                          align="left"
                          padding="none"
                          className={classes.tableCell}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <Typography>{eventTime}</Typography>
                          {eventTime && eventTime !== "n/a" && (
                            <Typography variant="caption">By {eventOwner}</Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell className={classes.tableCell} colSpan={12} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            classes={{ actions: classes.actions }}
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={ndtOrders.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  );
}
